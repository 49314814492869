// src/utils/caching/indexedDBCache.js

const DB_NAME = 'DrawingCacheDB';
const DB_VERSION = 5; // Повышать для автоматического обновления схемы
const STORE_NAME = 'layerCaches';
const TEXTURE_STORE_NAME = 'textureCaches'; // Новый магазин для текстур

// Максимальное количество кешей для одного слоя
const MAX_CACHES_PER_LAYER = 5;
// Время хранения кешей (например, 7 дней)
const CACHE_RETENTION_TIME = 7 * 24 * 60 * 60 * 1000;

/**
 * Открывает (или создаёт) IndexedDB.
 * Если база существует в старой версии, onupgradeneeded выполнится автоматически.
 * @returns {Promise<IDBDatabase>}
 */
function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onerror = () => reject(request.error);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Создаем или обновляем магазин layerCaches
      let layerStore;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        layerStore = db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      } else {
        layerStore = request.transaction.objectStore(STORE_NAME);
      }
      if (!layerStore.indexNames.contains('game_layer_cache')) {
        layerStore.createIndex('game_layer_cache', ['gameId', 'layerId', 'cacheId'], { unique: false });
      }
      if (!layerStore.indexNames.contains('timestamp')) {
        layerStore.createIndex('timestamp', 'timestamp', { unique: false });
      }

      // Создаем или обновляем магазин textureCaches
      let textureStore;
      if (!db.objectStoreNames.contains(TEXTURE_STORE_NAME)) {
        textureStore = db.createObjectStore(TEXTURE_STORE_NAME, { keyPath: 'id', autoIncrement: true });
      } else {
        textureStore = request.transaction.objectStore(TEXTURE_STORE_NAME);
      }
      if (!textureStore.indexNames.contains('brush_texture_type')) {
        textureStore.createIndex('brush_texture_type', ['brushName', 'textureName', 'type'], { unique: true });
      }
      if (!textureStore.indexNames.contains('timestamp')) {
        textureStore.createIndex('timestamp', 'timestamp', { unique: false });
      }
    };
    request.onsuccess = () => resolve(request.result);
  });
}

/**
 * Сохраняет новый кеш для слоя.
 * Все параметры приводятся к нужным типам, чтобы всё работало автоматически.
 * @param {string} gameId - Идентификатор рисунка (игры).
 * @param {string} layerId - Идентификатор слоя.
 * @param {number} cacheId - Идентификатор кеша (например, lastStroke.time).
 * @param {string} blob - PNG-данные offscreen‑канваса.
 * @returns {Promise<number>} - ID сохранённой записи.
 */
async function addLayerCache({gameId, layerId, cacheId, blob, activeLayerId}) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const record = {
      gameId: String(gameId),
      layerId: String(layerId),
      cacheId: Number(cacheId),
      timestamp: Date.now(),
      data: blob,
    };
    const request = store.add(record);
    request.onerror = () => reject(request.error);
    request.onsuccess = async () => {
      try {
        await enforceMaxGameCaches({ gameId, activeLayerId });
        resolve(request.result);
      } catch (e) {
        reject(e);
      }
    };
  });
}

/**
 * Получает все кеши для указанного рисунка (gameId).
 * Работает автоматически без вмешательства пользователя.
 * @param {string} gameId 
 * @returns {Promise<Array>} - Массив объектов кеша.
 */

async function getCachesForGame(gameId) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    let index;
    try {
      index = store.index('game_layer_cache');
    } catch (error) {
      return reject(error);
    }
    // Диапазон ключей: от [gameId] до [gameId, "\uffff", Number.MAX_SAFE_INTEGER]
    const keyRange = IDBKeyRange.bound(
      [String(gameId)],
      [String(gameId), "\uffff", Number.MAX_SAFE_INTEGER]
    );
    const request = index.getAll(keyRange);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);
  });
}


async function enforceMaxGameCaches({ gameId, activeLayerId }) {
  // Получаем все кеши для данной игры
  const allCaches = await getCachesForGame(gameId);
  
  // Группируем кеши по layerId
  const cachesByLayer = {};
  allCaches.forEach(record => {
    const layerId = record.layerId;
    if (!cachesByLayer[layerId]) {
      cachesByLayer[layerId] = [];
    }
    cachesByLayer[layerId].push(record);
  });

  // Список всех кэшей с их timestamp для определения 5 последних глобально
  const allTimestamps = allCaches.map(record => ({
    id: record.id,
    layerId: record.layerId,
    timestamp: record.timestamp
  }));

  // 1. Определяем защищённые кэши на уровне слоёв (по времени создания, timestamp)
  for (const layerId in cachesByLayer) {
    // Сортируем кеши по убыванию timestamp (самый новый первым)
    cachesByLayer[layerId].sort((a, b) => b.timestamp - a.timestamp);
    
    // Определяем, сколько кэшей защищать: 5 для активного слоя, 1 для остальных
    const isActiveLayer = activeLayerId && layerId === activeLayerId;
    const protectedCount = isActiveLayer ? Math.min(5, cachesByLayer[layerId].length) : 1;
    
    // Помечаем первые protectedCount элементов как защищённые на уровне слоя
    cachesByLayer[layerId].forEach((record, index) => {
      record._protectedByLayer = index < protectedCount;
    });
  }

  // 2. Определяем 5 последних кэшей глобально (по timestamp)
  if (allTimestamps.length > 0) {
    // Сортируем все кэши по убыванию timestamp
    allTimestamps.sort((a, b) => b.timestamp - a.timestamp);
    // Берем 5 последних (или меньше, если кэшей меньше 5)
    const globalProtectedTimestamps = new Set(
      allTimestamps.slice(0, Math.min(5, allTimestamps.length)).map(t => t.id)
    );

    // Помечаем кэши, входящие в 5 последних, как защищённые глобально
    for (const layerId in cachesByLayer) {
      cachesByLayer[layerId].forEach(record => {
        if (globalProtectedTimestamps.has(record.id)) {
          record._protectedGlobally = true;
        } else {
          record._protectedGlobally = false;
        }
      });
    }
  }

  // 3. Определяем окончательную защиту: кэш защищён, если он защищён либо по слою, либо глобально
  for (const layerId in cachesByLayer) {
    cachesByLayer[layerId].forEach(record => {
      record._protected = record._protectedByLayer || record._protectedGlobally;
    });
  }

  // 4. Общее количество кешей и число уникальных слоёв
  const totalCaches = allCaches.length;
  const layersCount = Object.keys(cachesByLayer).length;
  
  // Лимит для игры: если слоёв ≤ 20, максимум 20 кешей, иначе допускаем минимум по 1 на слой
  const allowedTotal = layersCount <= 20 ? 20 : layersCount;
  
  // Если уже кешей меньше или равно лимиту – ничего не делаем
  if (totalCaches <= allowedTotal) return;
  
  // Вычисляем, сколько кешей нужно удалить
  const deleteCount = totalCaches - allowedTotal;
  
  // 5. Собираем все удаляемые (не защищённые) кеши
  let deletable = [];
  for (const layerId in cachesByLayer) {
    const records = cachesByLayer[layerId];
    records.forEach(record => {
      if (!record._protected) {
        deletable.push(record);
      }
    });
  }
  
  // Сортируем удаляемые кеши по возрастанию timestamp (старые первыми)
  deletable.sort((a, b) => a.timestamp - b.timestamp);
  
  // Выбираем ровно deleteCount самых старых записей для удаления
  const toDelete = deletable.slice(0, deleteCount);
  
  // 6. Удаляем выбранные записи из базы
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    Promise.all(
      toDelete.map(record => {
        return new Promise((res, rej) => {
          const req = store.delete(record.id);
          req.onsuccess = () => res();
          req.onerror = () => rej(req.error);
        });
      })
    )
      .then(resolve)
      .catch(reject);
  });
}


/**
 * Очищает все кеши, созданные ранее, чем CACHE_RETENTION_TIME.
 * Работает автоматически, не требуя от пользователя никаких действий.
 * @returns {Promise<void>}
 */
async function clearOldCachesFromIDB() {
  const cutoffTime = Date.now() - CACHE_RETENTION_TIME;
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const index = store.index('timestamp');
    const keyRange = IDBKeyRange.upperBound(cutoffTime);
    const request = index.openCursor(keyRange);
    request.onerror = () => reject(request.error);
    request.onsuccess = function () {
      const cursor = request.result;
      if (cursor) {
        store.delete(cursor.primaryKey);
        cursor.continue();
      } else {
        resolve();
      }
    };
  });
}

async function loadAllLayersFromIDB(gameId, layerInfo, layerCaches) {
  const records = await getCachesForGame(gameId);

  window.logPerformance?.(`🗂 Local cache load started`)

  let loadedCount = 0;

  for (const record of records) {
    const lId = record.layerId;
    try {
      const offscreenCanvas = await createCanvasFromBuffer(record.data);
      if (!layerCaches[lId]) {
        layerCaches[lId] = new Map();
      }
      layerCaches[lId].set(record.cacheId, {
        time: Date.now(),
        canvas: offscreenCanvas,
      });
      loadedCount ++;
    } catch (err) {
      console.error(`Ошибка загрузки кеша для layerId=${lId}, record.id=${record.id}:`, err);
    }
  }

  window.logPerformance?.(`🗂 Loaded ${loadedCount} local layer caches`)
  return layerCaches;
}

function createCanvasFromBuffer(buffer) {
  const blob = new Blob([buffer], { type: 'image/png' }); 
  return createImageBitmap(blob).then((imageBitmap) => {
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0);
    return canvas;
  });
}


async function deleteLayerCaches(gameId, layerId) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const index = store.index('game_layer_cache');
    
    // Диапазон ключей: все записи для [gameId, layerId]
    const keyRange = IDBKeyRange.bound(
      [String(gameId), String(layerId)],
      [String(gameId), String(layerId), "\uffff"]
    );
    
    const request = index.openCursor(keyRange);
    
    request.onerror = () => reject(request.error);
    
    request.onsuccess = function () {
      const cursor = request.result;
      if (cursor) {
        // Удаляем текущую запись и переходим к следующей
        store.delete(cursor.primaryKey);
        cursor.continue();
      } else {
        resolve();
      }
    };
  });
}




async function addTextureCache({ brushName, textureName, type, blob }) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(TEXTURE_STORE_NAME, 'readwrite');
    const store = transaction.objectStore(TEXTURE_STORE_NAME);
    const record = {
      brushName: String(brushName),
      textureName: String(textureName),
      type: String(type),
      timestamp: Date.now(),
      data: blob,
    };
    const index = store.index('brush_texture_type');
    const keyRange = IDBKeyRange.only([String(brushName), String(textureName), String(type)]);
    index.get(keyRange).onsuccess = (event) => {
      if (event.target.result) {
        const updateRequest = store.put({ ...record, id: event.target.result.id });
        updateRequest.onerror = () => reject(updateRequest.error);
        updateRequest.onsuccess = () => resolve(updateRequest.result);
      } else {
        const addRequest = store.add(record);
        addRequest.onerror = () => reject(addRequest.error);
        addRequest.onsuccess = () => resolve(addRequest.result);
      }
    };
  });
}

async function getTextureFromCache({ brushName, textureName, type }) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(TEXTURE_STORE_NAME, 'readonly');
    const store = transaction.objectStore(TEXTURE_STORE_NAME);
    const index = store.index('brush_texture_type');
    const keyRange = IDBKeyRange.only([String(brushName), String(textureName), String(type)]);
    const request = index.get(keyRange);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => {
      const record = request.result;
      resolve(record ? record.data : null);
    };
  });
}

async function getTexturesForBrush(brushName) {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(TEXTURE_STORE_NAME, 'readonly');
    const store = transaction.objectStore(TEXTURE_STORE_NAME);
    const index = store.index('brush_texture_type');
    const keyRange = IDBKeyRange.bound(
      [String(brushName)],
      [String(brushName), '\uffff', '\uffff']
    );
    const request = index.getAll(keyRange);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);
  });
}

async function clearOldTexturesFromIDB(retentionTime = CACHE_RETENTION_TIME) {
  const cutoffTime = Date.now() - retentionTime;
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(TEXTURE_STORE_NAME, 'readwrite');
    const store = transaction.objectStore(TEXTURE_STORE_NAME);
    const index = store.index('timestamp');
    const keyRange = IDBKeyRange.upperBound(cutoffTime);
    const request = index.openCursor(keyRange);
    request.onerror = () => reject(request.error);
    request.onsuccess = function () {
      const cursor = request.result;
      if (cursor) {
        store.delete(cursor.primaryKey);
        cursor.continue();
      } else {
        resolve();
      }
    };
  });
}


export {
  openDatabase,
  addLayerCache,
  getCachesForGame,
  clearOldCachesFromIDB,
  loadAllLayersFromIDB,
  deleteLayerCaches,
  createCanvasFromBuffer,
  MAX_CACHES_PER_LAYER,
  CACHE_RETENTION_TIME,

  addTextureCache,
  getTextureFromCache,
  getTexturesForBrush,
  clearOldTexturesFromIDB,
};
