// rembrandt.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 
const defaultBrushSettings = brushDefaults.rembrandt || {};

export async function drawRembrandtStroke(stroke, context, params) {

  let {
    color,
    gradientColor,
    lineWidth,
    points,
    sets = {},
  } = stroke;

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  let { 
    cutEdges = false,
    shadowStrength = 0.5,
    density = 0.5,
    tapering = 0.5,
  } = brushSettings;

  if (density < 0.5) { density = Math.pow(density, 2) * 2; }

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  color = chromaColor.alpha(1).hex();

  const darkenedColor = chromaColor.darken(12 * shadowStrength).hex();

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  const π = Math.PI;
  const maxBristles = 400;

  let bristleCount = Math.round((lineWidth / 8) ** 2 * π * density * 8);
  let bristleWidth = 0.7;

  if (bristleCount > maxBristles) {
    bristleWidth *= Math.sqrt(2 * bristleCount / maxBristles)
    bristleCount = maxBristles;
  }

  const actualStroke = Object.assign({}, stroke, {
    color,
  });

  drawBristles({
    stroke: actualStroke, 
    bufferCtx, 
    bristleCount, 
    bristleWidth, 
    tapering,
    cutEdges,
  });

  if (gradientColor) {
    await fillGradient(bufferCtx, points, color, gradientColor, lineWidth)
  }

  // Создаем второй буферный холст для теней
  const shadowCanvas = document.createElement('canvas');
  shadowCanvas.width = context.canvas.width;
  shadowCanvas.height = context.canvas.height;
  const shadowCtx = shadowCanvas.getContext('2d');

  // Рисуем белую тень
  shadowCtx.save();
  shadowCtx.globalAlpha = 0.5;
  shadowCtx.globalCompositeOperation = 'source-over';
  shadowCtx.shadowColor = 'white';
  shadowCtx.shadowBlur = 0.5;
  shadowCtx.shadowOffsetX = 0.4;
  shadowCtx.shadowOffsetY = 0.4;
  shadowCtx.drawImage(bufferCanvas, 0, 0);
  shadowCtx.restore();

  // Рисуем черную тень
  shadowCtx.save();
  shadowCtx.globalCompositeOperation = 'source-over';
  shadowCtx.shadowColor = darkenedColor;


  if (shadowStrength < 1) {

    shadowCtx.shadowOffsetX = 0.7;
    shadowCtx.shadowOffsetY = 0.7;
    shadowCtx.globalAlpha = 0.7 * Math.sqrt(shadowStrength * 2);
    shadowCtx.shadowBlur = 2 * Math.cbrt(shadowStrength * 2);
    shadowCtx.drawImage(bufferCanvas, 0, 0);

  } else {

    shadowCtx.shadowOffsetX = shadowStrength * 0.5;
    shadowCtx.shadowOffsetY = shadowStrength;
    shadowCtx.globalAlpha = 0.7 * Math.sqrt(shadowStrength * 2);
    shadowCtx.shadowBlur = 2 * Math.sqrt(shadowStrength * 2);

    for (let i = 0; i < Math.ceil(shadowStrength); i++) {
      shadowCtx.drawImage(bufferCanvas, 0, 0);
    }


  }



  shadowCtx.restore();

  // Рисуем оригинальное изображение поверх теней
  shadowCtx.globalCompositeOperation = 'source-over';
  shadowCtx.drawImage(bufferCanvas, 0, 0);

  // Рисуем финальный результат на основном холсте
  context.globalAlpha = gradientColor ? 1 : originalAlpha;
  context.drawImage(shadowCanvas, 0, 0);
  context.globalAlpha = 1; // Возвращаем исходное значение альфа-канала


}

function drawBristles ({
  stroke, 
  bufferCtx, 
  bristleCount, 
  bristleWidth, 
  tapering,
  cutEdges,
}) {

  const {
    lineWidth,
    points,
    time,
    color,
  } = stroke;

  const randomnessFactor = 0.3; // Контролирует степень случайности (0-1)

  const offsets = generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor)
  // .concat(generateEdgeBristleOffsets(time, lineWidth, bristleCount/10, randomnessFactor));

  bufferCtx.lineCap = 'square';
  bufferCtx.lineJoin = 'round';

  offsets.forEach(offset => {
    drawStroke({
      points,
      lineWidth: bristleWidth, 
      mainLineWidth: lineWidth,
      color,
      offset,
      tapering,
      cutEdges,
      time,
    }, bufferCtx);
  });
  
}


// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}



// Функция для генерации отступов ворсинок
function generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor) {
  const baseRadius = lineWidth * 0.5 * 0.9;
  let offsets = [];

  for (let i = 0; i < bristleCount; i++) {
    const angle = seededRandom(time + i) * Math.PI * 2;
    const distance = seededRandom(time + i + 100) * baseRadius;

    const x = Math.cos(angle) * distance;
    const y = Math.sin(angle) * distance;

    const randX = (seededRandom(time + i + 200) - 0.5) * 2 * randomnessFactor * baseRadius;
    const randY = (seededRandom(time + i + 300) - 0.5) * 2 * randomnessFactor * baseRadius;

    offsets.push({ 
      x: x + randX, 
      y: y + randY,
      distance: Math.sqrt((x + randX) ** 2 + (y + randY) ** 2)  // Добавляем расстояние от центра
    });
  }

  // Фильтруем ворсинки в центре
  const innerThreshold = baseRadius * 2/3;
  const innerBristles = offsets.filter(offset => offset.distance < innerThreshold);
  const outerBristles = offsets.filter(offset => offset.distance >= innerThreshold);

  // Оставляем только половину внутренних ворсинок
  const keptInnerBristles = innerBristles
    .sort(() => seededRandom(time + 400) - 0.5)  // Случайная сортировка
    .slice(0, Math.ceil(innerBristles.length * 0.3));

  // Объединяем отфильтрованные внутренние ворсинки с внешними
  offsets = [...keptInnerBristles, ...outerBristles];

  // Добавляем центральную ворсинку
  offsets.push({ x: 0, y: 0, distance: 0 });

  return offsets;
}

function generateEdgeBristleOffsets(time, lineWidth, bristleCount, randomnessFactor) {
  const baseRadius = lineWidth * 0.5 * 0.9;
  let offsets = [];

  for (let i = 0; i < bristleCount; i++) {
    // Базовый угол для равномерного распределения по окружности
    const baseAngle = (i / bristleCount) * Math.PI * 2;
    
    // Добавляем случайное отклонение к углу
    const angleVariation = (seededRandom(time + i) - 0.5) * 2 * randomnessFactor * Math.PI / 4; // до ±45 градусов
    const angle = baseAngle + angleVariation;

    // Базовое расстояние - близко к радиусу окружности
    const baseDistance = baseRadius * (0.9 + seededRandom(time + i + 100) * 0.2); // 90-110% от baseRadius
    
    // Добавляем случайное отклонение к расстоянию
    const distanceVariation = (seededRandom(time + i + 200) - 0.5) * 2 * randomnessFactor * baseRadius * 0.2;
    const distance = baseDistance + distanceVariation;

    const x = Math.cos(angle) * distance;
    const y = Math.sin(angle) * distance;

    offsets.push({ 
      x: x, 
      y: y,
      distance: Math.sqrt(x ** 2 + y ** 2)
    });
  }

  return offsets;
}


function drawStroke({
  points,
  lineWidth,
  mainLineWidth,
  color,
  offset = { x: 0, y: 0, distance: 0 },
  plusWidth = 0,
  tapering,
  cutEdges,
  time,
}, context) {

  if (cutEdges) {
    points = adjustStrokePointsFlatEnds(points, offset, mainLineWidth);
  } else {
    // points = roundStrokePoints(points, offset, tapering);
    points = adjustStrokePoints(points, offset, tapering);
  }

  const stroke = {
    // points,
    points, // Применяем корректировку точек
    lineWidth, 
    color, 
    offset, 
    plusWidth, 
    time,
  }
 
  if (points.length === 0) {
    return;
  } else if (points.length === 1) {
    return drawPoint(stroke, context);
  } else {
    return drawPlainStroke(stroke, context);
  } 
}


// Основная функция корректировки точек штриха с плоскими концами
function adjustStrokePointsFlatEnds(points, offset) {
  if (points.length < 4) return points;

  // Функция для вычисления вектора между двумя точками
  function calculateVector(p1, p2) {
    return [p2[0] - p1[0], p2[1] - p1[1]];
  }

  // Функция для вычисления длины вектора
  function vectorLength(v) {
    return Math.sqrt(v[0] * v[0] + v[1] * v[1]);
  }

  // Функция для нормализации вектора
  function normalizeVector(v) {
    const length = vectorLength(v);
    return [v[0] / length, v[1] / length];
  }

  // Вычисляем векторы направления в начале и конце штриха
  const startVector = normalizeVector(calculateVector(points[0], points[2]));
  const endVector = normalizeVector(calculateVector(points[points.length - 3], points[points.length - 1]));

  // Проецируем смещение на направление штриха
  const startProjection = Math.abs(offset.x * startVector[0] + offset.y * startVector[1]);
  const endProjection = Math.abs(offset.x * endVector[0] + offset.y * endVector[1]);

  let adjustedPoints = [...points];

  // Функция для обработки конца штриха (начало или конец)
  function processEnd({
    startIndex,
    endIndex,
    step,
    spliceStep,
    projection,
  }) {
    let currentIndex = startIndex;
    let remainingDistance = projection;

    while (currentIndex !== endIndex && remainingDistance > 0) {
      const p1 = adjustedPoints[currentIndex];
      const p2 = adjustedPoints[currentIndex + step];

      if (!p1 || !p2) break;

      const v = calculateVector(p1, p2);
      const vLength = vectorLength(v);

      if (vLength <= remainingDistance) {
        // Если расстояние между точками меньше оставшегося расстояния для укорочения,
        // удаляем точку и продолжаем обработку
        adjustedPoints.splice(currentIndex, 1);
        currentIndex += spliceStep;
        remainingDistance -= vLength;
      } else {
        // Иначе смещаем точку
        const ratio = remainingDistance / vLength;
        adjustedPoints[currentIndex] = [
          Math.round((p1[0] + v[0] * ratio) * 10) / 10,
          Math.round((p1[1] + v[1] * ratio) * 10) / 10
        ];
        break;
      }
    }
  }

  // Обрабатываем начало штриха
  processEnd({
    startIndex: 0,
    endIndex: adjustedPoints.length - 2,
    step: 1,
    spliceStep: 0,
    projection: startProjection + seededRandom(offset.x) * 0.1 * startProjection,
  });

  // Обрабатываем конец штриха
  processEnd({
    startIndex: adjustedPoints.length - 1,
    endIndex: 1,
    step: -1,
    spliceStep: -1,
    projection: endProjection + seededRandom(offset.y) * 0.1 * startProjection,
  });

  return adjustedPoints;
}




// Основная функция корректировки точек штриха
function adjustStrokePoints(points, offset, tapering) {

  if (points.length < 4) return points;

  // Функция для вычисления наклона между двумя точками
  function calculateSlope(p1, p2) {
    const dx = p2[0] - p1[0];
    const dy = p2[1] - p1[1];
    return Math.atan2(dy, dx);
  }

  // Функция для вычисления разницы наклонов в диапазоне от 0 до 1
  function calculateSlopeDifference(slope1, slope2) {
    let diff = Math.abs(slope1 - slope2);
    diff = diff > Math.PI ? 2 * Math.PI - diff : diff;
    return (diff / (Math.PI / 2));
  }

  // Вспомогательная функция для вычисления длины вектора
  function vectorLength(v) {
    return Math.sqrt(v[0] * v[0] + v[1] * v[1]);
  }

  const offsetSlope = calculateSlope([0, 0], [offset.x, offset.y]);
  const startStrokeSlope = calculateSlope(points[2], points[0]);
  const startSlopeDifference = calculateSlopeDifference(offsetSlope, startStrokeSlope);
  const endStrokeSlope = calculateSlope(points[points.length-3], points[points.length-1]);
  const endSlopeDifference = calculateSlopeDifference(offsetSlope, endStrokeSlope);
  
  const startEffectiveDistance = offset.distance * startSlopeDifference * tapering * 2;
  const endEffectiveDistance = offset.distance * endSlopeDifference * tapering * 2;

  let adjustedPoints = [...points];

  // Функция для обработки конца штриха (начало или конец)
  function processEnd({
    startIndex, 
    endIndex, 
    step,
    spliceStep,
    effectiveDistance,
  }) {
    let currentIndex = startIndex;
    let remainingDistance = effectiveDistance * 2;

    while (currentIndex !== endIndex && remainingDistance > 0) {
      const p1 = adjustedPoints[currentIndex];
      const p2 = adjustedPoints[currentIndex + step];

      if (!p1 || !p2) break;

      const v = [p2[0] - p1[0], p2[1] - p1[1]];
      const vLength = vectorLength(v);

      if (vLength <= remainingDistance) {
        // Если расстояние между точками меньше оставшегося расстояния для укорочения,
        // удаляем точку и продолжаем обработку
        adjustedPoints.splice(currentIndex, 1);
        currentIndex += spliceStep;
        remainingDistance -= vLength;
      } else {

        // Иначе смещаем точку
        const ratio = remainingDistance / vLength;
        adjustedPoints[currentIndex] = [
          Math.round((p1[0] + v[0] * ratio) * 10)/10,
          Math.round((p1[1] + v[1] * ratio) * 10)/10
        ];
        break;
      }
    }
  }

  processEnd({
    startIndex: 0,
    endIndex: adjustedPoints.length - 1,
    step: 1,
    spliceStep: 0,
    effectiveDistance: startEffectiveDistance,
  });
  processEnd({
    startIndex: adjustedPoints.length - 1,
    endIndex: 0,
    step: -1,
    spliceStep: -1,
    effectiveDistance: endEffectiveDistance,
  });
  
  adjustedPoints = adjustedPoints.length > 1 ? adjustedPoints : [];
  return adjustedPoints;
}




function roundStrokePoints(points, offset, tapering) {
  if (points.length < 4) return points;

  function calculateSlope(p1, p2) {
    const dx = p2[0] - p1[0];
    const dy = p2[1] - p1[1];
    return Math.atan2(dy, dx);
  }

  function calculateSlopeDifference(slope1, slope2) {
    let diff = Math.abs(slope1 - slope2);
    diff = diff > Math.PI ? 2 * Math.PI - diff : diff;
    return (diff / (Math.PI / 2));
  }

  function vectorLength(v) {
    return Math.sqrt(v[0] * v[0] + v[1] * v[1]);
  }

  function dotProduct(v1, v2) {
    return v1[0] * v2[0] + v1[1] * v2[1];
  }

  const offsetSlope = calculateSlope([0, 0], [offset.x, offset.y]);
  const startStrokeSlope = calculateSlope(points[0], points[1]);
  const startSlopeDifference = calculateSlopeDifference(offsetSlope, startStrokeSlope);
  const endStrokeSlope = calculateSlope(points[points.length-2], points[points.length-1]);
  const endSlopeDifference = calculateSlopeDifference(offsetSlope, endStrokeSlope);
  
  const startEffectiveDistance = offset.distance * startSlopeDifference * tapering;
  const endEffectiveDistance = offset.distance * endSlopeDifference * tapering;
  // const startEffectiveDistance = offset.distance * startSlopeDifference * tapering;
  // const endEffectiveDistance = offset.distance * endSlopeDifference * tapering;

  let adjustedPoints = [...points];

  function processEnd({
    startIndex, 
    endIndex, 
    step,
    effectiveDistance,
    isStart
  }) {
    let currentIndex = startIndex;
    let remainingDistance = effectiveDistance * 2;

    // Вычисляем направление для начала или конца штриха
    const strokeDirection = isStart 
      ? [points[1][0] - points[0][0], points[1][1] - points[0][1]]
      : [points[points.length-1][0] - points[points.length-2][0], points[points.length-1][1] - points[points.length-2][1]];
    
    // Нормализуем направление штриха
    const strokeLength = vectorLength(strokeDirection);
    const normalizedStrokeDirection = [strokeDirection[0] / strokeLength, strokeDirection[1] / strokeLength];

    // Вычисляем перпендикулярный вектор к направлению штриха
    const perpStrokeDirection = [-normalizedStrokeDirection[1], normalizedStrokeDirection[0]];

    while (currentIndex !== endIndex && remainingDistance > 0) {
      const p1 = adjustedPoints[currentIndex];
      const p2 = adjustedPoints[currentIndex + step];

      if (!p1 || !p2) break;

      const v = [p2[0] - p1[0], p2[1] - p1[1]];
      const vLength = vectorLength(v);

      // Определяем, с какой стороны от центральной линии находится точка
      const offsetVector = [offset.x, offset.y];
      const side = Math.sign(dotProduct(offsetVector, perpStrokeDirection));

      // Вычисляем коэффициент смещения
      const shiftRatio = Math.min(1, remainingDistance / effectiveDistance / 2);

      // Применяем перпендикулярное смещение с учетом стороны
      // Инвертируем side, чтобы загибать внутрь
      adjustedPoints[currentIndex] = [
        Math.round((p1[0] - perpStrokeDirection[0] * offset.distance * shiftRatio * side) * 10) / 10,
        Math.round((p1[1] - perpStrokeDirection[1] * offset.distance * shiftRatio * side) * 10) / 10
      ];

      remainingDistance -= vLength;
      currentIndex += step;
    }
  }

  processEnd({
    startIndex: 0,
    endIndex: Math.floor(adjustedPoints.length / 2),
    step: 1,
    effectiveDistance: startEffectiveDistance,
    isStart: true
  });
  processEnd({
    startIndex: adjustedPoints.length - 1,
    endIndex: Math.ceil(adjustedPoints.length / 2) - 1,
    step: -1,
    effectiveDistance: endEffectiveDistance,
    isStart: false
  });
  
  return adjustedPoints.length > 1 ? adjustedPoints : [];
}

function drawPlainStroke ({
  points, lineWidth, color, offset, plusWidth, time,
},context) {

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  context.beginPath();
  context.moveTo(points[0][0] + offset.x, points[0][1] + offset.y);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      // (nextPoint[0] + currentPoint[0] + seededRandom(time + i + 100) * 3) / 2,
      // (nextPoint[1] + currentPoint[1] + seededRandom(time + i + 100) * 3) / 2,
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2,
    ];
    context.quadraticCurveTo(nextPoint[0] + offset.x, nextPoint[1] + offset.y, midPoint[0] + offset.x, midPoint[1] + offset.y);
  }
  const lastPoint = points[points.length - 1]
  context.lineTo(
    lastPoint[0] + offset.x, 
    lastPoint[1] + offset.y,
    );
  context.stroke();
  
}

function drawPoint ({
  points, lineWidth, color, offset, plusWidth,
}, context) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  context.beginPath();
  context.arc(
    point[0] + offset.x, 
    point[1] + offset.y, 
    (lineWidth / 2) + plusWidth, 
    0, 
    Math.PI * 2
    );
  context.fillStyle = color;
  context.fill();
}

