import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';

let closeTime = 0;

const TemporaryHint = ({ message, duration = 2500, style = {}, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = useCallback(() => {
    setIsVisible(isVisible => {
      if (isVisible) { closeTime = Date.now(); }
      return false;
    });
    onClose();
  }, [onClose]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, duration);

    const handleClick = (e) => {
      e.stopPropagation();
      handleClose();
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);
    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [duration, handleClose]);

  if (!isVisible) return null;

  return (
    <div
      className={`temporary-hint ${isVisible ? 'temporary-hint--visible' : 'temporary-hint--hidden'}`}
      style={{
        position: 'fixed',
        top: '50px',
        left: '50%',
        transform: 'translate(-50%)',
        minWidth: '300px',
        padding: '18px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        textAlign: 'center',
        borderRadius: '17px',
        zIndex: 11500,
        userSelect: 'none',
        pointerEvents: 'none',
        transition: 'opacity 0.3s ease-in-out',
        ...style,
      }}
    >
      {typeof message === 'string' ? message : React.cloneElement(message, { style: { pointerEvents: 'none' } })}
    </div>
  );
};

export const showTemporaryHint = (message, more = {}, style = {}) => {
  const { duration = 2500 } = more;
  if (closeTime > Date.now() - 300 && !more.force) { return; }
  const hintContainer = document.createElement('div');
  document.body.appendChild(hintContainer);

  const root = createRoot(hintContainer);

  const removeHint = () => {
    root.unmount();
    hintContainer.remove();
  };

  root.render(
    <TemporaryHint
      message={message}
      duration={duration}
      style={style}
      onClose={removeHint}
    />
  );
};

export default TemporaryHint;