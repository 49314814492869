// src/components/puzzle/PuzzleBoard.js
import './PuzzleBoard.css';

import usePuzzlePointer from '../../hooks/puzzle/puzzlePointer';

import React, { useEffect } from 'react';
import useStore from '../../store';
import PuzzleArea from './PuzzleArea';
import PieceTray from './PieceTray';
import DraggingPiece from './DraggingPiece';
import Spinner from '../spinner/Spinner';
import GameNotLoaded from '../menus/results/GameNotLoaded';
import ChatOverlay from '../board/indicators/ChatOverlay';
import i18n from '../../locales/i18n'; // Импортируем i18n
import { FaSun, FaMoon } from 'react-icons/fa'; // Импортируем иконки для переключения темы

const PuzzleBoard = () => {
  usePuzzlePointer();

  // Подписываемся только на нужные части состояния для ререндера
  const isInitialized = useStore(state => state.ref.puzzle.state.isInitialized);
  const isLoading = useStore(state => state.ref.puzzle.state.isLoading);
  const gameError = useStore(state => state.gameError);
  const puzzleDataExists = useStore(state => !!state.ref.info.game?.puzzle);
  const puzzleFileId = useStore(state => state.ref.info.game?.puzzle?.file_id); // Используем file_id для useEffect
  const gameSettings = useStore(state => state.ref.info.game?.settings);
  const gameAuthor = useStore(state => state.ref.info.game?.author);
  // Подписываемся на ререндер и состояние темы
  useStore(state => state.puzzleRender);
  const isDarkTheme = useStore(state => state.ref.puzzle.state.isDarkTheme);

  const { ref } = useStore.getState(); // Получаем ref один раз
  const { methods: puzzleMethods } = ref.puzzle;

  // Инициализация темы при монтировании компонента
  useEffect(() => {
    puzzleMethods.initTheme();
  }, [puzzleMethods]);

  // Trigger initialization when puzzle data becomes available or file_id changes
  useEffect(() => {
    if (puzzleDataExists) {
      puzzleMethods.initializePuzzle();
    }
    // Зависимость от puzzleFileId гарантирует перезапуск при смене файла пазла
  }, [puzzleFileId, puzzleDataExists, puzzleMethods]);

  // Reset puzzle state if the game mode changes away from puzzle
  useEffect(() => {
    // Если больше нет данных пазла (режим игры сменился) и пазл был инициализирован
    if (!puzzleDataExists && isInitialized) {
      window.croco?.log("Exiting puzzle mode, resetting state.");
      puzzleMethods.reset();
    }
    // Следим за наличием данных пазла и состоянием инициализации
  }, [puzzleDataExists, isInitialized, puzzleMethods]);


  // --- Loading / Error States ---
  if (isLoading) {
    // Показываем спиннер, если идет активная загрузка
    return <Spinner store={useStore.getState()} style={{ opacity: 1, transition: "opacity 0.5s ease" }} />;
  }

  if (gameError?.error?.includes('puzzle')) {
     // Показываем ошибку, если она связана с пазлом
     return <GameNotLoaded store={useStore.getState()} />;
  }

  // Если еще не инициализировано, но загрузка не идет (ожидание данных или первая загрузка)
  if (!isInitialized) {
       // Можно показать "Инициализация..." или просто пустой экран/легкий спиннер
       return (
            <div className="puzzle-board-container">
                <div className="puzzle-title">{i18n.t('puzzle.initializing')}</div>
                {/* Можно добавить легкий индикатор загрузки */}
                 <Spinner store={useStore.getState()} style={{ opacity: 0.5 }} />
            </div>
       );
  }
  // --- End Loading / Error States ---

  const puzzleName = gameSettings?.title || i18n.t('puzzle.title', {
       author: gameAuthor?.first_name || i18n.t('puzzle.unknown_author')
   });

  // Функция для обработки клика по кнопке темы
  const handleThemeToggle = () => {
    puzzleMethods.toggleTheme();
  };

  return (
    // Добавляем класс 'touch-active' для стилей, если нужно
    <div className="puzzle-board-container touch-active">
      <ChatOverlay store={useStore.getState()} />
      <div className="puzzle-title">
        <div 
          className="theme-toggle-button" 
          onClick={handleThemeToggle}
          title={isDarkTheme ? "Переключить на светлую тему" : "Переключить на темную тему"}
        >
          {isDarkTheme ? <FaSun /> : <FaMoon />}
        </div>
        {puzzleName}
      </div>
      {/* Важно: PuzzleArea и PieceTray рендерятся только ПОСЛЕ инициализации */}
      <PuzzleArea />
      <PieceTray />
      <DraggingPiece />
      {/* SVG defs теперь рендерятся внутри PuzzleArea */}
    </div>
  );
};

export default PuzzleBoard;