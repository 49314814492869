// PaletteInstruments.js

import React, { useState, useRef, useCallback } from 'react';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import './PaletteInstruments.css';  // Импортируем CSS файл
import SavedColorsPalette from './SavedColorsPalette';
import ColorModeSwitch from './ColorModeSwitch';

import useStore from '../../../../store';

function GradientInstrument({ 
  store,
 }) {

  const { t } = useTranslation();

  const color = useStore((state) => state.color);
  const gradientColor = useStore((state) => state.gradientColor);
  const premiumGrayscale = useStore((state) => state.premiumGrayscale);

  const { menu, work, info, brush, premium, } = store.ref;

  const { user } = info;
  const { premiumFeatures = [] } = premium;


  const {
    delayPremiumMenu,
    showTemporaryHint,

    forceRender,
  } = menu.methods;
  
  const {
    saveUserGameSets,
    getUserGameSets,
    saveUserSets,
  } = work.methods;

  const {
    setTheColorFun,
  } = brush.methods;

  const gradientRef = useRef(null);

  const addCurrentColor = () => {

    if (!premiumFeatures.includes('saveColors') 
    || user.premiumOff) {
      delayPremiumMenu('menu');
      return;
    }
    const newColors = [gradientColor, ...(user.colors || [])];
    saveUserSets({colors: newColors});
    forceRender();

  };


  const handleGradientClick = useCallback((event) => {
    
    const currentGradientColor = gradientColor;
    setTheColorFun(color, 'gradientColor');
    setTheColorFun(currentGradientColor, 'color');

  }, [color, gradientColor, setTheColorFun]);



  const toggleGradient = (e) => {

    e.preventDefault();

    let newValue = !getUserGameSets().gradientOn;
    if (!newValue) { useStore.setState({gradientPalette: false}); }
    
    saveUserGameSets({gradientOn: newValue})

    return showTemporaryHint(t(`tooltip.${newValue ? 'gradient_on' : 'gradient_off'}`),  {duration: 1500, force: true}, {minWidth: '200px'})

  }

  const rows = [[], [], [], []];
  const showColors = (user.colors || []).slice(0, 20);
  showColors.forEach((savedColor, i) => {
    const rowIndex = Math.floor(i / 5);
    if (rows[rowIndex]) {
      rows[rowIndex].unshift({savedColor, i});
    }
  });

  return (
  <div className="palette-instruments-container">
    <div className="gradient-instrument-column">
    <SavedColorsPalette
      store={store}
    />
    <div className="controls-container">
      <div className='top-controls'>
        <div
          ref={gradientRef}
          className={`gradient-main-view ${getUserGameSets().gradientOn ? 'gradient-on' : ''}`}
          onClick={ handleGradientClick }
          onContextMenu={ toggleGradient }
          style={{ background: getUserGameSets().gradientOn ? 
            `linear-gradient(to right, ${color}, ${gradientColor || '#0000'})` :
            `linear-gradient(to right, white, black)`
           }}
        />
      </div>
    </div>
    </div>
    <div className="controls-container">
    <div className="bottom-controls">
        <div onClick={addCurrentColor} className="saved-add-color">
          <svg className="saved-icon" viewBox="0 0 24 24">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
          </svg>
        </div>
        {premiumFeatures.includes('colors') && !user.premiumOff && (
          <ColorModeSwitch 
            premiumGrayscale={premiumGrayscale} 
            useStore={useStore}
          />
        )}
      </div>
      <div className="top-controls">
        <button className='gradient-ok-button'
            onClick={()=>{useStore.setState({gradientPalette: false});}}
        >
          OK
        </button>
      </div>

    </div>
  </div>
  );
}

export default GradientInstrument;
