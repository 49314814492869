// ResultsOverlay.js
import React from 'react';
import './ResultsOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';

function ResultsOverlay({ 
  store
  // setContinueDrawing,
 }) {

  const { t } = useTranslation();

  const { ref, canDraw } = store;
  const { info, work } = ref;
  const { game } = info;

  const { saveUserGameSets } = work.methods;

  const results = game.results;

  const mainMessage = results.text || (results.moder ? 
  t('results.moder', { moderName: results.moder.first_name }) :
  results.pass ? 
  t('results.passed', { authorName: game.author.first_name }) :
  t('results.winner', { winnerName: results.winner?.first_name }));

  const stayButtonLabel = canDraw ? 
  t('results.continue') : 
  t('results.stay');


  return (
    <>
      <div className='overlay-background'
        // onClick={() => saveUserGameSets({continueDrawing: true})}
      ></div>
      <div className="results">
        <div className="results-text">{mainMessage}</div>
        {game.word ? (
          <div className="word-highlight">{game.word}</div>
        ) : null}

      <div className="button-container">
        <button 
          className="results-button" 
          onClick={() => saveUserGameSets({continueDrawing: true})}>
          {stayButtonLabel}
        </button>
        <button 
          className="results-button lower-button" 
          onClick={window.Telegram.WebApp.close}>
          {t('results.close')}
        </button>
      </div>
      </div>
    </>
  );
}

export default ResultsOverlay;
