// src/store/index.js
import { create } from 'zustand';
import createTelegramSlice from './telegramSlice';
import createSocketSlice from './socketSlice';

import createInfoSlice from './infoSlice';
import createWorkSlice from './workSlice';
import createMenuSlice from './menuSlice';

import createConvySlice from './convySlice';
import createBrushSlice from './brushSlice';
import createDrawingSlice from './drawingSlice';
import createHistorySlice from './historySlice';
import createMountSlice from './mountSlice';
import createPremiumSlice from './premiumSlice';
import createPuzzleSlice from './puzzleSlice'; // <--- Import new slice

import createDebugSlice from './debugSlice';

import setupCanvasSizes from '../utils/drawing/canvasSetup';

const ref = { 
  methods: {},
  components: {},
  history: {},
 };

const useStore = create((set, get) => ({
  // Единый объект для не реактивных данных
  ref,
  // Подключаем слайсы, которые будут использовать общий объект ref
  setupCanvasSizes: () => setupCanvasSizes(),

  ...createTelegramSlice(set, get, ref),
  ...createSocketSlice(set, get, ref),

  ...createInfoSlice(set, get, ref),
  ...createPremiumSlice(set, get, ref),
  ...createWorkSlice(set, get, ref),
  ...createMenuSlice(set, get, ref),

  ...createConvySlice(set, get, ref),
  ...createBrushSlice(set, get, ref),
  ...createDrawingSlice(set, get, ref),
  ...createHistorySlice(set, get, ref),
  ...createMountSlice(set, get, ref),
  ...createPuzzleSlice(set, get, ref),
  ...createDebugSlice(set, get, ref),
}));

export default useStore;
