// EffectMenu

import './BrushMenu.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../store';

import { 
  FaFillDrip, FaTint, 
  FaSun, FaMoon,
  FaHotjar, FaToiletPaper,
  FaCog,
 } from 'react-icons/fa';
import {
  TbGrain, 
  TbContrast2Filled, TbContrast2Off,
  TbZoomIn, TbZoomOut,
  TbLineScan,
  TbTexture,
  TbFilter,
 } from 'react-icons/tb';
import { MdGridOn } from "react-icons/md";

import { createBetterClick } from '../../../utils/interface/createBetterClick'

const effectIcons = {
  'filler': FaFillDrip,
  'blur': FaTint,
  'noise': TbGrain,
  'texture': TbTexture,
  'pixelate': MdGridOn,
  'lighten': FaSun,
  'darken': FaMoon,
  'saturate': FaHotjar,
  'desaturate': FaToiletPaper,
  'contrast': TbContrast2Filled,
  'decontrast': TbContrast2Off,
  'zoomin': TbZoomIn,
  'zoomout': TbZoomOut,
  // 'outline': TbLineScan,
};

function EffectMenu({ store }) {
  const { t } = useTranslation();

  useStore(state=>state.render);
  const effectType = useStore(state=>state.effectType);

  const { ref } = store;
  const { telegram, menu, brush, info, work, premium } = ref;
  const { game, user, bot, group } = info;

  const {
    premiumFeatures = [],
  } = premium;

  const {
    openMenu,
    closeMenu,
    delayPremiumMenu,
  } = menu.methods;

  const {
    saveUserSets,
  } = work.methods;

  const {
    brushSetsFun,
    brushLinkFun,
    customBrushSetsFun,
    changeEffectType,
  } = brush.methods;

  const effects = [
    'filler',
    'blur',
    'noise',
    'texture',
    'pixelate',
    'lighten',
    'darken',
    'saturate',
    'desaturate',
    'contrast',
    'decontrast',
    'zoomin',
    'zoomout',
    // 'outline',

    // Новые фильтры из glfx.js
    'sharpen',          // Резкость
    'sepia',            // Сепия
    'triangleBlur',     // Треугольное размытие
    'edgeWork',         // Выделение краёв
    'ink',              // Эффект чернил

    'lensBlur',         // Размытие линзы
    'tiltShift',        // Эффект Tilt-Shift
    'zoomBlur',         // Зум-размытие


    // // Доработать
    // 'glfxBulgePinch',   // Выпуклость/вогнутость
    // 'vibrance',         // Усиление яркости цветов

    // // Красивые фильтры
    // 'colorHalftone',    // Цветной полутон
    // 'hexagonalPixelate', // Шестиугольная пикселизация
    // 'dotScreen',        // Точечный экран

    // // Не приспособлены для эффекта кисти
    // 'vignette',         // Виньетка

    // // Не работают
    // 'swirl',            // Завихрение
    // 'posterize',        // Постеризация
    // 'perspective',      // Перспективное искажение
    // 'matrixWarp',       // Матричное искажение
    // 'solarize',         // Соляризация


  ].map(key => ({
    key,
    label: t(`effect.${key}.name`),
    icon: effectIcons[key] || TbFilter,
  }));

  const haveParameters = ['filler', 'pixelate', 'texture'];

  const handleOverlayClick = () => {
    if (brush.lastActionTime < Date.now() - 300) {
      closeMenu(); 
    }
  };

  const handleSwitchEffect = (newEffectType) => {
    if (brush.lastActionTime > Date.now() - 300) { return; }

    if (newEffectType !== 'filler' && 
      (!premiumFeatures.includes('brushes') 
      || user.premiumOff)
    ) {
      closeMenu(); 
      delayPremiumMenu('menu');
    } else {
      const previousEffect = effectType;

      if (previousEffect !== newEffectType) {
        window.croco?.appleHaptic('medium');
        window.croco?.androidHaptic();
      }

      brush.effectHistory.push(newEffectType);
      changeEffectType(newEffectType);
      closeMenu(); 

    }
  };

  const handleOpenEffectSettings = (newEffectType, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    brush.lastActionTime = Date.now();
    openMenu('customizeBrush', { brush: newEffectType, effect: true, layer: true, backMenu: 'effect' });

    if (newEffectType !== 'filler' && 
      (!premiumFeatures.includes('effects') 
      || user.premiumOff)
    ) {
      return;
    }

    if (newEffectType !== 'common') {
      brush.effectHistory.push(newEffectType);
      changeEffectType(newEffectType);
    }
  };

  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="effect-menu add-scroll touch-active">
        <span 
          className="brush-menu-title"
        >
          {t('effect.title')}
        </span>

        <div className="brush-menu-list">
        
        {effects.map((effect) => (
          <div 
            key={effect.key} 
            className={`brush-option-row ${effectType === effect.key ? 'active' : ''}`}

            // onClick={() => handleSwitchEffect(effect.key)}
            // onContextMenu={haveParameters.includes(effect.key) ? (e) => handleOpenEffectSettings(effect.key, e) : null}

            { ...createBetterClick({
              onClick: (e) => {handleSwitchEffect(effect.key)},
              onContextMenu: haveParameters.includes(effect.key) ? (e) => handleOpenEffectSettings(effect.key, e) : null,
            }) }
            
          >
            <label className="brush-label">
              <effect.icon className={`brush-icon ${effectType === effect.key ? 'active' : ''}`}/>
              <span> {effect.label}</span>
              {haveParameters.includes(effect.key) && effectType === effect.key && (
                <FaCog
                  className="brush-settings-icon"
                  { ...createBetterClick({
                    onClick: (e) => {handleOpenEffectSettings(effect.key, e);},
                    onContextMenu: (e) => {handleOpenEffectSettings(effect.key, e);},
                  }) }
                />
              )}
            </label>
          </div>
        ))}
        </div>
      </div>
    </>
  );
}

export default EffectMenu;