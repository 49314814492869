// src/store/workSlice.js


const createWorkSlice = (set, get, ref) => {

  ref.work = {
    debouncing: {
      user: {},
      game: {
        sets: {},
        saveTime: 0,
      },
      gameUser: {
        sets: {},
        saveTime: 0,
      },
    }
  };

  ref.work.methods = {

    changeWord: (direction) => {
      if (!get().canDraw) return;

      return new Promise((resolve, reject) => {
        const socket = get().socket;
        socket?.emit('word', { 
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.activeUserId,
         }, direction, (word) => {
          if (word) {
            window.croco?.haptic?.();
            setTimeout(() => {
              window.croco?.haptic?.();
            }, 100);
            resolve(word);
          } 
        });
      });
    },

    openLetter: (num) => {
      if (!get().canDraw) return;

      const socket = get().socket;
      socket?.emit(
        'hint',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.activeUserId,
        },
        num,
        (result) => {
          window.croco?.haptic?.();
        }
      );
    },

    activateHints: () => {
      const socket = get().socket;
      if (!socket) return;
      socket.emit(
        'hints',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.telegramUserId,
        },
        (result) => {
          window.croco?.haptic?.();
        }
      );
    },

    saveUserSets: (sets, callback) => {
      const socket = get().socket;
      if (!socket) return;

      for (let param in sets) {
        const [level1, level2] = param.split('.'); 
        const value = sets[param];
        if (level2) {
          ref.info.user[level1] = ref.info.user[level1] || {}; 
          ref.info.user[level1][level2] = value;
        } else {
          ref.info.user[level1] = value;
        }
      }

      // for (let param in sets) {
      //   ref.info.user[param] = sets[param];
      // }

      window.logPerformance?.('saveUserSets');

      socket.emit(
        'saveSets',
        {
          userId: ref.telegram.telegramUserId,
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
        },
        sets,
        (result) => {
          callback && callback(result);
        }
      );
      // Если у вас есть функция для принудительного ререндеринга, можно её вызвать:

      ref.menu.methods.forceRender();

    },

    authorGameSets: (sets, callback) =>{

      // Проверяем, что текущий пользователь – владелец игры
      if (
        String(ref.telegram.telegramUserId) !== String(ref.info.game.user_key)
        && !ref.info.user.moder
        ) {
        return ref.menu.methods.showTemporaryHint(window.t('tooltip.only_author'), { force: true });
      };
      ref.work.methods.saveGameSets(sets, callback);

    },


    saveGameSets: (sets, callback) => {

      const socket = get().socket;
      if (!socket) return;

      const gameSettings = ref.info.game.settings;
      for (let param in sets) {
        gameSettings[param] = sets[param];
      }

      ref.menu.methods.forceRender();

      if (ref.telegram.testCanvas) { return; }

      socket.emit(
        'gameSets',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.telegramUserId,
        },
        sets,
        (result) => {
          callback && callback(result);
        }
      );

    },

    debounceGameSets: (sets, callback) => {

      ref.info.game.settings = ref.info.game.settings || {};
      const gameSettings = ref.info.game.settings;

      for (let param in sets) {
        gameSettings[param] = sets[param];
      }

      const debouncing = ref.work.debouncing.game;
      Object.assign(debouncing.sets, sets);

      const timeout = Math.max(0, 1000 -(Date.now() - debouncing.saveTime)) 
      
      clearTimeout(debouncing.timer);
      debouncing.timer = setTimeout(() => {

        debouncing.saveTime = Date.now();
        const setsToSave = debouncing.sets;
        debouncing.sets = {};
        console.log(`debounced save: ${JSON.stringify(setsToSave)}`)
        ref.work.methods.saveGameSets(setsToSave, callback);

      }, timeout);

    },

    debounceUserGameSets: (sets, callback) => {

      const userId = ref.telegram.telegramUserId;

      ref.info.game.settings = ref.info.game.settings || {};
      const gameSettings = ref.info.game.settings;

      gameSettings[userId] = gameSettings[userId] || {};
      const userGameSettings = gameSettings[userId];

      for (let param in sets) {
        userGameSettings[param] = sets[param];
      }

      const debouncing = ref.work.debouncing.gameUser;
      Object.assign(debouncing.sets, sets);

      const timeout = Math.max(0, 1000 -(Date.now() - debouncing.saveTime)) 
      
      clearTimeout(debouncing.timer);
      debouncing.timer = setTimeout(() => {

        debouncing.saveTime = Date.now();
        const setsToSave = debouncing.sets;
        debouncing.sets = {};
        console.log(`debounced save: ${JSON.stringify(setsToSave)}`)
        ref.work.methods.saveUserGameSets(setsToSave, callback);

      }, timeout);

    },


    saveUserGameSets: (sets, callback) => {

      const socket = get().socket;
      if (!socket) return;

      const userId = sets.userId || ref.telegram.telegramUserId;

      ref.info.game.users ??= {};
      ref.info.game.users[userId] ??= {};
      ref.info.game.users[userId].sets ??= {};

      const userGameSettings = ref.info.game.users[userId].sets;
      for (let param in sets) {
        userGameSettings[param] = sets[param];
      }

      if (ref.telegram.testCanvas) { return; }

      socket.emit(
        'gameUserSets',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.telegramUserId,
        },
        userGameSettings,
        (result) => {
          callback && callback(result);
        }
      );

      ref.menu.methods.forceRender();
    },


    saveUserGameSets0: (sets, callback) => {

      const socket = get().socket;
      if (!socket) return;

      const userId = ref.telegram.telegramUserId;
      const gameSettings = ref.info.game.settings;
      gameSettings[userId] = gameSettings[userId] || {};
      const userGameSettings = gameSettings[userId];
      for (let param in sets) {
        userGameSettings[param] = sets[param];
      }

      if (ref.telegram.testCanvas) { return; }

      socket.emit(
        'gameSets',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.telegramUserId,
        },
        { [userId]: userGameSettings },
        (result) => {
          callback && callback(result);
        }
      );

      ref.menu.methods.forceRender();
    },

    getUserGameSets: (userId) => {

      userId = userId || ref.telegram.telegramUserId;

      const gameSettings = ref.info.game?.settings;
      const oldUserGameSettings = gameSettings[userId] || {};

      ref.info.game.users ??= {};
      ref.info.game.users[userId] ??= {};

      const user = ref.info.game.users[userId];
      user.sets ??= {};

      const userGameSettings = {...oldUserGameSettings, ...user.sets }
      user.sets = userGameSettings;

      return userGameSettings;
    },


    askGroupPremium: () => {
      const socket = get().socket;
      if (!socket) return;
      socket.emit(
        'askGroupPrem',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.telegramUserId,
        },
        (result) => {
          window.croco?.haptic?.();
        }
      );
    },

    hostAction: (action, data, callback) => {
      const socket = get().socket;
      if (!socket) return;
      socket.emit(
        action,
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.telegramUserId,
        },
        data,
        (result) => {
          callback && callback(result);
        }
      );
    },

    hostActionPromise: (action, data) => {
      return new Promise((resolve, reject) => {
        ref.work.methods.hostAction(action, data, (result)=>{
          resolve(result);
        })
      });
    },



  }

  // Этот слайс не возвращает отдельное состояние
  return {};
};

export default createWorkSlice;
