// BrushContext.js
import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import { useGameContext } from './GameContext';
import { getTexture, sources, brushDefaults } from '../hooks/convy/brushes/helpers/brushLoader';
import { filterClosePoints } from '../hooks/convy/brushes/helpers/points';


const BrushContext = createContext();

export const BrushProvider = ({ children }) => {

  const { t } = useTranslation();

  const gameContext = useGameContext();
  const { 
    gameInfoRef,
    userSetsRef,
    premiumFeaturesRef,

    saveGameSets, 

    showMenu, openMenu,
    showTemporaryHint,
    
    transparentBgRef,
   } = gameContext;

  const lastActionTimeRef = useRef(Date.now());
  const [activeTool, setActiveTool] = useState("brush"); // По умолчанию активен инструмент кисти
  const activeToolRef = useRef(activeTool); 
  const previousToolRef = useRef(activeTool);

  const [brushType, setBrushType] = useState("plain");
  const brushTypeRef = useRef(brushType); 
  const brushHistoryRef = useRef([]); 

  const [effectType, setEffectType] = useState("filler");
  const effectTypeRef = useRef(effectType); 
  const effectHistoryRef = useRef([]); 

  const [color, setColor] = useState('#000000FF');
  const colorRef = useRef(color);
  const prevColorRef = useRef(color);

  const [gradientColor, setGradientColor] = useState('#FF0000FF');
  const gradientColorRef = useRef(gradientColor);
  const [gradientPalette, setGradientPalette] = useState(null);
  const gradientPaletteRef = useRef(gradientPalette);


  const colorToClipboardRef = useRef(null);
  const brushColorRef = useRef(color);
  const eraserColorRef = useRef('#FFFFFFFF');
  const [grayPaletteColor, setGrayPaletteColor] = useState(color);

  const quickColorChangeTimeRef = useRef(0);
  const lastColorsRef = useRef([]);

  const previousColorRef = useRef(color);

  const [lineWidth, setLineWidth] = useState(7);
  const lineWidthRef = useRef(lineWidth);
  const brushSizeRef = useRef(7);
  const eraserSizeRef = useRef(25);

  const previousLineWidthRef = useRef(lineWidth);
  const [visibleLineWidth, setVisibleLineWidth] = useState(lineWidth);

  const [softness, setSoftness] = useState(0.0);
  const softnessRef = useRef(softness);


  const [hotMouseActive, setHotMouseActive] = useState(false);
  const hotMouseStartPositionRef = useRef({x: 0, y: 0});

  const [isResizingBrush, setIsResizingBrush] = useState(false);
  const [isChangingBrightness, setIsChangingBrightness] = useState(false);
  const [isChangingOpacity, setIsChangingOpacity] = useState(false);
  const [isChangingSoftness, setIsChangingSoftness] = useState(false);

  const lastBrushChangeTime = useRef(0);

  const shiftIsDownRef = useRef(false);
  const altIsDownRef = useRef(false);
  const controlIsDownRef = useRef(false);
  const spaceIsDownRef = useRef(false);
  const tabIsDownRef = useRef(false);
  const keyZIsDownRef = useRef(false);

  const [showPalette, setShowPalette] = useState(false);
  const showPaletteRef = useRef(false);
  const [magnifierOn, setMagnifierOn] = useState(false);

  const [premiumGrayscale, setPremiumGrayscale] = useState(false);

  const [isPipettePicking, setIsPipettePicking] = useState(false);
  const [pickingPlace, setPickingPlace] = useState({x: 0, y: 0});
  const pickingPlaceRef = useRef({x: 0, y: 0});

  const longPressTimeoutRef = useRef();
  const shapeStartPointRef = useRef();
  const drawingShapeRef = useRef(false);
  const lastShapeMadeTimeRef = useRef(0);


  const [multiplierTextColor, setMultiplierTextColor] = useState('black');
  const [brushIndicatorFrame, setBrushIndicatorFrame] = useState('none');

  const gradientColorFun = () => {

    let actualGradientColor = gameInfoRef.current?.settings?.gradientOn ? gradientColorRef.current : null;
    if (['effect', 'eraser'].includes(activeToolRef.current)) {
      actualGradientColor = null;
    }
    return actualGradientColor;

  }

  const actualSoftnessFun = () => {

    const sets = brushSetsFun();
    const softness = sets.softness || softnessRef.current || 0;
    const actualSoftness = activeTool === 'effect' ? 0 : softness;
    return actualSoftness;

  }

  const customizeCurrentBrush = (actualBrushType) =>{
    actualBrushType = actualBrushType || 
    (activeToolRef.current === 'effect' ? effectTypeRef.current :
    activeToolRef.current === 'eraser' ? 'plain' :
    brushTypeRef.current);

    openMenu('customizeBrush', {brush: actualBrushType, layer: true})
  }

  const brushSetsFun = (brushKey) => {

    brushKey = brushKey || 
    (activeToolRef.current === 'eraser' ? 'plain' :
    activeToolRef.current === 'effect' ? effectTypeRef.current :
    brushTypeRef.current)

    const sets = Object.assign({}, brushDefaults[brushKey] || {}, userSetsRef.current[brushKey] || {});
    return sets;

  }


  const brushLinkFun = () => {

    let brushKey = brushTypeRef.current; ;

    if (
      ['eraser', 'effect'].includes(activeToolRef.current)
      || gameInfoRef.current?.mode === 'sprint'
      ) {
      brushKey ='plain';
    }

    const sets = userSetsRef.current[brushKey] || {};
    const basicBrushLink = sets?.clone || brushKey;
    return basicBrushLink;

  }

  const ifIndicateBrushFun = () => {

    const indicateEffectBrush = 
    ['brush', 'eraser', 'effect'].includes(activeTool) 
    && !['filler'].includes(effectType) ;

    const indicateStandartBrush = ['brush', 'eraser'].includes(activeTool) ;

    const indicateBrush = 
    ((indicateStandartBrush 
      || indicateEffectBrush 
      || quickColorChangeTimeRef.current > Date.now() - 100)
    && !showMenu?.[0]
    && !controlIsDownRef.current
    && !shiftIsDownRef.current
    && !spaceIsDownRef.current)
    || hotMouseActive;

    return indicateBrush;
  }

  const visibleColorFun = () => {
    if (transparentBgRef.current) {
      if (activeToolRef.current === 'eraser') { return '#FFFFFF50'; }
      if (chroma(colorRef.current).alpha() === 0) { return '#FFFFFF50'; }
    }

    if (activeToolRef.current === 'effect') {
      if (['filler'].includes(effectTypeRef.current)) { return colorRef.current; }
      if (['darken'].includes(effectTypeRef.current)) { return '#00000030'; }
      return '#FFFFFF50';
    }

    return colorRef.current;
  }

  const setTheColorFun = (colorInput, param) => {
  
    let newColor = typeof colorInput === 'function' ? colorInput(color) : colorInput;

    if (
      !premiumFeaturesRef.current.includes('colors') 
      || userSetsRef.current.premiumOff
      ) {
      // newColor= chroma(newColor).set('hsl.s', 0).hex('rgba');
      const alpha = chroma(newColor).alpha(); // Сохраняем прозрачность текущего цвета
      newColor = chroma(newColor).set('hsv.s', 0).alpha(alpha).hex('rgba'); 
    }

    if (
      param !== 'color'
      && (gradientPaletteRef.current || param === 'gradientColor')
      ) {
      gradientColorRef.current = newColor;
      return setGradientColor(newColor);
    }

    if (
      activeToolRef.current === 'eraser' 
      || (activeToolRef.current === 'pipette' && previousToolRef.current === 'eraser')
      ) {
      eraserColorRef.current = newColor;
    } else {
      brushColorRef.current = newColor;
    }
    colorRef.current = newColor;
    setColor(newColor);
  };

  const setTheSizeFun = (sizeInput) => {
    const newSize = typeof sizeInput === 'function' ? sizeInput(lineWidth) : sizeInput;
    if (activeToolRef.current === 'eraser') {
      eraserSizeRef.current = newSize;
    } else {
      brushSizeRef.current = newSize;
    }
    setLineWidth(newSize);
  };


  const handleLastColors = (newColor = colorRef.current) => {
    setColor(newColor);  // Установка нового цвета
    colorRef.current = newColor;  // Обновление текущего цвета в рефе
  
    const filteredColors = lastColorsRef.current.filter(color => color !== newColor);
    filteredColors.push(newColor);

    lastColorsRef.current = filteredColors;
  };

  function adjustColor(cssVariable, baseColor) {
    function getCSSVariableValue(variable) {
      return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    }
  
    const cssColor = getCSSVariableValue(cssVariable);
    const cssChroma = chroma(cssColor);
    const baseChroma = chroma(baseColor);
    const [, s, l] = cssChroma.hsl();
    const [h, , ] = baseChroma.hsl();
    const resultColor = chroma.hsl(h, s, l);
    return resultColor.css();
  }

  function blendColors(cssVariable, baseColor, opacity = 0.2) {
    function getCSSVariableValue(variable) {
      return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    }
    const cssColor = getCSSVariableValue(cssVariable);
    const cssChroma = chroma(cssColor);
    const baseChroma = chroma(baseColor);
    const blendedColor = chroma.mix(cssChroma, baseChroma, opacity, 'rgb');
    return blendedColor.css();
  }



  const clipboardColor = async (action) => {

    if (action === 'copy') {
      return await copyCurrentColorToClipboard();
    }

    try {

      let clipboardText = await navigator.clipboard.readText();

      if (
        chroma.valid(clipboardText)
        ) {
        const hexColor = chroma(clipboardText).hex();
        if (
          hexColor !== color 
          && hexColor !== colorToClipboardRef.current
          ) {
          setTheColorFun(hexColor);
          showTemporaryHint(
            <ColorHint text={hexColor + t('tooltip.color_pasted')} hexColor={hexColor} />,
            {force: true}, 
            );
          return true; 
        }
      }
      
    } catch (e) {}

    return await copyCurrentColorToClipboard();

  };

  // Вспомогательная функция для копирования текущего цвета в буфер
  const copyCurrentColorToClipboard = async () => {
    try {
      const hexColor = chroma(color).hex();
      await navigator.clipboard.writeText(hexColor);
      colorToClipboardRef.current = hexColor;
      
      showTemporaryHint(
        <ColorHint text={hexColor + t('tooltip.color_copied')} hexColor={hexColor} />,
        {force: true}, 
        );


    } catch (e) { }
  };

  const ColorHint = ({ text, hexColor }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          width: '25px',
          height: '25px',
          borderRadius: '50%',
          backgroundColor: hexColor,
          margin: '7px',
        }}
      />
      <span style = {{paddingRight: '10px'}}>{text}</span>
    </div>
  );

  // Обновляем ref при изменении соответствующих значений
  useEffect(() => {
    colorRef.current = color;
    gradientColorRef.current = gradientColor;
    gradientPaletteRef.current = gradientPalette;

    const cssPaletteColor = gradientPalette ? gradientColor : color;
    document.documentElement.style.setProperty('--brush-color', cssPaletteColor);
    document.documentElement.style.setProperty('--brush-solid-color', chroma(cssPaletteColor).alpha(1).hex('rgba'));
  }, [color, gradientColor, gradientPalette]);

  useEffect(() => {
    lineWidthRef.current = lineWidth;
  }, [lineWidth]);

  useEffect(() => {
    softnessRef.current = softness ;
  }, [softness]);
  
  useEffect(() => {
    brushTypeRef.current = brushType ;
  }, [brushType]);
  useEffect(() => {
    effectTypeRef.current = effectType ;
  }, [effectType]);

  useEffect(() => {
    activeToolRef.current = activeTool;

    if (activeTool === 'eraser') {
      setColor(eraserColorRef.current); 
      setLineWidth(eraserSizeRef.current); 
    } else if (['brush', 'filler'].includes(activeTool)) {
      setColor(brushColorRef.current);
      setLineWidth(brushSizeRef.current);
    }
  }, [activeTool]);

  useEffect(() => {
    // Проверяем, стало ли значение showPalette равным false
    if (!showPalette) {
      handleLastColors();  // Вызываем функцию для обработки последних цветов
    }
  }, [showPalette]);  // Зависимость от showPalette

  // Обводка индикатора кисти
  useEffect(() => {

    const chromaColor = chroma(color); // текущий цвет
    const currentColorLuminance = chromaColor.luminance();
  
    const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--tg-theme-bg-color');
    const chromaBgColor = chroma(bgColor);
    const bgColorLuminance = chromaBgColor.luminance();

    const whiteLuminance = chroma('#FFFFFFFF').luminance();
    const blackLuminance = chroma('#00000000').luminance();
  
    const isLuminanceClose = Math.abs(currentColorLuminance - bgColorLuminance) < 0.05;

    const isLuminanceCloseToWhite = Math.abs(currentColorLuminance - whiteLuminance) < 0.05;
    const isLuminanceCloseToBlack = Math.abs(currentColorLuminance - blackLuminance) < 0.05;

    const textColorCss = getComputedStyle(document.documentElement).getPropertyValue('--tg-theme-text-color').trim();
    const rgbaTextColor = chroma(textColorCss).alpha(0.3).css(); // Преобразование в rgba с прозрачностью 0.5

    if (activeTool === 'eraser') {

      const brushIndicatorFrameValue = 
      isLuminanceCloseToWhite ? `0 0 0 1px rgba(30, 30, 30, 0.2)` :
      isLuminanceCloseToBlack ? `0 0 0 1px rgba(175, 175, 175, 0.35)` :
      (isLuminanceClose || chromaColor.alpha() < 0.1)
        ? `0 0 0 1px ${rgbaTextColor}` // используем переменную CSS для цвета текста
        : `0 0 0 1px rgba(30, 30, 30, 0.2)`;
        // : 'none';
      setBrushIndicatorFrame(brushIndicatorFrameValue);

    } else if (activeTool === 'effect') {
      
      if (['darken'].includes(effectTypeRef.current)) {
        setBrushIndicatorFrame(`0 0 0 1px rgba(255, 255, 255, 0.4)`);
      } else {
        setBrushIndicatorFrame(`0 0 0 1px rgba(0, 0, 0, 0.4)`);
      }


    } else {

      const brushIndicatorFrameValue = 
      isLuminanceCloseToWhite ? `0 0 0 1px rgba(30, 30, 30, 0.2)` :
      (isLuminanceClose || chromaColor.alpha() < 0.1)
      ? `0 0 0 1px ${rgbaTextColor}` // используем переменную CSS для цвета текста
      : 'none';
      setBrushIndicatorFrame(brushIndicatorFrameValue);
    }

    // Определяем цвет текста в зависимости от яркости текущего цвета
    const textColor = (currentColorLuminance > 0.9) || (chromaColor.alpha() < 0.1) ? '#222222' : '#EEEEEE';
    setMultiplierTextColor(textColor);
  
  }, [color, activeTool, brushType, effectType]);

  useEffect(() =>{
    showPaletteRef.current = showPalette;
    if (showPalette) {
      prevColorRef.current = color;
    } else {
      saveGameSets({
        color,
        gradientColor,
        eraserColor: eraserColorRef.current,
      });
    }
  }, [showPalette])


  return (
    <BrushContext.Provider value={{ 

      lastActionTimeRef,
      activeTool, setActiveTool,
      activeToolRef,
      previousToolRef,

      brushType, setBrushType,
      brushTypeRef,
      brushHistoryRef,

      effectType, setEffectType,
      effectTypeRef,
      effectHistoryRef,

      color, setTheColorFun,
      colorRef,
      prevColorRef,
      visibleColorFun,

      gradientColor, setGradientColor,
      gradientColorRef,
      gradientPalette, setGradientPalette,
      gradientPaletteRef,
      gradientColorFun,

      actualSoftnessFun,
      customizeCurrentBrush,
      brushSetsFun,
      brushLinkFun,
      ifIndicateBrushFun,

      brushColorRef,
      eraserColorRef,
      grayPaletteColor, setGrayPaletteColor,


      quickColorChangeTimeRef,
      previousColorRef,
      
      lastColorsRef,
      handleLastColors,

      setTheSizeFun,
      lineWidth, setLineWidth: setTheSizeFun,
      lineWidthRef,
      previousLineWidthRef,
      visibleLineWidth, setVisibleLineWidth,

      softness, setSoftness,
      softnessRef,

      hotMouseActive, setHotMouseActive,
      hotMouseStartPositionRef,

      isResizingBrush, setIsResizingBrush,
      isChangingBrightness, setIsChangingBrightness,
      isChangingOpacity, setIsChangingOpacity,
      isChangingSoftness, setIsChangingSoftness,

      lastBrushChangeTime,

      shiftIsDownRef,
      altIsDownRef,
      controlIsDownRef,
      spaceIsDownRef,
      tabIsDownRef,
      keyZIsDownRef,

      showPalette, setShowPalette,
      showPaletteRef,
      magnifierOn, setMagnifierOn,
      premiumGrayscale, setPremiumGrayscale,

      isPipettePicking, setIsPipettePicking,
      pickingPlace, setPickingPlace,
      pickingPlaceRef,

      longPressTimeoutRef,
      shapeStartPointRef,
      drawingShapeRef,
      lastShapeMadeTimeRef,

      multiplierTextColor, setMultiplierTextColor,
      brushIndicatorFrame, setBrushIndicatorFrame,

      adjustColor,
      blendColors,
      clipboardColor,
      ColorHint,

      getTexture, sources, brushDefaults,
      filterClosePoints,

       }}>
      {children}
    </BrushContext.Provider>
  );
};

export const useBrushContext = () => useContext(BrushContext);