// brushLoader.js

// brushLoader.js
import {
  addTextureCache,
  getTextureFromCache,
  getTexturesForBrush,
} from '../../../../utils/caching/indexedDBcache'; // Импортируем функции для работы с текстурами в IndexedDB


export const brushDefaults = {
  common: {
    smoothing: 0,
    squareBrush: false,
    magnifierButton: false,
    brushMagnifier: false,
    eraserMagnifier: false,
    pressureOn: true,
    sensitivity: 1,
    pressureCoef: 1,
    pressureExponent: 2.5,
    minimalPressure: 0,
  },
  test: {
    pressureAvailable: true,
  },
  liner: {
    pressureAvailable: false,
  },
  plain: {
    pressureAvailable: true,
  },
  stylus: {
    pressureAvailable: true,
  },
  shape: {
    figure: 'line',
    brush: 'plain',
    shape: true,
  },
  outlined: {
    outlineSize: 5,
    outlineColor: '#000000FF',
    outlineOpacity: 1,
    pressureAvailable: true,
  },
  dashed: {
    dashSize: 3,
    gapSize: 5,
  },
  spray: {
    softness: 0.3,
    pressureAvailable: true,
    pressureOn: false,
  },
  bristle: {
    wobble: 0.05,
    ragged: 0,
    opacity: 0.3,
    pressureAvailable: true,
    pressureOn: true,
  },
  feather: {
    speedDependence: false,

    centralWidth: 0.4,
    
    minWidthStart: 0.01,
    taperStartPoint: 0.5,
    minWidthEnd: 0.01,
    taperEndPoint: 0.5,

    transparentEdges: false,
    opacityOnStart: 0,
    fadeStartPoint: 0.3,
    opacityOnEnd: 0,
    fadeEndPoint: 0.7,
  },
  ink: {
    speedDependence: false,

    edgeWidening: true,
    centralWidth: 0.4,
    
    minWidthStart: 1,
    taperStartPoint: 0.5,
    minWidthEnd: 1,
    taperEndPoint: 0.5,

    transparentEdges: false,
    opacityOnStart: 0,
    fadeStartPoint: 0.3,
    opacityOnEnd: 0,
    fadeEndPoint: 0.7,
  },
  watercolor: {
    textureOn: true,
    texture: 'waterAi',
    opacity: 0.7,
    spreading: true,
    textureScale: 1,
    waterBlurSize: 15,
    waterBlurAlpha: 0.8,

    outline: true,
    outlineSize: 2,
    outlineOpacity: 0.7,

    pressureAvailable: true,
    pressureOn: false,
  },

  pencil: {
    textureOn: true,
    texture: 'basic',
    opacity: 0.8,
    outline: true,
    textureScale: 1,

    tapering: false,
    minWidthStart: 0.6,
    taperStartPoint: 0.5,
    minWidthEnd: 0.6,
    taperEndPoint: 0.5,

    transparentEdges: false,
    opacityOnStart: 0,
    fadeStartPoint: 0.5,
    opacityOnEnd: 0,
    fadeEndPoint: 0.5,

    pressureAvailable: true,
    pressureOn: false,
  },
  oil: {
    textureOn: true,
    texture: 'fabric',

    speedDependence: true,
    minWidthStart: 0.3,
    taperStartPoint: 0.5,
    minWidthEnd: 0.3,
    taperEndPoint: 0.5,
    opacity: 1,
    textureScale: 1,

    pressureAvailable: true,
    pressureOn: false,
  },
  rembrandt: {
    cutEdges: true,
    density: 0.5,
    tapering: 0.5,
    wobble: 0,
    opacity: 1,
    ragged: 1,
    shadowStrength: 0.5,
    ropeEffect: false,

    pressureAvailable: true,
    pressureOn: true,

  },

  neon: {
    neonSize: 25,
    opacity: 0.75,
  },
  sparkle: {
    highDensity: false,
    bigSizes: false,
    sparkleDensity: 0.5,
    sparkleSize: 1, 
    sparkleDistributionPower: 0.8, 
    sparkleShape: 'square', 
    opacity: 1, 

    pressureAvailable: true,
  },
  glyph: {
    pressureAvailable: true,
    pressureOn: true,
    pressureOpacity: false,
    glyphRotation: false,
    opacity: 1, 
    enhance: true,
    stepSize: 1,
    highTransparency: false, 
    shadowStrength: 0,
  },
  filler: {
    tolerance: 5,
    antialiasing: 0.5,
    eatEdges: true,
  },
  pixelate: {
    size: 20,
    sizeX: 20,
    sizeY: 20,
  },
  texture: {
    textureOn: true,
    texture: 'waterAi',
    textureScale: 1,
    opacity: 0.2,
    blending: 0.2,
    composition: 'luminosity',
  },
  
}


export const sources = {
  texture: {
    waterAi: {
      stroke: '/textures/water-ai-400.png',
      light: '/textures/water-ai-400-l.png',
    },
    naturalPaper: {
      stroke: '/textures/test/natural-paper.png',
      light: '/textures/test/natural-paper-l2.png',
    },
    paper: {
      stroke: '/textures/test/paper.png',
      light: '/textures/test/paper-l.png',
    },
    pencil: {
      stroke: '/textures/pencil-150.png',
      light: '/textures/pencil-150.png',
    },
    graphite: {
      stroke: '/textures/graphite-250.png',
      light: '/textures/graphite-250.png',
    },
    fabric: {
      stroke: './textures/fabric.png',
      light: './textures/fabric.png',
    },
 
  },
  backup: {
    diagonal: {
      stroke: '/textures/test/diagonal-striped-brick.png',
    }
  },
  watercolor: {
    waterAi: {
      stroke: '/textures/water-ai-400.png',
      light: '/textures/water-ai-400-l.png',
    },
    naturalPaper: {
      stroke: '/textures/test/natural-paper.png',
      light: '/textures/test/natural-paper-l2.png',
    },
    paper: {
      stroke: '/textures/test/paper.png',
      light: '/textures/test/paper-l.png',
    },
    // paper1: {
    //   stroke: '/textures/test/paper-1.png',
    //   light: '/textures/test/paper-1.png',
    // },
    // paper2: {
    //   stroke: '/textures/test/paper-2.png',
    //   light: '/textures/test/paper-2.png',
    // },
  },
  pencil: {
    basic: {
      stroke: '/textures/pencil-150.png',
    },
    graphite: {
      stroke: '/textures/graphite-250.png',
    },
    // basic200: {
    //   stroke: '/textures/pencil-200.png',
    // },
  },
  oil: {
    fabric: {
      stroke: './textures/fabric.png',
    },
  },
};





const textures = {};

/**
 * Загружает текстуру с сервера как HTMLImageElement.
 * @param {string} src - URL текстуры.
 * @returns {Promise<HTMLImageElement>} - Изображение.
 */
function loadTexture(src) {
  window.logPerformance?.(`Texture loading STARTED: ${src}`);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      window.logPerformance?.(`Texture loading FINISHED: ${src}`);
      resolve(image);
    };
    image.onerror = reject;
    image.src = src;
  });
}

async function imageToBlob(image) {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png');
  });
}

async function blobToImage(blob) {
  const url = URL.createObjectURL(blob);
  const image = new Image();
  return new Promise((resolve, reject) => {
    image.onload = () => {
      URL.revokeObjectURL(url);
      resolve(image);
    };
    image.onerror = () => {
      URL.revokeObjectURL(url);
      reject(new Error('Failed to load image from blob'));
    };
    image.src = url;
  });
}

export async function getTexture(brush, base, type = 'stroke') {

  if (textures[brush]?.[base]?.[type]) {
    return textures[brush][base][type];
  }

  window.croco?.log(`🌐 Getting texture: ${brush}/${base}/${type}`);

  const src = sources[brush]?.[base]?.[type];
  if (!src) {
    window.croco?.log(`❓ Texture not found: ${brush}/${base}/${type}`);
    return await getTexture('backup', 'diagonal', 'stroke');
  }

  // Пытаемся загрузить текстуру из IndexedDB
  let cachedBlob = null;
  try {
    cachedBlob = await getTextureFromCache({ brushName: brush, textureName: base, type });
  } catch (error) {
    window.croco?.log(`❗️ Failed to load texture from cache for ${brush}/${base}/${type}:`, error);
  }

  let image;
  if (cachedBlob) {
    try {
      image = await blobToImage(cachedBlob);
      window.croco?.log(`📂 Texture loaded from cache: ${brush}/${base}/${type}`);
    } catch (error) {
      window.croco?.log(`❗️ Failed to convert cached blob to image for ${brush}/${base}/${type}:`, error);
      image = null; // Если преобразование не удалось, попробуем загрузить из сети
    }
  }

  if (!image) {
    // Загружаем текстуру из сети с помощью loadTexture
    image = await loadTexture(src);
    window.croco?.log(`⬇️ Texture loaded from server: ${brush}/${base}/${type}`);

    // Сохраняем текстуру в IndexedDB как Blob
    try {
      const blob = await imageToBlob(image);
      await addTextureCache({ brushName: brush, textureName: base, type, blob });
      window.croco?.log(`💾 Texture cached in IndexedDB: ${brush}/${base}/${type}`);
    } catch (error) {
      window.croco?.log(`❗️ Failed to cache texture in IndexedDB for ${brush}/${base}/${type}:`, error);
    }
  }

  if (!textures[brush]) textures[brush] = {};
  if (!textures[brush][base]) textures[brush][base] = {};
  textures[brush][base][type] = image;

  return image;
}



export async function preloadTextures(brushName, textureName) {
  if (!sources[brushName]) {
    throw new Error(`Brush "${brushName}" not found in sources`);
  }

  const texturesToLoad = {};

  if (!textureName) {
    for (const [tName, tValue] of Object.entries(sources[brushName])) {
      texturesToLoad[tName] = tValue;
    }
  } else {
    if (!sources[brushName][textureName]) {
      throw new Error(`Texture "${textureName}" not found for brush "${brushName}"`);
    }
    texturesToLoad[textureName] = sources[brushName][textureName];
  }

  for (const [tName, tValue] of Object.entries(texturesToLoad)) {
    for (const type of Object.keys(tValue)) {
      await getTexture(brushName, tName, type)
    }
  }

  window.croco?.log(`🔰 All textures preloaded for brush "${brushName}${textureName ? `, texture "${textureName}"` : ''}`);

}

