// PaletteInstruments.js

import React, { useState, useRef } from 'react';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import './PaletteInstruments.css';  // Импортируем CSS файл
import SavedColorsPalette from './SavedColorsPalette';
import ColorModeSwitch from './ColorModeSwitch';

import useStore from '../../../../store';

function PaletteInstruments({ 
  store,
  onClose,
 }) {

  const { t } = useTranslation();

  const color = useStore((state) => state.color);
  const gradientColor = useStore((state) => state.gradientColor);
  const premiumGrayscale = useStore((state) => state.premiumGrayscale);

  const { work, menu, info, brush, premium, } = store.ref;

  const { user } = info;
  const { premiumFeatures = [] } = premium;

  const {
    appleHaptic,

    delayPremiumMenu,
    showTemporaryHint,

    forceRender,
  } = menu.methods;

  const {

    saveUserGameSets,
    getUserGameSets,
    saveUserSets,
  } = work.methods;

  const addCurrentColor = () => {

    if (!premiumFeatures.includes('saveColors') 
    || user.premiumOff) {
      delayPremiumMenu('menu');
      return;
    }
    const newColors = [color, ...(user.colors || [])];
    saveUserSets({colors: newColors});
    forceRender();

    appleHaptic('soft');

  };

  const handleGradient = (e) => {

    e.preventDefault();
    e.stopPropagation();

    if (Date.now() - brush.lastActionTime < 500) {return;}
    if (!getUserGameSets().gradientOn) {
      return showTemporaryHint(t('tooltip.gradient'),  {duration: 6000})
    }
    useStore.setState({gradientPalette: true});

  }

  const toggleGradient = (e) => {

    e.preventDefault();
    e.stopPropagation();

    let newValue = !getUserGameSets().gradientOn;
    brush.lastActionTime = Date.now();
    if (!newValue) { useStore.setState({gradientPalette: false}); }

    saveUserGameSets({gradientOn: newValue})
    useStore.setState(state=>({gradientPalette: state.gradientPalette}));

    return showTemporaryHint(t(`tooltip.${newValue ? 'gradient_on' : 'gradient_off'}`),  {duration: 1500, force: true}, {minWidth: '200px'})

  }

  const rows = [[], [], [], []];
  const showColors = (user.colors || []).slice(0, 20);
  showColors.forEach((savedColor, i) => {
    const rowIndex = Math.floor(i / 5);
    if (rows[rowIndex]) {
      rows[rowIndex].unshift({savedColor, i});
    }
  });

  return (
    <div className="palette-instruments-container">
    <SavedColorsPalette
      store={store}
    />
    <div className="controls-container">
      <div 
        style={{width: '100%', height: '50px', marginBottom: '10px'}}
        onClick={ onClose }
      ></div>
      <div 
        className='top-controls'
        onClick={ handleGradient }
        // onTouchStart={ handleGradient }
        onContextMenu={ toggleGradient }
      >
        <div
          className={`gradient-color-switch ${getUserGameSets().gradientOn ? 'gradient-on' : ''}`}
          style={{ background: getUserGameSets().gradientOn ? 
            `linear-gradient(to right, ${color}, ${gradientColor || '#0000'})` :
            `linear-gradient(to right, white, black)`
           }}
        />
      </div>

      <div className="bottom-controls">
        <div onClick={addCurrentColor} className="saved-add-color">
          <svg className="saved-icon" viewBox="0 0 24 24">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
          </svg>
        </div>
        {premiumFeatures.includes('colors') && !user.premiumOff && (
          <ColorModeSwitch 
            premiumGrayscale={premiumGrayscale} 
            useStore={useStore}
          />
        )}
      </div>
    </div>
  </div>
  );
}

export default PaletteInstruments;
