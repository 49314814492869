// BrightnessControl.js
import React, { useState, useEffect } from 'react';
import chroma from 'chroma-js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.js";
import ColorIndicator from './ColorIndicator'; 
import { filterPosition } from './Throttler/filterPosition.js'; // путь к вашему хуку
import useStore from '../../../store';

const BrightnessControl = ({ 
  store,
  icon,
  available,
}) => {

  const { color, gradientColor, activeTool } = useStore.getState();
  const { menu, brush } = store.ref;
  const { setTheColorFun, changeTool } = brush.methods;
  const { forceRender } = menu.methods;

  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 

  const startChangingBrightness = (event) => {
    event.preventDefault();
    const workColor = brush.gradientPalette ? gradientColor : color;

    if (event?.button === 2) { return; }

    brush.fast.isChangingBrightness = true;

    const x = event.clientX || event.touches?.[0]?.clientX;
    const y = event.clientY || event.touches?.[0]?.clientY;

    setClickPosition({ x, y });
    setPointerPosition({ x, y });

    // Сохраняем начальные значения в brush.fast
    brush.fast.startTime = Date.now();
    brush.fast.startTouchPoint = event?.touches?.[0] || event;
    brush.fast.initialSaturation = chroma(workColor).get('hsv.s');
    brush.fast.initialHue = chroma(workColor).get('hsv.h');
    brush.fast.initialAlpha = chroma(workColor).alpha();
  };

  useEffect(() => {

    const handlePointerMove = (event) => {
      event.preventDefault();
      const workColor = brush.gradientPalette ? gradientColor : color;

      if (event?.button === 2) { return; }
      if (!brush.fast.isChangingBrightness) return;

      const position = {
        x: event.clientX || event.touches?.[0]?.clientX,
        y: event.clientY || event.touches?.[0]?.clientY,
      };

      const filteredPosition = filterPosition(position, 'pointerPosition', 100);
      const diffY = pointerPosition.y - filteredPosition.y;
      setPointerPosition(filteredPosition);
      
      if (diffY === 0) return;

      // Изменение яркости
      const currentColor = chroma(workColor);
      const currentBrightness = currentColor.get('hsv.v');
      let newBrightness = Math.max(0, Math.min(1, currentBrightness + (diffY * 0.005)));

      if (newBrightness < 0.01) { return; }
  
      let newColor = currentColor;
      newColor = newColor.set('hsv.v', newBrightness);
      newColor = newColor.set('hsv.s', brush.fast.initialSaturation);
      newColor = newColor.set('hsv.h', brush.fast.initialHue);
      newColor = newColor.alpha(brush.fast.initialAlpha);

      let finalBrightness = chroma(newColor).get('hsv.v');
      if (!finalBrightness) { return; }
      
      setTheColorFun(chroma(newColor).hex());
      brush.fast.hasMoved = true;
    };

    const handlePointerUp = (event) => {
      event.preventDefault();

      if (event?.button === 2) { return; }

      brush.fast.isChangingBrightness = false;
      if (brush.fast.startTouchPoint && Date.now() - brush.fast.startTime < 350) {
        changeTool("pipette");
      }
      forceRender();
    };

    if (brush.fast.isChangingBrightness) {
      document.addEventListener('mousemove', handlePointerMove);
      document.addEventListener('mouseup', handlePointerUp);
      document.addEventListener('touchmove', handlePointerMove);
      document.addEventListener('touchend', handlePointerUp);
    }

    return () => {
      document.removeEventListener('mousemove', handlePointerMove);
      document.removeEventListener('mouseup', handlePointerUp);
      document.removeEventListener('touchmove', handlePointerMove);
      document.removeEventListener('touchend', handlePointerUp);
    };
  }, [brush.fast.isChangingBrightness]);

  const brightness = chroma(color).get('hsv.v').toFixed(2) + ' ☼';

  return (
    <div>
      <div
        onMouseDown={startChangingBrightness}
        onTouchStart={startChangingBrightness}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50px',
          height: '50px',
        }}
      >
        <CircleSwitch
          icon={icon}
          available={available}
          isActive={activeTool === "pipette"}
        />
      </div>
      {brush.fast.isChangingBrightness && (
        <ColorIndicator
          store={store}
          position={pointerPosition}
          clickPosition={clickPosition}
          brightness={brightness}
        />
      )}
    </div>
  );
};

export default BrightnessControl;
