// useShapes.js

import { useCallback, useEffect, useRef, } from 'react';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { drawPlainMarkerStroke } from './brushes/plain';
import { drawFeatherStroke } from './brushes/feather';

export const useShapes = ({
  saveHistory,
  getEventPos,
  redrawCanvas,

  touchZoomStart,
  touchZoomMove,

  wipeTempCanvas,

  addStroke,
  drawPreview,
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    activeUserIdRef,
    forceRender,
  } = gameContext;

  const {
    isDrawingRef,

    tempCanvasRef,
    canvasRef,
    touchMoveLastTimeRef,
    touchEndLastTimeRef,
  
  } = canvasContext;

  const {
    colorRef,
    lineWidthRef,
    visibleColorFun,
    gradientColorFun,

    longPressTimeoutRef,
    shapeStartPointRef,
    drawingShapeRef,
    lastShapeMadeTimeRef,

    brushLinkFun,
    brushSetsFun,
  } = brushContext;

  const {
    userStrokesRef,
  } = HistoryContext;


  const straightLine = ({ nativeEvent }) => {

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (myStrokes.length === 0) return;

    let myHistory = myStrokes.filter(stroke=> !stroke.cancelled);
    const lastStroke = myHistory[myHistory.length - 1];

    if (!lastStroke?.points?.length) return;

    const {x, y} = getEventPos(nativeEvent);

    if (lastStroke.points.length === 1) {
      lastStroke.hidden = true;
    } 

    const shapeId = lastStroke.shapeId || Date.now();
    lastStroke.shapeId = shapeId;
    lastStroke.hidden = true;

    if (nativeEvent.button === 2) {

      addStroke([...lastStroke.points, [x, y]], { 
        time: Date.now(),
        shapeId,
       })

    } else {

      const lastStrokeLastPoint = lastStroke.points[lastStroke.points.length - 1];

      let newStrokePoints = lastStroke.points.length === 1 ? 
      [lastStrokeLastPoint, [x, y]] : 
      [...lastStroke.points, lastStrokeLastPoint, [x, y]];

      addStroke(newStrokePoints, { 
        time: Date.now(),
        shapeId,
       })

    }

    // if (nativeEvent.button === 2) {

    //   const shapeId = lastStroke.shapeId || Date.now();

    //   lastStroke.shapeId = shapeId;
    //   lastStroke.hidden = true;

    //   addStroke([...lastStroke.points, [x, y]], { 
    //     time: Date.now(),
    //     shapeId,
    //    })

    // } else {

    //   const lastPoint = lastStroke.points[lastStroke.points.length - 1];
    //   addStroke([lastPoint, [x, y]], { time: Date.now() })

    // }


  };

  const handleShapeStart = useCallback((event) => {

    shapeStartPointRef.current = getEventPos(event);  // Record the start point

    const activationTime = 
    lastShapeMadeTimeRef.current > Date.now() - 2000 ? 500 : 500;

    clearTimeout(longPressTimeoutRef.current);
    longPressTimeoutRef.current = setTimeout(() => {

      const now = Date.now();

      if (
        now - touchMoveLastTimeRef.current > activationTime
        && now - touchEndLastTimeRef.current > activationTime
        ) {

        drawingShapeRef.current = true;
        isDrawingRef.current = false;

        // wipeTempCanvas();
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

      }

    }, activationTime);  

  }, [drawingShapeRef, drawingShapeRef, getEventPos, wipeTempCanvas]);


  const handleShapeMove = useCallback(async (event) => {

    if (!drawingShapeRef.current) return;

    wipeTempCanvas();

    const sets = brushSetsFun();
    const commonSets = brushSetsFun('common');
    Object.assign(sets, commonSets, { composition: sets.composition ?? commonSets.composition });

    const now = Date.now();
    const stroke = {
      points: [shapeStartPointRef.current, getEventPos(event)].map(point=>{
        if (Array.isArray(point)) {return point}
        else {return [point.x, point.y]}
      }),
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      sets,
      time: now,
      shapeId: now,
    };

    await drawPreview(stroke);


  }, [getEventPos, wipeTempCanvas]);

  const handleShapeEnd = useCallback((event) => {

    clearTimeout(longPressTimeoutRef.current);
    const now = Date.now();

    addStroke(
      [shapeStartPointRef.current, getEventPos(event.changedTouches[0])],
      { 
        time: now,
        shapeId: now,
       }
      );
    wipeTempCanvas(); // Clear preview canvas
    lastShapeMadeTimeRef.current = Date.now();
    
  }, [addStroke, saveHistory, wipeTempCanvas]);



  return {
    straightLine,

    handleShapeStart,
    handleShapeMove,
    handleShapeEnd,
  }
};

