// src/components/RootComponent.jsx
import React, { useEffect } from 'react';
import useStore from '../store';
// import Board from './board/Board';
import Main from './Main';

const RootComponent = () => {

  // Functions
  const initTelegram = useStore(state => state.initTelegram);
  const connectSocket = useStore(state => state.connectSocket);
  const loadGameData = useStore(state => state.loadGameData);
  
  // flags
  const telegramInitialized = useStore(state => state.telegramInitialized);
  const socketStatus = useStore(state => state.socketStatus);
  const isValid = useStore(state => state.isValid);

  const socket = useStore(state => state.socket);
  const gameInfo = useStore(state => state.gameInfo);
  const applyHistory = useStore(state => state.applyHistory);
  
  const { ref, setupCanvasSizes } = useStore.getState();
  // 1. Инициализация Telegram
  useEffect(() => {
    initTelegram((menu) => {
      // Пример обработчика открытия меню (можно заменить на свою логику)
      console.log('Открыть меню:', menu);
    });
  }, []);

  // 2. Подключение сокета после инициализации Telegram
  useEffect(() => {
    if (telegramInitialized  && !socket?.connected) {
      connectSocket();
    }
  }, [telegramInitialized]);

  // 3. Загрузка данных игры после успешной регистрации (isValid становится не null)
  useEffect(() => {
    if (socket?.connected && isValid !== null) {
      if (isValid) {
        loadGameData();
      } else {
        console.error('Регистрация не прошла: пользователь невалидный');
      }
    }
  }, [socket, socketStatus, isValid, loadGameData]);

  useEffect(() => {
    if (gameInfo && !ref.history.mounted) {
      applyHistory();
    }
  }, [gameInfo]);



  return (
    <div>
      <Main />
    </div>
  );
};

export default RootComponent;
