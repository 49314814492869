// blur.js
import { seededRandom } from '../pencil'; // Reusing the seededRandom function

export function drawBlurStroke({
  lineWidth,
  points,
  time,
}, context) {
  // if (points.length < 2) return;

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Copy the original canvas content to the buffer
  bufferCtx.drawImage(context.canvas, 0, 0);

  // Create a mask for the stroke
  const maskCanvas = document.createElement('canvas');
  maskCanvas.width = bufferCanvas.width;
  maskCanvas.height = bufferCanvas.height;
  const maskCtx = maskCanvas.getContext('2d');

  // Draw the stroke path on the mask with gradient
  drawGradientStroke(maskCtx, points, lineWidth);

  // Apply blur effect
  applyBlurAlongPath(bufferCtx, maskCanvas, lineWidth);

  // Add some noise to simulate pencil texture
  // addNoise(bufferCtx, maskCanvas, time);

  // Draw the result on the main canvas
  context.drawImage(bufferCanvas, 0, 0);
}

function drawGradientStroke(ctx, points, lineWidth) {
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
  ctx.lineWidth = lineWidth;

  const gradient = ctx.createLinearGradient(points[0][0], points[0][1], points[points.length-1][0], points[points.length-1][1]);
  gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

  ctx.strokeStyle = gradient;
  ctx.beginPath();
  ctx.moveTo(points[0][0], points[0][1]);
  for (let i = 1; i < points.length; i++) {
    ctx.lineTo(points[i][0], points[i][1]);
  }
  ctx.stroke();
}

function applyBlurAlongPath(ctx, maskCanvas, lineWidth) {
  const maxBlur = Math.min(20, lineWidth / 2);
  const blurLevels = 10;
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = ctx.canvas.width;
  tempCanvas.height = ctx.canvas.height;
  const tempCtx = tempCanvas.getContext('2d');

  // Create multiple blur layers
  for (let i = 0; i < blurLevels; i++) {
    tempCtx.save();
    tempCtx.filter = `blur(${maxBlur * (1 - i / blurLevels)}px)`;
    tempCtx.globalAlpha = 1 / (i + 1);
    tempCtx.drawImage(ctx.canvas, 0, 0);
    tempCtx.restore();
  }

  // Apply the blur using the mask
  ctx.save();
  ctx.globalCompositeOperation = 'source-over';
  ctx.drawImage(tempCanvas, 0, 0);
  ctx.globalCompositeOperation = 'destination-in';
  ctx.drawImage(maskCanvas, 0, 0);
  ctx.restore();
}

function addNoise(ctx, maskCanvas, time = 0) {
  const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  const maskData = maskCanvas.getContext('2d').getImageData(0, 0, maskCanvas.width, maskCanvas.height);
  const data = imageData.data;
  const maskDataArr = maskData.data;

  for (let i = 0; i < data.length; i += 4) {
    if (maskDataArr[i + 3] > 0) {
      const noise = (seededRandom(time + i) - 0.5) * 30 * (maskDataArr[i + 3] / 255);
      data[i] = Math.max(0, Math.min(255, data[i] + noise));
      data[i + 1] = Math.max(0, Math.min(255, data[i + 1] + noise));
      data[i + 2] = Math.max(0, Math.min(255, data[i + 2] + noise));
    }
  }
  ctx.putImageData(imageData, 0, 0);
}