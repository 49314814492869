// src/store/menuSlice.js

import chroma from 'chroma-js';

import { showTemporaryHint } from '../utils/interface/showHint';
import { createBetterClick } from '../utils/interface/createBetterClick';
  

const createMenuSlice = (set, get, ref) => {

  ref.menu = {
    params: {},
    opened: {},
    panel: {
      offset: 400,
      fastColorIndicatorY: 445,
    },
    source: {
      isOpen: false,
      imageUrl: '',
      aspectRatio: 1,
      position: { x: 20, y: 20 },
      size: { width: 200, height: 120 }
    },
  }

  ref.menu.methods = {
    showTemporaryHint,
    createBetterClick,

    forceRender: ()=>{
      set(state=>({render: state.render + 1}))
    },
    render: ()=>{
      set(state=>({render: state.render + 1}))
    },
    showPopupMessage: (message) =>{
      set({popupMessage: message});
      if (message && !message.mute) { 
        const level = message.haptic?.level || 'warning';
        const reason = message.haptic?.reason || 'notificationOccurred';

        ref.menu.methods.appleHaptic(level, reason);
      }
      ref.menu.methods.render();
    },
    openMenu: (menuId, more = {}) =>{
      ref.menu.params = more;
      ref.menu.opened[menuId] = more;
      set(state=>{
        if (more.layer) {
          return { showMenu: [menuId, ...(state.showMenu.filter(currentId => currentId !== menuId))] };
        } else {
          return { showMenu: [menuId] }
        }
      })
    },

    toggleMenu: (menuId) =>{
      set(state => ({showMenu: state.showMenu?.[0] === menuId ? [] : [menuId]}))
    },

    closeMenu: (menuId) =>{
      set(state=>({showMenu: menuId ? state.showMenu.filter(currentId => currentId !== menuId) : []}))
    },


    delayPremiumMenu: (value, delay = 50)=>{
      setTimeout(() => {
        set({showPremiumMenu: value})
      }, delay);
    },

    adjustColor: (cssVariable, baseColor) => {
      function getCSSVariableValue(variable) {
        return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
      }
    
      const cssColor = getCSSVariableValue(cssVariable);
      const cssChroma = chroma(cssColor);
      const baseChroma = chroma(baseColor);
      const [, s, l] = cssChroma.hsl();
      const [h, , ] = baseChroma.hsl();
      const resultColor = chroma.hsl(h, s, l);
      return resultColor.css();
    },
  
     blendColors: (cssVariable, baseColor, opacity = 0.2) => {
      function getCSSVariableValue(variable) {
        return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
      }
      const cssColor = getCSSVariableValue(cssVariable);
      const cssChroma = chroma(cssColor);
      const baseChroma = chroma(baseColor);
      const blendedColor = chroma.mix(cssChroma, baseChroma, opacity, 'rgb');
      return blendedColor.css();
    },

    appleHaptic: (level = 'light', reason = 'impactOccurred') => {
      if (!ref.info.user.vibro) { return; }
      if (ref.info.methods.isAppleDevice()) {
        window.Telegram?.WebApp?.HapticFeedback?.[reason]?.(level);
      }
    },
    androidHaptic: (level = 'success', reason = 'notificationOccurred') => {
      if (!ref.info.user.vibro) { return; }
      if (!ref.info.methods.isAppleDevice()) {
        window.Telegram?.WebApp?.HapticFeedback?.[reason]?.(level);
      }
    },
  
  }

  window.croco = window.croco || {}
  window.croco.haptic = (level = 'success', reason = 'notificationOccurred') => {
    if (ref.info.methods.isAppleDevice()) {
      ref.menu.methods.appleHaptic(level, reason);
    } else {
      ref.menu.methods.androidHaptic(level, reason)
    }
  }
  window.croco.appleHaptic = ref.menu.methods.appleHaptic;
  window.croco.androidHaptic = ref.menu.methods.androidHaptic;

  return {
    render: 0,
    spinner: 0,
    showMenu: [],
  }

};

export default createMenuSlice;
