// BrushSizeControl.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';

import BrushSizeIndicator from './BrushSizeIndicator'; 
import { filterPosition } from './Throttler/filterPosition.js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.js";
import useStore from '../../../store';

const BrushSizeControl = ({ 
  store,
  icon,
  buttonColor,
  defaultBrushSize = 7,
  maxButtonSize = 50,
  hideButton = false,
  hideInidicator = false,
  showMultiplier = true,
  style,
  brushInfoStyle,
}) => {

  const { t } = useTranslation();

  const lineWidth = useStore((state) => state.lineWidth);
  const zoomFactor = useStore((state) => state.zoomFactor);
  const color = useStore((state) => state.color);

  const { menu, brush } = store.ref;
  // Используем brush.fast для хранения временных данных (уже создан заранее)
  
  const { visibleLineWidthFun, setTheSizeFun, visibleColorFun, actualSoftnessFun, brushSetsFun, brushLinkFun } = brush.methods;
  const { forceRender, showTemporaryHint, appleHaptic } = menu.methods;
  
  const [needTipIcon, setNeedTipIcon] = useState(true);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 

  // Максимальный размер для отображения размера кисти
  const displaySize = Math.min(visibleLineWidthFun(), maxButtonSize);
  const scale = visibleLineWidthFun() / maxButtonSize;

  const brushSizeText = lineWidth.toFixed(Math.min(1, Math.floor(zoomFactor / 8)));
  const bushOpacityText = Math.round(chroma(buttonColor || color).alpha() * 100);

  const squareIndicator = brushSetsFun('common').squareBrush && ['plain', 'dashed', 'outlined', 'pencil', 'feather'].includes(brushLinkFun());

  const chromaColor = chroma(buttonColor || color); // текущий цвет
  const multiplierTextColor = (chromaColor.luminance() > 0.9) || (chromaColor.alpha() < 0.1) ? '#222222' : '#EEEEEE';

  const startResizing = (event) => {
    brush.fast.isResizingBrush = true;
    const x = event.clientX || event.touches?.[0]?.clientX;
    const y = event.clientY || event.touches?.[0]?.clientY;

    setClickPosition({ x, y });
    setPointerPosition({ x, y });

    // Сохраняем данные в brush.fast вместо useRef
    brush.fast.startTime = Date.now();
    brush.fast.startTouchPoint = event?.touches?.[0] || event;

    appleHaptic?.('soft');
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setTheSizeFun(defaultBrushSize);
    showTemporaryHint(t('tooltip.line_width_default', { defaultBrushSize }));
  };

  useEffect(() => {
    const handlePointerMove = (event) => {
      event.preventDefault();

      if (!brush.fast.isResizingBrush) return;

      const position = {
        x: event.clientX || event.touches?.[0]?.clientX,
        y: event.clientY || event.touches?.[0]?.clientY,
      };

      const filteredPosition = filterPosition(position, 'pointerPosition', 100);
      const diffY = pointerPosition.y - filteredPosition.y;
      setPointerPosition(filteredPosition);

      if (diffY === 0) return;

      const deviderCoef = 1.5;
      let lineWidthChange = Math.min(Math.max(diffY / deviderCoef, -12), 12) / zoomFactor;

      setTheSizeFun(currentLineWidth => {
        const visualSize = currentLineWidth * zoomFactor;
        if (visualSize < 30) {
          lineWidthChange = lineWidthChange / Math.sqrt(30 - visualSize);
        }
        return Math.max(1, currentLineWidth + lineWidthChange);
      });

      // Отмечаем, что движение произошло
      brush.fast.hasMoved = true;
    };

    const handleHint = (event) => {
      if (event?.button === 2) return;
      showTemporaryHint(t('tooltip.line_width_button'), {}, { top: '45%' });
    };

    const handlePointerUp = (event) => {
      event.preventDefault();
      brush.fast.isResizingBrush = false;
    
      if (brush.fast.startTouchPoint) {
        // Используем changedTouches вместо touches для корректного получения координаты
        const y = event.clientY || event.changedTouches?.[0]?.clientY;
        const diffY = brush.fast.startTouchPoint.clientY - y;
    
        if (Date.now() - brush.fast.startTime < 200 && Math.abs(diffY) < 5) {
          handleHint(event);
        }
        setNeedTipIcon(false);
      }
      forceRender();
    };
    

    if (brush.fast.isResizingBrush) {
      document.addEventListener('mousemove', handlePointerMove);
      document.addEventListener('mouseup', handlePointerUp);
      document.addEventListener('touchmove', handlePointerMove);
      document.addEventListener('touchend', handlePointerUp);
    }

    return () => {
      document.removeEventListener('mousemove', handlePointerMove);
      document.removeEventListener('mouseup', handlePointerUp);
      document.removeEventListener('touchmove', handlePointerMove);
      document.removeEventListener('touchend', handlePointerUp);
    };

  }, [pointerPosition, brush.fast.isResizingBrush]);

  const blurSize = `${actualSoftnessFun() * displaySize}px`;

  return (
    <div
      className='allow-context-menu'
      onMouseDown={startResizing}
      onContextMenu={handleContextMenu}
      onTouchStart={startResizing}
      style={{
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'ns-resize',
        userSelect: 'none',
        position: 'relative',
        zIndex: 200,
        ...style,
      }}
    >
      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 201,
        opacity: 0,
        top: 0,
        left: 0,
      }}></div>

      <div style={{
        width: `${displaySize}px`,
        height: `${displaySize}px`,
        borderRadius: squareIndicator ? '' : '50%',
        background: buttonColor || visibleColorFun(),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        zIndex: 99,
        boxShadow: '0 0 0 0.3px grey, 0 0 0 0.3px black',
        filter: `blur(${blurSize})`,
      }}>
        { showMultiplier && (<span style={{
          color: multiplierTextColor,
          fontSize: '13px',
          position: 'absolute',
          left: '51%',
          top: '47%',
          transform: 'translate(-50%, -50%)',
          opacity: scale > 1 ? 0.8 : 0,
        }}>
          x{scale.toFixed(2)}
        </span>)}
      </div>
      <div style={{
        opacity: (visibleLineWidthFun() < 35) ? 1 : 0,
        transition: 'opacity 200ms',
      }}>
        { hideButton ? null : (
          <CircleSwitch
            icon={needTipIcon ? icon : null}
            isActive={brush.fast.isResizingBrush}
            cursor='ns-resize'
          />
        )}
      </div>

      <div className="brush-info" stule={{...brushInfoStyle}}>
        {brushSizeText}px<br/>
        {bushOpacityText}%
      </div>

      {brush.fast.isResizingBrush && !hideInidicator && (
        <BrushSizeIndicator
          store={store}
          position={pointerPosition}
          clickPosition={clickPosition}
        />
      )}
    </div>
  );
};

export default BrushSizeControl;
