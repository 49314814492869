import { useEffect } from 'react';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import useStore from '../../store';

export const useColor = (ref) => {
  const showPalette = useStore(state => state.showPalette);
  const { t } = useTranslation();
  const { menu, convy, drawing, info, brush, history, components } = ref;
  const { appleHaptic, showTemporaryHint } = menu.methods;
  const { setTheColorFun, handleLastColors } = brush.methods;
  const { getCanvasCoordinates, getReferenceCoordinates } = drawing.methods;
  const { pipette } = brush;
  const { keyboard } = convy;
  const { ColorHint } = components;

  // Получение цвета с основного холста
  function getColorByEvent({ nativeEvent }) {
    const canvas = convy.main.ref;
    brush.pipette.canvas = convy.main.ref;

    const context = canvas.getContext('2d');
    let { x, y } = getCanvasCoordinates(nativeEvent);
    brush.pipette.canvasCoordinates = { x, y };

    x = Math.max(x, 1);
    y = Math.max(y, 1);
    x = Math.min(x, canvas.width - 1);
    y = Math.min(y, canvas.height - 1);
    const pixel = context.getImageData(x, y, 1, 1).data;
    const rgbaColor = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`;
    return chroma(rgbaColor).hex();
  }

  // Получение цвета с референсного изображения (через оффскрин-канвас)
  function getColorFromReference({ nativeEvent }) {
    // Оффскрин-канвас с референсом сохранён в store.ref.menu.source.canvas
    const referenceCanvas = menu?.source?.canvas;
    if (!referenceCanvas) return '#000000';

    brush.pipette.canvas = menu.source.canvas;

    const { x, y } = getReferenceCoordinates(nativeEvent);
    brush.pipette.canvasCoordinates = { x, y };

    const ctx = referenceCanvas.getContext('2d');
    const pixel = ctx.getImageData(x, y, 1, 1).data;
    const rgbaColor = `rgba(${pixel[0]}, ${pixel[1]}, ${pixel[2]}, ${pixel[3] / 255})`;
    return chroma(rgbaColor).hex();
  }

  // Универсальная функция выбора источника цвета
  function getColor({ nativeEvent }) {
    brush.pipette.pointerType = nativeEvent.pointerType || brush.pipette.pointerType;

    // Если под указателем находится референсное окно, используем его канвас
    if (nativeEvent.target.closest('.reference-image-wrapper')) {
      brush.pipette.source = 'reference';
      return getColorFromReference({ nativeEvent });
    } else {
      brush.pipette.source = 'canvas';
      return getColorByEvent({ nativeEvent });
    }
  }

  const startPickingColorFromCanvas = ({ nativeEvent }) => {
    const { activeTool, color } = useStore.getState();
    if (activeTool !== 'pipette') return;
    brush.lastActionTime = Date.now();
    pipette.pickedColor = getColor({ nativeEvent });
    setTheColorFun(pipette.pickedColor);
    const place = {
      x: nativeEvent.touches?.[0]?.clientX || nativeEvent.clientX,
      y: nativeEvent.touches?.[0]?.clientY || nativeEvent.clientY,
    };
    brush.prevColor = color;
    useStore.setState({ isPipettePicking: true, pickingPlace: place });
    appleHaptic?.('soft');
  };

  const searchForColorFromCanvas = ({ nativeEvent }) => {
    const { activeTool, isPipettePicking } = useStore.getState();
    if (!isPipettePicking || activeTool !== 'pipette') return;
    pipette.pickedColor = getColor({ nativeEvent });
    setTheColorFun(pipette.pickedColor);
    const place = {
      x: nativeEvent.touches?.[0]?.clientX || nativeEvent.clientX,
      y: nativeEvent.touches?.[0]?.clientY || nativeEvent.clientY,
    };
    useStore.setState({ pickingPlace: place });
  };

  const pickColorFromCanvas = ({ nativeEvent }) => {
    const activeTool = useStore.getState().activeTool;
    if (activeTool !== 'pipette') return;
    if (!keyboard.altIsDown) {
      useStore.setState({ activeTool: brush.previousTool });
      brush.lastActionTime = Date.now();
    }
    useStore.setState({ isPipettePicking: false });
    handleLastColors();
    appleHaptic?.('soft');
    showTemporaryHint(
      <ColorHint text={t('tooltip.saved_color_taken')} hexColor={pipette.pickedColor} />,
      { force: true, duration: 1500 },
      { backgroundColor: 'rgba(0, 0, 0, 0.3)', minWidth: '100px', padding: '8px' }
    );
  };

  const stopPickingColorFromCanvas = (event) => {
    useStore.setState({ isPipettePicking: false });
  };

  useEffect(() => {
    if (showPalette) {
      brush.prevColor = useStore.getState().color;
    } else {
      handleLastColors();
    }
  }, [showPalette]);

  return {
    startPickingColorFromCanvas,
    searchForColorFromCanvas,
    pickColorFromCanvas,
    stopPickingColorFromCanvas,
  };
};
