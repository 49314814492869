
import { MdZoomIn } from 'react-icons/md'; // Иконка зума
import { AiOutlineClose } from 'react-icons/ai'; // Иконка крестика

import useStore from '../../../store';


function ControlButtons({ store }) {

    const zoomFactor = useStore((state) => state.zoomFactor);

    const { 
      resetZoom,
     } = store.ref.drawing.methods;

    return (
    <div>
      {zoomFactor > 1 && (
        <div 
          onClick={resetZoom} 
          style={{ 
            position: 'absolute',  // Абсолютное позиционирование для панели зума
            top: '2px', // Смещение вверх относительно кнопок
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            gap: '10px',
            padding: '5px',
            height: '23px',
            background: 'rgba(200, 200, 200, 0.3)',
            borderRadius: '12px',
            zIndex: 1100, // Убедитесь, что панель зума будет находиться над другими элементами
          }}
        >
          <MdZoomIn style={{ cursor: 'pointer'}} />
          <div style={{ 
              minWidth: '35px', // Минимальная ширина достаточная для большинства значений
              textAlign: 'center', // Центрирование текста внутри блока
              fontSize: '18px',
          }}>
            {Math.round(zoomFactor * 10) / 10}
          </div>
          <AiOutlineClose 
              style={{ cursor: 'pointer' }} 
              onClick={resetZoom} 
          />
      </div>
      )}
</div>
    );
}

export default ControlButtons;





