// src/hooks/drawing/useRedraw.js
import useStore from '../../store';

import { freeMemory } from './imageCached';

import { createFill } from './brushes/filler';
import { drawPlainMarkerStroke } from './brushes/marker';

import { drawDashedStroke } from './brushes/dashed';
import { drawOutlinedStroke } from './brushes/outlined';
import { drawSprayStroke } from './brushes/spray';
import { drawSprayNoBlurStroke } from './brushes/sprayNoBlur';
import { drawFeatherStroke } from './brushes/feather';
import { drawPencilStroke } from './brushes/pencil';
import { drawOilStroke } from './brushes/oil';
import { drawWatercolorStroke } from './brushes/watercolor';
import { drawBristleStroke } from './brushes/bristle';
import { drawRembrandtStroke } from './brushes/rembrandt';
import { drawBlurStroke } from './brushes/blur';
import { drawEffectStroke } from './brushes/effect';
import { drawSparkleStroke } from './brushes/sparkle';
import { drawNeonStroke } from './brushes/neon';
import { drawTextureStroke } from './brushes/texture';
import { drawGlyphStroke } from './brushes/glyph';


export const useRedraw = (ref) => {
  const { menu, debug, mount, telegram, info, convy, drawing, brush, history } = ref;
  const { game } = info;
  const { showTemporaryHint, forceRender } = menu.methods;
  const { isAppleDevice, serverTimeFun } = info.methods;
  const { wipeTempCanvas } = drawing.methods;
  const { userStrokes } = history;

  // Для кеширования теперь используем отдельное хранилище для каждого слоя:
  if (!history.layerImageCache) {
    history.layerImageCache = {}; // { [layerId]: Map() }
  }

  // Функция отрисовки фона
  function drawBackground({ context, color }) {
    context.save();
    context.fillStyle = color;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.restore();
  }

  // Универсальная функция отрисовки штриха (с нормализацией точек)
  async function drawStroke(stroke, context) {

    stroke = { ...stroke };
    if (stroke.points) {
      if (!Array.isArray(stroke.points)) return;
      stroke.points = stroke.points.map(point =>
        Array.isArray(point) ? { x: point[0], y: point[1] } : point
      );
    }
    const { brush, effect, sets = {} } = stroke;
    const softness = sets?.softness || stroke.softness || 0;
    const params = { isApple: isAppleDevice() };

    context.save();
    context.globalCompositeOperation = (stroke.sets && stroke.sets.composition) || 'source-over';

    if (stroke.tool === 'eraser' && !stroke.oldCanvas) {
      context.globalCompositeOperation = 'destination-out';
    }

    if (['feather', 'ink'].includes(brush)) {
      await drawFeatherStroke(stroke, context, params);
    } else if (brush === 'pencil') {
      await drawPencilStroke(stroke, context, params);
    } else if (brush === 'oil') {
      await drawOilStroke(stroke, context, params);
    } else if (brush === 'watercolor') {
      await drawWatercolorStroke(stroke, context, params);
    } else if (brush === 'bristle') {
      await drawBristleStroke(stroke, context, params);
    } else if (brush === 'rembrandt') {
      await drawRembrandtStroke(stroke, context, params);
    } else if (effect === 'blur' || brush === 'blur') {
      await drawBlurStroke(stroke, context, params);
    } else if (effect === 'texture') {
      await drawTextureStroke(stroke, context, params);
    } else if (effect === 'noise' || brush === 'noise') {
      await drawEffectStroke({ ...stroke, effect: 'noise' }, context, params);
    } else if (effect) {
      await drawEffectStroke(stroke, context, params);
    } else if (brush === 'dashed') {
      await drawDashedStroke(stroke, context, params);
    } else if (brush === 'outlined') {
      await drawOutlinedStroke(stroke, context, params);
    } else if (brush === 'neon') {
      await drawNeonStroke(stroke, context, params);
    } else if (brush === 'sparkle') {
      await drawSparkleStroke(stroke, context, params);
    } else if (brush === 'glyph') {
      await drawGlyphStroke(stroke, context, params);
    } else if (brush === 'test') {
      // Можно добавить свою логику для тестовой кисти
    } else if (brush === 'spray' || softness > 0) {
      // if (isAppleDevice() && sets.appleSprayFixFinal) {
      if (isAppleDevice()) {
        await drawSprayStroke(stroke, context, convy.soft.ctx, params);
        // await drawSprayNoBlurStroke(stroke, context, convy.soft.ctx);
      } else {
        await drawSprayStroke(stroke, context, convy.soft.ctx, params);
      }
    } else {
      await drawPlainMarkerStroke(stroke, context, params);
    }
    context.restore();
  }

  // Функция выполнения заливки (fill) – теперь заливка происходит в том слое, который указан в stroke.layerId
  function makeFill({ context, stroke }) {
    try {
      createFill(
        stroke,
        context,
      );
      saveCache(context, stroke, stroke.layerId || 'base');
      stroke.rendered = true;
    } catch (e) {
      console.error(e);
    }
  }

  // Функция кеширования: сохраняет offscreen-канвас для последнего штриха в данном слое

  function saveCache({
    layerCtx, 
    lastStroke, 
    layerId, 
  }) {

    if (!lastStroke) return;
    const canvas = layerCtx.canvas;
    const layerCache = history.layerImageCache[layerId];
  
    // Создаём offscreen-канвас с такими же размерами, как исходный
    const cacheCanvas = document.createElement('canvas');
    cacheCanvas.width = canvas.width;
    cacheCanvas.height = canvas.height;
    const cacheCtx = cacheCanvas.getContext('2d');
  
    // Копируем текущее содержимое канваса
    cacheCtx.drawImage(canvas, 0, 0);
    // if (lastStroke.time !== history.lastRenderedStroke.time) { return; }

    layerCache.set(lastStroke.time, {
      time: Date.now(),
      canvas: cacheCanvas,
    });
    window.croco?.log(`saved: ${lastStroke.brush}: ${lastStroke.time} | ${layerId}`);
  
    const activeLayer = convy.methods.getActiveLayer();

    freeMemory({ 
      imageCache: history.layerImageCache, 
      activeLayerId: activeLayer.id,
     });
  
    cacheCanvas.toBlob(async (blob) => {
      if (!blob) return;
      
      // Сохраняем Blob в IndexedDB
      // mount.methods.addLayerCache(telegram.gameId, layerId, lastStroke.time, blob)
      mount.methods.addLayerCache({
        gameId: telegram.gameId, 
        layerId, 
        cacheId: lastStroke.time, 
        blob, 
        activeLayerId: activeLayer.id,
      })
      .then((id) => {
        window.croco?.log(`IDB+ ${lastStroke.brush}: ${lastStroke.time}`);
      })
      .catch((err) => {
        window.croco?.log(`IDB error ${lastStroke.brush}: ${lastStroke.time}`);
      });
  
      // Отправляем бинарные данные по сокету (ключ изменён на imageWebp)
      mount.methods.saveLayerToServer({
        layerId,
        bufferWebp: blob,
        time: lastStroke.time,
      })
      .then(() => {
        console.log(`Кеш для слоя ${layerId} сохранён на сервере`);
      })
      .catch((err) => {
        console.error('Ошибка сохранения кеша в на сервере:', err);
      });

    }, 'image/png', 0.5);
  }


  // Основная функция перерисовки: теперь рендерим по каждому слою отдельно, а затем компонуем на основном канвасе
  const redraw = async(parm = {}) =>{

    history.playerCount = Object.keys(userStrokes || {}).length;
    if (!convy.main.ref || !convy.main.ctx) {
      return setTimeout(() => { redraw(); }, 1500);
    };
    if (drawing.isDrawing && !parm.preview) return;
    if (history.isRendering) return;

    history.isRendering = { started: Date.now(), };

    debug.metrics.redraw = {
      started: Date.now(),
      strokes: 0,
    };

    await redrawMultipleLayers(parm);
    
    debug.metrics.draw.push({
      ss: debug.metrics.redraw.strokes,
      dif: debug.metrics.redraw.difficulty,
      sdif: debug.metrics.redraw.saveDifficulty,
      tm: debug.metrics.redraw.mle,
      id: history.lastRenderedStroke?.time % 10000,
    });
    delete debug.metrics.redraw;
    
    menu.methods.render();

    history.isRendering = null;



  }


  const redrawMultipleLayers = async (parm = {}) => {
    const mainCanvas = convy.main.ref;
    const mainCtx = convy.main.ctx;

    const { activeTool } = useStore.getState();

    const bannedUsers = Object.entries(game.users)
    .filter(([userKey, user]) => user?.sets?.banStrokes)
    .map(([userKey])=>userKey);
  
    // Получаем список слоёв из convy.layerInfo и сортируем их по order (от нижнего к верхнему)
    const layersObj = convy.layerInfo || {};
    const layersArray = Object.keys(layersObj)
      .map(id => ({ id, ...layersObj[id] }))
      .sort((a, b) => a.order - b.order)
      .filter(layerData => layerData.visible && !layerData.deleted)
  
    debug.metrics.redraw.dls = Date.now() - debug.metrics.redraw.started;
  
    // Получаем подготовленные штрихи для всех слоёв
    const strokesData = drawing.methods.prepareStrokesForAllLayers();
  
    // Для каждого слоя:
    for (const layerData of layersArray) {
      const layerId = layerData.id;

      const layer = ref.convy.methods.getLayer(layerId);
      const { canvas: layerCanvas, ctx: layerCtx, info: layerInfo } = layer;
  
      // Если слой выключен или уже готов – пропускаем его отрисовку
      if (!layerInfo.visible) continue;
      if (layer.ready) continue;
  
      // Очищаем канвас слоя
      layerCtx.save();
      layerCtx.setTransform(1, 0, 0, 1, 0, 0);
      layerCtx.clearRect(0, 0, layerCanvas.width, layerCanvas.height);
      layerCtx.restore();
  
      const { strokes, difficulty, saveDifficulty } = strokesData[layerId];

      if (difficulty) {
        // Получаем для слоя подготовленные штрихи и сложность
        debug.metrics.redraw.difficulty = difficulty;
        debug.metrics.redraw.saveDifficulty = saveDifficulty;
      }

      await drawOnLayer({
        layerCtx, 
        layerData, 
        strokes, 
        difficulty, 
        preview: parm.preview,
        bannedUsers,
      });

    }
  
    debug.metrics.redraw.dle = Date.now() - debug.metrics.redraw.started;
  
    // Очищаем основной канвас
    mainCtx.clearRect(0, 0, mainCtx.canvas.width, mainCtx.canvas.height);
    mainCtx.fillStyle = info.game?.settings?.backgroundColor || '#FFFFFFFF';
    mainCtx.fillRect(0, 0, mainCanvas.width, mainCanvas.height);
  
    if (!parm.preview) { wipeTempCanvas(); }

    layersArray.forEach(layerData=>{
      const currentLayer = convy.methods.getLayer(layerData.id);
      delete currentLayer.maskTempCanvas;
      delete currentLayer.maskTempCtx;
      delete currentLayer.previewTempCanvas;
      delete currentLayer.previewTempCtx;
    })

    const activeLayer = convy.methods.getActiveLayer();
    if (parm.preview) {
      drawActivePreview({ activeLayer, activeTool });
    }

    const mainLayers = layersArray.filter(layer => !layer.clippingFor);
  
    for (const layerData of mainLayers) {

      const layerId = layerData.id;
      const layer = convy.methods.getLayer(layerId);

      drawMasks(layer, layersArray);

      mainCtx.save();
      transformation(mainCtx, layerData);

      const layerCanvas = (layer.maskTempCanvas || layer.previewTempCanvas || layer.canvas);
      mainCtx.drawImage(layerCanvas, -layerCanvas.width / 2, -layerCanvas.height / 2);
      mainCtx.restore();

    }
  
    debug.metrics.redraw.mle = Date.now() - debug.metrics.redraw.started;
  };

  const drawActivePreview = ({ activeLayer, activeTool }) => {
    
    convy.clone.canvas.width = activeLayer.canvas.width;
    convy.clone.canvas.height = activeLayer.canvas.height;

    convy.clone.ctx.globalCompositeOperation = 'source-over';
    convy.clone.ctx.clearRect(0, 0, convy.clone.canvas.width, convy.clone.canvas.height);

    convy.clone.ctx.drawImage(activeLayer.canvas, 0, 0);

    if (activeTool === 'eraser') {
      convy.clone.ctx.globalCompositeOperation = 'destination-out';
    }
    convy.clone.ctx.drawImage(convy.preview.canvas, 0, 0);

    activeLayer.previewTempCanvas = convy.clone.canvas;
    activeLayer.previewTempCtx = convy.clone.ctx;

  }

  const drawMasks = (mainLayer, layersArray) =>{

     // maskGroup
    const attachedMasks = layersArray
     .filter(layerData => layerData.clippingFor === mainLayer.id)
     .sort((a, b) => a.order - b.order)
     .map(layerData=>convy.methods.getLayer(layerData.id));

    if (!attachedMasks[0]) { return; }

    // Creating Mask Temp Canvas
    const maskTempCanvas = document.createElement('canvas');
    maskTempCanvas.width = mainLayer.canvas.width;
    maskTempCanvas.height = mainLayer.canvas.height;
    const maskTempCtx = maskTempCanvas.getContext('2d');

    // То что было
    maskTempCtx.drawImage(mainLayer.previewTempCanvas || mainLayer.canvas, 0, -0);

    // Накладываем все слои
    maskTempCtx.save();

    attachedMasks.forEach(maskLayer=>{
      
      addOpacityAndComposition(maskTempCtx, maskLayer.info);
      addShadow(maskTempCtx, maskLayer.info);
      addFilters(maskTempCtx, maskLayer.info);
      const sizeDif = {
        x: maskLayer.canvas.width - maskTempCtx.canvas.width,
        y: maskLayer.canvas.height - maskTempCtx.canvas.height,
      }

      if (maskLayer.info.erasingMask) {
        maskTempCtx.globalCompositeOperation = 'destination-out';
      } else {
        maskTempCtx.globalCompositeOperation = 'source-atop';
      }

      maskTempCtx.drawImage(maskLayer.previewTempCanvas || maskLayer.canvas, 0, 0);
      // maskTempCtx.drawImage(maskLayer.previewTempCanvas || maskLayer.canvas, -sizeDif.x/2, -sizeDif.y/2);

      maskTempCtx.restore();
    })

    mainLayer.maskTempCanvas = maskTempCanvas;
    mainLayer.maskTempCtx = maskTempCtx;

  }


  const drawMasks0 = (mainLayer, layersArray) =>{

     // maskGroup
    const attachedMasks = layersArray
     .filter(layerData => layerData.clippingFor === mainLayer.id)
     .sort((a, b) => a.order - b.order)
     .map(layerData=>convy.methods.getLayer(layerData.id));

    if (!attachedMasks[0]) { return; }

    // Creating Mask Temp Canvas
    const maskTempCanvas = document.createElement('canvas');
    maskTempCanvas.width = mainLayer.canvas.width;
    maskTempCanvas.height = mainLayer.canvas.height;
    const maskTempCtx = maskTempCanvas.getContext('2d');

    // То что было
    maskTempCtx.drawImage(mainLayer.previewTempCanvas || mainLayer.canvas, 0, -0);

    // Накладываем все слои
    maskTempCtx.save();

    attachedMasks.forEach(maskLayer=>{
      
      addOpacityAndComposition(maskTempCtx, maskLayer);
      addShadow(maskTempCtx, maskLayer);
      addFilters(maskTempCtx, maskLayer);
      const sizeDif = {
        x: maskLayer.canvas.width - maskTempCtx.canvas.width,
        y: maskLayer.canvas.height - maskTempCtx.canvas.height,
      }

      if (maskLayer.info.erasingMask) {
        maskTempCtx.globalCompositeOperation = 'destination-out';
      } else {
        maskTempCtx.globalCompositeOperation = 'source-over';
      }

      maskTempCtx.drawImage(maskLayer.previewTempCanvas || maskLayer.canvas, 0, 0);
      // maskTempCtx.drawImage(maskLayer.previewTempCanvas || maskLayer.canvas, -sizeDif.x/2, -sizeDif.y/2);

      maskTempCtx.restore();
    })

    // Накладываем обрезающую маску
    maskTempCtx.globalCompositeOperation = 'destination-in';
    maskTempCtx.drawImage(mainLayer.previewTempCanvas || mainLayer.canvas, 0, 0);

    mainLayer.maskTempCanvas = maskTempCanvas;
    mainLayer.maskTempCtx = maskTempCtx;

  }


  const drawOnLayer = async ({
    layerCtx, 
    layerData, 
    strokes, 
    difficulty, 
    preview,
    bannedUsers,
  }) => {

    if (!strokes[0]) { return; }
    
    const layerId = layerData.id;
    const renderStartTime = Date.now();
    let layerLastRenderedStroke;
    let lastHintTime = 0;

    convy.soft.canvas.width = layerCtx.canvas.width;
    convy.soft.canvas.height = layerCtx.canvas.height;
  
    let i = 0;


    for (const stroke of strokes) {

      stroke.rendered = true; 

      if (bannedUsers.includes(stroke.userId)) { return; }

      if (stroke.cancelled || stroke.hidden) continue;

      debug.metrics.redraw.strokes++;
      history.lastStrokeRenderTime = Date.now();
      history.lastRenderedStroke = stroke;
      layerLastRenderedStroke = stroke;
  
      const layerCache = history.layerImageCache[layerId];
  
      if (layerCache && layerCache.get(stroke.time)) {
        // Если для штриха уже есть кеш – используем offscreen-канвас
        const strokeCache = layerCache.get(stroke.time);
        layerCtx.drawImage(strokeCache.canvas, 0, 0);
        debug.metrics.redraw.strokes--;
      } else if (stroke.type === 'background') {
        drawBackground({ context: layerCtx, color: stroke.color });
      } else if (stroke.type === 'fill') {
        makeFill({ context: layerCtx, stroke });
      } else if (stroke.type === 'stroke') {
        await drawStroke(stroke, layerCtx);
      }
  
      i++;
      const currentTime = Date.now();
      if (currentTime - renderStartTime > 500 && currentTime - lastHintTime > 1000) {
        lastHintTime = currentTime;
        showTemporaryHint(
          `⏳ ${layerData.name}: ${i} / ${strokes.length}`,
          { force: true, duration: 800 }
        );
        forceRender();
        await new Promise(resolve => setTimeout(resolve, 300));
      }
    }
  
    // Определяем порог кеширования: если включён режим moreCache – 2, иначе 4
    const cacheThreshold = info.user.moreCache ? 20 : 40;

    if (layerLastRenderedStroke) {
      window.croco?.log(`:: ${layerLastRenderedStroke.brush || layerLastRenderedStroke.type}: ${difficulty}/${cacheThreshold} =${layerLastRenderedStroke.time}`);
    }
 

    if (
      !preview 
      && layerLastRenderedStroke
      && difficulty >= cacheThreshold
      ) {
      const lastStroke = layerLastRenderedStroke;
      setTimeout(() => {
        window.croco?.log(`::-- ${lastStroke.brush}: ${difficulty}/${cacheThreshold} =${lastStroke.time}`);

        // strokes.forEach(stroke => { stroke.rendered = true; });
        saveCache({
          layerCtx, 
          lastStroke, 
          layerId, 
        });
      }, 0);

    }
  
    const layer = ref.convy.methods.getLayer(layerId);
    layer.ready = Date.now();

  };
  

  
  function transformation (mainCtx, layerInfo) {

    addOpacityAndComposition(mainCtx, layerInfo);
    addShadow(mainCtx, layerInfo);
    addFilters(mainCtx, layerInfo);
    translatePosition (mainCtx, layerInfo);

  }

  function translatePosition (mainCtx, layerInfo) {
      // Вычисляем центр основного канваса
      const centerX = mainCtx.canvas.width / 2;
      const centerY = mainCtx.canvas.height / 2;
      mainCtx.translate(centerX, centerY);
      mainCtx.translate(layerInfo.offset?.x || 0, layerInfo.offset?.y || 0);
      if (layerInfo.rotation) {
        mainCtx.rotate((layerInfo.rotation * Math.PI) / 180);
      }
      if (layerInfo.scale && layerInfo.scale !== 1) {
        mainCtx.scale(layerInfo.scale, layerInfo.scale);
      }
  }

  function addShadow (mainCtx, layerInfo) {

    // Если заданы параметры тени, применяем их:
    if (layerInfo.shadowOn) {
      mainCtx.shadowColor = layerInfo.shadowColor || '#000000FF';
      mainCtx.shadowBlur = layerInfo.shadowBlur || 0;
      mainCtx.shadowOffsetX = layerInfo.shadowOffsetX || 0;
      mainCtx.shadowOffsetY = layerInfo.shadowOffsetY || 0;
    }
    
  }

  function addOpacityAndComposition (mainCtx, layerInfo) {

    // Устанавливаем прозрачность и режим композитинга
    mainCtx.globalAlpha = layerInfo.opacity !== undefined ? layerInfo.opacity : 1;
    mainCtx.globalCompositeOperation = layerInfo.composition || 'source-over';
    
  }

  function addFilters (mainCtx, layerInfo) {

    if (layerInfo.filterOn) {
      let filterParts = [];
      
      // brightness: по умолчанию 1
      if (typeof layerInfo.filterBrightness !== 'undefined' && layerInfo.filterBrightness !== 1) {
        filterParts.push(`brightness(${layerInfo.filterBrightness})`);
      }
      
      // contrast: по умолчанию 1
      if (typeof layerInfo.filterContrast !== 'undefined' && layerInfo.filterContrast !== 1) {
        filterParts.push(`contrast(${layerInfo.filterContrast})`);
      }
      
      // blur: по умолчанию 0
      if (typeof layerInfo.filterBlur !== 'undefined' && layerInfo.filterBlur !== 0) {
        filterParts.push(`blur(${layerInfo.filterBlur}px)`);
      }
      
      // grayscale: по умолчанию 0%
      if (typeof layerInfo.filterGrayscale !== 'undefined' && layerInfo.filterGrayscale !== 0) {
        filterParts.push(`grayscale(${layerInfo.filterGrayscale}%)`);
      }
      
      // hue-rotate: по умолчанию 0deg
      if (typeof layerInfo.filterHueRotate !== 'undefined' && layerInfo.filterHueRotate !== 0) {
        filterParts.push(`hue-rotate(${layerInfo.filterHueRotate}deg)`);
      }
      
      // invert: по умолчанию 0%
      if (typeof layerInfo.filterInvert !== 'undefined' && layerInfo.filterInvert !== 0) {
        filterParts.push(`invert(${layerInfo.filterInvert}%)`);
      }
      
      // opacity: по умолчанию 1
      if (typeof layerInfo.filterOpacity !== 'undefined' && layerInfo.filterOpacity !== 1) {
        filterParts.push(`opacity(${layerInfo.filterOpacity})`);
      }
      
      // saturate: по умолчанию 1
      if (typeof layerInfo.filterSaturate !== 'undefined' && layerInfo.filterSaturate !== 1) {
        filterParts.push(`saturate(${layerInfo.filterSaturate})`);
      }
      
      // sepia: по умолчанию 0%
      if (typeof layerInfo.filterSepia !== 'undefined' && layerInfo.filterSepia !== 0) {
        filterParts.push(`sepia(${layerInfo.filterSepia}%)`);
      }
      
      if (filterParts.length) {
        mainCtx.filter = filterParts.join(' ');
      }
    
    }
  }


  const renderForServer = async (pref = {})=>{

    try {

      window.croco?.log('📨💻render for server start');
      // await redraw()
      const lastStroke = history.lastRenderedStroke;
      const imagePng = convy.main.ref.toDataURL('image/png');
      window.croco?.log('✔️💻render for server dataUrl');

      return {
        lastStroke,
        imagePng,
      }
      
    } catch (e) {
      return { error: {
        message: e.message,
        stack: e.stack,
      },}
    }


  }


  return {
    drawStroke,
    redraw,
    renderForServer,
  };
};
