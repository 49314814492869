// OpacityControl.js
import React, { useState, useRef, useEffect } from 'react';
import CircleSwitch from "./CircleSwitch/CircleSwitch.js";
import ColorIndicator from './ColorIndicator'; 

import useStore from '../../../store';

const SoftnessControl = ({ 
  store,
  onContextMenu,
  icon,

  // canvasContext,
  // gameContext,
  // brushContext,
  // drawMethods,

 }) => {

    const { activeTool } = useStore.getState()
    const { info, menu, brush, premium, } = store.ref;

    const { user } = info;
    const { changeTool } = brush.methods;
    const { forceRender, delayPremiumMenu } = menu.methods;
    const { premiumFeatures = [] } = premium;

    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);

    const startChangingOpacity = (event) => {

      if (event?.button === 2) { return; }

        brush.fast.isChangingSoftness = true;

        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
    };

    useEffect(() => {
        const handlePointerMove = (event) => {

            if (event?.button === 2) { return; }

            if (!brush.fast.isChangingSoftness) return;
            const x = event.clientX || event.touches[0]?.clientX;
            const y = event.clientY || event.touches[0]?.clientY;

            let diffY = pointerPosition.y - y;
            setPointerPosition({ x, y }); // Обновление позиции кружка
            
            if (diffY === 0) return;
            if (diffY < 0) {diffY *= 1.5;}
            const softnessChange = diffY * 0.002;

            useStore.setState((state) => ({
              softness: Math.max(0, Math.min(0.3, state.softness + softnessChange)),
            }));
            hasMoved.current = true; // Установка при перемещении

        };

        const handlePointerUp = (event) => {

          if (event?.button === 2) { return; }

          brush.fast.isChangingSoftness = false;

          if (hasMoved.startTouchPoint && Date.now() - hasMoved.startTime < 350) {
            changeTool("brush")
          } 


          useStore.setState((state) => {
            let newSoftness = state.softness < 0.02 ? 0 : state.softness;
            if (
              newSoftness > 0 && 
              (!premiumFeatures.includes('brushes') 
              || user.premiumOff)
              ) {
              delayPremiumMenu('menu');
              newSoftness = 0;
            }
            return { softness: newSoftness, }
          });

          forceRender();

        };

        if (brush.fast.isChangingSoftness) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };
    }, [pointerPosition, brush.fast.isChangingSoftness]);

    return (
      <div>
        <div
            onContextMenu={onContextMenu}
            onMouseDown={startChangingOpacity}
            onTouchStart={startChangingOpacity}
            style={{
                cursor: 'pointer', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
            }}
        >
            <CircleSwitch
									icon={icon}
									isActive={activeTool === "brush"}
                  menu={true}
								/>
        </div>
        {brush.fast.isChangingSoftness && (
            <ColorIndicator
              store={store}
              position={pointerPosition}
              clickPosition={clickPosition}
            />
            )}
      </div>
    );
};

export default SoftnessControl;