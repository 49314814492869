// PopupMessage.js
import React, { useState, } from 'react';
import './PopupMessage.css'; // Подключаем стили

import useStore from '../../../store';

function PopupMessageOverlay({ store }) {

  const popupMessage = useStore((state)=>state.popupMessage);

  const { ref } = store;
  const { menu, work, info } = ref;

  const {
    user,
  } = info;

  const { showPopupMessage } = menu.methods;
  const { saveUserSets } = work.methods;


  const [inputValue, setInputValue] = useState(popupMessage.input?.value || '');

  const handleButtonClick = (buttonId, parm = {}) => {
    if (popupMessage.callback) {
      if (buttonId === 'submit') {
        popupMessage.callback('submit', inputValue);
      } else {
        popupMessage.callback(buttonId);
      }
    }
    if (!parm.ignore) {
      showPopupMessage(null);
    }
  };

  const handleUserSetCheckbox = (key) => {
    saveUserSets({ [key]: !user[key] })
  };

  const handleClosePopup = (key) => {

    if (popupMessage.callback) {
      popupMessage.callback('close');
    }
    showPopupMessage(null);

  }

  const input = popupMessage.input || null;
  const buttons = popupMessage.buttons || 
  (input ? 
    [
      { type: 'cancel', text: 'Cancel',},
      { id: 'submit', type: 'default', text: 'OK',},
    ] :
    [{ type: 'default', text: 'OK', }]
  )

  const wideButtons = popupMessage.wideButtons;

  const renderContent = () => {
    if (popupMessage.content) {
      return popupMessage.content;
    } else {
      return (
        <span 
          className="message-popup-text"
          dangerouslySetInnerHTML={{ __html: popupMessage.message || popupMessage.text || '' }}
        />
      );
    }
  };

  return (
    <>
      <div className="overlay-background" style={{zIndex: '1149'}} onClick={handleClosePopup}></div>
      <div className="message-popup">
        <div className="message-popup-title">{popupMessage.title || ''}</div>
        {renderContent()}

        {popupMessage.items && popupMessage.items.map((item) => 
            item.type === 'set' ? (
              <div key={item.key} className="settings-item">
                <label className="message-popup-settings-label" style = {{
                  opacity: user[item.key] ? 1 : 0.5
                }}>
                  <input
                    type="checkbox"
                    style={{  width: '20px', height: '20px'}}
                    checked={user[item.key]}
                    onChange={() => handleUserSetCheckbox(item.key)}
                  />
                  <span className='message-popup-settings-text'>{item.label}</span>
                </label>
              </div>
            ) : (
              <div className="settings-item" onClick={() => handleButtonClick(item.id, item)}>
                <label className="message-popup-settings-label">
                { item.icon && (<item.icon className="settings-label-icon"/>)}
                <span>{item.label}</span>
                </label>
              </div>
            )
          )
        }
        {input && (
          <input
            type="text"
            value={inputValue}
            onChange={(e)=>{ setInputValue(e.target.value) }}
            className="message-popup-input-field input-field"
            placeholder={input.placeholder || ''}
            autoFocus={true}
          />
        )}

        {
          wideButtons &&  
          (
            <div className="message-popup-button-container vertical">
              {wideButtons?.map((button) => (
                <button
                  key={button.id}
                  className={`message-popup-button wide ${ {
                    cancel: 'cancel-button',
                    destructive: 'destructive-button',
                  }[button.type] || ''}`}
                  onClick={() => handleButtonClick(button.id, button)}
                >
                  {button.text}
                </button>
              ))}
            </div>
          )
        }

        <div className="message-popup-button-container">
          {buttons.map((button) => (
            <button
              key={button.id}
              className={`message-popup-button ${ {
                cancel: 'cancel-button',
                destructive: 'destructive-button',
              }[button.type] || ''}`}
              onClick={() => handleButtonClick(button.id, button)}
            >
              {button.text}
            </button>
          ))}
        </div>
        
      </div>
     
    </>
  );
}

export default PopupMessageOverlay;
