// src/components/board/MetricsDebug.jsx
import React, { useEffect } from 'react';
import './DebugMenu.css';
import useStore from '../../../store';

const DebugMenu = ({ store }) => {
  useStore((state) => state.debugRender);
  const { telegram, work, info, history, debug } = store.ref;
  const { user } = info;
  const { saveUserSets } = work.methods;

  const metrics = debug.metrics;

  const checkFreeStorage = async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      try {
        const { quota, usage } = await navigator.storage.estimate();
        metrics.storage = `quota: ${(quota / 1024 / 1024).toFixed(3)} Mb, usage: ${(usage / 1024 / 1024).toFixed(3)} Mb`;
        console.log('Storage checked', { storage: metrics.storage });
      } catch (err) {
        metrics.storage = null;
        console.log('Storage check failed', { error: err.message });
      }
    } else {
      metrics.storage = null;
      console.log('Storage API not available');
    }
  };

  useEffect(() => {
    checkFreeStorage();
    const interval = setInterval(checkFreeStorage, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const getDisplayData = () => {
    switch (user.debugMenu) {
      case 'strokes':
        return getStrokesData();
      case 'info':
        return getInfoData();
      case 'log':
        return getLogData();
      default:
        return getStrokesData();
    }
  };

  const getStrokesData = () => {
    const lastRenders = metrics.draw?.slice(-5) || [];
    const timeAvAll = lastRenders.length
      ? Math.round(lastRenders.reduce((sum, value) => sum + (value.tm), 0) / lastRenders.length)
      : 0;
    const caches = Object.values(history.layerImageCache).reduce((acc, set) => acc + set.size, 0);
    const myStrokes = (history.userStrokes[telegram.activeUserId] || [])
      .sort((a, b) => a.time - b.time)
      .slice(-5)
      .map(stroke => {
        let line = stroke.cancelled ? '✖️' : '';
        line += stroke.points?.length ? `${stroke.points.length} •` : `${stroke.type}`;
        if (stroke.brush) line += ` ${stroke.brush}`;
        line += ` id: ${stroke.time % 100000}`;
        return line;
      });

    return {
      renders: JSON.stringify(lastRenders),
      strokes: myStrokes.join('\n'),
      timeAvAll,
      caches,
      glyphTextures: window.croco.glyphTextures,
    };
  };

  const getInfoData = () => ({
    telegramUserId: telegram.telegramUserId,
    activeUserId: telegram.activeUserId,
    storage: `${metrics.storage || '?'} Mb`,
    errors: JSON.stringify(metrics.errors || []),
  });

  const getLogData = () => ({
    log: debug.methods.readLog(20, { includeTimestamp: true }),
  });

  const handleModeSwitch = (mode) => {
    saveUserSets({ debugMenu: mode });
  };
  const handleDebugMode = () => {
    if (['1431457632', '141015727'].includes(telegram.telegramUserId)) {
      saveUserSets({debugMode: !user.debugMode})
    }
  };

  if (!metrics || !metrics.draw) return null;

  const displayData = getDisplayData();

  return (
    <div className="metrics-debug">
      <div className="debug-mode-switcher">
        <span 
          style={{margin: '3px'}}
          onClick={handleDebugMode}
          >{user.debugMode ? '💡💻' : '➖'}
        </span>
        <button
          onClick={() => handleModeSwitch('strokes')}
          className={user.debugMenu === 'strokes' ? 'active' : ''}
        >
          strokes
        </button>
        <button
          onClick={() => handleModeSwitch('info')}
          className={user.debugMenu === 'info' ? 'active' : ''}
        >
          info
        </button>
        <button
          onClick={() => handleModeSwitch('log')}
          className={user.debugMenu === 'log' ? 'active' : ''}
        >
          log
        </button>
      </div>
      <ul>
        {Object.entries(displayData).map(([key, value]) => (
          <li key={key}>
            <strong>{key}</strong>: {String(value)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DebugMenu;