// ConvyBoard.js
import React, { useRef } from 'react';

import { useGameContext } from '../../contexts/GameContext'; 

import useConvy from '../../hooks/useConvy'; 
import ControlButtons from './tools/ControlButtons';
import panelOffset from './tools/panelOffset';
import ZoomFactor from './drawing/ZoomFactor';

import ConvyBrushIndicator from './drawing/ConvyBrushIndicator'; 
import ChatOverlay from './indicators/ChatOverlay'; 
import ConnectionIndicator from './indicators/ConnectionIndicator'; 
import { createBetterClick } from '../../utils/createBetterClick'
import { renderCheckerboardBackground, renderAccessoryBackground } from './accessory/renderBackground'

function ConvyBoard() {

    const gameContext = useGameContext();

    const { 
      gameInfoRef, 
      moderatorModeRef,
      gameInfo,
      openMenu,
      gameWordFun,

      transparentBgRef,
     } = gameContext;
    

    const {
      canvasContext, 
      brushContext,
      drawMethods,
    } = useConvy();

    const {
      canvasRef,
      tempCanvasRef,
      visibleSizeRef,

      canvasStyleDimensionsRef,

      zoomFactor,
      panOffset,
      currentCanvasRectRef,
      controlPanelStuckRef,
    } = canvasContext;

    const {
      activeTool, 
      effectType, 
      visibleLineWidth, 

      controlIsDownRef,
      shiftIsDownRef,
      spaceIsDownRef,

      isResizingBrush,

      quickColorChangeTimeRef,
      hotMouseActive,

      ifIndicateBrushFun,

    } = brushContext;

    const {
      handleMouseDown,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      handleContextMenu,
      stopPickingColorFromCanvas,
    } = drawMethods;

    const { controlButtonsStyle } = panelOffset({
      canvasContext,
    })

    const game = gameInfoRef.current;

    return (
        <
        div className="drawing-area"
        style={{
          width: `${visibleSizeRef.current}px`,
          height: `${Math.max(visibleSizeRef.current * 1.35, window.innerHeight)}px`,
          margin: '0 auto', // Центрирование по горизонтали
          top: '0', // Максимально вверху, но с небольшим отступом
          left: 0,
          right: 0,
        }}
        >

        {zoomFactor === 1 && (
          <div className="word" 
            {...createBetterClick({
              onClick: ()=>{
                (moderatorModeRef.current || (!game.word && !game?.settings?.title)) ? 
                openMenu('drawing') :
                openMenu('word');
              }
            })}
            style={{  cursor:  'pointer', }}
          >
          {gameWordFun()}  ⚙️
        </div>
        )}

        <ZoomFactor
          canvasContext={canvasContext}
          brushContext={brushContext}
          drawMethods={drawMethods}
        />

          {
            /* <div className="debug">
              {drawMethods.phoneZoomDebugText()}
            </div> */
          }

          {renderCheckerboardBackground({ gameContext, canvasContext })}
          {renderAccessoryBackground({ gameContext, canvasContext })}

          <canvas className='canvas'
              ref={canvasRef}
              style={{
                transformOrigin: '0 0',
                transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px) scale(${zoomFactor})`
              }}
            />

          {
            ifIndicateBrushFun() &&  (
            <ConvyBrushIndicator 
              gameContext={gameContext}
              canvasContext={canvasContext}
              brushContext={brushContext}
            />
          )}

          <ConnectionIndicator /> 
          <ChatOverlay /> 

          <canvas className='backing-canvas'
            onMouseDown={ gameInfo ? handleMouseDown : null }
            onTouchStart={ gameInfo ? handleTouchStart : null }
            onTouchMove={ handleTouchMove }
            onTouchEnd={ handleTouchEnd }
            onContextMenu={ handleContextMenu } 
            style = {{
              width: window.innerWidth,
              height: `${(currentCanvasRectRef.current?.bottom || 420) + 200 }px`,
            }}
            
          />

          <canvas className='temp-canvas'
            onMouseDown={  gameInfo ? handleMouseDown : null }
            // onMouseLeave={activeTool === 'pipette' ? stopPickingColorFromCanvas : null}
            onTouchStart={ gameInfo ? handleTouchStart : null }
            onTouchMove={ handleTouchMove }
            onTouchEnd={ handleTouchEnd }
            onContextMenu={handleContextMenu} 
            ref={tempCanvasRef}

            style={{ 
              transformOrigin: '0 0',
              transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px) scale(${zoomFactor})`,
              cursor: 
                hotMouseActive ? 'default' :
                ifIndicateBrushFun() ? 
                  (visibleLineWidth > 5 && !isResizingBrush) ? 'none' : 'default' :
                
                controlIsDownRef.current ? 'zoom-in' :
                spaceIsDownRef.current ? 'grab' :
                activeTool === 'pipette' ? 'crosshair' :
                (activeTool === 'effect' && effectType === 'filler') ? 'crosshair' : 'default',
                // activeTool === 'filler' ? 'cell' : 'default',
            }}
          />

          <div className="controls" style={controlButtonsStyle}>
            <ControlButtons
              gameContext={gameContext}
              canvasContext={canvasContext}
              brushContext={brushContext}
              drawMethods={drawMethods}
            />
          </div>
          <div className="under-controls"/>
      </div>
  );
}

export default ConvyBoard;
