// src/utils/styles/themeManager.js

// Ключ для хранения темы в localStorage
const THEME_STORAGE_KEY = 'crocopuzzle-theme';

// Доступные темы
const THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
};

// Настройки CSS переменных для каждой темы
const THEME_SETTINGS = {
  [THEMES.LIGHT]: {
    '--tg-theme-bg-color': '#FFFFFF',
    '--tg-theme-secondary-bg-color': '#F2F2F2',
    '--tg-theme-text-color': '#000000',
    '--tg-theme-hint-color': '#828282'
  },
  [THEMES.DARK]: {
    '--tg-theme-bg-color': '#222222',
    '--tg-theme-secondary-bg-color': '#333333',
    '--tg-theme-text-color': '#FFFFFF',
    '--tg-theme-hint-color': '#AAAAAA'
  }
};

/**
 * Получить текущую тему из localStorage или установить светлую по умолчанию
 * @returns {string} Текущая тема ('light' или 'dark')
 */
export const getCurrentTheme = () => {
  return localStorage.getItem(THEME_STORAGE_KEY) || THEMES.LIGHT;
};

/**
 * Применить тему к документу, обновляя CSS переменные
 * @param {string} theme - Тема для применения ('light' или 'dark')
 */
export const applyTheme = (theme) => {
  const themeSettings = THEME_SETTINGS[theme] || THEME_SETTINGS[THEMES.LIGHT];
  
  // Применяем каждую CSS переменную из настроек темы
  Object.entries(themeSettings).forEach(([cssVar, value]) => {
    document.documentElement.style.setProperty(cssVar, value);
  });
};

/**
 * Установить новую тему, сохранить в localStorage и применить
 * @param {string} theme - Тема для установки ('light' или 'dark')
 */
export const setTheme = (theme) => {
  if (theme !== THEMES.LIGHT && theme !== THEMES.DARK) {
    console.warn(`Неизвестная тема: ${theme}. Использую светлую тему по умолчанию.`);
    theme = THEMES.LIGHT;
  }
  
  localStorage.setItem(THEME_STORAGE_KEY, theme);
  applyTheme(theme);
  
  return theme;
};

/**
 * Переключить между светлой и темной темой
 * @returns {string} Новая активная тема
 */
export const toggleTheme = () => {
  const currentTheme = getCurrentTheme();
  const newTheme = currentTheme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
  
  return setTheme(newTheme);
};

/**
 * Инициализация темы при загрузке приложения
 */
export const initializeTheme = () => {
  const savedTheme = getCurrentTheme();
  applyTheme(savedTheme);
};

// Экспортируем константы для использования в других модулях
export const THEME = {
  LIGHT: THEMES.LIGHT,
  DARK: THEMES.DARK
}; 