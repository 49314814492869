// src/utils/canvasSetup.js
import useStore from '../../store';

/**
 * Функция настройки размеров канвасов.
 * Использует объекты из ref (например, ref.convy) и состояние game из стора.
 * Вызывается один раз при загрузке приложения и/или при изменении game,
 * а также регистрирует обработчик изменения размеров окна.
 */
const setupCanvasSizes = () => {
  const state = useStore.getState();
  const { ref, } = state;
  const { info, convy, menu } = ref;
  const { game } = info || {};

  if (!convy) {
    console.warn('Отсутствует convy в ref');
    return;
  }
  if (!game) {
    console.warn('game ещё не загружен');
    return;
  }

  // Функция инициализации размера конкретного канваса
  const initializeCanvasSize = (canvasObj, fill = false) => {
    if (!canvasObj.ref) return;
    // Если задан presetSize – используем его, иначе рассчитываем по ориентации и highRes‑флагу
    if (game.presetSize) {
      canvasObj.dimensions = game.presetSize;
    } else {
      const orientation = game.settings?.orientation || 'square';
      if (orientation === 'vertical') {
        if (game.settings?.highRes) {
          canvasObj.dimensions =
            game.dimensions?.height === 1920
              ? { width: 1280, height: 1920 }
              : { width: 800, height: 1200 };
        } else {
          canvasObj.dimensions = { width: 800, height: 1200 };
        }
      } else if (orientation === 'horizontal') {
        if (game.settings?.highRes) {
          canvasObj.dimensions =
            game.dimensions?.width === 1920
              ? { width: 1920, height: 1280 }
              : { width: 1200, height: 800 };
        } else {
          canvasObj.dimensions = { width: 1200, height: 800 };
        }
      } else {
        if (game.settings?.highRes) {
          canvasObj.dimensions = { width: 1280, height: 1280 };
        } else {
          canvasObj.dimensions = { width: 800, height: 800 };
        }
      }
    }
    const canvas = canvasObj.ref;
    const ctx = canvas.getContext('2d');
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    canvas.width = canvasObj.dimensions.width;
    canvas.height = canvasObj.dimensions.height;
    if (fill) {
      ctx.fillStyle = game.settings?.backgroundColor || '#FFFFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  };

  // Функция для пересчёта CSS‑размеров канваса и масштаба
  const recalcStyleDimensions = () => {
    if (!convy.main || !convy.main.dimensions) return;

    convy.basicSize = Math.min(window.innerWidth, window.innerHeight * 0.7);

    const orientation = game.settings?.orientation || 'square';
    let styleDimensions;

    const aspectRatio = convy.main.dimensions.height / convy.main.dimensions.width;

    if (orientation === 'vertical') {
      convy.basicSize = Math.min(window.innerWidth * aspectRatio, window.innerHeight * 0.71);
      styleDimensions = { width: (convy.basicSize * 0.98) / aspectRatio, height: convy.basicSize * 0.98 };
    } else if (orientation === 'horizontal') {
      convy.basicSize = Math.min(window.innerWidth, window.innerHeight);
      styleDimensions = { width: convy.basicSize * 0.98, height: convy.basicSize * 0.98 * aspectRatio };
    } else {
      convy.basicSize = Math.min(window.innerWidth, window.innerHeight * 0.7);
      styleDimensions = { width: convy.basicSize * 0.98, height: convy.basicSize * 0.98 };
    }
    convy.styleDimensions = styleDimensions;
    convy.scale = Math.min(
      styleDimensions.width / convy.main.dimensions.width,
      styleDimensions.height / convy.main.dimensions.height
    );

  };

  // Функция для применения CSS‑стилей к канвасу
  const applyStyleDimensions = (canvasObj) => {
    if (!canvasObj.ref || !convy.styleDimensions) return;
    canvasObj.ref.style.width = `${convy.styleDimensions.width}px`;
    canvasObj.ref.style.height = `${convy.styleDimensions.height}px`;
  };

  // Основная функция настройки: инициализируем реальные размеры, пересчитываем CSS‑размеры и применяем их
  const setup = () => {
    if (convy.main?.ref) initializeCanvasSize(convy.main, true);
    if (convy.bg?.ref) initializeCanvasSize(convy.bg);
    if (convy.temp?.ref) initializeCanvasSize(convy.temp);
    if (convy.soft?.ref) initializeCanvasSize(convy.soft);
    recalcStyleDimensions();

    convy.initialStyleDimensions = convy.styleDimensions;
    
    if (convy.main?.ref) applyStyleDimensions(convy.main);
    if (convy.bg?.ref) applyStyleDimensions(convy.bg);
    if (convy.temp?.ref) applyStyleDimensions(convy.temp);
    if (convy.soft?.ref) applyStyleDimensions(convy.soft);
    // Обновляем состояние стора (если требуется)
    useStore.setState({ canvasInitialized: true });
    menu.methods.render();
  };

  // Запускаем настройку сразу
  setup();

  // Регистрируем обработчик изменения размеров окна
  window.addEventListener('resize', () => {

    recalcStyleDimensions();

    if (convy.main?.ref) applyStyleDimensions(convy.main);
    if (convy.bg?.ref) applyStyleDimensions(convy.bg);
    if (convy.temp?.ref) applyStyleDimensions(convy.temp);
    if (convy.soft?.ref) applyStyleDimensions(convy.soft);

    menu.methods.render();

  });

  // Также можно зарегистрировать обработчик Telegram (если доступен)
  window.Telegram?.WebApp?.onEvent?.('viewportChanged', () => {
    recalcStyleDimensions();
    if (convy.main?.ref) applyStyleDimensions(convy.main);
    if (convy.bg?.ref) applyStyleDimensions(convy.bg);
    if (convy.temp?.ref) applyStyleDimensions(convy.temp);
    if (convy.soft?.ref) applyStyleDimensions(convy.soft);
  });
};

export default setupCanvasSizes;
