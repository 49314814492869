import React, { useState, useEffect } from 'react';
import useStore from '../../../../store';
import chroma from 'chroma-js';

function EraserColorPicker({ store }) {
  const color = useStore((state) => state.color);
  const lineWidth = useStore((state) => state.lineWidth);
  const softness = useStore((state) => state.softness);

  const { menu, info, brush } = store.ref;
  const { setTheColorFun, visibleLineWidthFun, setTheSizeFun } = brush.methods;
  const { isAppleDevice } = info.methods;

  const [opacity, setOpacity] = useState(() => chroma(color).alpha());

  useEffect(() => {
    setOpacity(chroma(color).alpha());
  }, [color]);

  const handleOpacityChange = (e) => {
    const newOpacity = e.target.value / 100;
    setOpacity(newOpacity);
    const newColor = chroma('#FFFFFF').alpha(newOpacity).hex('rgba');
    setTheColorFun(newColor);
  };

  const handleLineWidthChange = (e) => {
    setTheSizeFun(+e.target.value);
  };

  const onClose = () => {
    useStore.setState({ showPalette: false });
  };

  const positionFromTop = Math.min(
    Math.max(210, menu.panel.offset - 210),
    window.innerHeight - 360
  );
  const blurSize = `${softness * visibleLineWidthFun()}px`;

  return (
    <div style={{ position: 'relative' }}>
      <div className='overlay-background'
        onClick={()=>{
          onClose ();
        }}
        onTouchEnd={()=>{
          setTimeout(() => { onClose() }, 100);
        }}
      ></div>

      <div
        style={{
          position: 'fixed',
          top: `${positionFromTop + 90}px`,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1200,
          width: '200px',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          background: color,
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '-32.5px',
            transform: 'translateX(-50%)',
            borderRadius: '50%',
            width: `${visibleLineWidthFun()}px`,
            height: `${visibleLineWidthFun()}px`,
            top: `${-50 - visibleLineWidthFun()}px`,
            background: color,
            boxShadow: softness ? 'none' : '0 0 0 0.3px grey, 0 0 0 0.3px black',
            filter: `blur(${blurSize})`,
          }}
        />

        <div
          style={{
            position: 'absolute',
            left: '-47px',
            top: '-20px',
          }}
        >
          <input
            type="range"
            min="1"
            max="100"
            value={lineWidth}
            onChange={handleLineWidthChange}
            onMouseDown={(e) => isAppleDevice() && e.preventDefault()}
            style={{
              height: '177px',
              width: '25px',
              writingMode: 'vertical-lr',
              direction: 'rtl',
              cursor: 'pointer',
              background: 'var(--tg-theme-button-color)',
            }}
          />
        </div>

        <div style={{ marginTop: '10px' }}>
          <label
            style={{
              display: 'block',
              marginBottom: '10px',
              color: 'var(--tg-theme-text-color)',
              textShadow: '0 0 1px gray, 0 0 1px gray',
            }}
          >
            {window.t('customize.prop.opacity')}: {Math.round(opacity * 100)}%
          </label>
          <input
            type="range"
            min="0"
            max="100"
            value={opacity * 100}
            onChange={handleOpacityChange}
            style={{
              width: '100%',
              cursor: 'pointer',
              background: 'var(--tg-theme-button-color)',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EraserColorPicker;