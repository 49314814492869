// plain.js
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 

const defaultBrushSettings = brushDefaults.marker;

export async function drawPlainMarkerStroke(stroke, context, params) {

  const {
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  await drawStrokeWithGradient(stroke, context, brushSettings)

}

export async function drawStrokeWithGradient(stroke, context, brushSettings) {
  const { gradientColor } = stroke;
  if (gradientColor) {
    await drawGradientStroke(stroke, context, brushSettings);
  } else {
    drawPlainStroke (stroke, context, brushSettings)
  }

}

async function drawGradientStroke (stroke, context, brushSettings) {

  const { points, color, gradientColor } = stroke;

  // Создаем отдельный буфер
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  if (stroke.lineDash) { bufferCtx.setLineDash(stroke.lineDash); }

  drawPlainStroke (
    {...stroke, color: 'black'}, 
    bufferCtx,
    brushSettings,
    )

  await fillGradient(bufferCtx, points, color, gradientColor, stroke.lineWidth)

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);
  
  
}


export function drawPlainStroke (stroke, context, brushSettings = {}) {

  const {
    points, color, lineWidth,
  } = stroke;

  context.lineCap = brushSettings.squareBrush ? 'square' : 'round';
  context.lineJoin = brushSettings.squareBrush ? 'miter' : 'round';
  // context.lineJoin = 'round';

  // Рисуем сплошной штрих
  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  if (points.length === 1) {
    drawPoint(points, context, lineWidth, color, brushSettings.squareBrush);
  } else if (points.length === 2) {
    drawStraightLine (points, context)
  } else {
    drawStroke(points, context);
  }
  
}



export function drawPoint(points, bufferCtx, lineWidth, color, squareBrush) {
  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
  
  bufferCtx.fillStyle = color;
  
  if (squareBrush) {
    // Рисуем квадрат
    const halfSize = lineWidth / 2;
    bufferCtx.fillRect(
      point[0] - halfSize, 
      point[1] - halfSize, 
      lineWidth, 
      lineWidth
    );
    
  } else {
    // Рисуем круг
    bufferCtx.beginPath();
    bufferCtx.arc(
      point[0], 
      point[1], 
      lineWidth / 2, 
      0, 
      Math.PI * 2
    );
    bufferCtx.fill();
  }
}

function drawStraightLine (points, context) {

  let firstPoint = points[0];
  let secondPoint = points[1];

  context.beginPath();
  context.moveTo(firstPoint[0], firstPoint[1]);

  context.lineTo(
    secondPoint[0], 
    secondPoint[1],
  );
  context.stroke();
  
}



function drawStroke (points, bufferCtx) {
  
  bufferCtx.beginPath();
  bufferCtx.moveTo(points[0][0], points[0][1]);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2
    ];
    bufferCtx.quadraticCurveTo(nextPoint[0], nextPoint[1], midPoint[0], midPoint[1]);
  }
  const lastPoint = points[points.length - 1]
  bufferCtx.lineTo(
    lastPoint[0], 
    lastPoint[1],
    );
  bufferCtx.stroke();
  
}

