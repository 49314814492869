// oil.js
import chroma from 'chroma-js';
import { interpolatePoints, computeLineWidth } from './feather';
import {  filterClosePoints } from './helpers/points';
import { getTexture } from './helpers/brushLoader';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 
const defaultBrushSettings = brushDefaults.rembrandt || {};

export async function drawOilStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  softness,
  time,
  sets = {},
}, context) {
  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  let { 
    speedDependence,
    texture = 'fabric',
  } = brushSettings;
  const oilTexture = await getTexture('oil', texture);

  if (!speedDependence) {
    points = filterClosePoints(points, Math.min(lineWidth, 20));
  }

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  color = chromaColor.alpha(1).hex();

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';


  let shadow = {
    offset: { x: 0.3, y: 0.3 },
    plusWidth: 1.9,
  }
  let light = {
    offset: { x: 0.1, y: 0.1 },
    plusWidth: 1.4,
  }

  // Рисуем тень (черный цвет со смещением 2px)
  bufferCtx.globalAlpha = 0.03;
  drawStroke({
    points,
    lineWidth,
    color: 'rgba(0, 0, 0, 1)',
    offset: shadow.offset,
    plusWidth: shadow.plusWidth,
    time,
  }, bufferCtx, brushSettings)

  // Рисуем подсветку (белый цвет со смещением 1px)
  bufferCtx.globalAlpha = 0.05;
  drawStroke({
    points,
    lineWidth,
    color: 'rgba(255, 255, 255, 1)',
    offset: light.offset,
    plusWidth: light.plusWidth,
    time,
  }, bufferCtx, brushSettings)
  
  // Стираем область под основным мазком
  bufferCtx.globalAlpha = 1;
  bufferCtx.globalCompositeOperation = 'destination-out';

  drawStroke({
    points,
    lineWidth,
    color: 'rgba(255, 255, 255, 1)',
    time,
  }, bufferCtx, brushSettings)

  // Возвращаем режим наложения в нормальное состояние
  bufferCtx.globalCompositeOperation = 'source-over';

  // Применяем текстуру к основному мазку
  if (oilTexture) {
    const texturedStrokeCanvas = document.createElement('canvas');
    texturedStrokeCanvas.width = bufferCanvas.width;
    texturedStrokeCanvas.height = bufferCanvas.height;
    const texturedStrokeCtx = texturedStrokeCanvas.getContext('2d');

    texturedStrokeCtx.lineCap = 'round';
    texturedStrokeCtx.lineJoin = 'round';

    drawStroke({
      points,
      lineWidth,
      color,
      time,
    }, texturedStrokeCtx, brushSettings)

    texturedStrokeCtx.globalCompositeOperation = 'source-in';
    
    const offsetX = 0;
    const offsetY = 0;
    // const offsetX = seededRandom(time) * oilTexture.width;
    // const offsetY = seededRandom(time + 1) * oilTexture.height;
    
    const patternCanvas = document.createElement('canvas');
    patternCanvas.width = oilTexture.width;
    patternCanvas.height = oilTexture.height;
    const patternCtx = patternCanvas.getContext('2d');
    
    patternCtx.drawImage(oilTexture, 0, 0);
    patternCtx.globalCompositeOperation = 'source-atop';
    patternCtx.drawImage(oilTexture, offsetX, offsetY);
    
    const pattern = texturedStrokeCtx.createPattern(patternCanvas, 'repeat');
    texturedStrokeCtx.fillStyle = pattern;
    texturedStrokeCtx.fillRect(0, 0, texturedStrokeCanvas.width, texturedStrokeCanvas.height);

    // Применяем цвет
    await fillGradient(texturedStrokeCtx, points, color, gradientColor, lineWidth)

    // Добавляем текстурированный мазок на буфер
    bufferCtx.drawImage(texturedStrokeCanvas, 0, 0);
  }

  // Рисуем буфер на основном холсте
  context.globalAlpha = originalAlpha;
  context.drawImage(bufferCanvas, 0, 0);
  context.globalAlpha = 1; // Возвращаем значение по умолчанию
}



// Функция для рисования сужающегося штриха
function drawStroke({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  plusWidth = 0,
  time,
}, context, brushSettings) {

  let rng = createSeededRandom(time);

  const stroke = {
    points, lineWidth, color, offset, plusWidth,
  }
 
  if (points.length === 1) {
    return drawPoint(stroke, context);
  } else if (lineWidth >= 200 || points.length >= 200) {
    return drawPlainStroke(stroke, context);
  } else {
    return drawTaperingStroke(stroke, context, brushSettings, rng);
  }

}


function drawTaperingStroke({
  points, lineWidth, color, offset, plusWidth,
}, context, brushSettings, rng) {

  let { 
    minWidthStart = 0.01, // Мин. толщина начала, 1 = lineWidth
    taperStartPoint = 0.5, // Длина сужения начала, 0.5 = целая половина штриха
    minWidthEnd = 0.01, // Мин. толщина конца, 1 = lineWidth
    taperEndPoint = 0.5, // Длина сужения конца
    edgeWidening,
    centralWidth = 0.2,
  } = brushSettings;

  if (taperStartPoint > taperEndPoint) {
    const midpoint = (taperStartPoint + taperEndPoint) / 2;
    taperStartPoint = midpoint;
    taperEndPoint = midpoint;
  }


  let numInterpolations = Math.max(5, lineWidth/5);
  numInterpolations = Math.min(numInterpolations, 20);
  numInterpolations = Math.ceil(numInterpolations);

  const interpolatedPoints = interpolatePoints(points, numInterpolations);
  const totalPoints = interpolatedPoints.length;

  let centerPartStart = Math.floor(totalPoints * taperStartPoint);
  let centerPartEnd = Math.floor(totalPoints * taperEndPoint);

  context.strokeStyle = color;

  for (let i = 0; i < totalPoints - 1; i++) {
    const startPoint = interpolatedPoints[i];
    const endPoint = interpolatedPoints[i + 1];

    let segmentWidth = computeLineWidth({
      index: i,
      totalPoints,
      lineWidth,
      centerPartStart,
      centerPartEnd,
      minWidthStart,
      minWidthEnd,
      edgeWidening,
      centralWidth,
    }) + plusWidth;

    segmentWidth += plusWidth;
    // segmentWidth = lineWidth / 3 + segmentWidth * 2 / 3 + plusWidth;

    context.beginPath();
    context.moveTo(startPoint[0] + offset.x, startPoint[1] + offset.y);
    context.lineTo(endPoint[0] + offset.x, endPoint[1] + offset.y);
    context.lineWidth = segmentWidth;
    context.stroke();
  }

}


function drawPlainStroke ({
  points, lineWidth, color, offset, plusWidth,
},context) {

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  context.beginPath();
  context.moveTo(points[0][0] + offset.x, points[0][1] + offset.y);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + offset.x + currentPoint[0] + offset.x) / 2,
      (nextPoint[1] + offset.y + currentPoint[1] + offset.y) / 2
    ];
    context.quadraticCurveTo(nextPoint[0] + offset.x, nextPoint[1] + offset.y, midPoint[0] + offset.x, midPoint[1] + offset.y);
  }
  const lastPoint = points[points.length - 1]
  context.lineTo(
    lastPoint[0] + offset.x, 
    lastPoint[1] + offset.y,
    );
  context.stroke();
  
}

function drawPoint ({
  points, lineWidth, color, offset, plusWidth,
}, context) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  context.beginPath();
  context.arc(
    point[0] + offset.x, 
    point[1] + offset.y, 
    (lineWidth / 2) + plusWidth, 
    0, 
    Math.PI * 2
    );
  context.fillStyle = color;
  context.fill();
}



function createSeededRandom(seed) {
  return function() {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
}