import React, { useState, useEffect } from 'react';

const ConvyBrushIndicator = ({
  gameContext,
  canvasContext,
  brushContext,
}) => {

  const {
    gameInfoRef,
  } = gameContext;

  const {
    canvasStyleDimensionsRef,
    canvasRef,
    isDrawingRef,
    isTouchDevice,
    mouseDetected,
    mousePositionRef,

    panOffset,
    rotation,
  } = canvasContext;

  const {

    actualSoftnessFun,
    gradientColor,
    visibleColorFun,
    visibleLineWidth,

    showPalette,
    isResizingBrush,
    isChangingBrightness,
    isChangingOpacity,
    isChangingSoftness,
    brushIndicatorFrame,
    activeTool,

    hotMouseStartPositionRef,
    hotMouseActive,

    brushSetsFun,
    brushLinkFun,

  } = brushContext;

  const [cursorPos, setCursorPos] = useState(positionInCanvas(mousePositionRef.current));
  const [isVisible, setIsVisible] = useState(false);

  const squareIndicator = brushSetsFun('common').squareBrush && ['plain', 'dashed', 'outlined', 'pencil', 'feather'].includes(brushLinkFun());


  function positionInCanvas ({x, y}) {
    if (!canvasRef.current) {return {x: 0, y: 0}};
    const canvasRect = canvasRef.current.getBoundingClientRect();
    return {
      // x: x,
      // y: y,
      x: x - canvasRect.left,
      y: y - canvasRect.top,
    };
  }

  useEffect(() => {
    if (
      (isTouchDevice && !mouseDetected) 
      || showPalette
      || isResizingBrush
      || isChangingBrightness
      || isChangingOpacity
      || isChangingSoftness
      ) {
      setIsVisible(false);
      // Если устройство с тач-интерфейсом, не регистрируем обработчики
      return;
    } 

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const fromOutsideWidth = 30

    const isInsideCanvas = (
      cursorPos.x >= - fromOutsideWidth 
      && cursorPos.x <= (canvasRect.right - canvasRect.left) + fromOutsideWidth 
      && cursorPos.y >= - fromOutsideWidth 
      && cursorPos.y <= (canvasRect.bottom - canvasRect.top) + fromOutsideWidth
    );

    if (isInsideCanvas) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  
  }, [cursorPos, canvasRef, isTouchDevice, mouseDetected, showPalette, isResizingBrush, activeTool, isChangingBrightness, isChangingOpacity, isChangingSoftness]); // Добавили isTouchDevice в зависимости
  
  
  useEffect(() => {

    const handleMouseMove = (event) => {
      if (!canvasRef.current) { return; }
      
      const canvasRect = canvasRef.current.getBoundingClientRect();
      const scaledPosition = hotMouseActive ? {
        x: (hotMouseStartPositionRef.current.x - canvasRect.left),
        y: (hotMouseStartPositionRef.current.y - canvasRect.top)
      } :
      {
        x: (event.clientX - canvasRect.left),
        y: (event.clientY - canvasRect.top)
      };
      setCursorPos(scaledPosition);
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
    
  }, []); // Добавили isTouchDevice в зависимости

  const actualSoftness = actualSoftnessFun();
  const blurSize = `${actualSoftness * visibleLineWidth}px`; // Пример коэффициента умножения на 3

  if (!canvasRef.current) {return;}
  const canvasRect = canvasRef.current.getBoundingClientRect();

  const gradientVisible = activeTool === 'brush' && gameInfoRef.current?.settings.gradientOn;

  return (isVisible) ? (
    <div className = 'temp-canvas' style = {{
      overflow: 'hidden',
      transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px)`,
      width: canvasRect.width,
      height: canvasRect.height,
      zIndex: 120,
      pointerEvents: 'none',
    }}>
      <div
        style={{
          position: 'absolute',
          left: `${cursorPos.x - visibleLineWidth/2}px`,
          top: `${cursorPos.y - visibleLineWidth/2}px`,
          width: `${visibleLineWidth}px`,
          height: `${visibleLineWidth}px`,
          background: isDrawingRef.current ?  '#00000000' : 
          gradientVisible ? `linear-gradient(to bottom, ${visibleColorFun()}, ${gradientColor || '#0000'})` :  visibleColorFun(), 
          // backgroundColor: isDrawingRef.current? '#00000000' : visibleColorFun(),
          borderRadius: squareIndicator ? '' : '50%',
          pointerEvents: 'none',
          cursor: 'none',
          boxShadow: actualSoftness ? 0 : brushIndicatorFrame,
          filter: `blur(${blurSize})`, 
          zIndex: 150,
        }}
      />
    </div>
  ) : null;
};

export default ConvyBrushIndicator;
