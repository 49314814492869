// BoardMain.js
import './menus/Buttons.css'; 
import React, { useEffect, } from 'react';

import useStore from '../store';
import Board from './board/Board';
import Spectator from './board/Spectator';
import PuzzleBoard from './puzzle/PuzzleBoard'; // <--- Import PuzzleBoard


// import SpectatorBoard from './SpectatorBoard';
import Spinner from './spinner/Spinner';

import GameNotLoaded from './menus/results/GameNotLoaded';
import ResultsOverlay from './menus/results/ResultsOverlay';

import ModeInfoOverlay from './menus/settings/ModeInfoOverlay';
import SettingsOverlay from './menus/settings/SettingsOverlay';
import DrawingSetsOverlay from './menus/settings/DrawingSetsOverlay';
import WordChangeOverlay from './menus/settings/WordChangeOverlay';

import EffectMenu from './menus/brush/EffectMenu';
import BrushMenu from './menus/brush/BrushMenu';
import EraserMenu from './menus/brush/EraserMenu';
import CustomizeBrush from './menus/brush/CustomizeBrush';

import LayerMenu from './menus/layer/LayerMenu';
import CustomizeLayer from './menus/layer/CustomizeLayer';

import PremiumMenu from './menus/premium/PremiumMenu';

import PopupMessage from './menus/popup/PopupMessage';
import LogOverlay from './menus/LogOverlay';
import StrokeHistoryOverlay from './board/history/StrokeHistoryOverlay';


import RicRoll from './menus/rickroll/RicRoll';

function BoardMain() {

  const store = useStore.getState();

  useStore(state=>state.render);

  const spinner = useStore(state=>state.spinner);
  const isChecked = useStore(state=>state.isChecked);
  const isHacker = useStore(state=>state.isHacker);
  const gameInfo = useStore(state=>state.gameInfo);
  const gameError = useStore(state=>state.gameError);
  const canDraw = useStore(state=>state.canDraw);
  const strokesApplied = useStore(state=>state.strokesApplied);
  const boardReady = useStore(state=>state.boardReady);

  const showMenu = useStore(state=>state.showMenu);
  const showPremiumMenu = useStore(state=>state.showPremiumMenu);
  const showModeHelp = useStore(state=>state.showModeHelp);
  const popupMessage = useStore(state=>state.popupMessage);

  const menuId = showMenu?.[0];

  const puzzleState = useStore(state => state.puzzleState); // Use puzzle state
  const isPuzzleMode = !!gameInfo?.puzzle; // Check if puzzle data exists

  if (gameInfo) {
    let a = 1;
  }
  const isReady = isPuzzleMode ? puzzleState?.isInitialized : boardReady;


  return (
    <>
      {((!isReady && !isHacker) || spinner > Date.now()) && (
        <Spinner style = {{
          opacity: 1,
          transition: "opacity 0.5s ease", // Плавный переход за 0.5 секунды
        }} 
          store={store}
        />
      )}
      {isChecked && isHacker && (
        <RicRoll />
      )}
      <div style={{
        zIndex: '1000',
        transition: "opacity 0.2s ease", 
        }}>
        {
          menuId === 'strokeHistory' ? (
            <StrokeHistoryOverlay store={store} />
          ) : 
          menuId === 'settings' ? (
            <SettingsOverlay 
              store={store}
            />
          ) : 
          menuId === 'drawing' ? (
            <DrawingSetsOverlay 
              store={store}
            />
          ) : 
          menuId === 'brush' && canDraw ? (
            <BrushMenu 
              store={store}
            />
          ) : 
          menuId === 'eraser' && canDraw ? (
            <EraserMenu 
              store={store}
            />
          ) : 
          menuId === 'effect' && canDraw ? (
            <EffectMenu 
              store={store}

            />
          ) : 
          menuId === 'customizeBrush' && canDraw ? (
            <CustomizeBrush 
              store={store}
            />
          ) : 
          menuId === 'layer' && canDraw ? (
            <LayerMenu 
              store={store}
            />
          ) : 
          menuId === 'customizeLayer' && canDraw ? (
            <CustomizeLayer
              store={store}
            />
          ) : 
          menuId === 'word' ? (
            <WordChangeOverlay 
              store={store}
            />
          ) : 
          menuId === 'lag' ? (
            <LogOverlay 
              store={store}
            />
          ) : 
          null
        }

        {showPremiumMenu && (
          <PremiumMenu 
            store={store}
          />
        )}
        {gameInfo?.modeHelp && showModeHelp && !gameInfo?.results && (
          <ModeInfoOverlay 
            store={store}
          />
        )}
        {popupMessage && (
          <PopupMessage 
            store={store}
          />
        )}
        {!gameInfo?.puzzle && gameInfo?.results && !store.ref.work.methods.getUserGameSets()?.continueDrawing && (
          <ResultsOverlay 
            store={store}
          />
        )}
    
        {gameError?.error && (
          <GameNotLoaded
            store={store}
            gameError={gameError}
          />
        )}
      </div>

      <div
        style = {{
          opacity: (strokesApplied || gameError) ? 1 : 0.00,
          transition: "opacity 0.3s ease", // Плавный переход за 0.5 секунды
        }}
      >
        { 
          isPuzzleMode && (
            <PuzzleBoard /> // Show PuzzleBoard if in puzzle mode
          )
        }
        { 
          !isPuzzleMode && strokesApplied && (canDraw ? 
          (
            <Board />
          ): (
            <Spectator />
          )
        )}
      </div>
      
    </>
  );
}

export default BoardMain;