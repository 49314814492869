// src/store/debugSlice.js

const createDebugSlice = (set, get, ref) => {
  // Инициализируем ref.debug, если его еще нет
  if (!ref.debug) {
    ref.debug = {
      logArr: [],
      metrics: {
        errors: [],
        draw: [],
        log: [],
        show: {},
      },
    };

    ref.debug.methods = {

      log: (text, params) => {
        const time = Date.now();
        const logEntry = { time, text, ...params };
        ref.debug.logArr.push(logEntry);
        console.log(`${time}: ${text}`, params); 
        ref.debug.methods.refresh();
      },

      refresh: ()=>{
        set(state=>({debugRender: state.debugRender + 1}))
      },

      readLog: (count = 20, options = { includeTimestamp: true }) => {
        const { includeTimestamp } = options;
        const logs = ref.debug.logArr.slice(-count);
      
        return logs
          .map(entry => {
            if (!includeTimestamp) return entry.text;
      
            const date = new Date(entry.time);
            const hours = String(date.getHours());
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            const hundredths = String(Math.floor(date.getMilliseconds() / 10)).padStart(2, '0'); // Сотые доли секунды
            const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
            const formattedTime = `${hours}:${minutes}:${seconds}.${hundredths}`;
      
            return `${formattedTime}: ${entry.text}`;
          })
          .join('\n');
      },
    }

  }

  // Добавляем window.croco.log для глобального доступа
  window.croco = window.croco || {}
  window.croco.log = ref.debug.methods.log; 

  return {
    debugRender: 0,
    // Здесь можно добавить реактивные состояния, если нужно
  };
};

export default createDebugSlice;