// createBetterClick.js

let pointerStartTarget = null;

let contextMenuOpened = false;
let isPointerDown = false;
let hasMoved = false;

let startTime = 0;

function createBetterClick({onClick, onContextMenu, short}) {

  function handleClick(e) {

    isPointerDown = false;
    if (short && startTime < Date.now() - 300) { 
      return; 
    }
    onClick?.(e);

  }

  function handlePointerDown(e) {
    pointerStartTarget = e.target;
    isPointerDown = true;
    startTime = Date.now();

    contextMenuOpened = false;
    hasMoved = false;
  }

  function handlePointerMove() {
    hasMoved = true;
  }

  function handleContextMenu(e) {
    contextMenuOpened = true;
    onContextMenu?.(e)
  }

  async function handlePointerUp(e) {

    const element = pointerStartTarget;
    
    // Проверяем, находится ли точка отпускания над начальным элементом
    const rect = element.getBoundingClientRect();
    const isWithinBounds = 
      e.clientX >= rect.left && 
      e.clientX <= rect.right &&
      e.clientY >= rect.top && 
      e.clientY <= rect.bottom;

    await new Promise(resolve=>setTimeout(resolve, 100));

    if (short && startTime < Date.now() - 300) { 
      return; 
    }

    if (!contextMenuOpened && isWithinBounds && isPointerDown && !hasMoved) {
      isPointerDown = false;
      onClick?.(e);
    } 
  }

  return {
    onClick: handleClick,
    onPointerDown: handlePointerDown,
    onPointerMove: handlePointerMove,
    onPointerUp: handlePointerUp,
    onContextMenu: handleContextMenu,
  };
}

export { createBetterClick };