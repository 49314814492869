// src/components/menus/layer/CustomizeLayer.jsx
import './CustomizeLayer.css'; // Стили можно создать на основе CustomizeBrush.css
import useStore from '../../../store';
import React, { useRef, useState } from 'react';

import { FaClone, FaTrash, FaTimes } from 'react-icons/fa';
import LayerThumbnail from './LayerThumbnail'; // Импорт нового модуля

function CustomizeLayer({ store }) {

  const [isSliderActive, setIsSliderActive] = useState(false);

  const { t } = window;

  useStore(state => state.render);

  const { ref } = store;
  const { menu, work, convy, drawing } = ref;

  const {
    createBetterClick,
    showPopupMessage,
    showTemporaryHint,
    openMenu,
    closeMenu,
    render,
  } = menu.methods;
  const {
    debounceGameSets,

    saveUserGameSets,
    getUserGameSets,
    saveGameSets, 
    saveUserSets, 
  } = work.methods;
  const {
    redraw,
  } = drawing.methods;

  const { activeLayerId = 'base'} = getUserGameSets();

  // Из параметров меню получаем id слоя
  const layerId = menu.params?.layerId || activeLayerId;
  const layer = convy.methods.getLayer(layerId);
  const layerInfo = layer.info;

  // Сохраняем начальное состояние слоя при открытии модуля
  const initialLayerInfoRef = useRef(JSON.parse(JSON.stringify(layerInfo)));

  // Определяем набор методов композиции

  const oldCompMethods = [
    { value: 'source-over', label: t('customize.composition.source-over') },
    { value: 'multiply', label: t('customize.composition.multiply') },
    { value: 'darken', label: t('customize.composition.darken') },
    { value: 'color-burn', label: t('customize.composition.color-burn') },

    { value: 'screen', label: t('customize.composition.screen') },
    { value: 'lighten', label: t('customize.composition.lighten') },
    { value: 'color-dodge', label: t('customize.composition.color-dodge') },

    { value: 'overlay', label: t('customize.composition.overlay') },
    { value: 'exclusion', label: t('customize.composition.exclusion') },

    { value: 'hard-light', label: t('customize.composition.hard-light') },
    { value: 'soft-light', label: t('customize.composition.soft-light') },
    { value: 'difference', label: t('customize.composition.difference') },

    { value: 'hue', label: t('customize.composition.hue') },
    { value: 'saturation', label: t('customize.composition.saturation') },
    { value: 'color', label: t('customize.composition.color') },
    { value: 'luminosity', label: t('customize.composition.luminosity') },
  ]

  const badCompMethods = [
    { value: 'source-in', label: t('customize.composition.source-in') },
    { value: 'source-out', label: t('customize.composition.source-out') },
    { value: 'source-atop', label: t('customize.composition.source-atop') },
    { value: 'destination-over', label: t('customize.composition.destination-over') },
    { value: 'destination-atop', label: t('customize.composition.destination-atop') },
    { value: 'xor', label: t('customize.composition.xor') },
    { value: 'copy', label: t('customize.composition.copy') },

  ];

  const compositionMethods = [

    { value: 'source-over', label: t('customize.composition.source-over') },

    // Новые добавленные режимы композиции:
    { value: 'destination-in', label: t('customize.composition.destination-in') },
    { value: 'destination-out', label: t('customize.composition.destination-out') },
    
    { value: 'multiply', label: t('customize.composition.multiply') },
    { value: 'darken', label: t('customize.composition.darken') },
    { value: 'color-burn', label: t('customize.composition.color-burn') },

    { value: 'screen', label: t('customize.composition.screen') },
    { value: 'lighten', label: t('customize.composition.lighten') },
    { value: 'color-dodge', label: t('customize.composition.color-dodge') },

    { value: 'overlay', label: t('customize.composition.overlay') },
    { value: 'exclusion', label: t('customize.composition.exclusion') },

    { value: 'hard-light', label: t('customize.composition.hard-light') },
    { value: 'soft-light', label: t('customize.composition.soft-light') },
    { value: 'difference', label: t('customize.composition.difference') },

    { value: 'hue', label: t('customize.composition.hue') },
    { value: 'saturation', label: t('customize.composition.saturation') },
    { value: 'color', label: t('customize.composition.color') },
    { value: 'luminosity', label: t('customize.composition.luminosity') },
    { value: 'lighter', label: t('customize.composition.lighter') },
  ];

  // Задаём массив настроек слоя, аналогичный настройкам кисти
  const layerParams = [
    { type: 'toggle', key: 'visible', label: t('customize_layer.visible') },
    { conditions: ['clippingFor'],type: 'toggle', key: 'erasingMask', label: t('customize_layer.erasing_mask') },
    { conditionsNot: ['clippingFor'],type: 'toggle', key: 'alphaLock', label: t('customize_layer.alpha_lock') },
    { type: 'slider', key: 'opacity', label: t('customize_layer.opacity'), min: 0, max: 1, step: 0.01 },
    { conditionsNot: ['clippingFor'], type: 'select', key: 'composition', label: t('customize_layer.composition'), options: compositionMethods },
    { type: 'toggle', key: 'shadowOn', label: t('customize_layer.shadow_on') },
    { conditions: ['shadowOn'], type: 'slider', key: 'shadowBlur', label: t('customize_layer.shadow_blur'), min: 0, max: 100, step: 1 },
    { conditions: ['shadowOn'], type: 'slider', key: 'shadowOffsetX', label: t('customize_layer.shadow_offset_x'), min: -100, max: 100, step: 1 },
    { conditions: ['shadowOn'], type: 'slider', key: 'shadowOffsetY', label: t('customize_layer.shadow_offset_y'), min: -100, max: 100, step: 1 },

    { type: 'toggle', key: 'filterOn', label: t('customize_layer.filter_on') },
    { conditions: ['filterOn'], type: 'slider', key: 'filterBlur', label: t('customize_layer.filter_blur'), min: 0, max: 100, step: 0.1 },
    { conditions: ['filterOn'], type: 'slider', key: 'filterBrightness', label: t('customize_layer.filter_brightness'), min: 0, max: 3, step: 0.01 },
    { conditions: ['filterOn'], type: 'slider', key: 'filterContrast', label: t('customize_layer.filter_contrast'), min: 0, max: 3, step: 0.01 },
    { conditions: ['filterOn'], type: 'slider', key: 'filterSaturate', label: t('customize_layer.filter_saturate'), min: 0, max: 3, step: 0.01 },



  ];

  const currentLayerParams = (layerParams || []).filter((layerParam)=>{
    if (layerParam.conditions && !layerParam.conditions.every(condition=>layerInfo[condition])) { return false; }
    if (layerParam.conditionsNot && layerParam.conditionsNot.every(condition=>layerInfo[condition])) { return false; }
    return true;
  });

  // Функция обновления свойства слоя
  const updateSetting = (key, value) => {
    layerInfo[key] = value;
    layerInfo.wasTransformed = true;

    debounceGameSets({ layerInfo: convy.layerInfo })
    redraw();
    render();
  };

  // Функция рендеринга отдельной настройки
  const createSetting = (setting) => {
    switch (setting.type) {
      case 'toggle':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <input
                type="checkbox"
                checked={layerInfo[setting.key]}
                onChange={(e) => updateSetting(setting.key, e.target.checked)}
              />
              <span>{setting.label}</span>
            </label>
          </div>
        );
      case 'slider':
        return (
          <div className="settings-item touch-active" key={setting.key}>
            <label className="settings-label touch-active">
              <span>{setting.label}: {layerInfo[setting.key] !== undefined ? layerInfo[setting.key] : setting.min}</span>
            </label>
            <input
              type="range"
              className="customize-brush-slider touch-active"
              min={setting.min}
              max={setting.max}
              step={setting.step}
              value={layerInfo[setting.key] !== undefined ? layerInfo[setting.key] : setting.min}
              onChange={(e) => updateSetting(setting.key, Number(e.target.value))}
              onTouchStart={() => setIsSliderActive(true)}
              onTouchEnd={() => setIsSliderActive(false)}
              onTouchCancel={() => setIsSliderActive(false)}
              onMouseDown={() => setIsSliderActive(true)}
              onMouseUp={() => setIsSliderActive(false)}
              onMouseLeave={() => setIsSliderActive(false)}
            />
          </div>
        );
      case 'select':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <span>{setting.label}</span>
            </label>
            <select
              value={layerInfo[setting.key] || setting.options[0].value}
              onChange={(e) => updateSetting(setting.key, e.target.value)}
              className="customize-brush-select touch-active"
            >
              {setting.options.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  // Обработка редактирования имени слоя
  const handleNameChange = (e) => {
    layerInfo.name = e.target.value;
    debounceGameSets({ layerInfo: convy.layerInfo });
    render();
  };
  const handleNameSubmit = () => {
    debounceGameSets({ layerInfo: convy.layerInfo })
    render();
  };

  const handleCancelTransformation = () => {

    showPopupMessage({
      title: t('customize_layer.cancel_transformation_title'),
      message: t('customize_layer.cancel_transformation_text', { name: layerInfo.name }),
      buttons: [
        { type: 'cancel', text: t('customize_layer.button_cancel') },
        { id: 'confirm', type: 'destructive', text: t('customize_layer.button_confirm_remove_transfomration') },
      ],

      callback: (buttonId) => {
        if (buttonId === 'confirm') {
          window.croco?.haptic?.();

          layerInfo.offset = { x: 0, y: 0 };
          layerInfo.rotation = 0;
          layerInfo.scale = 1;
      
          debounceGameSets({ layerInfo: convy.layerInfo });
          redraw();
        }
      }
    });



  }

  // Клонирование слоя с новым именем
  const handleCloneLayer = () => {
    showPopupMessage({
      title: t('customize_layer.clone_layer_title'),
      message: t('customize_layer.clone_layer_text'),
      input: {
        value: (layerInfo.name || layerId) + ' copy',
      },
      buttons: [
        { type: 'cancel', text: t('customize_layer.button_cancel') },
        { id: 'submit', type: 'submit', text: t('customize_layer.button_submit') },
      ],
      callback: (buttonId, inputValue) => {
        if (buttonId === 'submit' && inputValue) {
          window.croco?.haptic?.();
          
          const newLayerId = 'layer_' + Date.now();
          convy.layerInfo[newLayerId] = {
            ...layerInfo,
            name: inputValue,
            order: layerInfo.order + 1,
            id: newLayerId,
          };
          openMenu('layer');
          redraw();

        }
      }
    });
  };
  
  const handleDeleteLayer = () => {

    const layers = Object.values(convy.layerInfo).filter(layerInfo=>!layerInfo.deleted)

    if (layers.length <= 1) {
      return showTemporaryHint(window.t('customize_layer.cant_delete_single_layer'));
    }

    showPopupMessage({
      title: t('customize_layer.delete_layer_title'),
      message: t('customize_layer.delete_layer_text', { layerName: layerInfo.name }),
      buttons: [
        { type: 'cancel', text: t('customize_layer.button_cancel') },
        { id: 'delete', type: 'destructive', text: t('customize_layer.button_delete') },
      ],
      callback: (buttonId) => {
        if (buttonId === 'delete') {
          window.croco?.haptic?.();

          const prev = JSON.parse(JSON.stringify(convy.layerInfo[layerId]));
          convy.layerInfo[layerId].deleted = true;
          ref.history.methods.transformationStroke({
            layerId: layerInfo.id, 
            prev, 
            note: 'delete layer',
          });
          
          if (activeLayerId === layerId) {
            // const remaining = Object.keys(convy.layerInfo);
            // saveUserGameSets({ activeLayerId: remaining[0] || null });
          }
          openMenu('layer');
          redraw();
        }
      }
    });
  };

  const handleClose = () => {
    if (JSON.stringify(initialLayerInfoRef.current) !== JSON.stringify(layerInfo)) {
      const prev = JSON.parse(JSON.stringify(initialLayerInfoRef.current));
      ref.history.methods.transformationStroke({ layerId: layerInfo.id, prev, note: 'customize layer' });
    }
    closeMenu();
  };
  

  return (
    <>
      <div className="overlay-background" onClick={handleClose} style = {{opacity: 0}}></div>
      <div className={`customize-layer-menu add-scroll touch-active ${isSliderActive ? 'slider-active' : ''}`}>
        <div className="customize-layer-header">
        <LayerThumbnail
          layerId={layer.id}
          store={store}
          style={{
            marginRight: '10px',
            maxHeight: '50px',
            maxWidth: '40px',
          }}
        />
        <input 
          className="layer-name-input input-field"
          type="text"
          value={layerInfo.name}
          onChange={handleNameChange}
          onBlur={handleNameSubmit}
          // autoFocus
          />
        </div>
        <div className="customize-layer-settings">
          {currentLayerParams.map(setting => createSetting(setting))}
        </div>
        {/* <div className="icon-button-row">
          <button 
            className="icon-button"
            {...createBetterClick({ onClick: handleCloneLayer })}
          >
            <FaClone />
          </button>
          <button 
            className="icon-button"
            {...createBetterClick({ onClick: handleDeleteLayer })}
          >
            <FaTrash />
          </button>
        </div> */}
        {
          layer.isTransformed() && (
            <div className="button-row">
              <button 
                  className="wide-button"
                  {...createBetterClick({ onClick: handleCancelTransformation })}
                >
                <FaTimes /> {t('customize_layer.button_cancel_transformation')}
              </button>
            </div>
          )
        }
        <div className="button-row">
          <button 
              className="wide-button"
              {...createBetterClick({ onClick: handleDeleteLayer })}
            >
            <FaTrash /> {t('customize_layer.button_delete')}
          </button>
        </div>
        <div className="button-row">
          <button className="small-gray-button" onClick={() => openMenu('layer')}>
            {t('customize_layer.button_back')}
          </button>
          <button className="small-gray-button" onClick={handleClose}>
            {t('customize_layer.button_close')}
          </button>
        </div>

        <button className="close-button" onClick={handleClose}>&#10006;</button>
      </div>
    </>
  );
}

export default CustomizeLayer;
