// src/components/puzzle/PieceTray.js
import React, { useRef, useEffect, useState } from 'react';
import useStore from '../../store';
import PuzzlePiece from './PuzzlePiece';
import './PieceTray.css'; // Убедитесь, что CSS импортирован
import { FaArrowUp, FaTrash } from 'react-icons/fa'; // Импорт иконки стрелки вниз и мусорки

const PieceTray = () => {
  // Подписываемся на рендер и данные для трея
  useStore((state) => state.puzzleRender);


  const { ref } = useStore.getState();
  const { puzzle } = ref;
  const pieces = puzzle.state.pieces;


  const trayScrollTop = puzzle.state.trayScrollTop; 


  // Определяем, является ли устройство сенсорным
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Проверяем, поддерживает ли устройство касания
    setIsTouchDevice(
      'ontouchstart' in window || 
      navigator.maxTouchPoints > 0 || 
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  const trayScrollRef = useRef(null); // Ref для скролл-контейнера
  const trayContentRef = useRef(null); // Ref для контента трея
  const touchStartPosRef = useRef(null); // Ref для хранения начальной позиции касания
  const isTouchScrollingRef = useRef(false); // Ref для отслеживания режима прокрутки

  const { methods: puzzleMethods } = ref.puzzle;

  // --- Фильтрация и сортировка элементов для трея ---
  const pieceIds = Object.keys(pieces || {});
  const allPieceIds = pieceIds
    .sort((a, b) => (pieces[a]?.order ?? 0) - (pieces[b]?.order ?? 0)); // Сортируем по order (или 0, если нет)


  // --- Восстановление позиции скролла ---
  useEffect(() => {
    const scrollElement = trayScrollRef.current;
    if (scrollElement) {
      // Устанавливаем только scrollTop при монтировании или изменении сохраненных значений
      // Делаем это асинхронно, чтобы гарантировать, что контент уже отрисован
      const timerId = setTimeout(() => {
          if (scrollElement.scrollTop !== trayScrollTop) {
               scrollElement.scrollTop = trayScrollTop;
          }
          // Горизонтальной прокрутки нет, поэтому не устанавливаем scrollLeft
      }, 0);
       return () => clearTimeout(timerId);
    }
    // Зависимости: сохраненное значение вертикальной прокрутки
  }, [trayScrollTop]);

  // --- Обработчик скролла ---
  const handleScroll = (e) => {
    // Сохраняем только вертикальную позицию скролла
    puzzleMethods.setTrayScroll({
        scrollLeft: 0, // Всегда 0, т.к. горизонтальной прокрутки нет
        scrollTop: e.target.scrollTop
    });
  };

  // Функция проверки процента выложенных кусочков
  const getPlacedVisiblePercentage = () => {
    const visiblePieces = allPieceIds.filter(id => pieces[id].ghost.visible);
    const placedVisiblePieces = visiblePieces.filter(id => pieces[id].placed);
    
    if (visiblePieces.length === 0) return 0;
    return (placedVisiblePieces.length / visiblePieces.length) * 100;
  };

  // Функция очистки выложенных кусочков из трея
  const clearPlacedPieces = () => {
    allPieceIds.forEach(pieceId => {
      const piece = pieces[pieceId];
      if (piece.placed && piece.ghost.visible) {
        piece.ghost.visible = false;
      }
    });
    puzzleMethods.render();
  };

  const shouldShowTrashIcon = getPlacedVisiblePercentage() > 10;
  

  // --- Обработчики тач-событий для улучшенной прокрутки ---
  const handleTouchStart = (e) => {
    // Проверяем, является ли цель касания элементом пазла
    const isPuzzlePiece = e.target.closest('.puzzle-piece');
    
    if (!isPuzzlePiece) {
      // Если касание произошло НЕ на элементе пазла (на пустой области)
      // Запоминаем начальную позицию касания
      touchStartPosRef.current = {
        y: e.touches[0].clientY,
        scrollTop: trayScrollRef.current.scrollTop
      };
      isTouchScrollingRef.current = true;
    }
  };

  const handleTouchMove = (e) => {
    // Если мы в режиме прокрутки (касание началось на пустой области)
    if (isTouchScrollingRef.current && touchStartPosRef.current) {
      // Предотвращаем действия браузера по умолчанию (например, прокрутку страницы)
      e.preventDefault();
      
      const yDiff = touchStartPosRef.current.y - e.touches[0].clientY;
      
      // Применяем разницу только к вертикальной позиции скролла
      if (trayScrollRef.current) {
        trayScrollRef.current.scrollTop = touchStartPosRef.current.scrollTop + yDiff;
      }
    }
  };

  const handleTouchEnd = () => {
    // Сбрасываем состояние прокрутки
    isTouchScrollingRef.current = false;
    touchStartPosRef.current = null;
  };

  return (
    <div
      ref={trayScrollRef}
      id="piece-tray-scroll-container"
      className={`piece-tray-scroll-container ${
        (isTouchDevice || shouldShowTrashIcon) ? 'with-side-padding' : ''
      } ${
        isTouchDevice ? 'hide-scrollbar' : ''
      }`}
      onScroll={handleScroll}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ touchAction: 'pan-y' }}
    >
      <div
        ref={trayContentRef}
        id="piece-tray-content" // ID для расчетов в stopDragging
        className="piece-tray-content-wrapper"
      >
        {/* Рендерим элементы пазла, находящиеся в трее */}
        {allPieceIds.map(pieceId => 
          {
            const { draggingPiece } = puzzle.state;
            const isDraggingThis = draggingPiece?.pieceId === pieceId; 

            const piece = pieces[pieceId];
            const isInTray = !piece.onBoard && !piece.placed && !isDraggingThis && piece.ghost.visible;
            const showGhost = !isInTray && piece?.ghost.visible;

            if (isInTray) {
              return <PuzzlePiece key={pieceId} pieceId={pieceId} />;
            } else if (showGhost) {
              return <PuzzlePiece key={pieceId} pieceId={pieceId} isGhost={true} />;
            } else {
              return null;
            }

          }
        )}
      </div>
      
      {/* Контейнер для иконок */}
      {(isTouchDevice || shouldShowTrashIcon) && (
        <div className="side-icons-container">
          {shouldShowTrashIcon && (
            <div className="side-icon" onClick={clearPlacedPieces}>
              <FaTrash />
            </div>
          )}
          {isTouchDevice && (
            <div className="side-icon">
              <FaArrowUp />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PieceTray;