// src/components/puzzle/PuzzlePiece.js
import React, { useRef, useEffect, useCallback } from 'react';
import useStore from '../../store';
import './PuzzlePiece.css';
import { getUserColor } from '../../utils/styles/userColor';

const PuzzlePiece = ({ pieceId, isGhost }) => {
  const pieceRef = useRef(null);
  const store = useStore.getState();
  const { ref } = store;
  const { puzzle } = ref;
  const { methods: puzzleMethods } = puzzle;

  useStore(state => state.puzzleRender);

  const { draggingPiece } = puzzle.state;
  
  const config = puzzle.state.config;
  
  const piece = puzzle.state.pieces?.[pieceId];
  const imageDimensions = puzzle.state.imageDimensions;
  const boardDimensions = puzzle.state.boardDimensions;
  const bgUrl = puzzle.state.bgUrl;
  const isInitialized = puzzle.state.isInitialized;
  
  const draggingPieceId = draggingPiece?.pieceId;
  const isDraggingThis = !isGhost && draggingPieceId === pieceId;
  const isHighlighted = !isGhost && piece.highlighted && piece.highlighted > Date.now();
  const isPlaced = !isGhost && piece.placed;
  const isInTrayContext = (isGhost || (!piece.placed && !piece.onBoard && !isDraggingThis));
  const reserved = piece.reservedUntil > Date.now();
  const isUnstable = piece.reserved > Date.now() - 5000 || piece.unreserved > Date.now() - 5000;

  useEffect(() => {
    if (!isDraggingThis) return;
    const handlePointerMove = (e) => {
      if (ref.puzzle.state.draggingPiece?.pointerId === e.pointerId) {
        window.requestAnimationFrame(() => {
          puzzleMethods.updateDragPosition(e);
        });
      }
    };
    const handlePointerUpOrCancel = (e) => {
      if (ref.puzzle.state.draggingPiece?.pointerId === e.pointerId) {
        puzzleMethods.stopDragging(e);
      } else if (!e.pointerId && ref.puzzle.state.draggingPiece?.pieceId === pieceId) {
        console.warn("PointerUp/Cancel event without pointerId for", pieceId);
        puzzleMethods.stopDragging(e);
      }
    };
    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('pointerup', handlePointerUpOrCancel);
    window.addEventListener('pointercancel', handlePointerUpOrCancel);
    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUpOrCancel);
      window.removeEventListener('pointercancel', handlePointerUpOrCancel);
    };
  }, [isDraggingThis, puzzleMethods, pieceId, ref.puzzle.state]);

  const handlePointerDown = useCallback((e) => {
    if (piece?.placed || e.button !== 0) return;
    
    // Предотвращаем действие по умолчанию для запуска перетаскивания
    e.preventDefault();
    e.stopPropagation();
    
    // Устанавливаем атрибут touch-action: none для элемента
    // Это предотвращает прокрутку трея при начале перетаскивания пазла
    if (pieceRef.current) {
      pieceRef.current.style.touchAction = 'none';
    }
    
    // Добавляем класс, чтобы явно указать, что элемент перетаскивается
    if (pieceRef.current) {
      pieceRef.current.classList.add('dragging-active');
    }
    
    puzzleMethods.startDragging(pieceId, e, pieceRef.current);
  }, [pieceId, piece?.placed, puzzleMethods]);

  if (!piece || !isInitialized || !boardDimensions?.width || !config || !imageDimensions?.width) {
    return null;
  }

  const imageScaleX = boardDimensions.width / imageDimensions.width;
  const imageScaleY = boardDimensions.height / imageDimensions.height;
  const boardPieceWidth = piece.baseDimensions.width * imageScaleX;
  const boardPieceHeight = piece.baseDimensions.height * imageScaleY;
  const scaleFactor = isInTrayContext ? (config.trayPieceScale || 1) : 1.0;
  const displayWidth = boardPieceWidth * scaleFactor;
  const displayHeight = boardPieceHeight * scaleFactor;

  const posX = piece.currentPos?.x ?? 0;
  const posY = piece.currentPos?.y ?? 0;
  const positionStyle = 
  isDraggingThis ? 'absolute' : 
  isInTrayContext ? 'relative' : 
  'absolute';

  const baseStyle = {
    position: positionStyle,
    left: !isInTrayContext && !isDraggingThis ? `${posX}px` : undefined,
    top: !isInTrayContext && !isDraggingThis ? `${posY}px` : undefined,
    width: `${displayWidth}px`,
    height: `${displayHeight}px`,
    zIndex: piece.zIndex,
    backgroundImage: bgUrl ? `url(${bgUrl})` : 'none',
    '--bg-width': `${imageDimensions.width * imageScaleX * scaleFactor}px`,
    '--bg-height': `${imageDimensions.height * imageScaleY * scaleFactor}px`,
    '--bg-pos-x': `-${piece.imageCrop.x * imageScaleX * scaleFactor}px`,
    '--bg-pos-y': `-${piece.imageCrop.y * imageScaleY * scaleFactor}px`,
    '--clip-path': piece.svgClipPathId ? `url(#${piece.svgClipPathId})` : 'none',
    '--piece-width': `${displayWidth}px`,
    '--piece-height': `${displayHeight}px`,
    touchAction: 'none',
    overflow: 'visible',
  };

  const trayStyle = isGhost ? {
    opacity: piece?.ghost?.opacity || 0.2,
  } :  {};


  const stateStyle = isDraggingThis
    ? {
        position: 'fixed',
        cursor: 'grabbing',
        transform: 'translate(-50%, -50%)',
        boxShadow: '1px 1px 12px rgba(0, 0, 0, 0.4)',
        overflow: 'visible',
        ...draggingPiece?.style || {},
      }
    : isInTrayContext
    ? trayStyle
    : isPlaced
    ? {
        cursor: 'default',
        boxShadow: 'none',
        opacity: 1,
        transition: 'box-shadow 1s ease-out',
      }
    : {
        cursor: 'grab',
        transition: 'box-shadow 0.3s ease-out',
        filter: '2px 2px 4px rgb(255, 255, 255), 4px 4px 8px rgb(255, 255, 255)'
      };

  // Получаем имя пользователя, установившего пазлинку
  const users = ref.info.game?.users || {};
  let placedByName = '';
  let userColor = '';
  
  if (piece.placedBy) {
    userColor = getUserColor(piece.placedBy);
    
    if (isHighlighted && users[piece.placedBy]) {
      placedByName = users[piece.placedBy].first_name || `Игрок ${piece.placedBy}`;
      if (placedByName.length > 10) {
        placedByName = placedByName.substring(0, 10);
      }
    }
  }

  const highlightStyle = isHighlighted
    ? {
        boxShadow: `0 0 6px 3px ${userColor || 'rgba(90, 220, 80, 0.7)'}`,
        zIndex: 500,
        transition: 'box-shadow 1s ease-out',
        overflow: 'visible',
      }
    : {};

  const style = {
    ...baseStyle,
    ...stateStyle,
    ...highlightStyle,
  };

  // Добавляем специальный класс для пазлов в трее для стилизации

  
  const className = `puzzle-piece ${isInTrayContext ? 'in-tray' : ''} ${isDraggingThis ? 'dragging' : ''} ${reserved ? 'reserved' : ''} ${isGhost ? 'ghost' : ''} ${isUnstable ? 'unstable' : ''}`;

  return (
    <div
      ref={pieceRef}
      id={pieceId}
      className={className}
      style={style}
      onPointerDown={handlePointerDown}
      data-piece-id={pieceId}
    >
      {isHighlighted && placedByName && (
        <div 
          className="puzzle-piece-name" 
          style={{ 
            backgroundColor: `${userColor}66`, // добавляем прозрачность
            boxShadow: `0 0 6px ${userColor}4D`, // цвет с 30% прозрачностью
            color: '#ffffff',
            textShadow: `0 0 3px ${userColor}BB` // тень текста с пользовательским цветом
          }}
        >
          {placedByName}
        </div>
      )}
    </div>
  );
};

export default React.memo(PuzzlePiece);
