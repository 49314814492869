// src/store/puzzleSlice.js
import { generatePieces } from '../components/puzzle/utils/pieceGenerator';
import { checkPlacement } from '../components/puzzle/utils/geometry';
import i18n from '../locales/i18n';
import { getCurrentTheme, toggleTheme, THEME, initializeTheme } from '../utils/styles/themeManager';

const createPuzzleSlice = (set, get, ref) => {

  // --- Ссылка для хранения состояния и методов ---
  ref.puzzle = {
    config: {
      amount: 36,
      snapToleranceFactor: 0.5,
      trayPieceScale: 1,
      knobSizeFactor: 0.6,
      knobDepthFactor: 0.15,
      jitterFactor: 0.3,
      previewOpacity: 0.23,
      ghostOpacity: 0.15,
      touchDragOffsetY: -60,
      highlightDuration: 1000, // Длительность подсветки в мс

      disableClipPathTest: true,
      reserveTimeout: 60 * 1000,

    },
    state: {
      isInitialized: false,
      isLoading: false,
      puzzleImage: null,
      file_id: null,
      imageDimensions: { width: 0, height: 0 },
      boardDimensions: { width: 0, height: 0 },
      pieceBaseDimensions: { width: 0, height: 0 },
      rows: 0,
      cols: 0,
      pieces: {},
      placedPiecesCount: 0,
      draggingPiece: null, // { pieceId, pointerId, pointerType, dragStartPos, element }
      trayScrollLeft: 0,
      trayScrollTop: 0,
      svgDefs: null,
      bgUrl: null,
      dimensionsCalculated: null,
      isDarkTheme: false, // Флаг для отслеживания текущей темы
    },
    methods: {},
  };

  // --- Функция сброса состояния ---
  const resetPuzzleStateInternal = () => ({
    isInitialized: false,
    isLoading: false,
    puzzleImage: null,
    file_id: null,
    imageDimensions: { width: 0, height: 0 },
    boardDimensions: { width: 0, height: 0 },
    pieceBaseDimensions: { width: 0, height: 0 },
    rows: 0,
    cols: 0,
    pieces: {},
    placedPiecesCount: 0,
    draggingPiece: null, // { pieceId, pointerId, pointerType, dragOrigin: 'tray' | 'board', dragStartPos, element }
    trayScrollLeft: 0,
    trayScrollTop: 0,
    svgDefs: null,
    bgUrl: null,
    dimensionsCalculated: null,
    isDarkTheme: ref.puzzle.state.isDarkTheme, // Сохраняем текущую тему при сбросе
  });

  // --- Методы ---
  ref.puzzle.methods = {
    render: () => {
      set({ puzzleRender: Date.now() });
    },

    // --- Инициализация темы ---
    initTheme: () => {
      // Инициализируем тему из localStorage
      initializeTheme();
      // Обновляем флаг в состоянии
      ref.puzzle.state.isDarkTheme = getCurrentTheme() === THEME.DARK;
      ref.puzzle.methods.render();
    },

    // --- Переключение темы ---
    toggleTheme: () => {
      // Переключаем тему и получаем новую активную тему
      const newTheme = toggleTheme();
      // Обновляем состояние
      ref.puzzle.state.isDarkTheme = newTheme === THEME.DARK;
      // Хаптический отклик
      window.croco?.appleHaptic?.('light');
      // Обновляем UI
      ref.puzzle.methods.render();
    },

    // --- Инициализация Пазла ---
    initializePuzzle: async () => {
      const { info, puzzle } = ref;
      const puzzleData = info.game?.puzzle;
      const gameSettings = info.game?.settings;

      if (!puzzleData || puzzle.state.isLoading) return;

      if (puzzle.state.isInitialized && puzzle.state.file_id === puzzleData.file_id) {
         ref.puzzle.methods.syncPlacementFromHistory();
         return;
      }

      window.croco?.log("Initializing puzzle...");
      set({ spinner: Date.now() + 5000 });
      // Сбрасываем состояние перед инициализацией
      if (ref.puzzle.state.bgUrl) URL.revokeObjectURL(ref.puzzle.state.bgUrl);
      if (ref.puzzle.state.highlightTimeoutId) clearTimeout(ref.puzzle.state.highlightTimeoutId);
      ref.puzzle.state = resetPuzzleStateInternal();
      ref.puzzle.state.isLoading = true;
      ref.puzzle.methods.render();

      try {
        const file_id = puzzleData.file_id;
        const { image } = await ref.work.methods.hostActionPromise('loadPuzzle', { file_id });
        const config = { ...ref.puzzle.config, ...gameSettings?.puzzleConfig };
        ref.puzzle.state.config = config; // Обновляем рабочий конфиг

        const blob = new Blob([image], { type: 'image/png' });
        ref.puzzle.state.bgUrl = URL.createObjectURL(blob);

        const { pieces, imageDimensions, pieceBaseDimensions, rows, cols, svgDefs } =
          await generatePieces(image, config, ref); // Передаем актуальный config

        ref.puzzle.state.isLoading = false;
        ref.puzzle.state.isInitialized = true;
        ref.puzzle.state.puzzleImage = image;
        ref.puzzle.state.file_id = file_id;
        ref.puzzle.state.imageDimensions = imageDimensions;
        ref.puzzle.state.pieceBaseDimensions = pieceBaseDimensions;
        ref.puzzle.state.rows = rows;
        ref.puzzle.state.cols = cols;
        ref.puzzle.state.pieces = pieces;
        ref.puzzle.state.placedPiecesCount = 0;
        ref.puzzle.state.svgDefs = svgDefs;

        ref.puzzle.methods.calculateBoardDimensions(); // Вызов расчета размеров
        set({ puzzleState: { ...ref.puzzle.state } }); // Обновляем Zustand
        ref.puzzle.methods.syncPlacementFromHistory();
        window.croco?.log('Puzzle Initialized.');

      } catch (error) {
        console.error('Failed to initialize puzzle:', error);
        if (ref.puzzle.state.bgUrl) URL.revokeObjectURL(ref.puzzle.state.bgUrl);
        if (ref.puzzle.state.highlightTimeoutId) clearTimeout(ref.puzzle.state.highlightTimeoutId);
        ref.puzzle.state = resetPuzzleStateInternal(); // Сброс при ошибке
        ref.puzzle.state.isLoading = false; // Убедиться, что isLoading false
        set({ gameError: { error: 'Failed to load puzzle image or generate pieces.' } });
      } finally {
        set({ spinner: 0 });
        ref.puzzle.methods.render();
      }
    },

    // --- Расчет Размеров Доски ---
    calculateBoardDimensions: () => {
        const areaElement = document.getElementById('puzzle-area-container');
        const { puzzle } = ref;
        if (!areaElement || !puzzle.state.imageDimensions?.width || !puzzle.state.isInitialized) {
            return;
        }
        const { clientWidth, clientHeight } = areaElement;
        if (clientWidth <= 0 || clientHeight <= 0) return;
        const padding = 10;
        const availableWidth = clientWidth - padding;
        const availableHeight = clientHeight - padding;
        const imgRatio = puzzle.state.imageDimensions.width / puzzle.state.imageDimensions.height;
        const areaRatio = availableWidth / availableHeight;
        let boardW, boardH;
        if (imgRatio > areaRatio) {
            boardW = availableWidth * 0.98; boardH = boardW / imgRatio;
        } else {
            boardH = availableHeight * 0.98; boardW = boardH * imgRatio;
        }
        const newDims = { width: Math.floor(boardW), height: Math.floor(boardH) };
        const widthChanged = Math.abs(newDims.width - puzzle.state.boardDimensions.width) > 1;
        const heightChanged = Math.abs(newDims.height - puzzle.state.boardDimensions.height) > 1;
        if (widthChanged || heightChanged) {
            puzzle.state.boardDimensions = newDims;
            window.croco?.log("Board dimensions recalculated:", newDims);
            Object.values(puzzle.state.pieces).forEach(p => {
                if (p.placed) {
                    p.currentPos = { x: p.correctPos.x * newDims.width, y: p.correctPos.y * newDims.height };
                }
            });
            puzzle.methods.render();
        }
        puzzle.state.dimensionsCalculated = Date.now();
    },

    // --- Установка Скролла Трея ---
    setTrayScroll: (scrollData) => {
        // Устанавливаем вертикальную прокрутку и сбрасываем горизонтальную
        ref.puzzle.state.trayScrollLeft = 0;
        ref.puzzle.state.trayScrollTop = scrollData.scrollTop;
    },

    // --- Начало Перетаскивания ---
    startDragging: (pieceId, event, element) => {
      const { puzzle } = ref;
      const piece = puzzle.state.pieces[pieceId];
      if (piece.reservedUntil && piece.reservedUntil > Date.now()) return;
      if (!piece || piece.placed || puzzle.state.draggingPiece) return;

      window.croco?.appleHaptic('soft');

      try { element.setPointerCapture(event.pointerId); }
      catch (error) { console.warn("Could not set pointer capture:", error); }

      const { pointerPosition } = get();
      const dragStartPos = { ...pointerPosition };

      // Обновляем состояние
      Object.values(puzzle.state.pieces).forEach(p => p.zIndex = p.placed ? 30 : 40);
      piece.zIndex = 100;

      const dragOrigin = (piece.onBoard || piece.placed) ? 'board' : 'tray' ;

      puzzle.state.draggingPiece = {
          pieceId, 
          pointerId: event.pointerId, 
          pointerType: event.pointerType,
          dragOrigin,
          dragStartPos, 
          element, 
       };

      ref.puzzle.methods.updateDragPosition(event);
      ref.work.methods.hostAction('reservePiece', { pieceId });

    },

    reserveFromRemote: (pieceId) => {

      const { puzzle } = ref;
      const piece = puzzle.state.pieces[pieceId];
      if (!piece) return;
      piece.reserved = Date.now();
      piece.reservedUntil = Date.now() + puzzle.config.reserveTimeout;
      puzzle.methods.render();

    },

    releaseFromRemote: (pieceId) => {

      const { puzzle } = ref;
      const piece = puzzle.state.pieces[pieceId];
      if (!piece) return;
      piece.unreserved = Date.now();
      piece.reserved = 0;
      piece.reservedUntil = 0;
      puzzle.methods.render();

    },

    // --- Обновление Позиции Перетаскивания ---
    updateDragPosition: (event) => {
      const { puzzle } = ref;
      const { draggingPiece } = puzzle.state;
      if (!draggingPiece || draggingPiece.pointerId !== event.pointerId || !draggingPiece.element) return;

      const { pointerPosition } = get();

      let visualOffsetY = 0;
      if (draggingPiece.pointerType === 'touch') {
          visualOffsetY = puzzle.state.config.touchDragOffsetY;
      }

      draggingPiece.style = { left: pointerPosition.x, top: pointerPosition.y + visualOffsetY }

      // Обновляем стиль напрямую
      puzzle.methods.render(); // Ререндер для обновления zIndex других и класса .dragging

    },

    // --- Окончание Перетаскивания ---
    stopDragging: (event) => {
      const { puzzle, work, telegram } = ref; // Убрали menu
      const { draggingPiece } = puzzle.state;

      if (!draggingPiece || (event && draggingPiece.pointerId !== event.pointerId)) {
         if (!event && draggingPiece) { console.warn("Stopping drag due to pointercancel/event loss."); }
         else { return; }
      }

      const pieceId = draggingPiece.pieceId;
      const piece = puzzle.state.pieces[pieceId];
      const element = draggingPiece.element;

      ref.puzzle.methods._cleanupDragging(element, draggingPiece.pointerId);

      // Очищаем состояние перетаскивания СРАЗУ
      puzzle.state.draggingPiece = null;

      if (!piece) { puzzle.methods.render(); return; }
      if (!event) { // Обработка pointercancel
        console.error("Cannot determine drop location without event. Returning piece to tray.");
        piece.onBoard = false;
        piece.zIndex = 20;
        piece.currentPos = piece.initialTrayPos ? { ...piece.initialTrayPos } : { x: 0, y: 0 };
        puzzle.methods.render();
        return;
      }

      // Определяем позицию drop
      const boardGuideElement = document.getElementById('real-puzzle-area');
      const trayScrollContainer = document.getElementById('piece-tray-scroll-container');
      if (!boardGuideElement || !trayScrollContainer) {
        console.error("Required elements not found for stopDragging calc.");
        piece.currentPos = { ...draggingPiece.dragStartPos }; // Fallback
        puzzle.methods.render(); return;
      }

      const boardGuideRect = boardGuideElement.getBoundingClientRect();
      const trayScrollRect = trayScrollContainer.getBoundingClientRect();
      let currentX = event.clientX;
      let currentY = event.clientY;
      let logicalPointerY = currentY;

      if (draggingPiece.pointerType === 'touch' && ref.puzzle.config.touchDragOffsetY) {
          logicalPointerY += ref.puzzle.config.touchDragOffsetY;
      }

      let droppedInBoardArea = logicalPointerY >= boardGuideRect.top &&
                               logicalPointerY <= boardGuideRect.bottom &&
                               currentX >= boardGuideRect.left &&
                               currentX <= boardGuideRect.right;
      if (logicalPointerY >= trayScrollRect.top && currentY >= trayScrollRect.top) {
           droppedInBoardArea = false;
      }

      const { pieceBaseDimensions, imageDimensions, boardDimensions, config } = puzzle.state;
      const boardPieceW = (pieceBaseDimensions.width / imageDimensions.width) * boardDimensions.width;
      const boardPieceH = (pieceBaseDimensions.height / imageDimensions.height) * boardDimensions.height;
      let finalX, finalY;

      if (droppedInBoardArea) {

          const imageScaleX = boardDimensions.width / imageDimensions.width;
          const imageScaleY = boardDimensions.height / imageDimensions.height;
          const boardPieceWidth = piece.baseDimensions.width * imageScaleX;
          const boardPieceHeight = piece.baseDimensions.height * imageScaleY;

          // На доску
          finalX = currentX - boardGuideRect.left - boardPieceWidth / 2;
          finalY = logicalPointerY - boardGuideRect.top - boardPieceHeight / 2;
          const correctPixelPos = { x: piece.correctPos.x * boardDimensions.width, y: piece.correctPos.y * boardDimensions.height };
          const isCorrect = checkPlacement({ x: finalX, y: finalY }, correctPixelPos, { width: boardPieceW, height: boardPieceH }, config.snapToleranceFactor);

          if (isCorrect) {
              // Правильно!
              piece.currentPos = { ...correctPixelPos };
              ref.puzzle.methods._placePieceInternal(pieceId, telegram.activeUserId, correctPixelPos); // Вызовет подсветку и render
              const amount = Object.keys(puzzle.state.pieces).length;
              work.methods.hostAction('placePuzzle', { pieceId, amount });
              window.croco?.haptic?.();
              return; // Завершаем
          } else {
              // На доску, но неправильно

              piece.onBoard = true;
              piece.zIndex = 40;
              piece.currentPos = { x: finalX, y: finalY };
              window.croco?.appleHaptic('soft');
              ref.work.methods.hostAction('releasePiece', { pieceId });

          }
      } else {
          // В трей или мимо
          piece.onBoard = false;
          piece.zIndex = 10;
          finalX = currentX - trayScrollRect.left + trayScrollContainer.scrollLeft;
          finalY = logicalPointerY - trayScrollRect.top + trayScrollContainer.scrollTop;
          const trayPieceW = boardPieceW * config.trayPieceScale;
          const trayPieceH = boardPieceH * config.trayPieceScale;
          const trayContentWidth = trayScrollContainer.scrollWidth;
          const trayContentHeight = trayScrollContainer.scrollHeight;
          const padding = 5;
          finalX = Math.max(padding, Math.min(finalX, trayContentWidth - trayPieceW - padding));
          const maxVisibleY = Math.max(padding, trayContentHeight - trayPieceH - padding);
          finalY = Math.max(padding, Math.min(finalY, maxVisibleY));
          piece.currentPos = { x: finalX, y: finalY };

          window.croco?.appleHaptic('soft');
          ref.work.methods.hostAction('releasePiece', { pieceId });

      }
      puzzle.methods.render(); // Обновляем UI, если не было правильного размещения
    },

    // --- Внутреннее размещение и ПОДСВЕТКА ---
    _placePieceInternal: (pieceId, userId, exactPixelPos) => {
      const { puzzle } = ref;
      const piece = puzzle.state.pieces[pieceId];
      if (!piece) return false;
      piece.reservedUntil = null;
      if (piece.placed) return false;

      // Проверяем, не держит ли текущий пользователь этот пазл
      if (puzzle.state.draggingPiece?.pieceId === pieceId) {
        puzzle.methods.cancelDragging(pieceId, "Piece placement interrupted: another user placed it correctly");
      }

      piece.placed = true;
      piece.placedBy = userId;
      piece.currentPos = { ...exactPixelPos };
      piece.zIndex = 20;
      puzzle.state.placedPiecesCount = Object.values(puzzle.state.pieces).filter(p => p.placed).length;

      // --- Логика подсветки ---

      // Устанавливаем новый подсвечиваемый элемент
      piece.highlighted = Date.now() + (ref.puzzle.config.highlightDuration || 2000);
      piece.highLightTimeoutId = setTimeout(() => {
        // Рендер для окончания подсветки
        piece.highlighted = null;
        ref.puzzle.methods.render(); 
      }, ref.puzzle.config.highlightDuration || 2000);

      puzzle.methods.render(); // Ререндер для отображения размещения и НАЧАЛА подсветки
      ref.puzzle.methods.checkCompletion(); // Проверяем завершение
      return true;
    },

    // --- Локальное размещение (без изменений, вызывает _placePieceInternal) ---
    placePieceLocal: (pieceId, userId, correctPixelPos) => {
      const placed = ref.puzzle.methods._placePieceInternal(pieceId, userId, correctPixelPos);
      // Убрали showTemporaryHint
      if (placed) {
          window.croco?.haptic?.();
          // render() уже вызван в _placePieceInternal
      }
    },

    // --- Удаленное размещение (без изменений, вызывает _placePieceInternal) ---
    placePieceFromRemote: (pieceId, userId) => {
       /* ... как в предыдущей версии, вызывает _placePieceInternal ... */
       const { puzzle } = ref;
       const piece = puzzle.state.pieces[pieceId];
       if (!piece || piece.placed || !puzzle.state.boardDimensions?.width) return;
       const correctPixelPos = {
           x: piece.correctPos.x * puzzle.state.boardDimensions.width,
           y: piece.correctPos.y * puzzle.state.boardDimensions.height
       };
       ref.puzzle.methods._placePieceInternal(pieceId, userId, correctPixelPos);
       // render() уже вызван
    },

    // --- Синхронизация из истории (без изменений) ---
    syncPlacementFromHistory: () => {
        /* ... как в предыдущей версии, вызывает _placePieceInternal ... */
        const { puzzle, info } = ref;
        if (!puzzle.state.isInitialized || !puzzle.state.boardDimensions?.width) {
            setTimeout(ref.puzzle.methods.syncPlacementFromHistory, 150); return;
        }
        const placedPiecesMap = info.game?.puzzle?.placedPieces || {};
        let needsRender = false;
        Object.keys(placedPiecesMap).forEach(pieceId => {
            const placedPieceData = placedPiecesMap[pieceId];
            const userId = placedPieceData?.userId;
            if (!userId) return;
            const piece = puzzle.state.pieces[pieceId];
            if (piece && !piece.placed) {
                const correctPixelPos = { x: piece.correctPos.x * puzzle.state.boardDimensions.width, y: piece.correctPos.y * puzzle.state.boardDimensions.height };
                const placed = ref.puzzle.methods._placePieceInternal(pieceId, userId, correctPixelPos); // Вызовет render если true
                if (placed) needsRender = true; // Отмечаем, что изменения были
            } else if (piece && piece.placed && piece.placedBy !== userId) {
                piece.placedBy = userId; needsRender = true;
            }
        });
        // Обновляем счетчик один раз
        puzzle.state.placedPiecesCount = Object.values(puzzle.state.pieces).filter(p => p.placed).length;
        if (needsRender) {
            window.croco?.log(`Synced placed pieces from history.`);
            // render() вызывается внутри _placePieceInternal, дополнительный не нужен
        } else {
            // Если изменений не было, но счетчик мог измениться (редко), делаем render
            // Или если просто нужно проверить завершение
             puzzle.methods.checkCompletion();
        }

    },

    // --- Сброс ---
    reset: () => {
      window.croco?.log("Resetting puzzle state");
      if (ref.puzzle.state.bgUrl) URL.revokeObjectURL(ref.puzzle.state.bgUrl);
      // Очищаем таймер подсветки при сбросе
      if (ref.puzzle.state.highlightTimeoutId) clearTimeout(ref.puzzle.state.highlightTimeoutId);
      ref.puzzle.state = resetPuzzleStateInternal();
      ref.puzzle.methods.render();
    },

    // --- Проверка Завершения ---
    checkCompletion: () => {
        /* ... как в предыдущей версии ... */
        const { puzzle } = ref;
        const pieces = puzzle.state.pieces;
        const totalPieces = Object.keys(pieces).length;
        const placedCount = puzzle.state.placedPiecesCount;
        if (puzzle.state.isInitialized && totalPieces > 0 && placedCount === totalPieces) {
          setTimeout(() => {
              if (ref.puzzle.state.placedPiecesCount === totalPieces) {
                 ref.puzzle.methods.showResults();
              }
          }, 500);
        }
    },

    // --- Показ Результатов ---
    showResults: () => {
        /* ... как в предыдущей версии (без изменений в логике) ... */
        const { puzzle, info, work, menu, telegram } = ref;
        if (menu.isPopupVisible && menu.popupContent?.title === window.t('puzzle.results.title')) return;
        const pieces = puzzle.state.pieces;
        const results = {};
        Object.keys(pieces).forEach(pieceId => {
            if (pieces[pieceId].placed && pieces[pieceId].placedBy) {
                results[pieceId] = { placedBy: pieces[pieceId].placedBy };
            }
        });
        const counts = {};
        Object.values(pieces).forEach(piece => {
            if (piece.placed && piece.placedBy) {
                counts[piece.placedBy] = (counts[piece.placedBy] || 0) + 1;
            }
        });
        const winners = Object.entries(counts)
            .sort(([, countA], [, countB]) => countB - countA)
            .map(([userId, count]) => ({ userId: parseInt(userId, 10), count, name: info.game.users[userId]?.first_name || `User ${userId}` }));
        const medals = ['🥇', '🥈', '🥉'];

        const winnerLines = winners.map((winner, index) => {
          const place = medals[index] || `${index + 1}.`;
          const isCurrentUser = winner.userId === telegram.activeUserId;
          const nameDisplay = isCurrentUser ? `*${winner.name}*` : winner.name;
          return `${place} ${nameDisplay} — ${winner.count}🧩`;
        })
        let winnersText = winnerLines.join('<br/>');


        if (!winnersText) winnersText = window.t('puzzle.results.no_winners');
        menu.methods.showPopupMessage({
            title: window.t('puzzle.results.title'), message: winnersText, parse_mode: 'Markdown',
            wideButtons: [{ type: 'cancel', text: window.t('puzzle.results.button_stay') }, { id: 'close', ignore: true, text: window.t('puzzle.results.button_close') }],
            buttons: [],
            callback: (buttonId) => { if (buttonId === 'close') window.Telegram?.WebApp?.close(); }
        });
        work.methods.hostAction('puzzleResults', { winnersText: winnerLines.join('\n'), results });
    },

    _cleanupDragging: (element, pointerId) => {
      if (element) {
        try { element.releasePointerCapture(pointerId); } catch (error) {/* ignore */}
        element.style.position = '';
        element.style.left = '';
        element.style.top = '';
        element.style.zIndex = '';
        element.style.transform = '';
      }
      ref.puzzle.state.draggingPiece = null;
    },
    
    
    // Обновленная версия cancelDragging
    cancelDragging: (pieceId, reason) => {
      const { puzzle } = ref;
      if (!puzzle.state.draggingPiece) return;
      
      if (pieceId && puzzle.state.draggingPiece.pieceId !== pieceId) return;
      
      const element = puzzle.state.draggingPiece.element;
      const pointerId = puzzle.state.draggingPiece.pointerId;
      
      // Используем новую функцию очистки
      ref.puzzle.methods._cleanupDragging(element, pointerId);
      
      window.croco?.appleHaptic?.('notification');
      if (reason) window.croco?.log(reason);
      
      puzzle.methods.render();
    },

  };

  return {
    puzzleRender: Date.now(),
    pointerPosition: { x: 0, y: 0 },
  };
};

export default createPuzzleSlice;