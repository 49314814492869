import { brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.test || {};

export function drawTestStroke(stroke, context) {

  const {
    sets = {},
  } = stroke;
 
  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  // require('./res_oil/blur copy 2').drawBlurStroke(stroke, context, brushSettings)
  require('./res_oil/gradient_blur').drawBlurStroke(stroke, context, brushSettings)
  // require('./res_oil/akvarel_s_nazhimom').drawWatercolorStroke(stroke, context, brushSettings)
  // require('./res_oil/good_res_watercolor').drawWatercolorStroke(stroke, context, brushSettings)

}

