// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Функция для динамической загрузки переводов
const loadResources = () => {
  const resources = {};
  
  // Создаем контекст для всех JSON-файлов в текущей папке
  const requireContext = require.context('.', false, /\.json$/);
  
  requireContext.keys().forEach((fileName) => {
    // Извлекаем код языка из имени файла, например './en.json' -> 'en'
    const langMatch = fileName.match(/\.\/([\w-]+)\.json$/);

    if (langMatch && langMatch[1]) {
      const lang = langMatch[1];
      try {
        const translation = requireContext(fileName);
        resources[lang] = { translation };
      } catch (error) {
        console.warn(`Не удалось загрузить перевод для языка: ${lang}`, error);
      }
    }
  });
  
  return resources;
};

// Инициализация ресурсов
const resources = loadResources();

i18n
  .use(initReactI18next) // подключаем React-i18next
  .init({
    resources,
    lng: 'en', // язык по умолчанию
    fallbackLng: 'en', // запасной язык
    // lng: 'ru', // язык по умолчанию
    // fallbackLng: 'ru', // запасной язык
    interpolation: {
      escapeValue: false, // React уже защищает от XSS
    },
    react: {
      useSuspense: false, // можно настроить по необходимости
    },
  });

window.t = i18n.t;

export default i18n;
