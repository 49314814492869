// ColorIndicator.js
import useStore from '../../../store';

const ColorIndicator = ({ 
  store,
  position, 
  clickPosition = {x: 1000, y: 1000}, 
  maxVisibleLineWidth = 60,
  minVisibleLineWidth = 60,
  alpha,
  brightness,
 }) => {

    const color = useStore((state) => state.color);
    const visibleLineWidth = useStore((state) => state.visibleLineWidth);

    const { menu, brush } = store.ref;

    const {
      actualSoftnessFun,
      visibleColorFun,
    } = brush.methods;

    if (!position) {
      return null; // Не отображаем компонент, если позиция не задана
    }

    const indicatorSideWidth = Math.min(Math.max(visibleLineWidth, minVisibleLineWidth), maxVisibleLineWidth);
    const blurSize = `${actualSoftnessFun() * indicatorSideWidth}px`; // Пример коэффициента умножения на 3

    const maxOffsetY = menu.panel.fastColorIndicatorY;
    const indicatorText = alpha ?? brightness ?? '';

    return (
        <div
            style={{
                position: 'fixed',
                left: `${position.x - indicatorSideWidth/2}px`,
                top: `${Math.min(maxOffsetY, position.y) - 120}px`,
                width: indicatorSideWidth,
                height: indicatorSideWidth,
                zIndex: 200,
            }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  background: visibleColorFun(),
                  pointerEvents: 'none', // Исключает элемент из событий указателя
                  filter: `blur(${blurSize})`, 
                  boxShadow: '0 0 0 0.3px grey, 0 0 0 0.3px black',
              }}/>
              <div style={{
                  zIndex: 55, 
                  color: 'grey',
                  fontSize: '14px',
                  opacity: 0.9,
                  transform: `translate(${indicatorSideWidth + 3}px, ${- indicatorSideWidth/2 - 10}px)`
              }}>
               {indicatorText}
              </div>
        </div>
    );
};

export default ColorIndicator;