const COLORS = [
  '#e17076', // Красный
  '#f5a97f', // Оранжевый
  '#f7c56b', // Жёлтый
  '#6cc3a0', // Зелёный
  '#46a2da', // Голубой
  '#7b78b1', // Фиолетовый
  '#aa6ea1', // Розовый
  '#f9a6c9', // Светло-розовый
  '#e89494', // Светло-красный
  '#f3c583', // Светло-оранжевый
  '#f7d96b', // Светло-жёлтый
  '#8fd3b6', // Светло-зелёный
  '#6ab6e7', // Светло-голубой
  '#9e9ac0', // Светло-фиолетовый
  '#c68cb9', // Светло-розовый
  '#fbbdc9'  // Очень светло-розовый
];


export function getVibrantColor(userId) {
  if (typeof userId !== 'string') {
      userId = String(userId);
  }
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
      hash = (hash << 5) - hash + userId.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
  }
  return COLORS[Math.abs(hash) % COLORS.length];
}


export function getUserColor(userId) {
  const avatarColorArray = [
    ['#FF885E', '#FF516A'], // red
    ['#FFCD6A', '#FFA85C'], // orange
    ['#E0A2F3', '#D669ED'], // purple
    ['#A0DE7E', '#54CB68'], // green
    ['#53EDD6', '#28C9B7'], // sea
    ['#72D5FD', '#2A9EF1'], // blue
    ['#FFA8A8', '#FF719A']  // pink
  ];

  const nameIndex = Math.abs(userId) % 7;
  return avatarColorArray[nameIndex][0];
}