// useKeyboardHandlers

import chroma from 'chroma-js';
import { useEffect, useCallback } from 'react';
import useStore from '../../store';

import {
  handleToolSwitch,
  handleLayerSelection,
  handleMenuToggle,
  handleUserSetsToggle,
  handleModifierKeys,
  handleUndoRedo,
  handleLineWidth,
  handleVisibilityAndPaste,
  handleNavigation,
  handleResetZoom,
  handleCloseMenu,
} from './keyboardHandlers';

export const useKeyboardHandlers = (ref) => {

  const { telegram, menu, work, drawing, info, convy, brush, history } = ref;
  const { game, user } = info;
  const { mouse, keyboard, zoom } = convy;

  const {
    toggleMenu, 
    closeMenu,
    showPopupMessage,
  } = menu.methods;
  const {
    saveUserSets, 
  } = work.methods;
  const {
    changeZoomPc,
    changeLayerScalePc,
    resetZoom,
  } = drawing.methods;
  const {
    changeTool,
    setTheSizeFun,
    setTheColorFun,
  } = brush.methods;
  const {
    undoLastAction,
    redoLastAction,
  } = history.methods;


  const lineWidthControl = (delta) => {

    const lineWidth = useStore.getState().lineWidth;
    const stepSize = Math.round(Math.cbrt(lineWidth));

    let newLineWidth = lineWidth + (delta > 0 ? -stepSize : stepSize);
    newLineWidth = Math.max(1, newLineWidth);
    
    setTheSizeFun(newLineWidth);

  }

  const handleKeyDown = useCallback((event) => {

    const activeTool = useStore.getState().activeTool;

    if (event.target.closest('.input-field')) return;

    handleToolSwitch(event, { changeTool });
    if (handleLayerSelection(event, { convy })) return;
    handleMenuToggle(event, { toggleMenu });
    handleUserSetsToggle(event, { user, saveUserSets });
    handleModifierKeys(event, { keyboard, activeTool, brush });
    handleUndoRedo(event, { undoLastAction, redoLastAction });
    handleLineWidth(event, { setTheSizeFun });
    handleVisibilityAndPaste(event, ref);
    handleNavigation(event, { convy, drawing });
    handleResetZoom(event);
    handleCloseMenu(event, { closeMenu });

    useStore.setState(state => ({ keyPressed: state.keyPressed + 1 }));

  }, []);

  const handleKeyUp = useCallback((event) => {

    useStore.setState(state=>({keyPressed: state.keyPressed + 1}));

    if (event.target.closest('.input-field')) { return; }

    const activeTool = useStore.getState().activeTool;

    
    if (event.key === 'Alt') {
      keyboard.altIsDown = false;
      if (activeTool === 'pipette') {
        useStore.setState({
          pipettePicking: false,
          activeTool: brush.previousTool,
        })
      }
    }

    if (event.key === 'Control') {
      event.preventDefault();
      keyboard.controlIsDown = false;
      useStore.setState( { zoomMode: false });

      if(convy.layerTransformation?.type === 'keys') {
        history.methods.transformationStroke();
      }
    }

    if (event.code === 'Space') {
      event.preventDefault();
      keyboard.spaceIsDown = false;
      useStore.setState( { zoomMode: false });
    }

    if (event.code === 'Tab') {
      event.preventDefault();
      keyboard.tabIsDown = false;
    }

    if (event.key === 'Shift') {
      keyboard.shiftIsDown = false;
      useStore.setState( { showPalette: false });
    }

  }, []);


  const handleWheel = useCallback((event) => {

    if (event.target.closest('.add-scroll')) { return; }
    if (event.target.closest('.scroll-active')) { return; }
    if (event.target.closest('.premium-menu')) { return; }

    event.preventDefault();

    const color = useStore.getState().color;

    const delta = event.deltaY > 0 ? 150 : -150;

    if (keyboard.altIsDown && !keyboard.controlIsDown) {

      const currentColor = chroma(color);

      const initialSaturation = currentColor.get('hsv.s');
      const initialHue = currentColor.get('hsv.h');
      const currentBrightness = currentColor.get('hsv.v');
      const currentAlpha = currentColor.alpha();  // Получаем текущий уровень прозрачности
      
      let brightnessDelta = -delta * 0.0003;
      
      let newBrightness = Math.max(0.01, Math.min(1, currentBrightness + brightnessDelta));
      
      let newColor = currentColor;
      newColor = newColor.set('hsv.v', newBrightness);
      
      // Попытка сохранить насыщенность и оттенок при изменении яркости
      newColor = newColor.set('hsv.s', initialSaturation);
      newColor = newColor.set('hsv.h', initialHue);
      
      // Установка сохраненной прозрачности обратно в новый цвет
      newColor = newColor.alpha(currentAlpha);
      
      let finalBrightness = chroma(newColor).get('hsv.v');
      if (!finalBrightness) {return;}
      
      setTheColorFun(newColor.hex());
      brush.quickColorChangeTime = Date.now();

      // setActiveTool('brush');

    } else if (keyboard.shiftIsDown) {

      let newColor = chroma(color).alpha(Math.max(0, Math.min(1, chroma(color).alpha() + delta * 0.0005))).hex('rgba');
      setTheColorFun(newColor);
      brush.quickColorChangeTime = Date.now();

    } else if (keyboard.controlIsDown) {

      if (keyboard.altIsDown) {
        changeLayerScalePc(delta)
      } else {
        changeZoomPc(delta);
      }

    } else {

      lineWidthControl(delta);

    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [handleKeyDown, handleKeyUp, handleWheel]);

  return {
  };
};



