export const glyphCollection = {
  star: [
    { x: 0, y: -10 },
    { x: 3, y: -3 },
    { x: 10, y: -3 },
    { x: 5, y: 3 },
    { x: 7, y: 10 },
    { x: 0, y: 6 },
    { x: -7, y: 10 },
    { x: -5, y: 3 },
    { x: -10, y: -3 },
    { x: -3, y: -3 }
  ],
  
  circle: (function() {
    const points = [];
    const radius = 10; // Радиус круга, чтобы соответствовать масштабу
    const steps = 50; // Больше точек для плавности
    
    for (let t = 0; t <= 2 * Math.PI; t += 2 * Math.PI / steps) {
      const x = radius * Math.cos(t);
      const y = radius * Math.sin(t);
      points.push({ x, y });
    }
    return points;
  })(),

  heart: (function() {
    const points = [];
    const scale = 10; // Масштаб, чтобы соответствовать другим фигурам
    const steps = 50; // Больше точек для плавности
    for (let t = 0; t <= 2 * Math.PI; t += 2 * Math.PI / steps) {
      let x = scale * 16 * Math.pow(Math.sin(t), 3) / 17;
      let y = -scale * (13 * Math.cos(t) - 5 * Math.cos(2 * t) - 2 * Math.cos(3 * t) - Math.cos(4 * t)) / 17;
      x = Math.round(x * 100) / 100;
      y = Math.round(y * 100) / 100;
      points.push({ x, y });
    }
    return points;
  })(),

  square: [
    { x: -10, y: -10 },
    { x: 10, y: -10 },
    { x: 10, y: 10 },
    { x: -10, y: 10 }
  ],
  
  cross: [
    { x: -3, y: -10 },
    { x: 3, y: -10 },
    { x: 3, y: -3 },
    { x: 10, y: -3 },
    { x: 10, y: 3 },
    { x: 3, y: 3 },
    { x: 3, y: 10 },
    { x: -3, y: 10 },
    { x: -3, y: 3 },
    { x: -10, y: 3 },
    { x: -10, y: -3 },
    { x: -3, y: -3 }
  ],
};