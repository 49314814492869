// useStrokeTransform.js
import useStore from '../../store';

// Вспомогательные функции для вычислений
const calculateDistance = (point1, point2 = { clientX: 0, clientY: 0 }) => {
  const xDiff = (point1.x || point1.clientX) - (point2.x || point2.clientX);
  const yDiff = (point1.y || point1.clientY) - (point2.y || point2.clientY);
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

const calculateCenter = (touch1, touch2 = { clientX: 0, clientY: 0 }) => {
  return {
    x: (touch1.clientX + touch2.clientX) / 2,
    y: (touch1.clientY + touch2.clientY) / 2,
  };
};

const calculateAngle = (touch1, touch2) => {
  const dx = touch2.pageX - touch1.pageX;
  const dy = touch2.pageY - touch1.pageY;
  return Math.atan2(dy, dx) * 180 / Math.PI;
};


const calculateLayerInvariantOffset = ({
  initialLayerOffset,      // Исходное смещение слоя
  initialLayerScale,       // Исходный масштаб слоя
  newLayerScale,           // Новый масштаб слоя
  initialLayerRotation,    // Исходный угол слоя
  newLayerRotation,        // Новый угол слоя
  pivotLayerPoint,         // Точка на слое (центр жеста в координатах слоя)
  offsetDelta,             // Дополнительное смещение от движения центра (в экранных координатах)
  layerCanvas,             // Канвас слоя для вычисления центра
}) => {
  const radInitial = (initialLayerRotation * Math.PI) / 180;
  const radNew = (newLayerRotation * Math.PI) / 180;

  // Центр слоя
  const centerX = layerCanvas.width / 2;
  const centerY = layerCanvas.height / 2;

  // Смещение точки относительно центра слоя
  const pivotRelative = {
    x: pivotLayerPoint.x - centerX,
    y: pivotLayerPoint.y - centerY,
  };

  // Преобразование точки pivot с учётом исходного поворота
  const rotatedInitial = {
    x: pivotRelative.x * Math.cos(radInitial) - pivotRelative.y * Math.sin(radInitial),
    y: pivotRelative.x * Math.sin(radInitial) + pivotRelative.y * Math.cos(radInitial),
  };

  // Преобразование точки pivot с учётом нового поворота
  const rotatedNew = {
    x: pivotRelative.x * Math.cos(radNew) - pivotRelative.y * Math.sin(radNew),
    y: pivotRelative.x * Math.sin(radNew) + pivotRelative.y * Math.cos(radNew),
  };

  // Вычисляем новое смещение
  return {
    x: initialLayerOffset.x + (initialLayerScale * rotatedInitial.x - newLayerScale * rotatedNew.x) + offsetDelta.x,
    y: initialLayerOffset.y + (initialLayerScale * rotatedInitial.y - newLayerScale * rotatedNew.y) + offsetDelta.y,
  };
};



export const useLayerTransform = (ref) => {
  const { menu, drawing, info, telegram, convy, brush, history } = ref;
  const { user } = info;
  const { showTemporaryHint } = menu.methods;
  const { zoom } = convy;


  // Инициализация параметров трансформации слоя
  const initLayerTransform = () => {
    if (Object.keys(zoom.initialized || {}).length > 0) {
      return;
    }

    if (!useStore.getState().canDraw) { return; }

    zoom.layerMode = true;
    const activeLayer = convy.methods.getActiveLayer();
    zoom.initialLayerOffset = { ...activeLayer.info.offset };
    zoom.initialLayerScale = activeLayer.info.scale;
    zoom.initialLayerRotation = activeLayer.info.rotation;

    // Сохраняем центр жеста в экранных координатах и преобразуем в координаты слоя
    const centerScreen = zoom.initialZoomCenter;
    const { layerPoint } = drawing.methods.getLayerCoordinates(centerScreen, activeLayer.id);
    zoom.initialLayerGestureData = {
      distance: zoom.initialDistance,
      angle: zoom.initialTouchAngle,
      centerScreen: centerScreen,       // Центр на экране
      centerLayer: layerPoint,          // Центр на слое
    };

    zoom.initializedLayer = {}; // Сбрасываем пороги для слоя
    showTemporaryHint(window.t('customize_layer.transform_started', { layerName: activeLayer.info.name}), { force: true, duration: 2000 });

    if (!convy.layerTransformation) {
      convy.layerTransformation = {
        prev: JSON.parse(JSON.stringify(activeLayer.info)),
        type: 'touch',
      };
    }

    window.croco?.haptic?.();

  };


  const updateLayerTransform = (event) => {
    const activeLayer = convy.methods.getActiveLayer();
    const { zoomFactor, rotation } = useStore.getState();
    const layerCanvas = activeLayer.canvas;
  
    // Новые параметры жеста
    const newCenterScreen = calculateCenter(event.touches[0], event.touches[1]);
    let newDistance = calculateDistance(event.touches[0], event.touches[1]);
    let newAngle = calculateAngle(event.touches[0], event.touches[1]);
  
    // Масштаб
    let scaleFactor = newDistance / zoom.initialLayerGestureData.distance;
    let newLayerScale = zoom.initialLayerScale * scaleFactor;

    if (!zoom.initializedLayer.scale) {
      newLayerScale = zoom.initialLayerScale; 

      if (Math.abs(scaleFactor - 1) < 0.2) {

      } else {
        zoom.initializedLayer.scale = true;
        zoom.initialLayerGestureData.distance = newDistance;
  
      }
    }

  
    // Вращение
    let angleDelta = newAngle - zoom.initialLayerGestureData.angle;
    let newLayerRotation = zoom.initialLayerRotation + angleDelta;

    if (!zoom.initializedLayer.rotation) {
      newLayerRotation = zoom.initialLayerRotation

      if (Math.abs(angleDelta) < 7) {
      } else {
        zoom.initializedLayer.rotation = true;
        zoom.initialLayerGestureData.angle = newAngle;
      }
    }

  
    // Смещение от движения центра жеста в экранных координатах
    const rawOffsetDelta = {
      x: newCenterScreen.x - zoom.initialLayerGestureData.centerScreen.x,
      y: newCenterScreen.y - zoom.initialLayerGestureData.centerScreen.y,
    };
  
    // Применяем обратное вращение холста к смещению
    const canvasRotationRad = (rotation * Math.PI) / 180;
    const adjustedOffsetDelta = {
      x: rawOffsetDelta.x * Math.cos(-canvasRotationRad) - rawOffsetDelta.y * Math.sin(-canvasRotationRad),
      y: rawOffsetDelta.x * Math.sin(-canvasRotationRad) + rawOffsetDelta.y * Math.cos(-canvasRotationRad),
    };
  
    // Корректируем с учётом масштаба канваса и слоя
    const finalOffsetDelta = {
      x: adjustedOffsetDelta.x / (zoomFactor * convy.scale),
      y: adjustedOffsetDelta.y / (zoomFactor * convy.scale),
    };
  
    if (!zoom.initializedLayer.offset) {
      finalOffsetDelta.x = 0;
      finalOffsetDelta.y = 0; // Порог для смещения

      if (calculateDistance(newCenterScreen, zoom.initialLayerGestureData.centerScreen) < 15) {

      } else {
        zoom.initializedLayer.offset = true;
        zoom.initialLayerGestureData.centerScreen = newCenterScreen;
      }
    }
  
    // Новое смещение с учётом инвариантности
    const newLayerOffset = calculateLayerInvariantOffset({
      initialLayerOffset: zoom.initialLayerOffset,
      initialLayerScale: zoom.initialLayerScale,
      newLayerScale: newLayerScale,
      initialLayerRotation: zoom.initialLayerRotation,
      newLayerRotation: newLayerRotation,
      pivotLayerPoint: zoom.initialLayerGestureData.centerLayer,
      offsetDelta: finalOffsetDelta,
      convyScale: convy.scale,
      layerCanvas: layerCanvas,
    });
  
    // Применяем новые параметры
    activeLayer.info.scale = newLayerScale;
    activeLayer.info.rotation = newLayerRotation;
    activeLayer.info.offset = newLayerOffset;
  
    drawing.methods.redraw();
  };


  return {
    updateLayerTransform,
    initLayerTransform,
  };
};