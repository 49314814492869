// ConvyBrushIndicator.js
import useStore from '../../../store';

const ConvyBrushIndicator = ({ store }) => {

  const keyPressed = useStore((state) => state.keyPressed);
  const activeTool = useStore((state) => state.activeTool);
  const zoomFactor = useStore((state) => state.zoomFactor);
  const rotation = useStore((state) => state.rotation);
  const mousePosition = useStore((state) => state.mousePosition);

  const lineWidth = useStore((state) => state.lineWidth);
  const showPalette = useStore((state) => state.showPalette);

  const { 
    mouseDetected, 
    panOffset,
    brushType,
    gradientColor,
  } = store;

  const { info, work, convy, drawing, brush } = store.ref;
  const { user } = info;
  if (!user) return null;

  const { getUserGameSets } = work.methods;
  const {
    actualSoftnessFun,
    visibleColorFun,
    visibleLineWidthFun,
    brushSetsFun,
    brushLinkFun,

    ifIndicateBrushFun,

  } = brush.methods;

  const commonSets = brushSetsFun('common');
  const brushSets = brushSetsFun();
  const squareIndicator =
    commonSets.squareBrush &&
    ['plain', 'dashed', 'outlined', 'pencil', 'feather'].includes(brushLinkFun());

  if (!convy.main.ref) return null;
  const canvasRect = convy.main.ref.getBoundingClientRect();
  const canvasCenter = {
    x: canvasRect.left + canvasRect.width / 2,
    y: canvasRect.top + canvasRect.height / 2,
  };


  // Если активен hotMouse – берем стартовую позицию, иначе текущую позицию мыши
  const sourcePos = convy.mouse.hotMouseActive
    ? convy.mouse.hotMouseStartPosition
    : mousePosition;

  const { x, y } = drawing.methods.getCanvasCoordinates(sourcePos);

  const localX = x * convy.scale * zoomFactor;
  const localY = y * convy.scale * zoomFactor;

  
  const visible = ifIndicateBrushFun();

  const actualSoftness = actualSoftnessFun();
  const blurSize = `${actualSoftness * visibleLineWidthFun()}px`;

  const gradientVisible = activeTool === 'brush' && getUserGameSets().gradientOn;

  const indicatorStyle =
    visibleLineWidthFun() >= 3
    && (activeTool === 'eraser'
    || (user.solidBrushIndicator
    && visibleLineWidthFun() >= 3
    && actualSoftness === 0))
      ? {
          background: 'transparent',
          border: '0.5px solid black',
          boxShadow: '0 0 0 0.5px grey',
        }
      : {
          background: drawing.isDrawing
            ? '#00000000'
            : gradientVisible
            ? `linear-gradient(to bottom, ${visibleColorFun()}, ${gradientColor || '#0000'})`
            : visibleColorFun(),
          boxShadow: actualSoftness ? 0 : '0 0 0 0.3px grey, 0 0 0 0.3px black',
          filter: `blur(${blurSize})`,
        };

  const indicateCenter =
    (['feather'].includes(brushType) ||
      brushSetsFun().tapering ||
      (user.pointerType === 'pen' &&
        (commonSets.pressureOn || commonSets.pressureOpacity) &&
        brushSets.pressureAvailable)) &&
    !drawing.isDrawing;

  return visible ? (
    <div
      className="canvas-container"
      style={{
        left: '50%',       // Горизонтальное центрирование
        pointerEvents: 'none',
        overflow: 'hidden',
        width: `${convy.styleDimensions.width * zoomFactor}px`,
        height: `${convy.styleDimensions.height * zoomFactor}px`,
        transformOrigin: 'center center',
        transform: `translate(-50%, -50%) translate(${panOffset.x}px, ${panOffset.y + convy.styleDimensions.height / 2}px) rotate(${rotation}deg)`,
        zIndex: 220,
        // background: '#0003',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: `${localX - visibleLineWidthFun() / 2}px`,
          top: `${localY - visibleLineWidthFun() / 2}px`,
          width: `${visibleLineWidthFun() - 1}px`,
          height: `${visibleLineWidthFun() - 1}px`,
          borderRadius: squareIndicator ? '' : '50%',
          pointerEvents: 'none',
          cursor: 'none',
          zIndex: 250,
          ...indicatorStyle,
        }}
      >
        {indicateCenter && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: '1px',
              height: '1px',
              background: 'black',
              borderRadius: '50%',
              transform: 'translate(-50%, -50%)',
              boxShadow: '0 0 0 0.5px grey',
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default ConvyBrushIndicator;
