// src/store/historySlice.js

import i18n from '../locales/i18n';

const createHistorySlice = (set, get, ref) => {

  const { t } = i18n;

  ref.history = {
    userStrokes: {},
    imageCache: new Map(),
    layerImageCache: {},

    save: {
      threshold: 1000,
      sendTimer: null,
      lastCallTime: 0,
    }
  };

  // Функция для проверки возможности отмены штриха (если штрих слишком старый или не последний)

  function ifCanUndoBoard (myStroke) {

    if (!ref.info.game.board) { return true; }

    const allStrokes = Object.values(ref.history.userStrokes)
    .flat()
    .filter(stroke => !stroke.cancelled)
    .sort((a, b) => a.time - b.time);

    if (myStroke.time > ref.info.methods.serverTimeFun() - 60 * 1000) { return true; }

    const lastStrokeIsMine = myStroke && myStroke?.userId === allStrokes[allStrokes.length - 1]?.userId;

    if (lastStrokeIsMine) { return true; }

    const popupParams = {
      type: 'destructive',
      title: t('cant_undo_old.title'),
      message: t('cant_undo_old.text'),
      buttons: [
        { id: 'ok', type: 'submit', text: t('cant_undo_old.button_ok') },
      ]
    };
    ref.menu.methods.showPopupMessage(popupParams);

    return false;;
    
  }

  function countRenderDifficulty (lastStroke) {

    const needToRender = prepareStrokesForCount(lastStroke, lastStroke.layerId);

    const strokeTypes = {
      special: 0,
      fill: 0,
    };

    for (let stroke of needToRender) {
      strokeTypes[stroke.type] = strokeTypes[stroke.type] || 0;
      strokeTypes[stroke.type] ++;

      if(['feather', 'ink', 'oil', 'pencil', 'blur', 'noise', 'watercolor', 'bristle', 'rembrandt', 'test'].includes(stroke.brush)
      || stroke.effect
      ) { strokeTypes.special ++; }
    }

    const difficulty = needToRender.length / 500 + strokeTypes.fill / 2 + strokeTypes.special / 10;

    return difficulty;
    
  }

  function prepareStrokesForCount (lastStroke, layerId) {

    const allStrokes = Object.values(ref.history.userStrokes).flat();
    const combinedStrokes = allStrokes.filter(stroke => {
      return !stroke.cancelled 
      && stroke.layerId === layerId
      && stroke.time !== lastStroke.time;
    }).sort((a, b) => a.time - b.time);

    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');
    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);

    const layerCache = ref.history.layerImageCache[layerId] || new Map();

    const lastCacheIndex = strokesToRender.map(stroke => {
      const cachedData = layerCache.get(stroke.time);
      if (cachedData) { return true } else { return false; }
    }).lastIndexOf(true);

    const needToRender = strokesToRender.slice(Math.max(lastCacheIndex, 0));
    return needToRender;

  }


  function undoTransform (layer, stroke) {
    if (stroke.type === 'transform') {
      const order = layer.info.order;
      ref.convy.layerInfo[layer.id] = JSON.parse(JSON.stringify(stroke.prev))
      layer.info = ref.convy.layerInfo[layer.id];
      layer.info.order = order;
    }
  }
  function redoTransform (layer, stroke) {
    if (stroke.type === 'transform') {
      const order = layer.info.order;
      ref.convy.layerInfo[layer.id] = JSON.parse(JSON.stringify(stroke.info))
      layer.info = ref.convy.layerInfo[layer.id];
      layer.info.order = order;
    }
  }




  ref.history.methods = {
    // Отмена последнего действия
    undoLastAction: (parm = {}) => {

      const activeUserId = ref.telegram.activeUserId;
      let myStrokes = ref.history.userStrokes[activeUserId];

      if (!myStrokes) return;

      ref.menu.methods.appleHaptic('medium');

      const actualHistory = myStrokes
        .filter((stroke) => !stroke.cancelled && !stroke.tech)
        .sort((a, b) => a.time - b.time);

      const lastStroke = actualHistory.pop();
      const prevStroke = actualHistory.pop();

      if (!lastStroke) {
        return ref.menu.methods.showTemporaryHint(t('tooltip.no_more_undo'), { force: true });
      }

      if (!ifCanUndoBoard(lastStroke)) return;
      if (countRenderDifficulty(lastStroke) > 10) {
        return ref.history.methods.confirmUndoReload(lastStroke);
      }

      const layer = ref.convy.methods.getLayer(lastStroke.layerId);
      if (!layer.info.visible) {
        return ref.menu.methods.showTemporaryHint(t('tooltip.layer_is_invisible'), { force: true });
      }

      lastStroke.cancelled = ref.info.methods.serverTimeFun();
      lastStroke.time++;
      delete lastStroke.rendered;

      if (prevStroke) {
        if (prevStroke.hidden) {
          prevStroke.time++;
          delete prevStroke.hidden;
          delete prevStroke.rendered;
        }
      }

      undoTransform (layer, lastStroke);
      layer.ready = false;

      ref.history.methods.saveHistory();
      ref.menu.methods.showTemporaryHint(t('tooltip.undo', {layerName: layer.info.name || ''}), { force: true, duration: 1000 });
      ref.drawing.methods.redraw();

    },

    // Восстановление последнего отменённого действия
    redoLastAction: () => {
      const activeUserId = ref.telegram.activeUserId;
      let myStrokes = ref.history.userStrokes[activeUserId];
      if (!myStrokes) return;

      ref.menu.methods.appleHaptic('medium');

      const cancelledHistory = myStrokes
        .filter((stroke) => stroke.cancelled)
        .sort((a, b) => b.cancelled - a.cancelled);
      const firstStroke = cancelledHistory.shift();
      const actualHistory = myStrokes.filter((stroke) => !stroke.cancelled);
      const lastStroke = actualHistory[actualHistory.length - 1];

      if (firstStroke) {
        const layer = ref.convy.methods.getLayer(firstStroke.layerId);
        if (!layer.info.visible) {
          return ref.menu.methods.showTemporaryHint(t('tooltip.layer_is_invisible'), { force: true });
        }

        delete firstStroke.cancelled;
        firstStroke.time++;
        delete firstStroke.rendered;
        if (lastStroke && lastStroke.shapeId && lastStroke.shapeId === firstStroke.shapeId) {
          lastStroke.hidden = true;
          lastStroke.time++;
          delete lastStroke.rendered;
        }

        redoTransform (layer, firstStroke)
        layer.ready = false;

        ref.history.methods.saveHistory();
        ref.menu.methods.showTemporaryHint(t('tooltip.redo', {layerName: layer.info.name || ''}), { force: true, duration: 1000 });
        ref.drawing.methods.redraw();

      } else {
        ref.menu.methods.showTemporaryHint(t('tooltip.no_more_redo'), { force: true });
      }
    },


    handleStrokeHistoryClick: (stroke) => {

      if (!ifCanUndoBoard(stroke)) return;
      ref.history.loadTime = Math.min(ref.history.loadTime, stroke.time);
    
      if (stroke.cancelled) {
        // Восстановление штриха
        delete stroke.cancelled;
        stroke.time++; // Увеличиваем время для уникальности
        delete stroke.rendered;
  
        const layer = ref.convy.methods.getLayer(stroke.layerId);
        redoTransform (layer, stroke)
        layer.ready = false;
  
        ref.history.methods.saveHistory();
        ref.drawing.methods.redraw().then(()=>{
          ref.menu.methods.showTemporaryHint(t('tooltip.redo', {layerName: layer.info.name || ''}), { force: true, duration: 1000 });
        });
      } else {
        // Отмена штриха
        if (!ifCanUndoBoard(stroke)) return;
  
        stroke.cancelled = ref.info.methods.serverTimeFun();
        stroke.time++;
        delete stroke.rendered;
  
        const layer = ref.convy.methods.getLayer(stroke.layerId);
        undoTransform (layer, stroke)
        layer.ready = false;
  
        ref.history.methods.saveHistory();
        ref.drawing.methods.redraw().then(()=>{
          ref.menu.methods.showTemporaryHint(t('tooltip.undo', {layerName: layer.info.name || ''}), { force: true, duration: 1000 });
        });
      }
  
      ref.menu.methods.appleHaptic('medium'); // Тактильная отдача
    },


    // Подтверждение отмены с перезагрузкой (для сложных/старых штрихов)
    confirmUndoReload: (lastStroke) => {
      if (ref.drawing.isRendering) return;

      ref.menu.methods.showPopupMessage({
        type: 'destructive',
        title: t('undo_reload.title'),
        message: t('undo_reload.text'),
        buttons: [
          { id: 'cancel', type: 'cancel', text: t('undo_reload.button_cancel') },
          { id: 'confirm', type: 'destructive', text: t('undo_reload.button_confirm') },
        ],
        callback: (buttonId) => {
          if (buttonId === 'confirm') {
            lastStroke.time--;
            lastStroke.cancelled = ref.info.methods.serverTimeFun();

            ref.history.methods.saveHistory();
            setTimeout(() => { 
              window.location.reload(); 
            }, 200);
          }
        },
      });

    },

    // Подтверждение очистки доски с показом popup (в данном примере без выбора цвета)
    confirmClearCanvas: () => {

      if (ref.drawing.isRendering) return;




      if (ref.info.game.board) {

        const activeLayer = ref.convy.methods.getActiveLayer();
        const someoneElsesStroke = Object.values(ref.history.userStrokes)
        .flat()
        .filter(stroke=>stroke.layerId === activeLayer.id && !stroke.canceled && stroke.userId !== ref.telegram.activeUserId)[0];

        if (someoneElsesStroke) {
          return ref.menu.methods.showPopupMessage({
            title: t('cant_clear_board.title'),
            message: t('cant_clear_board.text'),
            buttons: [
              { id: 'ok', type: 'submit', text: t('cant_clear_board.button_ok') },
            ]
          });
        }
      }


      const ClearCanvasContent = ({ text, colors, onColorClick }) => {
        return (
          <>
            <span 
              className="message-popup-text"
              dangerouslySetInnerHTML={{ __html: text || '' }}
            />
            <div style={{ 
              display: 'flex', 
              gap: '13px',
              marginBottom: '10px', 
              marginRight: '10px', 
              justifyContent: 'flex-end',
              marginLeft: 'auto', //
              flexWrap: 'wrap',          // Добавляем перенос элементов
              }}>
              {colors.map((color, index) => (
                <div
                  key={index}
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: color,
                    cursor: 'pointer',
                    boxShadow: '0 0 0 0.5px grey',
                  }}
                  onClick={() => onColorClick(color)}
                />
              ))}
            </div>
          </>
        );
      };
  
      const colorsArr = [
        ...ref.brush.lastColors,
        ref.brush.brushColor,
      ];
      const uniqueColorsArr = ['#FFFFFFFF', '#000000FF', ...new Set(colorsArr.slice(-10))];
  
      const popupParams = {
        title: t('clear.title'),
        // message: t('clear.text'),
        content: <ClearCanvasContent 
          text={t('clear.text')} 
          colors={uniqueColorsArr} 
          onColorClick={(color)=>{
            ref.history.methods.clearCanvas(color);
            ref.menu.methods.showPopupMessage(null);
          }} 
        />,
        buttons: [
          { id: 'cancel', type: 'cancel', text: t('clear.button_cancel') },
          { id: 'clear', type: 'destructive', text: t('clear.button_confirm') },
        ]
      };
  
      popupParams.callback = (buttonId, color) => {
        if (buttonId === 'clear') { ref.history.methods.clearCanvas(color); }
      }
      ref.menu.methods.showPopupMessage(popupParams)

    },

    clearCanvas: (color) => {

      const layer = ref.convy.methods.getActiveLayer();
      const activeUserId = ref.telegram.activeUserId;

      let myStrokes = ref.history.userStrokes[activeUserId] || [];

      if (color) {
        const backgroundStroke = {
          type: 'background',
          color,
          time: ref.info.methods.serverTimeFun(),
          userId: ref.telegram.activeUserId,
          layerId: layer.id,
        };
        myStrokes.push(backgroundStroke);
      } else {
        const clearStroke = {
          type: 'clear',
          time: ref.info.methods.serverTimeFun(),
          userId: ref.telegram.activeUserId,
          layerId: layer.id,
        };
        myStrokes.push(clearStroke);
      }

      ref.history.userStrokes[activeUserId] = myStrokes.filter(stroke => !stroke.cancelled);
      layer.ready = false;

      ref.menu.methods.appleHaptic('light');
      ref.drawing.methods.redraw();
      ref.history.methods.saveHistory();

    },

    // Групповая перерисовка: отправка события через сокет
    groupRedraw: () => {
      const socket = get().socket;
      if (!socket) return;
      socket.emit(
        'redraw',
        {
          gameId: ref.telegram.gameId,
          gameParam: ref.telegram.gameParam,
          userId: ref.telegram.activeUserId,
        },
        (result) => {
          if (window.croco && window.croco.haptic) window.croco.haptic();
        }
      );
    },

    transformationStroke: (data = {}) => {

      let { layerId, prev, info, note, stroke = {} } = data;
      
      if (!prev && !ref.convy.layerTransformation) { return; }

      layerId = layerId || ref.convy.methods.getActiveLayer().id;
      const layer = ref.convy.methods.getLayer(layerId);

      prev = prev || JSON.parse(JSON.stringify(ref.convy.layerTransformation.prev));
      info =  info || JSON.parse(JSON.stringify(layer.info));

      const newStroke = {
        layerId,
        time: ref.info.methods.serverTimeFun(),
        userId: ref.telegram.activeUserId,
        type: 'transform',
        prev,
        info,
        note,
        ...stroke,
      }

      const userStrokes = ref.history.userStrokes;
      const activeUserId = ref.telegram.activeUserId;
      let myStrokes = userStrokes[activeUserId];
      myStrokes.push(newStroke);
      userStrokes[activeUserId] = myStrokes.filter(stroke => !stroke.cancelled);

      delete ref.convy.layerTransformation;
      ref.history.methods.saveHistory();

      layer.info.wasTransformed = true;
      ref.work.methods.saveGameSets({ layerInfo: ref.convy.layerInfo });

    },

    layersInfoChangeStroke: ({ prev, info }) =>{

      if (!prev) { return; }
      info =  info || JSON.parse(JSON.stringify(ref.convy.layerInfo));

      const newStroke = {
        time: ref.info.methods.serverTimeFun(),
        userId: ref.telegram.activeUserId,
        type: 'layers',
        prev,
        info,
        tech: true,
      }

      const userStrokes = ref.history.userStrokes;
      const activeUserId = ref.telegram.activeUserId;
      let myStrokes = userStrokes[activeUserId];
      myStrokes.push(newStroke);
      userStrokes[activeUserId] = myStrokes.filter(stroke => !stroke.cancelled);

      ref.history.methods.saveHistory();
      ref.work.methods.saveGameSets({ layerInfo: ref.convy.layerInfo });

    },


    // Сохранение истории: вызывает функцию сохранения (например, отправку на сервер)
    saveHistory: (parm = {}) => {
      const activeUserId = ref.telegram.activeUserId;
      const myHistory = ref.history.userStrokes[activeUserId] || [];
      const historyToSave = [...myHistory];

      ref.history.methods.saveDrawing?.({
        strokes: historyToSave,
        forced: parm.forced,
      });
    },

    saveDrawing: async ({
      strokes,
      forced,
    }) => {

      const { socket, connectSocket } = get();
      if (!socket) {return;}
      if (!socket.connected) { return connectSocket() }

      const { telegram, info, brush, convy, connection, history } = ref;
      const { save } = history;
      const { user } = info;

      if (ref.telegram.testCanvas) { return; }
    
      const {
        lastColors: colors,
      } = brush;
    
      const dimensions = convy.main.dimensions;
    
      clearTimeout(save.sendTimer);
      const needToWait = save.lastCallTime + save.threshold - Date.now();
    
      if (!forced && needToWait > 0) { await new Promise(resolve=>{save.sendTimer = setTimeout(resolve, needToWait)}); }
      save.lastCallTime = Date.now();
    
      try {
    
        const initTime = Date.now();
        const strokesToSend = strokes.slice(-100);
    
        const {
          gameId, 
          telegramUserId,
          activeUserId,
          clientId,
          moderId,
          gameParam,
        } = telegram;

        window.croco?.log('⬅️💾emit strokes');
    
        socket.emit('strokes', { 
          gameId, 
          gameParam, 
          userId: telegramUserId,
          clientId,
          debugMode: user.debugMode,
         }, { 
          dimensions, 
          strokes: strokesToSend, 
          length: strokes.length, 
          userId: activeUserId, 
          moderId,
          colors,
          }, (result)=>{
            window.croco?.log(`✔️💾strokes save result: ${result}`);
            if (result) { connection.lastSaveTime = Date.now(); }
        });
    
        setTimeout(() => {
          if (initTime > connection.lastSaveTime) {
            connection.lastConnectionErrorTime = Date.now();
          }
        }, 2000);
    
        console.log("Sending strokes: ", strokes.length); // Для отладки
    
      } catch (e) {
    
        connection.lastConnectionErrorTime = Date.now();
        console.error("Failed to send canvas data to server:", strokes); // Для отладки
        
      }
    },
    
  };

  return {
    userStrokes: {},
  };
};

export default createHistorySlice;
