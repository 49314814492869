// useZoomPhone.js
import useStore from '../../store';

// Вспомогательные функции для вычислений
const calculateDistance = (point1, point2 = { clientX: 0, clientY: 0 }) => {
  const xDiff = (point1.x || point1.clientX) - (point2.x || point2.clientX);
  const yDiff = (point1.y || point1.clientY) - (point2.y || point2.clientY);
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

const calculateCenter = (touch1, touch2 = { clientX: 0, clientY: 0 }) => {
  return {
    x: (touch1.clientX + touch2.clientX) / 2,
    y: (touch1.clientY + touch2.clientY) / 2,
  };
};

const calculateAngle = (touch1, touch2) => {
  const dx = touch2.pageX - touch1.pageX;
  const dy = touch2.pageY - touch1.pageY;
  return Math.atan2(dy, dx) * 180 / Math.PI;
};


const calculateInvariantOffset = ({
  initialOffset,
  convyScale,
  initialZoomFactor,
  newZoomFactor,
  initialRotation,
  newRotation,
  pivotCanvasPoint,
  offsetDelta = { x: 0, y: 0 },
}) => {
  const radInitial = (initialRotation * Math.PI) / 180;
  const radNew = (newRotation * Math.PI) / 180;

  // Преобразование точки pivot с учетом исходного поворота
  const rotatedInitial = {
    x: pivotCanvasPoint.x * Math.cos(radInitial) - pivotCanvasPoint.y * Math.sin(radInitial),
    y: pivotCanvasPoint.x * Math.sin(radInitial) + pivotCanvasPoint.y * Math.cos(radInitial),
  };

  // Преобразование точки pivot с учетом нового поворота
  const rotatedNew = {
    x: pivotCanvasPoint.x * Math.cos(radNew) - pivotCanvasPoint.y * Math.sin(radNew),
    y: pivotCanvasPoint.x * Math.sin(radNew) + pivotCanvasPoint.y * Math.cos(radNew),
  };

  return {
    x:
      initialOffset.x +
      convyScale * (initialZoomFactor * rotatedInitial.x - newZoomFactor * rotatedNew.x) +
      offsetDelta.x,
    y:
      initialOffset.y +
      convyScale * (initialZoomFactor * rotatedInitial.y - newZoomFactor * rotatedNew.y) +
      offsetDelta.y,
  };
};

export const useZoomPhone = (ref) => {
  const { menu, drawing, info, telegram, convy, brush, history } = ref;
  const { user } = info;
  const { centralCanvasOffset } = drawing.methods;
  const { showTemporaryHint } = menu.methods;
  const { zoom } = convy; // Объект для хранения параметров жеста

  const {
    startStrokeTransform,
    updateStrokeTransform,

    initLayerTransform,
    updateLayerTransform,
  } = drawing.methods;

  const touchZoomStart = (event) => {
    if (event.touches.length === 2 && !zoom.isZooming) {

      const { zoomFactor, panOffset, rotation } = useStore.getState();
      drawing.isDrawing = false;
      zoom.initialized = {}; // сбрасываем флаги обычной трансформации
      zoom.initialZoomFactor = zoomFactor;
      zoom.initialRotation = rotation;
      zoom.initialOffset = { ...panOffset };

      const distance = calculateDistance(event.touches[0], event.touches[1]);
      if (distance > 400) { return; }
      zoom.initialDistance = distance;

      const center = calculateCenter(event.touches[0], event.touches[1]);
      if (center.x < 0 || center.y < 0) { return; }
      // Сохраняем центр в экранных координатах
      zoom.initialZoomCenter = center;

      zoom.lastDistance = distance;
      zoom.lastZoomCenter = center;

      zoom.modeLocked = null;
      zoom.initialTouchAngle = calculateAngle(event.touches[0], event.touches[1]);
      zoom.initialCanvasPoint = centralCanvasOffset(center);

      zoom.isZooming = true;
      zoom.zoomStartTime = Date.now();

      // Запускаем таймер для переключения в режим трансформации слоя
      zoom.layerMode = false;
      zoom.strokeMode = false;

      zoom.specialModeTimer = setTimeout(() => {
        if (user.transformLayer) {
          initLayerTransform();
        } else if (user.hold2transform) {
          startStrokeTransform(event);
        }
      }, 1500);
    }
  };


  const touchZoomMove = (event) => {
    if (event.touches.length !== 2) {
      return;
    }
    if (!zoom.isZooming) {
      return;
    }

    const { rotation } = useStore.getState();
    drawing.isDrawing = false;

    if (zoom.layerMode) {
      return updateLayerTransform(event);
    } else if (zoom.strokeMode) {
      return updateStrokeTransform(event);
    }

    // --- Обычная трансформация холста ---
    let distance = calculateDistance(event.touches[0], event.touches[1]);
    let newCenter = calculateCenter(event.touches[0], event.touches[1]);
    const currentAngle = calculateAngle(event.touches[0], event.touches[1]);
    let angleChange = user.canvasRotation ? currentAngle - zoom.initialTouchAngle : 0;

    const isValidDistance = distance > 0 && !isNaN(distance) && distance < 400;
    const isValidCenter =
      newCenter &&
      !isNaN(newCenter.x) &&
      !isNaN(newCenter.y) &&
      newCenter.x > 0 &&
      newCenter.y > 0;

    if (!isValidDistance || !isValidCenter) {
      return;
    }

    zoom.lastDistance = distance;
    zoom.lastZoomCenter = newCenter;

    if (distance <= 0 || isNaN(distance)) {
      return;
    }
    if (!newCenter || isNaN(newCenter.x) || isNaN(newCenter.y)) {
      return;
    }

    if (!zoom.initialized.rotation) {
      if (Math.abs(angleChange) > 10) {
        zoom.initialized.rotation = true;
        zoom.initialTouchAngle = currentAngle;
      }
      angleChange = 0;
    }

    let newRotation = zoom.initialRotation + angleChange;
    if (zoom.initialized.rotation) {
      let rem = newRotation % 360;
      if (rem < 0) {
        rem += 360;
      }
      for (let card of [0, 90, 180, 270]) {
        let diff = card === 0 ? Math.min(rem, 360 - rem) : Math.abs(rem - card);
        if (diff <= 5) {
          newRotation = newRotation - rem + card;
          break;
        }
      }
    }

    if (!zoom.initialized.zoom) {
      if ((distance / zoom.initialDistance > 1.15 || distance / zoom.initialDistance < 0.8)) {
        zoom.initialized.zoom = true;
        zoom.initialDistance = distance;
      } else {
        distance = zoom.initialDistance;
      }
    }
    let newZoomFactor = Math.min(
      Math.max(zoom.initialZoomFactor * (distance / zoom.initialDistance), zoom.minZoomFactor),
      zoom.maxZoomFactor
    );
    if (!zoom.initialized.offset) {
      if (calculateDistance(newCenter, zoom.initialZoomCenter) > 15) {
        zoom.initialized.offset = true;
        zoom.initialZoomCenter = newCenter;
      } else {
        newCenter = zoom.initialZoomCenter;
      }

    }

    // Дополнительное смещение от перемещения центра жеста
    const offsetDelta = zoom.initialized.offset
      ? {
          x: newCenter.x - zoom.initialZoomCenter.x,
          y: newCenter.y - zoom.initialZoomCenter.y,
        }
      : { x: 0, y: 0 };

    // Вычисляем новое смещение с учетом зума, вращения и дополнительного панорамирования,
    // чтобы точка, соответствующая zoom.initialCanvasPoint, оставалась неизменной.
    const newOffset = calculateInvariantOffset({
      initialOffset: zoom.initialOffset,
      convyScale: convy.scale,
      initialZoomFactor: zoom.initialZoomFactor,
      newZoomFactor: newZoomFactor,
      initialRotation: zoom.initialRotation,
      newRotation: newRotation,
      pivotCanvasPoint: zoom.initialCanvasPoint,
      offsetDelta: offsetDelta,
    });

    
    if (
      zoom.initialized.zoom &&
      newZoomFactor >= 0.95 &&
      newZoomFactor <= 1.1 &&
      (zoom.initialZoomFactor < 0.95 || zoom.initialZoomFactor > 1.1) &&
      Math.abs(rotation % 360) === 0
    ) {
      menu.panel.stuck = false;
      newZoomFactor = 1;
      newRotation = 0;
      // Если происходит "прищелкивание", сбрасываем смещение
      newOffset.x = 0;
      newOffset.y = 0;
      zoom.initialized.rotation = true;
    }
    const newStates = {
      rotation: newRotation,
      zoomFactor: newZoomFactor,
      panOffset: newOffset,
    };
    useStore.setState(newStates);
  };
  
  // ------------------------------------------------------------------------------------
  // Функция завершения жеста.
  // Сбрасывает таймер и флаги, если все касания завершены.
  // ------------------------------------------------------------------------------------
  const touchZoomEnd = () => {

    if (!zoom.isZooming) { return; }

    if (zoom.specialModeTimer) {
      clearTimeout(zoom.specialModeTimer);
      zoom.specialModeTimer = null;
    }
    
    if (!zoom.layerMode && !zoom.strokeMode) {

      const { panOffset } = useStore.getState();
      const diff = Math.abs(zoom.lastDistance - zoom.initialDistance);
      const moved = calculateDistance(zoom.lastZoomCenter, zoom.initialZoomCenter);
      if (
        zoom.zoomStartTime > Date.now() - 300 
        && diff < 6 
        && moved < 6
        ) {
        if (user.tapToUndo) {
          history.methods.undoLastAction();
        }
      }

    }

    zoom.isZooming = false;
    zoom.layerMode = false;
    zoom.strokeMode = false;
  };

  return {
    touchZoomStart,
    touchZoomMove,
    touchZoomEnd,
  };
};
