// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/board/MetricsDebug.css */
.metrics-debug {
  position: fixed;
  top: 25px;
  left: 10px;
  width: 300px;
  max-height: 600px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
  padding: 5px;
  font-size: 12px;
  z-index: 1200;
  pointer-events: none;
  white-space: normal;       /* Позволяет перенос строк */
  overflow: hidden;          /* Убирает полосы прокрутки */
  word-break: break-word;    /* Разрывает слова, если они слишком длинные */
  white-space: pre-line; /* Это всё, что нужно */
}

.debug-mode-switcher {
  display: flex;
  gap: 7px;
  padding: 3px;
  border-bottom: 1px solid #ccc;
  pointer-events: auto;
}

.debug-mode-switcher button {
  background: none;
  border: none;
  padding: 3px 3px;
  cursor: pointer;
  font-size: 14px;
}

.debug-mode-switcher button.active {
  font-weight: bold;
  border-bottom: 2px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/components/board/indicators/DebugMenu.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE,eAAe;EACf,SAAS;EACT,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,0CAA0C;EAC1C,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,aAAa;EACb,oBAAoB;EACpB,mBAAmB,QAAQ,4BAA4B;EACvD,gBAAgB,WAAW,6BAA6B;EACxD,sBAAsB,KAAK,8CAA8C;EACzE,qBAAqB,EAAE,uBAAuB;AAChD;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,YAAY;EACZ,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,6BAA6B;AAC/B","sourcesContent":["/* src/components/board/MetricsDebug.css */\r\n.metrics-debug {\r\n  position: fixed;\r\n  top: 25px;\r\n  left: 10px;\r\n  width: 300px;\r\n  max-height: 600px;\r\n  background-color: rgba(255, 255, 255, 0.5);\r\n  opacity: 0.5;\r\n  padding: 5px;\r\n  font-size: 12px;\r\n  z-index: 1200;\r\n  pointer-events: none;\r\n  white-space: normal;       /* Позволяет перенос строк */\r\n  overflow: hidden;          /* Убирает полосы прокрутки */\r\n  word-break: break-word;    /* Разрывает слова, если они слишком длинные */\r\n  white-space: pre-line; /* Это всё, что нужно */\r\n}\r\n\r\n.debug-mode-switcher {\r\n  display: flex;\r\n  gap: 7px;\r\n  padding: 3px;\r\n  border-bottom: 1px solid #ccc;\r\n  pointer-events: auto;\r\n}\r\n\r\n.debug-mode-switcher button {\r\n  background: none;\r\n  border: none;\r\n  padding: 3px 3px;\r\n  cursor: pointer;\r\n  font-size: 14px;\r\n}\r\n\r\n.debug-mode-switcher button.active {\r\n  font-weight: bold;\r\n  border-bottom: 2px solid #000;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
