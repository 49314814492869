// useConvy.js

import useStore from '../store';

import { useHelpers } from './convy/useHelpers';  
import { useRedraw } from './convy/useRedraw'; 
import { useZoomPhone } from './convy/useZoomPhone'; 
import { useZoomPc } from './convy/useZoomPc'; 

import { useSoftDraw } from './convy/useSoftDraw'; 
import { useShapeDraw } from './convy/useShapeDraw'; 
import { useSmartShapeTransform } from './convy/smart/useSmartShapeTransform'; 
import { useColor } from './convy/useColor';
import { useHotMouse } from './convy/useHotMouse';
import { useStrokeTransform } from './convy/useStrokeTransform';
import { useLayerTransform } from './convy/useLayerTransform';

import { usePointer } from './convy/usePointer'; 
import { useKeyboardHandlers } from './convy/useKeyboardHandlers';

const useConvy = () => {

  const store = useStore.getState();
  const ref = store.ref;
  const { drawing } = ref;

  Object.assign(drawing.methods, useHelpers(ref));
  Object.assign(drawing.methods, useRedraw(ref));
  
  Object.assign(drawing.methods, useZoomPhone(ref));
  Object.assign(drawing.methods, useZoomPc(ref));

  Object.assign(drawing.methods, useSoftDraw(ref));
  Object.assign(drawing.methods, useShapeDraw(ref));

  Object.assign(drawing.methods, useColor(ref));
  Object.assign(drawing.methods, useHotMouse(ref));
  Object.assign(drawing.methods, useStrokeTransform(ref));
  Object.assign(drawing.methods, useLayerTransform(ref));
  Object.assign(drawing.methods, useSmartShapeTransform(ref));

  Object.assign(drawing.methods, usePointer(ref));
  Object.assign(drawing.methods, useKeyboardHandlers(ref));

  // useMountHistory(methods);
  return {};
};

export default useConvy;
