// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ricroll-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px; /* Можно изменить размер */
  height: 315px; /* Можно изменить размер */
  z-index: 1000;
}

.ricroll-container iframe {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/menus/rickroll/RicRoll.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY,EAAE,0BAA0B;EACxC,aAAa,EAAE,0BAA0B;EACzC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".ricroll-container {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 560px; /* Можно изменить размер */\r\n  height: 315px; /* Можно изменить размер */\r\n  z-index: 1000;\r\n}\r\n\r\n.ricroll-container iframe {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
