// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LogOverlay.css */
.log-menu {
  position: fixed;
  max-height: 95vh;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  text-align: center;
  background-color: var(--tg-theme-bg-color);
  padding: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1101;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.log-menu * {
  touch-action: pan-y;
}

.log-title {
  color: var(--tg-theme-button-color);
  font-size: 22px;
  margin-bottom: 5px;
}

.log-list {
  width: 100%;
  margin-bottom: 5px;
}

.log-item {
  margin-bottom: 3px;
  padding: 5px 10px 5px 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
}

.log-item-title {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--tg-theme-text-color);
}

.log-item-time {
  display: block;
  font-size: 13px;
  color: var(--tg-theme-text-color);
  opacity: 0.8;
}
.semi-bold {
  font-weight: 600; 
}
`, "",{"version":3,"sources":["webpack://./src/components/menus/LogOverlay.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;EAC1C,aAAa;EACb,yCAAyC;EACzC,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,qCAAqC;EACrC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iCAAiC;EACjC,YAAY;AACd;AACA;EACE,gBAAgB;AAClB","sourcesContent":["/* LogOverlay.css */\r\n.log-menu {\r\n  position: fixed;\r\n  max-height: 95vh;\r\n  overflow-y: auto;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 300px;\r\n  text-align: center;\r\n  background-color: var(--tg-theme-bg-color);\r\n  padding: 10px;\r\n  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);\r\n  border-radius: 12px;\r\n  z-index: 1101;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.log-menu * {\r\n  touch-action: pan-y;\r\n}\r\n\r\n.log-title {\r\n  color: var(--tg-theme-button-color);\r\n  font-size: 22px;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.log-list {\r\n  width: 100%;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.log-item {\r\n  margin-bottom: 3px;\r\n  padding: 5px 10px 5px 10px;\r\n  background-color: rgba(0, 0, 0, 0.05);\r\n  border-radius: 8px;\r\n  text-align: left;\r\n  cursor: pointer;\r\n}\r\n\r\n.log-item-title {\r\n  display: block;\r\n  font-weight: bold;\r\n  margin-bottom: 5px;\r\n  color: var(--tg-theme-text-color);\r\n}\r\n\r\n.log-item-time {\r\n  display: block;\r\n  font-size: 13px;\r\n  color: var(--tg-theme-text-color);\r\n  opacity: 0.8;\r\n}\r\n.semi-bold {\r\n  font-weight: 600; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
