
function newCanvas (context) {

  const newCanvas = document.createElement('canvas');
  newCanvas.width = context.canvas.width;
  newCanvas.height = context.canvas.height;
  const newCtx = newCanvas.getContext('2d');
  newCtx.lineCap = 'round';
  newCtx.lineJoin = 'round';

  return newCtx;
  
}

function cloneCanvas (context) {

  const newCanvas = document.createElement('canvas');
  newCanvas.width = context.canvas.width;
  newCanvas.height = context.canvas.height;
  const newCtx = newCanvas.getContext('2d');

  newCtx.drawImage(newCanvas.canvas, 0, 0);
  return newCtx;
  
}


module.exports = {
  newCanvas,
  cloneCanvas,
};