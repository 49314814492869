// oil.js
import chroma from 'chroma-js';
import {  filterClosePoints } from './helpers/points';
import { getTexture } from './helpers/brushLoader';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 

import { drawPlainStroke } from './helpers/plain'; 
import { createTaperingStroke } from './helpers/tapering';
import { createPressureStroke } from './helpers/pressure';
import { getStrokeBounds, translatePoints } from './helpers/points'; 


const defaultBrushSettings = brushDefaults.rembrandt || {};

export async function drawOilStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  softness,
  time,
  sets = {},
}, context) {
  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  let { 
    speedDependence,
    texture = 'fabric',
  } = brushSettings;
  const oilTexture = await getTexture('oil', texture);

  if (!speedDependence) {
    points = filterClosePoints(points, Math.min(lineWidth, 20));
  }

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  color = chromaColor.alpha(1).hex();

  // Вычисляем границы штриха
  const bounds = getStrokeBounds(points, lineWidth);
  if (!bounds) return;

  const offsetX = -bounds.x;
  const offsetY = -bounds.y;
  points = translatePoints(points, offsetX, offsetY);

  // Создаем временный канвас минимального размера
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = bounds.width;
  bufferCanvas.height = bounds.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';


  let shadow = {
    offset: { x: 0.3, y: 0.3 },
    plusWidth: 1.9,
  }
  let light = {
    offset: { x: 0.1, y: 0.1 },
    plusWidth: 1.4,
  }

  // Рисуем тень (черный цвет со смещением 2px)
  bufferCtx.globalAlpha = 0.03;
  drawStroke({
    points,
    lineWidth,
    color: 'rgba(0, 0, 0, 1)',
    offset: shadow.offset,
    plusWidth: shadow.plusWidth,
    time,
  }, bufferCtx, brushSettings)

  // Рисуем подсветку (белый цвет со смещением 1px)
  bufferCtx.globalAlpha = 0.05;
  drawStroke({
    points,
    lineWidth,
    color: 'rgba(255, 255, 255, 1)',
    offset: light.offset,
    plusWidth: light.plusWidth,
    time,
  }, bufferCtx, brushSettings)
  
  // Стираем область под основным мазком
  bufferCtx.globalAlpha = 1;
  bufferCtx.globalCompositeOperation = 'destination-out';

  drawStroke({
    points,
    lineWidth,
    color: 'rgba(255, 255, 255, 1)',
    time,
  }, bufferCtx, brushSettings)

  // Возвращаем режим наложения в нормальное состояние
  bufferCtx.globalCompositeOperation = 'source-over';

  // Применяем текстуру к основному мазку
  if (oilTexture) {
    const texturedStrokeCanvas = document.createElement('canvas');
    texturedStrokeCanvas.width = bufferCanvas.width;
    texturedStrokeCanvas.height = bufferCanvas.height;
    const texturedStrokeCtx = texturedStrokeCanvas.getContext('2d');

    texturedStrokeCtx.lineCap = 'round';
    texturedStrokeCtx.lineJoin = 'round';

    drawStroke({
      points,
      lineWidth,
      color,
      time,
    }, texturedStrokeCtx, brushSettings)

    texturedStrokeCtx.globalCompositeOperation = 'source-in';
    
    const offsetX = 0;
    const offsetY = 0;
    // const offsetX = seededRandom(time) * oilTexture.width;
    // const offsetY = seededRandom(time + 1) * oilTexture.height;
    
    const patternCanvas = document.createElement('canvas');
    patternCanvas.width = oilTexture.width;
    patternCanvas.height = oilTexture.height;
    const patternCtx = patternCanvas.getContext('2d');
    
    patternCtx.drawImage(oilTexture, 0, 0);
    patternCtx.globalCompositeOperation = 'source-atop';
    patternCtx.drawImage(oilTexture, offsetX, offsetY);
    
    const pattern = texturedStrokeCtx.createPattern(patternCanvas, 'repeat');
    texturedStrokeCtx.fillStyle = pattern;
    texturedStrokeCtx.fillRect(0, 0, texturedStrokeCanvas.width, texturedStrokeCanvas.height);

    // Применяем цвет
    fillGradient(texturedStrokeCtx, points, color, gradientColor, lineWidth)

    // Добавляем текстурированный мазок на буфер
    bufferCtx.drawImage(texturedStrokeCanvas, 0, 0);
  }

  // Рисуем буфер на основном холсте
  context.globalAlpha = originalAlpha;
  context.drawImage(
    bufferCanvas, 
    0, 0, bounds.width, bounds.height,
    bounds.x, bounds.y, bounds.width, bounds.height
  );
  context.globalAlpha = 1; // Возвращаем значение по умолчанию
}

// Функция для рисования сужающегося штриха
function drawStroke({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  plusWidth = 0,
  time,
  shapeId,
}, context, brushSettings) {

  const stroke = {
    points, lineWidth, color, offset, plusWidth, shapeId,
  }
 
  if (points.length === 1) {
    return drawPlainStroke(stroke, context);
  } else if (points[0]?.pressure) {
    createPressureStroke(stroke, context, brushSettings)
  } else if (lineWidth >= 200 || points.length >= 200) {
    return drawPlainStroke(stroke, context);
  } else {
    createTaperingStroke({
      lineWidth,
      points,
      shapeId,
    }, context, brushSettings);
  }

}
