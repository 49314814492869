// src/store/drawingSlice.js


const createDrawingSlice = (set, get, ref) => {
  ref.drawing = {
    isDrawing: false,
  };

  ref.drawing.methods = {

    // Функция проверки валидности кеша (сохраняем ту же логику)
    isCacheValid: (strokes, time) => {
      // if (Object.values(ref.history.userStrokes).length === 1 && ref.history.userStrokes[ref.telegram.activeUserId]) {
      //   return true;
      // }

      const notRendered = strokes.find(
        stroke => !stroke.rendered 
        && stroke.time < time 
        && stroke.time >= ref.history.loadTime
      );
      if (notRendered) {
        let a = 1;
      }
      return !notRendered;
    },


    // Функция подготовки штрихов для рендера для конкретного слоя
    prepareActiveStrokesForLayer: ({ layerId, layerStrokes, fromServerSave })=> {

      const layer = ref.convy.methods.getLayer(layerId);
      const currentLayerInfo = ref.convy.layerInfo[layerId]
      if (!currentLayerInfo) { return {}; }

      if (!layerStrokes) {
        layerStrokes = Object.values(ref.history.userStrokes).flat().filter(stroke=>stroke.layerId === layerId);
      }

      let sortedStrokes = layerStrokes.sort((a, b) => a.time - b.time);
      let clearIndex, cacheIndex, cacheStroke;

      const layerCache = ref.history.layerImageCache[layerId] || new Map();
      ref.history.layerImageCache[layerId] = layerCache;

      for (let i = sortedStrokes.length - 1; i >= 0; i--) {

        const stroke = sortedStrokes[i];
        if (stroke.cancelled || stroke.hidden) { continue; };

        if (fromServerSave) {
          if (currentLayerInfo.cache?.time === stroke.time) {
            cacheIndex = i;
            break;
          }
        } else {
          const cachedData = layerCache.get(stroke.time);
          if (cachedData) {
            // const validCache = true;
            const validCache = ref.drawing.methods.isCacheValid(
              sortedStrokes.slice(Math.max(i - 500, 0), i),
              stroke.time
            );
  
            if (validCache) {
              cacheStroke = stroke;
              cacheIndex = i;
              break;
            } else {
              layerCache.delete(stroke.time);
            }
          }
        }

        if (stroke.type === 'clear') {
          clearIndex = i;
          break;
        }
        if (stroke.type === 'background') {
          clearIndex = i;
          break;
        }
      }

      let strokesToRender;
      if (cacheIndex) {
        strokesToRender = sortedStrokes.slice(cacheIndex);
      } else if (clearIndex) {
        strokesToRender = sortedStrokes.slice(clearIndex);
      } else {
        strokesToRender = sortedStrokes;
      }
      if (strokesToRender.length) {
        ref.history.lastStrokeTime = strokesToRender[strokesToRender.length - 1].time || 0;
      }


      let difficulty = strokesToRender.reduce(
        (sum, stroke) => sum + ref.drawing.methods.computeStrokeDifficulty(stroke),
        0
      );


      if (layer) {
        if (fromServerSave) {
          layer.render.saveDifficulty = difficulty;
        } else {
          layer.render.difficulty = difficulty;
        }
      }


      return { 
        layerStrokes, 
        strokesToRender, 
        difficulty,
        cacheStroke,
       };


    },

    // Функция вычисления сложности отдельного штриха
    computeStrokeDifficulty: (stroke) => {
      if (stroke.cancelled || stroke.hidden) return 0;
      if (stroke.type === 'fill') {  return 100; }
      if (stroke.type === 'stroke') {

        const specialBrushes = [
          'feather', 'ink', 'oil', 'pencil', 'blur',
          'noise', 'watercolor', 'bristle', 'rembrandt',
          'sparkle', 'test', 'neon', 'glyph'
        ];

        if (specialBrushes.includes(stroke.brush)) { return 10; }
        if (stroke.effect) { return 10; }
        if (stroke.points?.[0]?.pressure) { return 10; }
        if (stroke.brush === 'spray' || stroke.softness) {
          if (ref.info.methods.isAppleDevice()) {return 10} else {return 5}; }
        return 1;
      }
      // Для остальных типов штрихов возвращаем 0
      return 0;
    },

    // Функция подготовки штрихов для всех слоёв
    prepareStrokesForAllLayers: ()=> {
      const result = {};
    
      const strokesByLayer = {};
      const userStrokes = ref.history.userStrokes;

      // Проходим по всем массивам strokes напрямую
      for (const userId in userStrokes) {
        const strokesArray = userStrokes[userId];
        for (let i = 0, len = strokesArray.length; i < len; i++) {
          const stroke = strokesArray[i];
          const layerId = stroke.layerId || 'base';
          if (!strokesByLayer[layerId]) {
            strokesByLayer[layerId] = [];
          }
          strokesByLayer[layerId].push(stroke);
        }
      }
    
      // Получаем список видимых слоёв из convy.layerInfo
      const layersObj = ref.convy.layerInfo || {};
      const layersArray = Object.keys(layersObj)
        .map(id => ({ id, ...layersObj[id] }))
        .filter(layerData => layerData.visible);
    
      layersArray.forEach(layerData => {

        const layerId = layerData.id;
        const layerStrokes = strokesByLayer[layerId] || [];

        const { strokesToRender, difficulty } = ref.drawing.methods.prepareActiveStrokesForLayer({layerId, layerStrokes});
        
        const { difficulty: saveDifficulty } = ref.drawing.methods.prepareActiveStrokesForLayer({layerId, layerStrokes, fromServerSave: true});

        result[layerId] = { strokes: strokesToRender, difficulty, saveDifficulty };
      });
    
      return result;
    }


  };

  return {
    redrawer: 0,
    drawingPosition: { x: 0, y: 0 },
  };
};

export default createDrawingSlice;
