// SellBrushPopup.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  FaPencilRuler, FaPalette, FaFillDrip,
} from 'react-icons/fa';
import { HexAlphaColorPicker } from "react-colorful";
 
import useStore from '../../../store';
import BrushSizeControl from '../../board/tools/BrushSizeControl'; 

import './SellBrushPopup.css';

function SellBrushPopup({ store, brushKey, handleSettingsChange, closePopup }) {
  const { t } = useTranslation();
  const lineWidth = useStore(state=>state.lineWidth);
  
  const { ref } = store;
  const { telegram, work, drawing, brush, info, menu } = ref;
  const { user, game, bot } = info;
  
  const { hostAction } = work.methods;
  
  const {
    brushLinkFun,
    brushSetsFun,
    customBrushSetsFun,
    filterClosePoints,
    generateWavePoints,
  } = brush.methods;

  const {
    showPopupMessage,
    showTemporaryHint,
  } = menu.methods;

  const { saveUserSets } = work.methods;

  const basicBrushLink = brushLinkFun(brushKey);
  const currentBrushSettings = brushSetsFun(brushKey);
  const actualBrushSettings  = customBrushSetsFun(brushKey);

  const [price, setPrice] = useState(actualBrushSettings.price || 0);
  const [about, setAbout] = useState(actualBrushSettings.about || '');
  const [colorPickerState, setColorPickerState] = useState({
    isOpen: false,
    key: null,
  });
  const sellPreviewCanvasRef = useRef(null);
  const isUpdatingPreviewRef = useRef(false);

  const sellBrushColor = user?.sellBrushColor || '#000000';
  const sellBgColor = user?.sellBgColor || '#FFFFFF';

  const updateBrushPreview = async () => {
    if (isUpdatingPreviewRef.current) { return; }
    
    const canvas = sellPreviewCanvasRef.current;
    if (!canvas) return;

    isUpdatingPreviewRef.current = true;
    
    const context = canvas.getContext('2d');
    context.fillStyle = sellBgColor;
    context.fillRect(0, 0, canvas.width, canvas.height);

    const sets = brushSetsFun(brushKey);
    const commonSets = brushSetsFun('common');

    let points = generateWavePoints({
      width: 500,
      height: 300,
      steps: 50,
      amplitude: 50,
      frequency: 0.025,
      x: 60,
    });

    const smooth = (!sets.speedDependence && !sets.spreading);
    if (smooth) {
      points = filterClosePoints?.(points, Math.round(commonSets.smoothing)) || points;
    }

    const stroke = {
      points,
      type: 'stroke',
      color: sellBrushColor,
      lineWidth: lineWidth,
      brush: basicBrushLink,
      sets,
      time: 0,
      softness: 0,
    };

    context.globalCompositeOperation = stroke.sets?.composition || 'source-over';
    await drawing.methods.drawStroke(stroke, context);

    // Add price text if price > 0
    if (price > 0) {
      context.font = 'bold 40px Arial'; // Use a beautiful font (you can customize this)
      context.fillStyle = sellBrushColor; // Match the brush color or choose a contrasting color
      context.textAlign = 'right'; // Align text to the right
      context.textBaseline = 'bottom'; // Align text to the bottom
      const priceText = `${price}$`;
      
      // Add a slight shadow for better visibility
      context.shadowColor = 'rgba(0, 0, 0, 0.5)';
      context.shadowBlur = 4;
      context.shadowOffsetX = 2;
      context.shadowOffsetY = 2;

      // Draw the price in the bottom right corner with padding
      context.fillText(priceText, canvas.width - 20, canvas.height - 20);

      // Reset shadow to avoid affecting other drawings
      context.shadowBlur = 0;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
    }


    isUpdatingPreviewRef.current = false;
  };

  useEffect(() => {
    updateBrushPreview();
  }, [price, lineWidth, colorPickerState, user?.sellBrushColor, user?.sellBgColor]);

  const handleSell = async () => {
    const canvas = sellPreviewCanvasRef.current;
    const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

    const sellBrushSettings = { ...actualBrushSettings };

    if (sellBrushSettings.glyph && !sellBrushSettings.polyGlyph) { sellBrushSettings.glyphArr = [sellBrushSettings.glyph]; }

    sellBrushSettings.author = {
      id: telegram.telegramUserId,
      link: user.link,
      first_name: user.first_name,
    };
    sellBrushSettings.price = price;
    sellBrushSettings.about = about;

    sellBrushSettings.preview = {
      color: user?.sellBrushColor || "#000000",
      bgColor: user?.sellBgColor || '#FFFFFF',
      lineWidth: lineWidth,
    }

    sellBrushSettings.market = Date.now();
    delete sellBrushSettings.custom;

    delete sellBrushSettings.default;
    sellBrushSettings.default = {...sellBrushSettings}
    
    const sellData = {
      
      locale: game.settings.locale,
      brush: sellBrushSettings,
      image: blob,

      price,
      about, 
      blocked: user.blockSoldBrush,
      toMarket: user.sellToMarket,
    };

    useStore.setState({spinner: Date.now() + 10 * 1000});

    hostAction('sellBrush', sellData, (result)=>{
      window.croco?.haptic?.();

      useStore.setState({spinner: 0});

      if (result.success) {
        
        showPopupMessage({
          title: t('sell.lot_created.success_title'),
          message: t('sell.lot_created.success_message'),
          vertical: true,
          wideButtons: [
            { id: 'pm', ignore: true, text: t('sell.lot_created.button_pm_bot'),},
            { id: 'share', ignore: true, text: t('sell.lot_created.button_share'),},
          ],
          buttons: [{ type: 'cancel', text: 'OK', }],
          callback: (buttonId)=>{

            if (buttonId === 'share') {
              try {
                window.Telegram.WebApp?.shareMessage?.(result.prepared?.id);
              } catch (e) {}
            } else if (buttonId === 'pm') {
              window.Telegram.WebApp.openTelegramLink(`https://t.me/${bot.username}`)
              setTimeout(() => {
                showTemporaryHint(t('sell.lot_created.minimize_app'));
              }, 1000);
            }
          }
        });

      } else {
        showPopupMessage({
          title: t('sell.lot_created.error_title'),
          message: t('sell.lot_created.error_message') + result?.error,
        });
      }

    });
    closePopup();
  };

  const handlePriceChange = (value) => {
    const newPrice = Math.max(0, Math.floor(Number(value) || 0));
    setPrice(newPrice);
    handleSettingsChange({price: value});
  };
  const handleAboutChange = (e) => {
    setAbout(e.target.value);
    handleSettingsChange({about: e.target.value});
  };

  return (
    <>
      <div className="sell-overlay-background" onClick={closePopup}></div>
      <div className="sell-brush-popup add-scroll touch-active">
        <span className="sell-brush-title">
          {t('sell.title', { label: currentBrushSettings.label })}
        </span>

        <canvas
          ref={sellPreviewCanvasRef}
          width={600}
          height={300}
          className="sell-brush-preview"
          style={{ cursor: 'pointer' }}
        />

        <div className="sell-parameter-row">
          <div 
            onClick={() => setColorPickerState({ isOpen: true, key: 'sellBgColor' })}
            className="sell-parameter-button"
          >
            <FaFillDrip style={{
              fontSize: '28px',
              color: sellBgColor,
              filter: 'drop-shadow(0px 0px 1px #808080) drop-shadow(0px 0px 2px #808080)',
            }}/>
          </div>
          <div 
            onClick={() => setColorPickerState({ isOpen: true, key: 'sellBrushColor' })}
            className="sell-parameter-button"
          >
            <FaPalette style={{
              fontSize: '28px',
              color: sellBrushColor,
              filter: 'drop-shadow(0px 0px 1px #808080) drop-shadow(0px 0px 2px #808080)',
            }}/>
          </div>
          <div style={{ height: '32px', margin: '10px' }}>
            <BrushSizeControl 
              store={store}
              icon={<FaPencilRuler />}
              buttonColor={sellBrushColor}
              defaultBrushSize={36}
              maxButtonSize={32}
              hideButton={true}
              hideInidicator={true}
              showMultiplier={false}
              style={{ width: '32px', height: '32px' }}
              brushInfoStyle={{ padding: '0px 0px' }}
            />
          </div>
        </div>

        <textarea
          className="sell-brush-about input-field"
          placeholder={t('sell.about')}
          value={about}
          onChange={handleAboutChange}
          onInput={(e) => {
            e.target.style.height = 'auto'; // Сбрасываем высоту
            e.target.style.height = `${e.target.scrollHeight + 3}px`; // Устанавливаем высоту под содержимое
          }}
        />

        <label className="sell-brush-label">
          <span>{t('sell.info')}</span>
        </label>


        {/* <label className="sell-brush-label">
          <input type="checkbox" checked={user.sellToMarket} onChange={(e) => {saveUserSets({sellToMarket: !user.sellToMarket})}} />
          <span>{t('sell.market')}</span>
        </label>
        <label className="sell-brush-label">
          <input type="checkbox" checked={user.blockSoldBrush} onChange={(e) => {saveUserSets({blockSoldBrush: !user.blockSoldBrush})}} />
          <span>{t('sell.block')}</span>
        </label> */}

        <label className="sell-brush-label">
          <span>{t('sell.label_price')}</span>
        </label>

        <div className="sell-brush-controls">
          <input
            type="range"
            min={0}
            max={100}
            value={price}
            onChange={(e) => handlePriceChange(e.target.value)}
            className="sell-brush-slider"
          />
          <input
            type="number"
            value={price}
            onChange={(e) => handlePriceChange(e.target.value)}
            className="sell-brush-price-input input-field"
            min={0}
          />
        </div>

        <div className="message-popup-button-container">
          <button className="message-popup-button cancel-button" onClick={closePopup}>
            {t('sell.cancel')}
          </button>
          <button className="message-popup-button" onClick={handleSell}>
            {t('sell.go')}
          </button>
        </div>
      </div>

      {colorPickerState.isOpen && (
        <>
          <div
            className="overlay-background"
            style={{ zIndex: 1600, opacity: 0 }}
            onClick={() => setColorPickerState({ isOpen: false, key: null })}
          ></div>
          <div 
            className="settings-color-picker-container" 
            style={{ zIndex: 1620, bottom: '10%' }}
          >
            <HexAlphaColorPicker
              color={user[colorPickerState.key] || '#000000'}
              onChange={(newColor) => saveUserSets({[colorPickerState.key]: newColor})}
            />
            <button
              className="wide-button"
              onClick={() => setColorPickerState({ isOpen: false, key: null })}
              style={{ marginTop: '10px' }}
            >
              {t('settings.set_color')}
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default SellBrushPopup;