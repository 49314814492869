// src/components/puzzle/utils/geometry.js

/**
 * Checks if a dropped piece's top-left corner is within the tolerance range of its correct top-left position.
 * @param {object} dropPosPx - { x, y } top-left position where the piece was dropped in pixels (relative to the PuzzleArea guide).
 * @param {object} correctPosPx - { x, y } the target top-left position of the piece in pixels (relative to the PuzzleArea guide).
 * @param {object} pieceDisplayDimensions - { width, height } display dimensions of the piece in pixels on the board.
 * @param {number} toleranceFactor - Factor (e.g., 0.35) of piece width/height allowed as tolerance.
 * @returns {boolean} - True if the placement is correct, false otherwise.
 */
export const checkPlacement = (dropPosPx, correctPosPx, pieceDisplayDimensions, toleranceFactor) => {
  if (!dropPosPx || !correctPosPx || !pieceDisplayDimensions || toleranceFactor === undefined) return false;

  // Calculate tolerance in pixels based on the *center* difference for a better feel
  const toleranceX = pieceDisplayDimensions.width * toleranceFactor;
  const toleranceY = pieceDisplayDimensions.height * toleranceFactor;

  const droppedCenterX = dropPosPx.x + pieceDisplayDimensions.width / 2;
  const droppedCenterY = dropPosPx.y + pieceDisplayDimensions.height / 2;

  const correctCenterX = correctPosPx.x + pieceDisplayDimensions.width / 2;
  const correctCenterY = correctPosPx.y + pieceDisplayDimensions.height / 2;

  const dx = Math.abs(droppedCenterX - correctCenterX);
  const dy = Math.abs(droppedCenterY - correctCenterY);

//   console.log(`Check Placement: DropCenter(x:${droppedCenterX.toFixed(1)}, y:${droppedCenterY.toFixed(1)}) CorrectCenter(x:${correctCenterX.toFixed(1)}, y:${correctCenterY.toFixed(1)}) Tol(x:${toleranceX.toFixed(1)}, y:${toleranceY.toFixed(1)}) Delta(dx:${dx.toFixed(1)}, dy:${dy.toFixed(1)})`);

  return dx <= toleranceX && dy <= toleranceY;
};

// distance function remains the same