import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaPaste } from 'react-icons/fa';
import { glyphCollection } from './glyphs';
import './GlyphSelector.css';

const GlyphSelector = ({
  currentBrushSettings,
  handleNewGlyph,
  handleSettingsChange,
  onOpenGlyphEditor,
  showTemporaryHint,
}) => {
  const { t } = useTranslation();

  const currentGlyph = currentBrushSettings.glyph;
  const glyphArr = currentBrushSettings.glyphArr || [];

  const recentGlyphs = glyphArr[0] ? glyphArr : Object.values(glyphCollection);

  const drawGlyphThumbnail = (canvas, glyph, size) => {
    if (!canvas || !glyph) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, size * 2, size * 2);

    // Рисуем шахматный фон
    const tileSize = size / 4;
    for (let y = 0; y < size * 2; y += tileSize) {
      for (let x = 0; x < size * 2; x += tileSize) {
        ctx.fillStyle = ((x / tileSize + y / tileSize) % 2 === 0) ? '#ffffff' : '#d3d3d3';
        ctx.fillRect(x, y, tileSize, tileSize);
      }
    }

    // Рисуем сам глиф поверх фона
    ctx.fillStyle = 'black';
    const scaleFactor = size / 10; // Исходный диаметр звезды = 20
    const offset = size; // Смещаем в центр канваса

    // Совместимость со старыми глифами (массив точек)
    let glyphStrokes = [];
    if (Array.isArray(glyph) && glyph.length > 0 && !glyph[0].points) {
      glyphStrokes = [{ points: glyph }];
    } else if (Array.isArray(glyph)) {
      glyphStrokes = glyph;
    }

    if (glyphStrokes.length === 0) return;

    glyphStrokes.forEach(stroke => {
      const points = stroke.points;
      if (!points || points.length === 0) return;

      ctx.beginPath();
      ctx.moveTo(offset + points[0].x * scaleFactor, offset + points[0].y * scaleFactor);
      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(offset + points[i].x * scaleFactor, offset + points[i].y * scaleFactor);
      }
      ctx.closePath();
      ctx.fill();
    });
  };

  const handleGlyphSelect = (selectedGlyph) => {
    const sets = { glyph: selectedGlyph };

    if (currentGlyph) {
      const existsInHistory = recentGlyphs.find((glyph) => JSON.stringify(glyph) === JSON.stringify(currentGlyph));
      if (!existsInHistory) {
        recentGlyphs.push(currentGlyph);
        sets.glyphArr = recentGlyphs;
      }
    }

    handleSettingsChange(sets);
  };

  const handleGlyphRemove = (glyphToRemove) => {
    const newGlyphArr = recentGlyphs.filter(
      (glyph) => JSON.stringify(glyph) !== JSON.stringify(glyphToRemove)
    );
    handleSettingsChange({ glyphArr: newGlyphArr });
  };

  const handleCopyGlyph = () => {
    if (currentGlyph) {
      navigator.clipboard
        .writeText(JSON.stringify(currentGlyph))
        .then(() => {
          showTemporaryHint(t('glyph.copy.copied'), { force: true });
        })
        .catch((err) => {
          console.error('Failed to copy glyph:', err);
          showTemporaryHint(t('glyph.copy.copy_failed'), { force: true });
        });
    }
  };

  const handlePasteGlyph = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        try {
          const pastedGlyph = JSON.parse(text);
          // Проверяем, является ли это массивом (старый формат) или объектом (новый формат)
          if (Array.isArray(pastedGlyph) && (pastedGlyph.length === 0 || pastedGlyph[0].points || !pastedGlyph[0].points)) {
            handleNewGlyph(pastedGlyph);
            showTemporaryHint(t('glyph.copy.pasted'), { force: true });
          } else {
            showTemporaryHint(t('glyph.copy.invalid_data'), { force: true });
          }
        } catch (err) {
          console.error('Failed to parse glyph from clipboard:', err);
          showTemporaryHint(t('glyph.copy.paste_failed'), { force: true });
        }
      })
      .catch((err) => {
        console.error('Failed to read clipboard:', err);
        showTemporaryHint(t('glyph.copy.paste_failed'), { force: true });
      });
  };

  const flipGlyphHorizontally = (glyph) => {
    let glyphStrokes = [];
    
    // Совместимость со старым форматом (массив точек)
    if (Array.isArray(glyph) && glyph.length > 0 && !glyph[0].points) {
      glyphStrokes = [{ points: glyph }];
    } else if (Array.isArray(glyph)) {
      glyphStrokes = glyph;
    }
  
    if (glyphStrokes.length === 0) return glyph;
  
    // Отражаем все точки по горизонтали (инвертируем координату X)
    const flippedStrokes = glyphStrokes.map(stroke => ({
      points: stroke.points.map(point => ({
        x: -point.x,  // Отражение по горизонтали
        y: point.y
      }))
    }));
  
    handleNewGlyph(flippedStrokes);
  };

  return (
    <div className="settings-item glyph-selection touch-active">
      <label className="section-title" onClick={onOpenGlyphEditor}>
        {t('glyph.selector.press_glyph')}
      </label>

      <div className="glyph-options">
        <div className="glyph-option-container">
          { !currentBrushSettings.author && (
            <button className="glyph-action-button paste-button" onClick={handlePasteGlyph}>
              <FaPaste />
            </button>
          )}

          <div className="glyph-option active" 
            onClick={onOpenGlyphEditor}
            onContextMenu={(e) => {
              e.preventDefault();
              flipGlyphHorizontally(currentGlyph);
            }}
          >
            <canvas
              ref={(canvas) => {
                if (canvas) {
                  drawGlyphThumbnail(canvas, currentGlyph || glyphCollection.star, 50);
                }
              }}
              width={100}
              height={100}
              style={{ borderRadius: '5px' }}
            />
          </div>

          { !currentBrushSettings.author && (
            <button className="glyph-action-button copy-button" onClick={handleCopyGlyph}>
              <FaCopy />
            </button>
          )}
   
        </div>
      </div>

      {recentGlyphs.length > 0 && (
        <>
          <label className="section-title" onClick={onOpenGlyphEditor}>
            {t('glyph.selector.glyph_history')}
          </label>

          <div className="glyph-history">
            <div className="glyph-history-options">
              {recentGlyphs.map((glyph, index) => (
                <div
                  key={index}
                  className="glyph-history-option"
                  onClick={() => handleGlyphSelect(glyph)}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    handleGlyphRemove(glyph);
                  }}
                >
                  <canvas
                    ref={(canvas) => {
                      if (canvas) {
                        drawGlyphThumbnail(canvas, glyph, 25);
                      }
                    }}
                    width={50}
                    height={50}
                    style={{ borderRadius: '5px' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GlyphSelector;