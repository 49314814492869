// src/store/telegramSlice.js

const createTelegramSlice = (set, get, ref) => {
  // Извлекаем параметры из URL сразу при создании стора

  const urlParams = new URLSearchParams(window.location.search);
  const startParam = urlParams.get('tgWebAppStartParam') || '67ed66316a7ce4c761a2f191--0';
  // const startParam = urlParams.get('tgWebAppStartParam') || '67e9448a91ef897a3fc70f7f--0';
  // const startParam = urlParams.get('tgWebAppStartParam') || '67e8ef9dc653c36fe03fa5e8--0';
  // const startParam = urlParams.get('tgWebAppStartParam') || '67e27f7e8daec3de369a7ade--0';
  const inBrowser = urlParams.get('inBrowser');
  const cacheOff = urlParams.get('cacheOff');
  const parts = startParam.split('-');
  const gameId = parts[0];
  const testCanvas = ['test', 'brush'].includes(gameId);
  const gameParam = parts[1];
  const socketPortNum = parts[2];
  const moderMode = parts[4] === 'moder';

  // const adminUserId = 1431457632;
  const adminUserId = 141015727;
  const telegramUserId = `${window.Telegram?.WebApp?.initDataUnsafe?.user?.id || adminUserId}`;
  const languageCode = `${window.Telegram?.WebApp?.initDataUnsafe?.user?.language_code || 'ru'}`;
  const initData = window.Telegram?.WebApp?.initData || '';

  const clientStartTime = Date.now();
  const clientId = `${telegramUserId}_${clientStartTime}`;

  ref.telegram = {
    urlParams,
    startParam,
    inBrowser,
    cacheOff,
    gameId,
    testCanvas,
    gameParam,
    socketPortNum,
    moderMode,

    telegramUserId,
    languageCode,
    initData,
    clientStartTime,
    clientId,

  }

  ref.telegram.activeUserId = telegramUserId;

  return {

    // Флаги проверки и безопасности
    isValid: null,
    isChecked: null,
    isHacker: false,
    telegramInitialized: false,

    // Действия (actions):
    initTelegram: () => {
      window.logPerformance?.('Telegram script load started');
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-web-app.js';
      script.async = true;
      script.onload = () => {

        if (ref.telegram.initialized) { return; }
        ref.telegram.initialized = true;

        window.logPerformance?.('Telegram script load ended');
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.ready();
          window.Telegram.WebApp.expand();
          // Настройка кнопки «настроек»
          window.Telegram.WebApp.SettingsButton.show();
          // window.Telegram.WebApp.BackButton.show();
          // window.Telegram.WebApp.MainButton.enable();
          // window.Telegram.WebApp.MainButton.show();
          window.Telegram.WebApp.onEvent('settingsButtonClicked', () => {
            ref.menu.methods.openMenu('drawing');
          });
          set({ telegramInitialized: true });

          try {
            window.Telegram.WebApp.disableVerticalSwipes();
          } catch (e) {
            document.body.classList.add('is-scroll-hack-enabled');
            setInterval(() => {
              window.scrollTo(2500, 2500); // Используем window.scrollTo
            }, 200);
          }
        }
      };
      document.body.appendChild(script);
    },

    setIsValid: (value) => {
      set({
        isValid: value,
        isChecked: true,
        isHacker: value ? false : true,
      });
    },
  };
};

export default createTelegramSlice;
