// outlined.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { drawPressureStroke } from './helpers/pressure';
import { drawStrokeWithGradient } from './marker';
import { getStrokeBounds, translatePoints } from './helpers/points'; 

const defaultBrushSettings = brushDefaults.outlined || {};

export async function drawOutlinedStroke(stroke, context, params) {
  const {
    points,
    lineWidth,
    gradientColor,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    outlineSize = 0,
    outlineColor = 'black',
    outlineOpacity = 1,
  } = brushSettings;

  // Вычисляем границы штриха
  const bounds = getStrokeBounds(points, lineWidth, outlineSize);
  if (!bounds) return;

  const offsetX = -bounds.x;
  const offsetY = -bounds.y;
  const translatedPoints = translatePoints(points, offsetX, offsetY);

  // Создаем временный канвас минимального размера
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = bounds.width;
  bufferCanvas.height = bounds.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.globalAlpha = outlineOpacity;

  // Рисуем обводку увеличенного размера на буферном канвасе
  drawPressureStroke({
    points: translatedPoints,
    lineWidth: lineWidth,
    increaseLineWidth: outlineSize * 2,
    color: outlineColor,
    gradientColor: null,
  }, bufferCtx, brushSettings);

  bufferCtx.globalAlpha = 1;

  // Устанавливаем режим композиции для вычитания внутренней части
  bufferCtx.globalCompositeOperation = 'destination-out';
  // Рисуем внутреннюю часть, чтобы вычесть ее из обводки
  drawPressureStroke({
    points: translatedPoints,
    lineWidth: lineWidth - 0.7,
    color: 'black',
    gradientColor,
  }, bufferCtx, brushSettings);

  // Сбрасываем режим композиции
  bufferCtx.globalCompositeOperation = 'source-over';

  // Копируем буферный канвас на основной контекст
  context.drawImage(
    bufferCanvas, 
    0, 0, bounds.width, bounds.height,
    bounds.x, bounds.y, bounds.width, bounds.height
  );

  // Рисуем основной штрих с нужным цветом или градиентом
  drawStrokeWithGradient(stroke, context, brushSettings);
}
