// ChatOverlay.js
import React, { useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';

function ConnectionIndicator({store}) {

    const { t } = useTranslation();

    const { menu, info, work, history, connection } = store.ref;

    const {
      groupRedraw,
    } = work.methods;
    const {
      showPopupMessage,
    } = menu.methods;

    const [indicatorStr, setIndicatorStr] = useState('');  // Состояние для контроля видимости чата
    const onIndicatorClickRef = useRef(null);  // Состояние для контроля видимости чата

    useEffect(() => {

      const intervalId = setInterval(() => {

        if (!info.game) {return;}

        onIndicatorClickRef.current = groupRedraw;

        const allStrokes = Object.values(history.userStrokes).flat();
        const combinedStrokes = 
        allStrokes.filter(stroke => !stroke.cancelled && !stroke.hidden)
        .sort((a, b) => a.time - b.time);

        const lastStroke = combinedStrokes[combinedStrokes.length - 1];
        let lastStrokeTime = lastStroke?.time || 0;
        if (lastStroke?.type === 'clear') {lastStrokeTime = 0;}

        const {
          lastSaveTime,
          lastPreviewTime,
          lastConnectionErrorTime,
          lastErrorPopupTime,
        } = connection;
        
        if (!combinedStrokes.length || lastStrokeTime === info.game.lastRenderedStrokeTime) {
          setIndicatorStr(t('connection.published'));
          onIndicatorClickRef.current = null;
        } else if (
          lastConnectionErrorTime > lastSaveTime
          ) {
          setIndicatorStr(t('connection.lost'))
        } else {
          setIndicatorStr(t('connection.force'))
        }

        const now = Date.now();

        if (
          lastConnectionErrorTime > lastSaveTime
          && lastSaveTime < now - 1 * 60 * 1000
          && lastErrorPopupTime < now - 1 * 60 * 1000
          ) {
            connection.lastErrorPopupTime = now;
            showPopupMessage({
              title: t('connection.error_popup.title'),
              message: t('connection.error_popup.text'),
              buttons: [
                { type: 'submit', text: t('connection.error_popup.button_ok')},
              ],
          });

        }
        
      }, 300);

      return () => clearInterval(intervalId);
    }, []);

    return (
      <>
        { indicatorStr && <div 
          onClick={onIndicatorClickRef.current}
            style={{
              position: 'absolute',
              top: '4px',
              left: '15px',
              zIndex: 200,
              color: 'var(--tg-theme-text-color)',

              // backgroundColor: 'rgba(215, 215, 215, 0.4)',
              margin: '2px 0',
              padding: '2px 6px 3px',
              borderRadius: '10px',
              fontSize: '10',
              opacity: 0.75,
              display: 'inline-block', // Элемент адаптируется по размеру контента
              // backgroundColor: 'rgba(0, 0, 0, 0.1)', // Добавляем слегка видимый фон

              cursor: 'pointer', // Явно добавляем курсор-указатель

        }}> 

          {indicatorStr}

        </div>}
        

      </>
  );
}

export default ConnectionIndicator;
