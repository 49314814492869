// glfxFilters.js
import glfx from 'glfx';

export async function applyGlfxFilter(canvas, filterType, options = {}) {
  try {
    // Инициализируем WebGL canvas через glfx
    const fxCanvas = glfx.canvas();

    // Создаём текстуру из входного canvas
    const texture = fxCanvas.texture(canvas);

    // Рисуем текстуру на fxCanvas
    fxCanvas.draw(texture);

    // Применяем фильтр в зависимости от типа
    switch (filterType) {
      case 'glfxBrightnessContrast':
        fxCanvas.brightnessContrast(options.brightness || 0, options.contrast || 0);
        break;
      case 'glfxHueSaturation':
        fxCanvas.hueSaturation(options.hue || 0, options.saturation || 0);
        break;
      case 'glfxNoise':
        fxCanvas.noise(options.noise || 0);
        break;
      case 'glfxDenoise':
        fxCanvas.denoise(options.exponent || 20);
        break;
      case 'glfxUnsharpMask':
        fxCanvas.unsharpMask(options.radius || 20, options.strength || 1);
        break;
      case 'glfxVibrance':
        fxCanvas.vibrance(options.amount || 0);
        break;
      case 'glfxSepia':
        fxCanvas.sepia(options.amount || 0);
        break;
      case 'glfxVignette':
        fxCanvas.vignette(options.size || 0.5, options.amount || 0.5);
        break;
      case 'glfxLensBlur':
        fxCanvas.lensBlur(options.radius || 10, options.brightness || 0, options.angle || 0);
        break;
      case 'glfxTiltShift':
        fxCanvas.tiltShift(
          options.startX || 0,
          options.startY || 0,
          options.endX || canvas.width,
          options.endY || canvas.height,
          options.blurRadius || 15,
          options.gradientRadius || 200
        );
        break;
      case 'glfxTriangleBlur':
        fxCanvas.triangleBlur(options.radius || 20);
        break;
      case 'glfxZoomBlur':
        fxCanvas.zoomBlur(
          options.centerX || canvas.width / 2,
          options.centerY || canvas.height / 2,
          options.strength || 0.2,
          options.innerRadius || 50,
          options.outerRadius || 200
        );
        break;
      case 'glfxColorHalftone':
        fxCanvas.colorHalftone(
          options.centerX || canvas.width / 2,
          options.centerY || canvas.height / 2,
          options.angle || 0,
          options.size || 10
        );
        break;
      case 'glfxDotScreen':
        fxCanvas.dotScreen(
          options.centerX || canvas.width / 2,
          options.centerY || canvas.height / 2,
          options.angle || 0,
          options.size || 10
        );
        break;
      case 'glfxEdgeWork':
        fxCanvas.edgeWork(options.radius || 10);
        break;
      case 'glfxHexagonalPixelate':
        fxCanvas.hexagonalPixelate(
          options.centerX || canvas.width / 2,
          options.centerY || canvas.height / 2,
          options.scale || 10
        );
        break;
      case 'glfxInk':
        fxCanvas.ink(options.strength || 0.5);
        break;
      case 'glfxBulgePinch':
        fxCanvas.bulgePinch(
          options.centerX || canvas.width / 2,
          options.centerY || canvas.height / 2,
          options.radius || 100,
          options.strength || 0.5
        );
        break;
      case 'glfxSwirl':
        fxCanvas.swirl(
          options.centerX || canvas.width / 2,
          options.centerY || canvas.height / 2,
          options.radius || 100,
          options.angle || 0
        );
        break;
      case 'glfxMatrixWarp':
        fxCanvas.matrixWarp(
          options.matrix || [
            1, 0, 0, 0,
            0, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
          ],
          options.inverse || false,
          options.bilinear || true
        );
        break;
      case 'glfxPerspective':
        fxCanvas.perspective(
          options.from || [
            0, 0,
            canvas.width, 0,
            canvas.width, canvas.height,
            0, canvas.height
          ],
          options.to || [
            0, 0,
            canvas.width, 0,
            canvas.width, canvas.height,
            0, canvas.height
          ],
          options.bilinear || true
        );
        break;
      case 'glfxPosterize':
        fxCanvas.posterize(options.levels || 6);
        break;
      case 'glfxSolarize':
        fxCanvas.solarize(options.amount || 1);
        break;
      default:
        console.error('Unknown glfx filter type:', filterType);
        return false;
    }

    // Обновляем текстуру с применённым фильтром
    fxCanvas.update();

    // Копируем результат обратно на исходный canvas
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(fxCanvas, 0, 0, canvas.width, canvas.height);

    return true;
  } catch (error) {
    console.error('Error applying glfx filter:', error);
    return false;
  }
}