import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { interpolatePoints } from './helpers/pressure';
import { fillGradient } from './helpers/gradient'; 
import { filterClosePoints, getStrokeBounds, translatePoints } from './helpers/points';

const defaultBrushSettings = brushDefaults.glyph || {};

const starGlyph = [
  { x: 0, y: -10 },
  { x: 3, y: -3 },
  { x: 10, y: -3 },
  { x: 5, y: 3 },
  { x: 7, y: 10 },
  { x: 0, y: 6 },
  { x: -7, y: 10 },
  { x: -5, y: 3 },
  { x: -10, y: -3 },
  { x: -3, y: -3 },
];

// Optimized glyph texture creation with caching
export function createGlyphTexture(glyph, size, fillStyle = 'black') {
  const textureKey = `${JSON.stringify(glyph)}-${size}-${fillStyle}`;
  if (createGlyphTexture.cache[textureKey]) {
    return createGlyphTexture.cache[textureKey];
  }

  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = size * 2;
  tempCanvas.height = size * 2;
  const tempCtx = tempCanvas.getContext('2d');

  tempCtx.fillStyle = fillStyle;
  const glyphStrokes = Array.isArray(glyph) && glyph.length > 0 && !glyph[0].points
    ? [{ points: glyph }]
    : glyph;

  const scaleFactor = size / 10;

  glyphStrokes.forEach(stroke => {
    const points = stroke.points;
    if (!points || points.length < 3) return;

    tempCtx.beginPath();
    tempCtx.moveTo(size + points[0].x * scaleFactor, size + points[0].y * scaleFactor);
    for (let i = 1; i < points.length; i++) {
      tempCtx.lineTo(size + points[i].x * scaleFactor, size + points[i].y * scaleFactor);
    }
    tempCtx.closePath();
    tempCtx.fill();
  });

  createGlyphTexture.cache[textureKey] = tempCanvas;
  return tempCanvas;
}
createGlyphTexture.cache = new Map();

function pseudoRandom(time, x, y) {
  const seed = (time || 0) + x * 73856093 + y * 19349663;
  const value = Math.sin(seed) * 10000;
  return value - Math.floor(value);
}

function getDynamicParams(pressure, baseParams) {
  let {
    pressureOn,
    pressureOpacity,
    pressuredStroke,
    opacity = 1,
    highTransparency,
  } = baseParams;

  let scale = 1;
  if (pressuredStroke && pressureOn && pressure) { scale = pressure; }

  if (highTransparency) opacity *= 0.1;

  if (pressuredStroke && pressureOpacity && pressure) {
    let compensatedOpacity = pressure === 1 ? pressure : pressure * 0.3;
    opacity *= (pressureOn ? pressure : compensatedOpacity);
  }

  return { scale, opacity };
}


export class GlyphBrush {
  constructor({
    glyph,
    size,
    color = 'black',
    scale = 1,
    rotation = 0,
    opacity = 1,
  }) {
    this.glyph = glyph;
    this.size = size;
    this.color = color;
    this.baseParams = { scale, rotation, opacity };
    this.updateTexture();
  }

  updateTexture() {
    this.glyphTexture = createGlyphTexture(this.glyph, this.size, this.color);
  }

  drawGlyph(ctx, x, y, scale, rotation, opacity, mirror = false) {
    const textureSize = this.size * 2 * scale;
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(rotation);
    if (mirror) {
      ctx.scale(-1, 1); // Горизонтальное отражение
      x = -x; // Компенсация координаты x
    }
    ctx.globalAlpha = opacity;
    ctx.drawImage(
      this.glyphTexture,
      -textureSize / 2,
      -textureSize / 2,
      textureSize,
      textureSize
    );
    ctx.restore();
  }

  updateColor(color) {
    this.color = color;
    this.updateTexture();
  }
}

export function drawGlyphStroke(stroke, context, params) {
  context.save();

  const {
    points, color, lineWidth, time, sets, gradientColor, step = 0,
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);


  const {
    pressureOn,
    pressureOpacity,
    enhance,
    glyph = starGlyph,
    polyGlyph = false,
    glyphArr = [],
    angle = 0,
    mirror = false,
    autoRotation,
    opacity,
    highTransparency,
    stepSizeDependence,
    shadowStrength = 0,
  } = brushSettings;

  let {
    stepSize = 1,
  } = brushSettings;
  if (stepSizeDependence) {
    stepSize = Math.max(Math.round(stepSize*lineWidth/100), 1)
  }

  const radians = angle * Math.PI / 180;
  const pressuredStroke = points[0].pressure;

  if (!points || points.length === 0) {
    console.warn('No points provided to drawGlyphStroke');
    return;
  }

  const bounds = getStrokeBounds(points, lineWidth);
  if (!bounds) return;

  const offsetX = -bounds.x;
  const offsetY = -bounds.y;
  const translatedPoints = translatePoints(points, offsetX, offsetY);

  let currentPoints = enhance ? interpolatePoints(translatedPoints) : translatedPoints;
  currentPoints = stepSize > 1 ? filterClosePoints(currentPoints, stepSize) : currentPoints;

  const size = lineWidth / 2;
  
  // Унифицируем работу с glyphArr
  const effectiveGlyphArr = (polyGlyph && glyphArr.length > 0) ? glyphArr : [glyph];
  
  // Создаем массив экземпляров кистей для каждого глифа
  const glyphBrushes = effectiveGlyphArr.map(g => new GlyphBrush({
    glyph: g,
    size,
    color,
    opacity,
  }));

  const baseParams = {
    scale: 1,
    rotation: 0,
    step,
    opacity,
    highTransparency,
    pressureOn,
    pressureOpacity,
    pressuredStroke,
    radians,
    autoRotation,
    stepSize,
    mirror,
    polyGlyph,
    glyphCount: glyphBrushes.length,
  };

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = bounds.width;
  bufferCanvas.height = bounds.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  if (translatedPoints.length === 1) {
    const point = translatedPoints[0];
    const pointPressure = point.pressure || 1;
    const pseudoRandomValue = pseudoRandom(time, point.x, point.y);
    const { scale: dynamicScale, opacity: dynamicOpacity } = getDynamicParams(
      pointPressure,
      { ...baseParams, highTransparency: false },
    );

    const stepGlyphBrush = step % glyphBrushes.length;
    
    // Используем первый глиф для одиночной точки
    glyphBrushes[stepGlyphBrush].drawGlyph(bufferCtx, point.x, point.y, dynamicScale, radians, dynamicOpacity, mirror);
  } else {
    drawContinuousGlyphStroke(bufferCtx, glyphBrushes, currentPoints, baseParams, time);
  }

  if (gradientColor) {
    fillGradient(bufferCtx, translatedPoints, color, gradientColor, lineWidth);
  }

  if (shadowStrength > 0) {
    context.shadowColor = chroma('black').alpha(shadowStrength).css();
    context.shadowBlur = 10;
    context.shadowOffsetX = 2;
    context.shadowOffsetY = 4;
  }

  context.drawImage(
    bufferCanvas,
    0, 0, bounds.width, bounds.height,
    bounds.x, bounds.y, bounds.width, bounds.height
  );

  context.restore();
}

function drawContinuousGlyphStroke(context, glyphBrushes, points, baseParams, time) {
  const lookAhead = Math.ceil(Math.min(5, 50 / baseParams.stepSize));
  let glyphIndex = 0;

  for (let i = 0; i < points.length - 1; i++) {
    const currentPoint = points[i];
    const nextPoint = points[i + 1];

    const dx = nextPoint.x - currentPoint.x;
    const dy = nextPoint.y - currentPoint.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    let smoothAngle = baseParams.radians || 0;
    if (baseParams.autoRotation) {
      const prevIndex = Math.max(0, i - lookAhead);
      const nextIndex = Math.min(points.length - 1, i + lookAhead);
      const prevPoint = points[prevIndex];
      const forwardPoint = points[nextIndex];

      const dxSmooth = forwardPoint.x - prevPoint.x;
      const dySmooth = forwardPoint.y - prevPoint.y;
      smoothAngle += Math.atan2(dySmooth, dxSmooth) - (90 * Math.PI / 180);
    }

    const steps = Math.max(1, Math.ceil(distance / baseParams.stepSize));
    let strokeLength = 0;

    for (let j = 0; j <= steps; j++) {
      const t = j / steps;
      const x = currentPoint.x + dx * t;
      const y = currentPoint.y + dy * t;
      strokeLength += distance / steps;

      const pointPressure = points.length > i + 1
        ? currentPoint.pressure + (nextPoint.pressure - currentPoint.pressure) * t
        : currentPoint.pressure || 1;

      const pseudoRandomValue = pseudoRandom(time, x, y);
      const { scale, opacity } = getDynamicParams(pointPressure, baseParams);

      const validStep = baseParams.stepSize < 1.5 ?
        (j && j % Math.round(baseParams.stepSize) === 0) :
        (j % Math.round(baseParams.stepSize) === 0);

      if (validStep) {
        // Выбираем кисть в зависимости от polyGlyph
        const currentBrush = baseParams.polyGlyph && baseParams.glyphCount > 1
          ? glyphBrushes[(baseParams.step + glyphIndex) % baseParams.glyphCount]
          : glyphBrushes[0];
        
        currentBrush.drawGlyph(context, x, y, scale, smoothAngle, opacity, baseParams.mirror);
        
        // Инкрементируем индекс только если polyGlyph включен и есть несколько глифов
        if (baseParams.polyGlyph && baseParams.glyphCount > 1) {
          glyphIndex++;
        }
      }
    }
  }
}