
import { getTexture, brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 
import { createTaperingStroke, calculateDistance } from './helpers/tapering';
import { createPressureStroke } from './helpers/pressure';
import { createFade } from './helpers/fade';
import { getStrokeBounds, translatePoints } from './helpers/points'; 

const defaultBrushSettings = brushDefaults.pencil;

export async function drawPencilStroke(stroke, context) {

  const {
    color,
    gradientColor,
    lineWidth,
    points,
    time,
    shapeId,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  // const textureName = '123';
  const textureName = brushSettings.texture || 'basic';
  const textureScale = brushSettings.textureScale || 1;
  const pencilTexture = await getTexture('pencil', textureName);

  if (brushSettings.outline) {
    const outlineSize = Math.min(6, Math.max(2, lineWidth / 3));
    await applyPencilStroke({
      color,
      gradientColor,
      lineWidth: lineWidth + outlineSize,
      points,
      time,
      shapeId,

      texture: pencilTexture,
      textureScale: textureScale * 1.5,
      textureOpacity: 0.5,
      brushSettings,
    }, context)
  }

  await applyPencilStroke({
    color,
    gradientColor,
    lineWidth,
    points,
    time,
    shapeId,

    texture: pencilTexture,
    textureScale,
    brushSettings,
  }, context)


}

async function applyPencilStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  time,
  texture,
  brushSettings,
  shapeId,

  textureScale = 1,
  textureOpacity = 1,
}, context) {

  const {
    tapering,
    transparentEdges,
    opacityOnStart,
    opacityOnEnd,
  } = brushSettings;

  // Вычисляем границы штриха
  const bounds = getStrokeBounds(points, lineWidth);
  if (!bounds) return;

  const offsetX = -bounds.x;
  const offsetY = -bounds.y;
  const translatedPoints = translatePoints(points, offsetX, offsetY);

  // Создаем временный канвас минимального размера
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = bounds.width;
  bufferCanvas.height = bounds.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  if (tapering && !translatedPoints[0]?.pressure) {

    createTaperingStroke({
      lineWidth,
      points: translatedPoints,
      shapeId,
    }, bufferCtx, brushSettings);

  } else {

    createPressureStroke({
      points: translatedPoints, 
      lineWidth,
    }, bufferCtx, brushSettings)

  }

  bufferCtx.globalCompositeOperation = 'destination-in';

  const patternCanvas = document.createElement('canvas');
  patternCanvas.width = texture.width * textureScale;
  patternCanvas.height = texture.height * textureScale;
  const patternCtx = patternCanvas.getContext('2d');
  
  patternCtx.scale(textureScale, textureScale);
  patternCtx.drawImage(texture, 0, 0);
  
  const pattern = bufferCtx.createPattern(patternCanvas, 'repeat');
  bufferCtx.fillStyle = pattern;

  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  // Применяем цвет
  bufferCtx.globalAlpha = brushSettings.opacity * textureOpacity;
  bufferCtx.globalCompositeOperation = 'source-in';

  if (transparentEdges 
    && (opacityOnStart < 1 || opacityOnEnd < 1)
    && calculateDistance(translatedPoints[0], translatedPoints[translatedPoints.length - 1]) > 15
    ) {
      createFade({
        color,
        gradientColor,
        points,
      }, bufferCtx, brushSettings)
  } else {

    fillGradient(bufferCtx, translatedPoints, color, gradientColor, lineWidth)

  }

  context.drawImage(
    bufferCanvas, 
    0, 0, bounds.width, bounds.height,
    bounds.x, bounds.y, bounds.width, bounds.height
  );
}