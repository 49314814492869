import React from 'react';
import "./RicRoll.css";

function RicRoll({ videoId }) {
  return (
    <div className="ricroll-container">
      <iframe
        src={`https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="video"
      ></iframe>
    </div>
    
  );
}

export default RicRoll;
