import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 

const defaultBrushSettings = brushDefaults.bristle || {};

export async function drawBristleStroke({
  color,
  gradientColor,
  lineWidth,
  points,
  time,
  sets = {},
}, context) {

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  const { 
    opacity = 0.5,
    wobble = 0.05,
  } = brushSettings;

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  
  let bristleColor;
  if (gradientColor) {
    bristleColor = chroma('black').alpha(opacity / 2).hex();
  } else {
    bristleColor = chromaColor.alpha(opacity / 2).hex();
  }

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  let bristleCount = 250;
  let bristleWidth = lineWidth / 15;
  let wobbleAmount = lineWidth * wobble;

  const randomnessFactor = 0.3; // Контролирует степень случайности (0-1)
  const offsets = generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor);

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  offsets.forEach(offset => {
    drawStroke({
      points,
      lineWidth: bristleWidth, // Уменьшаем толщину каждой линии
      mainLineWidth: lineWidth,
      color: bristleColor,
      time,
      offset,
      wobbleAmount,
    }, bufferCtx);
  });

  if (gradientColor) {
    await fillGradient(bufferCtx, points, color, gradientColor, lineWidth)
    context.globalAlpha = 1;
  } else {
    context.globalAlpha = originalAlpha;
  }

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);
  context.globalAlpha = 1; // Возвр
}


// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

// Функция для генерации отступов ворсинок
function generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor) {
  const baseRadius = lineWidth * 0.5 * 0.9;
  let offsets = [];

  for (let i = 0; i < bristleCount; i++) {
    const angle = seededRandom(time + i) * Math.PI * 2;
    const distance = seededRandom(time + i + 100) * baseRadius;

    const x = Math.cos(angle) * distance;
    const y = Math.sin(angle) * distance;

    const randX = (seededRandom(time + i + 200) - 0.5) * 2 * randomnessFactor * baseRadius;
    const randY = (seededRandom(time + i + 300) - 0.5) * 2 * randomnessFactor * baseRadius;

    offsets.push({ 
      x: x + randX, 
      y: y + randY,
      distance: Math.sqrt((x + randX) ** 2 + (y + randY) ** 2)  // Добавляем расстояние от центра
    });
  }

  // Фильтруем ворсинки в центре
  const innerThreshold = baseRadius * 2/3;
  const innerBristles = offsets.filter(offset => offset.distance < innerThreshold);
  const outerBristles = offsets.filter(offset => offset.distance >= innerThreshold);

  // Оставляем только половину внутренних ворсинок
  const keptInnerBristles = innerBristles
    .sort(() => seededRandom(time + 400) - 0.5)  // Случайная сортировка
    .slice(0, Math.ceil(innerBristles.length * 0.5));

  // Объединяем отфильтрованные внутренние ворсинки с внешними
  offsets = [...keptInnerBristles, ...outerBristles];

  // Добавляем центральную ворсинку
  offsets.push({ x: 0, y: 0, distance: 0 });

  return offsets;
}

function drawStroke({
  points,
  lineWidth,
  mainLineWidth,
  color,
  offset = { x: 0, y: 0 },
  plusWidth = 0,
  wobbleAmount,
  time,
}, context) {

  const stroke = {
    points, 
    lineWidth, 
    mainLineWidth, 
    color, 
    offset, 
    plusWidth, 
    wobbleAmount,
    time,
  }
 
  if (points.length === 1) {
    return drawPoint(stroke, context);
  } else {
    return drawPlainStroke(stroke, context);
  } 
}


function drawPlainStroke ({
    points, lineWidth, mainLineWidth, color, offset, plusWidth, wobbleAmount, time,
},context) {

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  context.beginPath();
  context.moveTo(points[0][0] + offset.x, points[0][1] + offset.y);

  const offsetSeed = offset.x * offset.y;

  for (let i = 1; i < points.length; i++) {

    const wobbleX = (seededRandom(time + offsetSeed + i) - 0.5) * wobbleAmount;
    const wobbleY = (seededRandom(time + offsetSeed + i + 100) - 0.5) * wobbleAmount;

    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0] + wobbleX) / 2,
      (nextPoint[1] + currentPoint[1] + wobbleY) / 2
    ];
    context.quadraticCurveTo(nextPoint[0] + offset.x, nextPoint[1] + offset.y, midPoint[0] + offset.x, midPoint[1] + offset.y);
  }
  const lastPoint = points[points.length - 1]
  context.lineTo(
    lastPoint[0] + offset.x, 
    lastPoint[1] + offset.y,
    );
  context.stroke();
  
}

function drawPoint ({
  points, lineWidth, color, offset, plusWidth,
}, context) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  context.beginPath();
  context.arc(
    point[0] + offset.x, 
    point[1] + offset.y, 
    (lineWidth / 2) + plusWidth, 
    0, 
    Math.PI * 2
    );
  context.fillStyle = color;
  context.fill();
}

