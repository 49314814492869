// src/store/infoSlice.js
import pako from 'pako';
import i18n from '../locales/i18n';

const createInfoSlice = (set, get, ref) => {

  const { t } = i18n;

  ref.info = {
    chat: [],
  };
  ref.info.methods = {
    serverTimeFun: () =>{ 
      return Date.now() - ref.timeDifference;
    },

    topMenuLayerTitle: () => {
      const layerName = ref.convy.methods.getActiveLayer().info.name; 
      return ` ${layerName} `
    },

    topMenuTitle: () =>{
      return ref.info.methods.gameWordFun();
    },

    gameWordFun: () => {

 
      const game = ref.info.game;
      const canDraw = get().canDraw;
  
      if (game?.board || game?.settings?.accessory) { 
        return (game.settings.title || '❔') + '  ⚙️'; 
      }
  
      if (ref.telegram.moderMode) {
        return  'Ⓜ️ ' + (game?.results ? game?.word : t('spectator.guess_the_word')) + '  ⚙️';
      } else if (game?.results || canDraw) {
        return game?.word + '  ⚙️';
      } else {
        return t('spectator.guess_the_word') + '  ⚙️';
      }
    },

    isAppleDevice: ()=>{
      const userAgent = navigator.userAgent.toLowerCase();
      const iosDevices = /iphone|ipad|ipod|macintosh|mac os/;
      return iosDevices.test(userAgent);
    }
  };


  const checkIfCanDraw = (game, user) => {

    if (ref.telegram.testCanvas) { return true; }

    let canDraw = false;

    if (user.moder && user.moderEditing === ref.telegram.gameId) {
      ref.telegram.moderMode = true;
    }

    if (game.notFound) {
      canDraw = false;
    } else if (game.board) {
      canDraw = true;
    } else if (game.mode === 'sprint') {
      if (game.word) {
        canDraw = true;
      } else {
        canDraw = false;
      }
    } else if (game.user_key && String(game.user_key) === String(ref.telegram.telegramUserId)) {
      canDraw = true;
    } else if (game.shared?.includes(String(ref.telegram.telegramUserId))) {
      canDraw = true;
    
    } else if (user.moder && ref.telegram.moderMode) {
      ref.telegram.activeUserId = game.user_key;
      ref.telegram.moderatorId = ref.telegram.telegramUserId;
      canDraw = true;
    }
    return canDraw;

  }

  return {
    isLoadingGame: false,
    gameIsLoaded: false,
    gameInfo: null,
    canDraw: false,
    gameError: null,
  
    // Функция для загрузки данных игры через сокет
    loadGameData: async () => {
  
      const {
        gameId, 
        gameParam, 
        telegramUserId, 
      } = ref.telegram;
  
      const { socket, socketStatus } = get();
      if (!socket || socketStatus !== 'connected') {
        console.warn('Socket is not connected, cannot load game data.');
        return;
      }

      set({ isLoadingGame: true });

      window.logPerformance?.('Game data loading STARTED');
  
      socket.emit('loadGame', { gameId, gameParam, userId: telegramUserId, compression: true }, async (loaded = {}) => {
        window.logPerformance?.('Game data LOADED');
        
        // Если данные сжаты, распаковываем их
        if (loaded.compressed) {
          try {
            const compressionTime = loaded.compressionTime;
            const uint8Array = new Uint8Array(loaded.data);
            const decompressed = pako.ungzip(uint8Array, { to: 'string' });
            loaded = JSON.parse(decompressed);
            window.logPerformance?.(`Game data DECOMPRESSED. Compression time: ${compressionTime} ms`);
          } catch (error) {
            set({ gameError: error, isLoadingGame: false });
            return;
          }
        }
        
        const { 
          game, 
          user = { empty: true}, 
          group, 
          bot, 
          prefs,
         } = loaded;
         
        if (!game) {
          set({ isLoadingGame: false });
          return;
        }
        
        if (game.error) {
          set({ gameError: game.error, isLoadingGame: false });
          return;
        }
        
        // Простейшая логика определения возможности рисования
        let canDraw = checkIfCanDraw(game, user);
        // if (game.board) {
        //   canDraw = true;
        // } else if (game.user_key && String(game.user_key) === String(telegramUserId)) {
        //   canDraw = true;
        // }
  
        Object.assign(ref.info, {
          game,
          user,
          group,
          bot,
          prefs,
          canDraw,
        })

        get().applyPremium();

        if (user.fullscreen && !game.puzzle) {
          try { window.Telegram.WebApp.requestFullscreen(); } catch (e) {}
        }

        set({ 
          gameInfo: game, 
          isLoadingGame: false, 
          gameIsLoaded: true, 
          canDraw,
          // canDraw: false,
         });
        
        i18n.changeLanguage(game?.locale || 'ru');
        window.logPerformance?.('Game data APPLIED')
  
      });
    },
  }
};

export default createInfoSlice;
