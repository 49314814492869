// SettingsOverlay.js

import React, { useState, useRef } from 'react';

import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

import { HexAlphaColorPicker } from "react-colorful";
import { FaWrench, FaCog, FaTabletAlt, FaRegClock } from 'react-icons/fa';

import './SettingsOverlay.css'; // Подключаем стили
import { SquareIcon, HorizontalIcon, VerticalIcon } from './OrientationIcons';


function SettingsOverlay({ store }) {
  
  const { t } = useTranslation();

  useStore(state=>state.render);

  const { ref } = store;
  const { telegram,menu, drawing, info, work, premium } = ref;
  const { game, user, group } = info;

  const {

    gameId,
    telegramUserId,

  } = telegram;

  const {
    premiumFeatures
  } = premium;

  const {
    saveGameSets, 
    saveUserSets, 

  } = work.methods;

  const {
    isAppleDevice,

  } = info.methods;

  const {

    openMenu, closeMenu,
    showPopupMessage,
    showTemporaryHint,
    delayPremiumMenu,

  } = menu.methods;


  const [userColorView, setUserColorView] = useState(false);
  const [viewedColor, setViewedColor] = useState(store.color);
  const setParamRef = useRef(null);

  let gameSettings = [
    { key: 'highRes', label: t('settings.high_res') },
    // { key: 'rotate', label: t('settings.rotate') },
  ];
  if (['horizontal', 'vertical'].includes(game?.settings?.orientation)) {gameSettings = gameSettings.filter(set=>set.key !== 'highRes')}

  const userSettings = [
    { key: 'fullscreen', label: t('settings.fullscreen') },
    { key: 'fingerOff', label: t('settings.finger_off') },
    { key: 'vibro', label: t('settings.vibro') },
    { key: 'cachePng', label: t('settings.cache_png') },
    { key: 'proCanvas', label: t('settings.pro_canvas') },
    { key: 'moreCache', label: t('settings.more_cache') },
    // { key: 'straightLine', label: t('settings.straight_line') },
    { key: 'tapToUndo', label: t('settings.tap_to_undo') },
    { key: 'hold2transform', label: t('settings.hold_2_transform') },
    { key: 'canvasRotation', label: t('settings.canvas_rotation') },
    { key: 'smartShape', label: t('settings.smart_shape') },
    { key: 'solidBrushIndicator', label: t('settings.solid_brush_indicator') },
    // { key: 'openBrowser', label: t('settings.open_browser_on_start') },
    { key: 'showDebug', label: 'Show Debug Info' }
  ];

  if (user.moder || user.premiumOff) {
    userSettings.push({ key: 'premiumOff', label: t('settings.premium_off') })
  }
  if (['1431457632', '141015727'].includes(telegramUserId)) {
    userSettings.push({ key: 'moder', label: 'Crocomoto' })
    userSettings.push({ key: 'debugMode', label: 'Debug Mode' })
    userSettings.push({ key: 'iphone', label: t('settings.iphone_fix') })
    userSettings.push({ key: 'testPressure', label: t('settings.test_pressure') })
  }

  const handleUserSetCheckbox = (key) => {
    const newValue = !user[key];
    saveUserSets({ [key]: newValue })

    if (key === 'fullscreen') {
      // const notMiniApp = !window.Telegram?.WebApp?.platform || window.Telegram.WebApp.platform === 'unknown';
      if (newValue) {
        try { window.Telegram.WebApp.requestFullscreen(); } catch (e) {}
        try { document.documentElement.requestFullscreen().catch(()=>{}); } catch (e) {}
        // try { if (notMiniApp) {document.documentElement.requestFullscreen();} } catch (e) {}
      } else {
        try { window.Telegram.WebApp.exitFullscreen(); } catch (e) {}
        try { if (document.fullscreenElement) document.exitFullscreen().catch(()=>{}); } catch (e) {}
      }
      setTimeout(() => { menu.methods.render(); }, 50);
    }
  };

  const startColorPick = (param) => {
    setParamRef.current = param;
    setViewedColor(user[param] || store.color);
    setUserColorView(true);
  };
  
  const onColorPicking = (userColor) =>{
    setViewedColor(userColor);
  }

  const endColorPick = () => {
    const param = setParamRef.current;
    saveUserSets({ [param]: viewedColor });
    setUserColorView(false);
  };

  const handleDeviceInfo = () =>{

    const userAgent = navigator.userAgent;
    const popupParams = {
      title: t('settings.your_device'),
      message: userAgent,
    };
    popupParams.callback = (buttonId) => {}
    showPopupMessage(popupParams)

  }



  return (
    <>
      <div className="overlay-background" onClick={() => closeMenu()}></div>
      <div className="settings-menu add-scroll touch-active" style = {{opacity: userColorView ? 0 : 1}}>
        <span className="settings-title">{t('settings.title')}</span>
        {userSettings.map((setting) => (
          <div key={setting.key} className="settings-item">
            <label className="settings-label" style = {{
              opacity: user[setting.key] ? 1 : 0.5
            }}>
              <input
                type="checkbox"
                checked={user[setting.key]}
                onChange={() => handleUserSetCheckbox(setting.key)}
              />
              <span>{setting.label}</span>
            </label>
          </div>
        ))}

        <div className="settings-item" onClick={handleDeviceInfo}>
          <label className="settings-label">
          <FaTabletAlt className="settings-label-icon"/>
            <span>{t('settings.device')}</span>
          </label>
        </div>
        <div className="settings-item" onClick={()=>{openMenu('lag', {layer: true})}}>
          <label className="settings-label">
          <FaRegClock className="settings-label-icon"/>
            <span>{t('settings.lags')}</span>
          </label>
        </div>

        <div className="settings-item" onClick={()=>{startColorPick('startColor')}}>
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span  className="settings-color-indicator" style={{
                backgroundColor: user.startColor || '#000000FF',
              }}
            ></span>
            <span>{t('settings.start_color')}</span>
          </label>
        </div>

        <div className="settings-item" onClick={()=>{startColorPick('startBgColor')}}>
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span  className="settings-color-indicator" style={{
                backgroundColor: user.startBgColor || '#000000FF',
              }}
            ></span>
            <span>{t('settings.start_bg_color')}</span>
          </label>
        </div>

        <button className="wide-button" onClick={() => {
          closeMenu();
          delayPremiumMenu('menu');
        }}>
          {t('settings.premium')}
        </button>

        <button className="gray-button" onClick={()=>{openMenu('drawing', true)}}>
          <FaWrench style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('drawing.title')}
        </button>

        <button className="gray-button" onClick={() => closeMenu()}>
          {t('settings.back')}
        </button>
      </div>

      { userColorView && (
          <>
            <div className="overlay-background" style={{zIndex: 1400}} onClick={endColorPick}></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker 
                color={viewedColor} 
                onChange={onColorPicking}
              />
              <button className="wide-button" onClick={endColorPick} style={{marginTop: '10px'}}>
                {t('settings.set_color')}
              </button>
            </div>
          </>
      ) }

    </>
  );
}

export default SettingsOverlay;
