// src/components/board/Board.jsx

import useStore from '../../store';
import './Board.css';
import useConvy from '../../hooks/useConvy';
import ControlPanel from './ControlPanel';

import ZoomFactor from './indicators/ZoomFactor';
import ConnectionIndicator from './indicators/ConnectionIndicator'; 
import ConvyBrushIndicator from './indicators/ConvyBrushIndicator'; 
import ChatOverlay from './indicators/ChatOverlay'; 
import DebugMenu from './indicators/DebugMenu';
import Reference from './reference/Reference';
import LayerThumbnail from '../menus/layer/LayerThumbnail'; 
import interfaceOffset from './tools/interfaceOffset';


const Board = () => {

  useStore((state) => state.render);
  useStore((state) => state.lineWidth);
  useStore((state) => state.keyPressed);

  const panOffset = useStore((state) => state.panOffset);
  const zoomFactor = useStore((state) => state.zoomFactor);
  const rotation = useStore((state) => state.rotation);
  const showReference = useStore((state) => state.showReference);

  const store = useStore.getState();
  const { activeTool, effectType } = store;

  const { telegram, menu, convy, brush, info, drawing, } = store.ref;

  const {
    topMenuLayerTitle,
    topMenuTitle,
  } = info.methods || {};

  const {
    openMenu,
  } = menu.methods || {};

  const {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleContextMenu,
  } = drawing.methods || {};

  const {
    ifIndicateBrushFun,
    visibleLineWidthFun,
  } = brush.methods || {};

  const {
    mouse, keyboard,
  } = convy;
  const {
    hotMouse, fast
  } = brush;

  const main = convy.main;
  const temp = convy.temp;

  useConvy();

  const { controlButtonsStyle, drawingAreaStyle } = interfaceOffset(store);
  const activeLayer = convy.methods.getActiveLayer();

  return (
    <div 
    className="board-container"
    style={drawingAreaStyle}
    >
      {(info?.user?.showDebug || info?.user?.debugMode) && (
        <DebugMenu store={ store } />
      )}

      {info.game && zoomFactor <= 1 && ((activeLayer && Object.keys(convy.layerInfo).length > 1) ? (
        <div className="layername" 
            {...menu.methods.createBetterClick({
              onClick: ()=>{
                telegram.moderMode ? 
                openMenu('drawing') :
                openMenu('word');
              }
            })}
            style={{  cursor:  'pointer', }}
          >
          <LayerThumbnail 
            layerId={convy.methods.getActiveLayer().id}
            store={store}
            style={{maxHeight: '30px', transform: `translate(0, -5%)`}}
            onClick= {(e)=>{e.stopPropagation(); openMenu('layer')}}
          />
          {topMenuLayerTitle()}
        </div>
      ) : (
          <div className="word" 
          {...menu.methods.createBetterClick({
            onClick: ()=>{
              telegram.moderMode ? 
              openMenu('drawing') :
              openMenu('word');
            }
          })}
            style={{  cursor:  'pointer', }}
          >
          {topMenuTitle()}
        </div>
      ))}

      <ZoomFactor
        store={store}
      />
      <ConnectionIndicator 
        store={store}
      /> 
      <ChatOverlay
        store={store}
      /> 
      <ConvyBrushIndicator 
        store={store}
      />
      
      <div className='backing-canvas'
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onContextMenu={handleContextMenu}
        style = {{
          width: window.innerWidth,
          height: `${(convy.main.ref?.bottom || 420) + 200 }px`,
        }}
      />
      
      <div className="canvas-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onContextMenu={handleContextMenu}
        style={{
          left: '50%',       // Горизонтальное центрирование
          transform: `translateX(-50%) translate(${panOffset.x}px, ${panOffset.y + convy.styleDimensions.height / 2}px) scale(${zoomFactor}) rotate(${rotation}deg)`,
        }}
      >


        <canvas
          key={main.id}
          width={main.width}
          height={main.height}
          ref={(el) => {
            if (el && !main.ref) {
              main.ref = el;
              main.ctx = el.getContext('2d');
              convy.scale = (el.clientWidth / el.width) / zoomFactor;

              setTimeout(() => {
                store.setupCanvasSizes();
                drawing.methods.redraw?.().then(async ()=>{
                  useStore.setState({ boardReady: true });
                  // setTimeout(() => { drawing.methods.redraw(); }, 1000);
                });
              }, 10);
       
            }
          }}
          className="main-canvas"
          style={{
            width: `${convy.styleDimensions.width}px`,
            height: `${convy.styleDimensions.height}px`,
            boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.2)`,
          }}
        />
        <canvas
          key={temp.id}
          width={temp.width}
          height={temp.height}
          ref={(el) => {
            if (el && !temp.ref) {
              temp.ref = el;
              temp.ctx = el.getContext('2d');
            }
          }}
          className="temp-canvas"
          style={{
            width: `${convy.styleDimensions.width}px`,
            height: `${convy.styleDimensions.height}px`,
            cursor: 
                hotMouse.active ? 'default' :
                ifIndicateBrushFun() ? 
                  (visibleLineWidthFun() > 5 && !fast.isResizingBrush) ? 'none' : 'default' :
                
                keyboard.controlIsDown ? 'zoom-in' :
                keyboard.spaceIsDown ? 'grab' :
                activeTool === 'pipette' ? 'crosshair' :
                (activeTool === 'effect' && effectType === 'filler') ? 'crosshair' : 'default',
                // activeTool === 'filler' ? 'cell' : 'default',
          }}
        />
      </div>
      <div 
        className="controls" 
        style={ controlButtonsStyle }
      >
        <ControlPanel 
          store = {store}
        />
      </div>
      <div className="under-controls"/>

      {showReference && (
        <Reference store={store} />
        // <ReferenceWindow store={store} />
      )}
    </div>
  );
};

export default Board;
