// src/store/mountSlice.js
import { addLayerCache, loadAllLayersFromIDB, clearOldCachesFromIDB, createCanvasFromBuffer } from '../utils/caching/indexedDBcache';

function ensureHaveDefaultLayer (userStrokes) {
  let hasNewStrokes = false;
  let hasOldStrokes = false;
  
  for (let userId in userStrokes) {
    for (let stroke of userStrokes[userId]) {
      if (stroke.layerId) { hasNewStrokes = true; } 
      else { 
        hasOldStrokes = true;
        stroke.oldCanvas = true;
      }
      stroke.layerId = stroke.layerId || 'base';
    }
  }

  const isOldCanvas = hasOldStrokes && !hasNewStrokes;
  return isOldCanvas;
}


function applyUserGameSets ({ userGameSets, game, ref, telegramUserId, set}) {

  const setParams = {};
  
  if (userGameSets.brushColor) {
    setParams.color = userGameSets.brushColor;
    ref.brush.brushColor = userGameSets.brushColor;
  } else if (ref.info.user.startColor) {
    setParams.color = ref.info.user.startColor;
    ref.brush.brushColor = ref.info.user.startColor;
  }

  if (userGameSets.gradientColor) {
    setParams.gradientColor = userGameSets.gradientColor;
  }
  if (userGameSets.eraserColor) {
    ref.brush.eraserColor = userGameSets.eraserColor;
  }
  if (userGameSets.brushSize) {
    setParams.lineWidth = userGameSets.brushSize;
    ref.brush.brushSize = userGameSets.brushSize;
  } else if (ref.telegram.testCanvas) {
    setParams.lineWidth = 40;
    ref.brush.brushSize = 40;
  }
  if (userGameSets.eraserSize) {
    ref.brush.eraserSize = userGameSets.eraserSize;
  }

  if (ref.info.game.brushPreview) {

    ref.brush.methods.changeBrushType('preview');
    const previewParams = ref.info.game.brushPreview.preview;
    if (previewParams) {
      setParams.lineWidth = +previewParams.lineWidth || 40;
      setParams.color = previewParams.color;
      ref.brush.brushColor = previewParams.color;
      ref.info.game.settings.backgroundColor = previewParams.bgColor;
    }

  } else if (ref.info.user.brushType) {
    ref.brush.methods.changeBrushType(ref.info.user.brushType)
  }
  if (ref.info.user.eraserType) {
    ref.brush.methods.changeEraserType(ref.info.user.eraserType)
  }
  if (ref.info.user.effectType) {
    ref.brush.methods.changeEffectType(ref.info.user.effectType)
  }

  ref.convy.layerInfo = game.settings.layerInfo || ref.convy.layerInfo;
  ref.brush.lastColors = game.colors?.[telegramUserId] || [];
  set(setParams);

  // ref.work.methods.saveGameSets({ layerInfo: ref.convy.layerInfo })

  
}

const createMountSlice = (set, get, ref) => {

  ref.mount = {
    
  }

  ref.mount.methods = {

    addLayerCache,
    loadAllLayersFromIDB,
    clearOldCachesFromIDB,

    saveLayerToServer: async (saveData) =>{

      const layer = ref.convy.methods.getLayer(saveData.layerId);
      const saveDifficulty = layer.render?.saveDifficulty || 0;

      if (saveDifficulty < 500) { return; }

      const result = await ref.work.methods.hostActionPromise('saveLayer', saveData);

      const {    
        error, 
        layerId, 
        filePath,
        time
      } = result;

      if (error) { return; }
      const layerInfo = ref.convy.layerInfo[layerId];
      if (!layerInfo) { return; }
      layerInfo.cache = {
        time,
        filePath,
      }
      ref.work.methods.saveGameSets({ layerInfo: ref.convy.layerInfo })

    },

    defineWhatLayersToLoad: ()=>{
      
      // Получаем подготовленные штрихи для всех слоёв
      const strokesData = ref.drawing.methods.prepareStrokesForAllLayers();
      const layersToLoad = [];

      for (let layerId in strokesData) {
        const { difficulty } = strokesData[layerId];
        if (difficulty > 250) {
          layersToLoad.push(layerId);
        }
      }
      return layersToLoad;
    },

    loadLayersFromServer: async ()=>{

      const layersArr = ref.mount.methods.defineWhatLayersToLoad();
      if (!layersArr[0]) { return; }

      window.logPerformance?.(`🗃 Server cache download started`)

      const { error, loadedLayers } = await ref.work.methods.hostActionPromise('loadLayers', {
        layersArr,
      });

      if (error) { return; }

      const layerCaches = ref.history.layerImageCache;

      let loadedCount = 0;


      for (let layerId in loadedLayers) {
        try {
          const layerData = loadedLayers[layerId];
          const offscreenCanvas = await createCanvasFromBuffer(layerData.bufferWebp);
          if (!layerCaches[layerId]) {
            layerCaches[layerId] = new Map();
          }
          layerCaches[layerId].set(layerData.time, {
            time: Date.now(),
            canvas: offscreenCanvas,
          });
          loadedCount ++;
          window.croco?.log(`⬇️ Кеш для слоя ${layerId} загружен с сервера успешно`);

        } catch (e) {
          window.croco?.log(`❌ Ошибка загрузки кеша слоя${layerId} с сервера`, e);
        }

      }

      window.logPerformance?.(`🗃 ${loadedCount} server caches downloaded`)


    }
  }


  return {

    strokesApplied: false,
    readyToShow: false,

    // Функция инициализации слушателей сокета для событий «strokes» и «render»
    initHistory: (socket) => {
  
      // Обработчик события 'strokes' – обновляет историю штрихов для нужного пользователя
      const handleStrokes = ({ userId: companionUserId, strokes, length, author }) => {
        length = length || strokes.length;
        const currentStrokes = ref.history.userStrokes;
        const companionStrokes = currentStrokes[companionUserId] || [];
  
        // Удаляем флаг rendered у входящих штрихов
        strokes.forEach(stroke => { delete stroke.rendered; });
  
        const cutStrokes = companionStrokes.slice(length - strokes.length);
  
        cutStrokes.forEach(cutStroke => {
          strokes.forEach(stroke => {
            if (stroke.time === cutStroke.time) {
              stroke.rendered = cutStroke.rendered;
            }
          });
        });
  
        strokes.forEach(stroke => {
          if (!stroke.rendered) {
            const layer = ref.convy.methods.getLayer(stroke.layerId || 'base');
            if (layer) { layer.ready = false; }
          }
        });
  
        const updatedStrokes = companionStrokes.slice(0, length - strokes.length).concat(strokes);
        currentStrokes[companionUserId] = updatedStrokes;
  
        ref.drawing.methods.redraw();
  
        const game = ref.info.game;
  
        const lastStroke = strokes[strokes.length - 1];
  
        if (game?.board && !lastStroke?.cancelled) {
          const brushName = lastStroke.effect || lastStroke.brush || lastStroke.type;
          const color = lastStroke.effect ? '#FFFFFF77' : (lastStroke.color || '#FFFFFF77');
          ref.info.chat?.push({
            author,
            color,
            text: brushName || '?',
          });
        }
  
      };
  
      // Обработчик события 'render'
      const handleRender = async (pref, callback) => {
        const result = await ref.drawing.methods.renderForServer?.(pref);
        callback?.(result);
      };
  
      const handleChatMessage = (message) => {
        const info = ref.info;
        info.chat = [...info.chat, message]
      };
  
      // Обработчик нового слова
      const handleNewWord = ({word, hint, definition}) => {
        const game = ref.info.game;
        game.word = word;
        game.hint = hint;
        ref.menu.methods.render();
      };
  
      // Обработчик нового определения
      const handleDefinitions = ({definitions}) => {
        const game = ref.info.game;
        game.definitions = definitions;
        ref.menu.methods.render();
      };
  
      // Обработчик нового слова
      const handleNewHint = ({hint}) => {
        const game = ref.info.game;
        game.hint = hint;
        ref.menu.methods.render();
      };
  
      // Обработчик Bru (поделиться кистью)
      const handleShared = ({shared}) => {
        const game = ref.info.game;
        game.shared = shared;
        ref.menu.methods.render();
      };
  
      // Обработчик завершения игры
      const handleFinish = (results) => {
        const game = ref.info.game;
        game.results = results;
        window.croco?.haptic?.('error');
        ref.menu.methods.render();
      };
  
      // Обработчик обновления превью
      const handlePreview = (lastRenderedStrokeTime) => {
        const game = ref.info.game;
        game.lastRenderedStrokeTime = lastRenderedStrokeTime;
        ref.connection.lastPreviewTime = Date.now();
        ref.menu.methods.render();
      };

      const handlePuzzleUpdate = (data) => {
        window.croco?.log('🧩 Puzzle Update Received:', data);
        const { action, pieceId, userId, author } = data || {};

        const myself = userId && userId === ref.telegram.activeUserId;

        if (!myself) {
          
          if (action === 'piecePlaced') {
            ref.info.game.users[userId] ??= { id: userId, first_name: author }
            ref.puzzle?.methods?.placePieceFromRemote?.(pieceId, userId);
          } else if (action === 'reservePiece') {
            ref.puzzle?.methods?.reserveFromRemote?.(pieceId);
          } else if (action === 'releasePiece') {
            ref.puzzle?.methods?.releaseFromRemote?.(pieceId);
          } else if (action === 'userJoined') {
            ref.info.chat?.push({
              message: window.t('puzzle.user_joined', { author }),
              untilTime: Date.now() + 10000,
            });
            ref.puzzle?.methods?.releaseFromRemote?.(pieceId);
          }
          
        } else {
          // if (action === 'userJoined') {
          //   ref.info.chat?.push({
          //     message: window.t('puzzle.user_joined', { author }),
          //     untilTime: Date.now() + 10000,
          //   });
          //   ref.puzzle?.methods?.releaseFromRemote?.(pieceId);
          // }

          // if (action === 'piecePlaced') {
          //   ref.info.game.users[userId] ??= { id: userId, first_name: author }
          //   ref.puzzle?.methods?.placePieceFromRemote?.(pieceId, userId);
          // } else if (action === 'reservePiece') {
          //   ref.puzzle?.methods?.reserveFromRemote?.(pieceId);
          // } else if (action === 'releasePiece') {
          //   ref.puzzle?.methods?.releaseFromRemote?.(pieceId);
          // }
        }
  
    
        
        // Add handlers for other puzzle actions if needed (e.g., 'puzzleReset')
      }
  
      socket.on('strokes', handleStrokes);
      socket.on('render', handleRender);
  
      socket.on('chat', handleChatMessage);
      socket.on('word', handleNewWord);
      socket.on('definitions', handleDefinitions);
      socket.on('hint', handleNewHint);
      socket.on('shared', handleShared);
      socket.on('preview', handlePreview);
      socket.on('finish', handleFinish);

      socket.on('puzzleUpdate', handlePuzzleUpdate);
  
      // Сохраняем функцию очистки слушателей в ref (если понадобится при размонтировании)
      ref.mountHistoryCleanup = () => {
        socket.off('strokes', handleStrokes);
        socket.off('render', handleRender);
  
        socket.off('chat', handleChatMessage);
        socket.off('word', handleNewWord);
        socket.off('definitions', handleDefinitions);
        socket.off('hint', handleNewHint);
        socket.off('shared', handleShared);
        socket.off('preview', handlePreview);
        socket.off('finish', handleFinish);
      };
    },
  
    // Функция загрузки истории штрихов из полученных данных игры
    applyHistory: async () => {
  
      const game = ref.info.game;
      if (!game || game.error) return;
  
      const currentStrokes = ref.history.userStrokes;
      const telegramUserId = ref.telegram.telegramUserId;
      const activeUserId = ref.telegram.activeUserId;
  
      currentStrokes[activeUserId] = currentStrokes[activeUserId] || [];
  
      const localHistory = currentStrokes[activeUserId] || [];
      const strokesFromGame = game.strokes || {};

      strokesFromGame[activeUserId] = strokesFromGame[activeUserId] || [];
      const myStrokes = strokesFromGame[activeUserId];
  
      if (localHistory.length >= myStrokes.length) {
        strokesFromGame[activeUserId] = localHistory;
      } 
  
      Object.assign(ref.history.userStrokes, strokesFromGame);
      ref.telegram.isOldCanvas = ensureHaveDefaultLayer (ref.history.userStrokes);
      if (ref.telegram.isOldCanvas) {
        try {
          ref.convy.layerInfo.base.edgeScale = 1;
          ref.convy.layerInfo.base.oldCanvas = true;
        } catch (e) {}
      }

      const userGameSets = ref.work.methods.getUserGameSets();
      applyUserGameSets ({ userGameSets, game, ref, telegramUserId, set});

      ref.history.playerCount = Object.keys(ref.history.userStrokes).length;
      ref.history.loadTime = ref.info.methods.serverTimeFun();
      
      // MOUNT CACHE
      // IDB cache

      if (ref.info.user.cachePng && !ref.telegram.cacheOff) {

        await ref.mount.methods.loadAllLayersFromIDB (ref.telegram.gameId, ref.convy.layerInfo, ref.history.layerImageCache);
        ref.mount.methods.clearOldCachesFromIDB();

        // Server cache
        await ref.mount.methods.loadLayersFromServer();
      } else {
        window.logPerformance?.(`✖️ Cashe is disabled`)
      }

      set({ strokesApplied: true, });

    },
    
  }
};

export default createMountSlice;
