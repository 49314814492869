const { brushDefaults } = require('./helpers/brushLoader');
const defaultBrushSettings = brushDefaults.filler;

// createFill – итеративная реализация алгоритма заливки
export function createFill(stroke, context) {
    const { sets = {} } = stroke;
    const brushSettings = Object.assign({}, defaultBrushSettings, sets);
    const { tolerance = 0, antialiasing = 0.5, eatEdges } = brushSettings;

    let startX = Math.round(stroke.x);
    let startY = Math.round(stroke.y);
    let fillColor = stroke.color;

    const canvas = context.canvas;
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const width = imageData.width;
    const height = imageData.height;

    // Добавим проверку, что стартовая точка в пределах канваса
    if (startX < 0 || startX >= width || startY < 0 || startY >= height) {
        console.warn("Fill start point is outside canvas bounds.");
        return;
    }

    const fillColorRgb = hexToRGBA(fillColor);
    let edgeFillColor = makeTransparentColor(fillColorRgb, 0.95);

    const targetColor = getColorAtPixel(imageData, startX, startY);

    if (colorsMatch(targetColor, fillColorRgb, 0)) {
         return; // Раскомментируйте, если заливка точно такого же цвета не нужна
    }

    const fillHistory = new Set();
    const edgeHistory = new Set();
    // Используем стек (Last-In, First-Out)
    const pixelsToProcess = [{ x: startX, y: startY }];

    // Проверяем, можно ли вообще начать заливку в стартовой точке
    if (!colorsMatch(getColorAtPixel(imageData, startX, startY), targetColor, tolerance)) {
        // Если стартовый пиксель не соответствует targetColor с учетом tolerance, заливать нечего.
        // Возможно, стоит применить обработку края (antialiasing/eatEdges) к стартовому пикселю?
        // Или просто выйти. Выходим, чтобы избежать неожиданного поведения.
        console.warn("Start pixel does not match target color within tolerance.");
        return;
    }

    while (pixelsToProcess.length > 0) {
        const { x, y } = pixelsToProcess.pop();

        const currentSeedPos = (y * width + x) * 4;
        if (fillHistory.has(currentSeedPos) || edgeHistory.has(currentSeedPos) || !colorsMatch(getColorAtPixel(imageData, x, y), targetColor, tolerance)) {
             continue;
        }

        if (x < 0 || x >= width || y < 0 || y >= height) continue;

        // Находим реальный левый и правый край СЕГМЕНТА, содержащего {x, y}
        let leftX = x;
        let rightX = x;

        // Сканирование влево (включая стартовый пиксель x)
        while (
            leftX >= 0 &&
            !fillHistory.has((y * width + leftX) * 4) && // Проверяем, не залит ли УЖЕ
            !edgeHistory.has((y * width + leftX) * 4) && // И не является ли краем
            colorsMatch(getColorAtPixel(imageData, leftX, y), targetColor, tolerance)
        ) {
            leftX--;
        }
        leftX++; // Вернуться к первому пикселю, который соответствует условиям

        rightX = x + 1;
        while (
            rightX < width &&
            !fillHistory.has((y * width + rightX) * 4) && // Проверяем, не залит ли УЖЕ
            !edgeHistory.has((y * width + rightX) * 4) && // И не является ли краем
            colorsMatch(getColorAtPixel(imageData, rightX, y), targetColor, tolerance)
        ) {
            rightX++;
        }
        rightX--; // Вернуться к последнему пикселю, который соответствует условиям

        // Заполнение найденной линии [leftX, rightX]
        for (let i = leftX; i <= rightX; i++) {
            const currentPos = (y * width + i) * 4;
            // Повторная проверка на fillHistory на всякий случай, хотя сканирование должно было это учесть
            if (!fillHistory.has(currentPos)) {
                const baseColor = getColorAtPixel(imageData, i, y); // Берем ТЕКУЩИЙ цвет пикселя для смешивания
                const blendedColor = blendColors(baseColor, fillColorRgb); // Смешиваем заливку с текущим цветом
                colorPixel(imageData, currentPos, blendedColor);
                fillHistory.add(currentPos);
            }
        }

        // Обработка краёв текущей ЗАПОЛНЕННОЙ линии [leftX, rightX]
        processEdge(leftX - 1, y);
        processEdge(rightX + 1, y);

        // Проверка строк выше и ниже относительно ЗАПОЛНЕННОЙ линии [leftX, rightX]
        checkLine(y - 1, leftX, rightX);
        checkLine(y + 1, leftX, rightX);
    }

    context.putImageData(imageData, 0, 0);

    // Функция для обработки края линии
    function processEdge(x, y) {
        if (x < 0 || x >= width || y < 0 || y >= height) return;
        const pos = (y * width + x) * 4;
        // Обрабатываем как край, только если он еще не был залит или обработан как край
        if (!fillHistory.has(pos) && !edgeHistory.has(pos)) {
            const pixelColor = getColorAtPixel(imageData, x, y);
            // Краем считаем пиксель, НЕ соответствующий целевому цвету
            if (!colorsMatch(pixelColor, targetColor, tolerance)) {
                if (eatEdges) {
                    const newEdgeColor = blendColors(pixelColor, edgeFillColor); // Смешиваем с полупрозрачной заливкой
                    colorPixel(imageData, pos, newEdgeColor);
                } else { // Antialiasing
                    const difference = colorDifference(pixelColor, targetColor);
                    // Убедимся, что antialiasing не 0, чтобы избежать деления на ноль
                    const effectiveAntialiasing = Math.max(antialiasing, 0.01);
                    const blendFactor = Math.max(0, (tolerance * 2.55 - difference) / (tolerance * 2.55)); // Нормализуем разницу относительно tolerance

                    const maxDiff = 255 * (tolerance / 100) * 0.5; // Используем ту же логику, что и в colorsMatch
                    const clampedDiff = Math.max(0, difference - maxDiff); // Разница сверх порога tolerance
                    // Пусть antialiasing определяет "ширину" градиента за порогом.
                    // Если antialiasing = 0.5, градиент будет ~ такой же ширины, как tolerance.
                    const gradientWidth = maxDiff * 2 * effectiveAntialiasing + 1; // +1 чтобы избежать деления на 0
                    const blendFactorAA = Math.max(0, 1.0 - clampedDiff / gradientWidth);


                    const newEdgeColor = blendColorsWithFactor(pixelColor, fillColorRgb, blendFactorAA);
                    colorPixel(imageData, pos, newEdgeColor);
                }
                edgeHistory.add(pos); // Помечаем как обработанный край
            }
        }
    }

    function checkLine(adjacentY, scanLeftX, scanRightX) {
        if (adjacentY < 0 || adjacentY >= height) return;

        let currentX = scanLeftX;
        while (currentX <= scanRightX) {
            const currentPos = (adjacentY * width + currentX) * 4;

            if (!fillHistory.has(currentPos) && !edgeHistory.has(currentPos) && colorsMatch(getColorAtPixel(imageData, currentX, adjacentY), targetColor, tolerance))
            {
                let segmentStartX = currentX;
                while (segmentStartX > 0) { // Сканируем влево от currentX
                     const prevX = segmentStartX - 1;
                     const prevPos = (adjacentY * width + prevX) * 4;
                     if (fillHistory.has(prevPos) || edgeHistory.has(prevPos) || !colorsMatch(getColorAtPixel(imageData, prevX, adjacentY), targetColor, tolerance)) {
                         break;
                     }
                     segmentStartX = prevX; // Продолжаем идти влево
                }

                pixelsToProcess.push({ x: segmentStartX, y: adjacentY });

                let segmentEndX = currentX;
                while (segmentEndX < width - 1) {
                    const nextX = segmentEndX + 1;
                    const nextPos = (adjacentY * width + nextX) * 4;
                     if (fillHistory.has(nextPos) || edgeHistory.has(nextPos) || !colorsMatch(getColorAtPixel(imageData, nextX, adjacentY), targetColor, tolerance)) {
                         // Следующий пиксель не подходит, значит segmentEndX - самый правый
                         break;
                     }
                     segmentEndX = nextX; // Продолжаем идти вправо
                }
                currentX = segmentEndX + 1;
            } else {
                 if (!fillHistory.has(currentPos) && !edgeHistory.has(currentPos)) {
                     // Вызываем processEdge только если он не подходит по цвету
                     if (!colorsMatch(getColorAtPixel(imageData, currentX, adjacentY), targetColor, tolerance)) {
                        processEdge(currentX, adjacentY);
                     }
                 }
                 currentX++; // Переходим к следующему пикселю
            }
        }
    }
}


function getColorAtPixel(imageData, x, y) {
  // Убедимся что координаты внутри границ перед доступом к data
  x = Math.max(0, Math.min(imageData.width - 1, x));
  y = Math.max(0, Math.min(imageData.height - 1, y));
  const offset = (y * imageData.width + x) * 4;
  // Добавим проверку на случай выхода за пределы массива, хотя предыдущие проверки должны спасать
  if (offset < 0 || offset + 3 >= imageData.data.length) {
      console.error(`Invalid coordinates or imageData: x=${x}, y=${y}, offset=${offset}, data length=${imageData.data.length}`);
      return { r: 0, g: 0, b: 0, a: 0 }; // Возвращаем черный прозрачный в случае ошибки
  }
  return {
    r: imageData.data[offset],
    g: imageData.data[offset + 1],
    b: imageData.data[offset + 2],
    a: imageData.data[offset + 3]
  };
}

function colorDifference(color1, color2) {
  const rDiff = Math.abs(color1.r - color2.r);
  const gDiff = Math.abs(color1.g - color2.g);
  const bDiff = Math.abs(color1.b - color2.b);
  const aDiff = Math.abs(color1.a - color2.a);
  // Возможно, стоит учитывать альфа-канал иначе или не учитывать вовсе?
  // Пока оставляем как есть.
  return Math.max(rDiff, gDiff, bDiff, aDiff);
}

function colorsMatch(a, b, tolerance = 0) {
  // Используем оригинальную формулу пользователя, но стоит обдумать её корректность
  // `* 0.5` делает tolerance менее чувствительным (tolerance 100 => maxDiff 127.5)
  const maxDifference = 255 * (tolerance / 100) * 0.5;
  // Сравнение должно быть <=
  return colorDifference(a, b) <= maxDifference;
}

function hexToRGBA(hex) {
    // Добавим поддержку короткого hex (#RGB, #RGBA)
    hex = hex.replace(/^#/, '');
    let r = 0, g = 0, b = 0, a = 255;

    if (hex.length === 3) { // #RGB
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 4) { // #RGBA
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
        a = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 6) { // #RRGGBB
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    } else if (hex.length === 8) { // #RRGGBBAA
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
        a = parseInt(hex.slice(6, 8), 16);
    } else {
        // Возвращаем черный цвет или выбрасываем ошибку при неверном формате
        console.error("Invalid hex color format:", hex);
        return { r: 0, g: 0, b: 0, a: 255 };
    }
    return { r, g, b, a };
}


function colorPixel(imageData, offset, color) {
  // Убедимся что цвет корректный и offset в границах
  if (offset < 0 || offset + 3 >= imageData.data.length) {
    // console.error(`Attempted to color pixel outside data bounds: offset=${offset}`);
    return; // Просто выходим, если offset некорректен
  }
  imageData.data[offset] = Math.round(color.r);
  imageData.data[offset + 1] = Math.round(color.g);
  imageData.data[offset + 2] = Math.round(color.b);
  imageData.data[offset + 3] = Math.round(color.a !== undefined ? color.a : 255);
}

// Стандартное alpha blending (source-over)
function blendColors(baseColor, overlayColor) {
  const baseA = (baseColor.a !== undefined ? baseColor.a : 255) / 255;
  const overlayA = (overlayColor.a !== undefined ? overlayColor.a : 255) / 255;

  // Если overlay полностью непрозрачный, результат - просто overlay
  if (overlayA >= 0.999) { // Используем небольшую погрешность для float
      return {
          r: overlayColor.r,
          g: overlayColor.g,
          b: overlayColor.b,
          a: overlayColor.a !== undefined ? overlayColor.a : 255
       };
  }
   // Если overlay полностью прозрачный, результат - просто base
  if (overlayA <= 0.001) {
      return {
           r: baseColor.r,
           g: baseColor.g,
           b: baseColor.b,
           a: baseColor.a !== undefined ? baseColor.a : 255
       };
  }

  const outA = overlayA + baseA * (1 - overlayA);
  if (outA <= 0.001) { // Если результат полностью прозрачный
    return { r: 0, g: 0, b: 0, a: 0 };
  }
  // Формула source-over
  const outR = (overlayColor.r * overlayA + baseColor.r * baseA * (1 - overlayA)) / outA;
  const outG = (overlayColor.g * overlayA + baseColor.g * baseA * (1 - overlayA)) / outA;
  const outB = (overlayColor.b * overlayA + baseColor.b * baseA * (1 - overlayA)) / outA;

  return {
    r: Math.round(outR),
    g: Math.round(outG),
    b: Math.round(outB),
    a: Math.round(outA * 255)
  };
}

function makeTransparentColor(baseColor, opacity = 0.5) {
  return {
    r: baseColor.r,
    g: baseColor.g,
    b: baseColor.b,
    // Убедимся, что opacity в пределах [0, 1]
    a: Math.round(baseColor.a * Math.max(0, Math.min(1, opacity)))
  };
}

// Blend с учетом фактора (для antialiasing)
function blendColorsWithFactor(baseColor, overlayColor, blendFactor = 1) {
  // blendFactor масштабирует прозрачность overlayColor
  const clampedFactor = Math.max(0, Math.min(1, blendFactor));
  const overlayColorWithFactor = {
      r: overlayColor.r,
      g: overlayColor.g,
      b: overlayColor.b,
      a: (overlayColor.a !== undefined ? overlayColor.a : 255) * clampedFactor
  };
  return blendColors(baseColor, overlayColorWithFactor);
}
