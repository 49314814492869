import useStore from '../../store';

export const useHotMouse = (ref) => {

    const { drawing, telegram, convy, info, brush, history } = ref;

    const { 
      centralCanvasOffset,
    } = drawing.methods;
    const { 
      setTheSizeFun,
    } = brush.methods;

    const {
      mouse,
      zoom,
    } = convy;


  function altRightClickStart ({ nativeEvent }) {

    const lineWidth = useStore.getState().lineWidth
    const softness = useStore.getState().softness

    mouse.hotMouseStartPosition = {
      x: nativeEvent.clientX,
      y: nativeEvent.clientY,
    }

    mouse.startLineWidth = lineWidth;
    mouse.startSoftness = softness;
    
  }

  function altRightClickMove ({ nativeEvent }) {

    const zoomFactor = useStore.getState().zoomFactor

    const x = nativeEvent.clientX;
    const y = nativeEvent.clientY;

    const diffY = mouse.hotMouseStartPosition.y - y;
    const diffX = x - mouse.hotMouseStartPosition.x;

    let softnessDifY = 0;
    let lineWidthDifx = 0;

    if (Math.abs(diffY) - Math.abs(diffX) > 0) {
      softnessDifY = diffY > 0 ?
      (Math.abs(diffY) - Math.abs(diffX)) :
      - (Math.abs(diffY) - Math.abs(diffX)) ;
    } else {
      lineWidthDifx = diffX > 0 ?
      (Math.abs(diffX) - Math.abs(diffY)) :
      - (Math.abs(diffX) - Math.abs(diffY)) ;
    }

    let newLineWidth = mouse.startLineWidth + lineWidthDifx * 1.4 / zoomFactor;
    newLineWidth = Math.max(1, newLineWidth);
    setTheSizeFun(newLineWidth);


    let newSoftness = mouse.startSoftness + softnessDifY * 0.003;
    // newSoftness = Math.min(Math.max(0, newSoftness), 0);
    newSoftness = Math.min(Math.max(0, newSoftness), 0.3);

    useStore.setState({ softness: newSoftness });
    
  }

  function startCtrlClickMove ({ nativeEvent }) {

    const panOffset = useStore.getState().panOffset;
    const zoomFactor = useStore.getState().zoomFactor;

    zoom.hotMouseStartPosition = {
      x: nativeEvent.clientX,
      y: nativeEvent.clientY,
    }

    zoom.startZoomFactor = zoomFactor;
    zoom.initialOffset = panOffset || {x: 0, y: 0};
    
  }

  function moveCtrlClickMove ({ nativeEvent }) {

    if (!zoom.hotMouseStartPosition) { return; }

    const panOffset = useStore.getState().panOffset;
    const zoomFactor = useStore.getState().zoomFactor;
    const rotation = useStore.getState().rotation;

    const x = nativeEvent.clientX;
    const y = nativeEvent.clientY;

    const diffY = zoom.hotMouseStartPosition.y - y;
    const diffX = x - zoom.hotMouseStartPosition.x;

    const coef = zoom.startZoomFactor + diffY < 1 ? 0.02 : 0.05;

    let newZoomFactor = zoom.startZoomFactor + diffY * coef;
    newZoomFactor = Math.max(newZoomFactor, zoom.minZoomFactor);
    newZoomFactor = Math.min(newZoomFactor, zoom.maxZoomFactor);

    if (newZoomFactor < 1.1 && newZoomFactor > 0.8) {newZoomFactor = 1;}

    const canvasDistanceFromCenter = centralCanvasOffset(zoom.hotMouseStartPosition) ; 
    // const canvasPoint = getCanvasPoint({x, y}) ; // Текущая позиция мыши

    // Преобразуем это смещение с учётом поворота:
    const angleRad = rotation * Math.PI / 180;
    const rotatedOffset = {
      x: canvasDistanceFromCenter.x * Math.cos(angleRad) - canvasDistanceFromCenter.y * Math.sin(angleRad),
      y: canvasDistanceFromCenter.x * Math.sin(angleRad) + canvasDistanceFromCenter.y * Math.cos(angleRad)
    };
  
    // Новое смещение (panOffset) корректируется с учетом разницы масштабов и поворота
    let newZoomOffset = {
      x: (rotatedOffset.x * convy.scale * (zoomFactor - newZoomFactor)) + panOffset.x,
      y: (rotatedOffset.y * convy.scale * (zoomFactor - newZoomFactor)) + panOffset.y,
    };

    if ((zoomFactor >= 1.1 || zoomFactor <= 0.8) && newZoomFactor === 1) {
      newZoomOffset = {x: 0, y: 0}
    }
   
    useStore.setState({ zoomFactor: newZoomFactor, panOffset: newZoomOffset });
    
  }

  return {
    altRightClickStart,
    altRightClickMove,

    startCtrlClickMove,
    moveCtrlClickMove,
  };
};