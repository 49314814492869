// LayerMenu.js
import React, { useState, useRef } from 'react';
import { HexAlphaColorPicker } from "react-colorful";
import { FaEye, FaEyeSlash, FaCog, FaPlus, FaArrowDown, FaTimes, FaEraser, FaTrash } from 'react-icons/fa';
import { TbArrowBigLeftLines } from 'react-icons/tb';

import LayerThumbnail from './LayerThumbnail';
import useDragAndDropSort from '../../../hooks/interface/useDragAndDropSort';
import useStore from '../../../store';
import './LayerMenu.css';

function LayerMenu({ store }) {
  useStore(state => state.render);

  const { ref } = store;
  const { menu, work, info, brush, convy, drawing, premium } = ref;
  const initialAllLayersInfoRef = useRef(JSON.parse(JSON.stringify(convy.layerInfo)));

  const { user } = info;
  const { showTemporaryHint, createBetterClick, delayPremiumMenu, closeMenu, showPopupMessage } = menu.methods;
  const { saveUserGameSets, getUserGameSets, saveGameSets, debounceGameSets, saveUserSets } = work.methods;
  const { redraw } = drawing.methods;

  const [userColorView, setUserColorView] = useState(false);
  const [viewedColor, setViewedColor] = useState(info.game.settings.backgroundColor || '#FFFFFFFF');
  const [showDeletedLayers, setShowDeletedLayers] = useState(false);
  const layerMenuRef = useRef(null);

  const { activeLayerId = 'base' } = getUserGameSets();

  // Собираем все слои (в зависимости от режима отображения)
  const allLayersArray = Object.keys(convy.layerInfo)
    .map(id => ({ id, ...convy.layerInfo[id] }))
    .sort((a, b) => b.order - a.order);

  const hasDeletedLayers = Object.values(convy.layerInfo).some(layer => layer.deleted);

  const mainLayers = allLayersArray.filter(layer => !layer.clippingFor)
  .filter(layerData => showDeletedLayers ? layerData.deleted : !layerData.deleted);

  const strokesByLayer = {};
  const userStrokes = ref.history.userStrokes;

  for (const userId in userStrokes) {
    const strokesArray = userStrokes[userId];
    for (let i = 0, len = strokesArray.length; i < len; i++) {
      const stroke = strokesArray[i];
      const layerId = stroke.layerId || 'base';
      if (!strokesByLayer[layerId]) {
        strokesByLayer[layerId] = [];
      }
      strokesByLayer[layerId].push(stroke);
    }
  }

  const setActiveLayer = (layerId) => {
    if (brush.lastActionTime > Date.now() - 300) return;

    brush.lastActionTime = Date.now();
    const layer = convy.layerInfo[layerId];
    if (!layer?.visible) {
      return showTemporaryHint(window.t('layer_menu.cant_choose_invisible'), { force: true });
    }
    saveUserGameSets({ activeLayerId: layerId });
    menu.methods.render();
    return showTemporaryHint(window.t('layer_menu.choosen_layer', { layerName: layer.name }), { force: true, duration: 500 });
  };

  const toggleMask = (layerId, type) => {

    brush.lastActionTime = Date.now();
    const layer = convy.layerInfo[layerId];

    if (layer.clippingFor) {

      const parentId = layer.clippingFor;
      delete layer.clippingFor;
      layer.order = convy.layerInfo[parentId].order + 0.5;
      convy.methods.updateLayerOrders();
      showTemporaryHint(window.t('layer_menu.mask_removed', { layerName: layer.name || layerId }), { force: true });

    } else {

      const attachedMasksForLayer = allLayersArray.filter(l => l.clippingFor === layerId);
      if (attachedMasksForLayer.length > 0) {
        return showTemporaryHint(window.t('layer_menu.operation_cannot_be_done', { layerName: layer.name || layerId }), { force: true });
      }
      const currentIndex = mainLayers.findIndex(l => l.id === layerId);
      if (currentIndex === -1 || currentIndex === mainLayers.length - 1) {
        return showTemporaryHint(window.t('layer_menu.operation_cannot_be_done'), { force: true });
      }
      const layerBelow = mainLayers[currentIndex + 1];
      layer.clippingFor = layerBelow.id;
      showTemporaryHint(window.t('layer_menu.mask_applied', { layerName: layer.name || layerId }), { force: true });
    }
    redraw();
    menu.methods.render();
    menu.methods.appleHaptic?.('light');
  };

  const addNewLayer = () => {
    if (!premium.premiumFeatures.includes('layers') || user.premiumOff) {
      delayPremiumMenu('menu');
      return;
    }
    brush.lastActionTime = Date.now();
    const activeLayer = convy.methods.getActiveLayer();
    const newLayer = convy.methods.createLayer(null, activeLayer.info.order);

    saveGameSets({ layerInfo: ref.convy.layerInfo });
    saveUserGameSets({ activeLayerId: newLayer.id });
    menu.methods.render();
    showTemporaryHint(window.t('layer_menu.new_layer_created'), { force: true });
    menu.methods.appleHaptic?.('light');
  };

  const toggleVisibility = (layerId) => {
    brush.lastActionTime = Date.now();
    const layerInfo = convy.layerInfo[layerId];
    if (layerInfo) {
      layerInfo.visible = !layerInfo.visible;
      showTemporaryHint(
        window.t(layerInfo.visible ? 'layer_menu.visible' : 'layer_menu.invisible', { layerName: layerInfo.name }),
        { force: true }
      );
      redraw();
      menu.methods.appleHaptic?.('light');
    }
  };

  const openCustomizeLayer = (layerId, e) => {
    e?.preventDefault();
    e?.stopPropagation();
    brush.lastActionTime = Date.now();
    menu.methods.openMenu('customizeLayer', { layerId });
    menu.methods.appleHaptic?.('light');
  };

  const handleRestoreLayer = (layerId, event) => {
    const layer = convy.layerInfo[layerId];
    if (!layer) return;
  
    if (event) {
      // Если передан event, показываем всплывающее меню для подтверждения
      event.stopPropagation();
      showPopupMessage({
        title: window.t('customize_layer.restore_layer_title'),
        message: window.t('customize_layer.restore_layer_text', { layerName: layer.name || layerId }),
        buttons: [
          { type: 'cancel', text: window.t('customize_layer.button_cancel') },
          { id: 'restore', type: 'default', text: window.t('customize_layer.restore_layer') },
        ],
        callback: (buttonId) => {
          if (buttonId === 'restore') {
            window.croco?.haptic?.();
            // Выполняем восстановление
            layer.deleted = false;
            showTemporaryHint(window.t('customize_layer.layer_restored', { layerName: layer.name || layerId }), { force: true });
            saveGameSets({ layerInfo: ref.convy.layerInfo });
            setShowDeletedLayers(false);
            menu.methods.render();
            redraw();
          }
        }
      });
    } else {
      // Если event не передан, выполняем восстановление напрямую
      layer.deleted = false;
      showTemporaryHint(window.t('customize_layer.layer_restored', { layerName: layer.name || layerId }), { force: true });
      saveGameSets({ layerInfo: ref.convy.layerInfo });
      setShowDeletedLayers(false);
      menu.methods.render();
      redraw();
    }
  };

  const previewColor = (userColor) => {
    setViewedColor(userColor);
    debounceGameSets({ backgroundColor: userColor });
    drawing.methods.redraw();
  };

  const pickColor = () => {
    debounceGameSets({ backgroundColor: viewedColor });
    setUserColorView(false);
    drawing.methods.redraw();
  };

  const handleSetTransformLayer = () => {
    const newValue = !user.transformLayer;
    saveUserSets({ transformLayer: newValue });
    if (newValue) {
      menu.methods.appleHaptic?.('light');
      showTemporaryHint(window.t('layer_menu.layer_transformation_is_on'), { force: true });
    }
  };

  const handleClose = () => {
    if (JSON.stringify(initialAllLayersInfoRef.current) !== JSON.stringify(convy.layerInfo)) {
      const prev = JSON.parse(JSON.stringify(initialAllLayersInfoRef.current));
      ref.history.methods.layersInfoChangeStroke({ prev });
    }
    closeMenu();
  };

  const { draggingId, dragOverId, dragDirection, ghostPosition, itemRefs, handlePointerDown, isDraggingActive } = useDragAndDropSort({
    items: allLayersArray,
    onSort: (updatedLayers) => {
      updatedLayers.forEach(layer => {
        convy.layerInfo[layer.id].order = layer.order;
      });
      redraw();
      menu.methods.render();
      menu.methods.appleHaptic?.('light');
    },
    containerRef: layerMenuRef,
    keyExtractor: (item) => item.id,
    dragThreshold: 0,
  });

  // Функция рендеринга строки активного слоя
  const renderLayerRow = (commonLayer) => {
    const isActiveLayer = activeLayerId === commonLayer.id && commonLayer.visible;

    return (
      <div
        key={commonLayer.id}
        ref={el => itemRefs.current[commonLayer.id] = el}
        data-draggable-id={commonLayer.id}
        className={`layer-row ${!commonLayer.visible ? 'invisible' : isActiveLayer ? 'active' : ''} ${isDraggingActive && draggingId === commonLayer.id ? 'dragging' : ''} ${dragOverId === commonLayer.id ? `drag-over drag-over-${dragDirection}` : ''}`}
        style={commonLayer.clippingFor ? { marginLeft: '10%', width: '90%' } : {}}
        onClick={() => setActiveLayer(commonLayer.id)}
      >
        <div className="drag-handle" onPointerDown={(e) => handlePointerDown(e, commonLayer.id)}>
          {commonLayer.clippingFor ? '⮦' : '☰'}
        </div>
        <div style={{ touchAction: 'none', cursor: 'move' }} onPointerDown={(e) => handlePointerDown(e, commonLayer.id)}>
          <LayerThumbnail layerId={commonLayer.id} store={store} />
        </div>
        <div className="layer-row-column">
          <div className="layer-row-main">
            <span className="layer-name">{commonLayer.name || commonLayer.id}</span>
          </div>
          <div className="layer-metrics">
            {ref.convy.methods.layerMetrics(commonLayer.id, strokesByLayer[commonLayer.id])}
          </div>
        </div>
        <button
          className={`layer-control-button ${isActiveLayer ? 'active' : ''}`}
          onClick={(e) => { e.stopPropagation(); toggleMask(commonLayer.id); }}
          {...createBetterClick({ onClick: (e) => { e.stopPropagation(); toggleMask(commonLayer.id); } })}
        >
          {commonLayer.clippingFor ? (
            commonLayer.erasingMask ? (
              <FaEraser className="layer-control-icon" />
            ) : (
              <FaTimes className="layer-control-icon" />
            )
          ) : (
            <FaArrowDown className="layer-control-icon" />
          )}
        </button>
        <button
          className={`layer-control-button ${isActiveLayer ? 'active' : ''}`}
          onClick={(e) => { e.stopPropagation(); toggleVisibility(commonLayer.id); }}
          {...createBetterClick({ onClick: (e) => { e.stopPropagation(); toggleVisibility(commonLayer.id); } })}
        >
          {commonLayer.visible ? 
            <FaEye className="layer-control-icon" /> : 
            <FaEyeSlash className="layer-control-icon" />
          }
        </button>
        <button
          className={`layer-control-button ${isActiveLayer ? 'active' : ''}`}
          {...createBetterClick({ onClick: (e) => { e.stopPropagation(); openCustomizeLayer(commonLayer.id, e); } })}
        >
          <FaCog className="layer-control-icon" />
        </button>
      </div>
    );
  };

  // Функция рендеринга строки удаленного слоя
  const renderDeletedLayerRow = (commonLayer) => {
    return (
      <div
        key={commonLayer.id}
        className={`layer-row deleted`}
        style={commonLayer.clippingFor ? { marginLeft: '10%', width: '90%' } : {}}
        onClick={(e) => handleRestoreLayer(commonLayer.id, e)}
      >
        <div className="drag-handle">
          {commonLayer.clippingFor ? '⮦' : '☰'}
        </div>
        <LayerThumbnail layerId={commonLayer.id} store={store} />
        <div className="layer-row-column">
          <div className="layer-row-main">
            <span className="layer-name">{commonLayer.name || commonLayer.id}</span>
          </div>
          <div className="layer-metrics">
            {ref.convy.methods.layerMetrics(commonLayer.id, strokesByLayer[commonLayer.id])}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="overlay-background" onClick={handleClose} style={{ opacity: 0 }}></div>
      <div className="layer-menu" ref={layerMenuRef}>
        <div className="layer-menu-header">
          <span className="layer-menu-title">
            {showDeletedLayers ? window.t('layer_menu.deleted_title') : window.t('layer_menu.title')}
          </span>
          <div className="layer-menu-buttons">
            {hasDeletedLayers && (
              <button
                className="new-layer-button"
                style={{ opacity: 0.5 }}
                {...createBetterClick({ onClick: () => setShowDeletedLayers(!showDeletedLayers) })}
              >
                {showDeletedLayers ? <TbArrowBigLeftLines /> : <FaTrash />}
                
              </button>
            )}
            <button 
              className="new-layer-button" 
              {...createBetterClick({ onClick: addNewLayer })}
            >
              <FaPlus />
            </button>
          </div>
        </div>

        <div className="layer-list add-scroll touch-active">
          {mainLayers.map(mainLayer => {
            const attachedMasks = allLayersArray
              .filter(layer => layer.clippingFor === mainLayer.id)
              .sort((a, b) => b.order - a.order);
            const group = [...attachedMasks, mainLayer];
            return group.map(commonLayer => 
              showDeletedLayers ? renderDeletedLayerRow(commonLayer) : renderLayerRow(commonLayer)
            );
          })}
        </div>

        <div className="divider"></div>
        <div className="settings-item">
          <label className="settings-label" style={{ opacity: user.transformLayer ? 1 : 0.5 }}>
            <input
              type="checkbox"
              className="word-menu-item-checkbox"
              checked={user.transformLayer}
              onChange={handleSetTransformLayer}
              style={{ height: '20px' }}
            />
            <span>{window.t('layer_menu.transform_layer')}</span>
          </label>
        </div>
        <div 
          className="settings-item" 
          onClick={() => setUserColorView(true)}
        >
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span className="settings-color-indicator" style={{
              backgroundColor: info.game.settings.backgroundColor || '#FFFFFFFF',
            }}></span>
            <span>{window.t('layer_menu.background_color')}</span>
          </label>
        </div>

        {isDraggingActive && draggingId && !showDeletedLayers && (
          <div
            className="layer-row ghost"
            style={{
              position: 'absolute',
              left: ghostPosition.x - 10,
              top: ghostPosition.y - 20,
              pointerEvents: 'none',
              width: itemRefs.current[draggingId]?.offsetWidth,
              zIndex: 1000,
            }}
          >
            <div className="drag-handle">☰</div>
            <LayerThumbnail
              layerId={draggingId}
              store={store}
            />
            <span className="layer-name">{convy.layerInfo[draggingId]?.name || draggingId}</span>
          </div>
        )}

        {userColorView && (
          <>
            <div className="overlay-background" style={{ zIndex: 1400, opacity: 0 }} onClick={() => setUserColorView(false)}></div>
            <div 
              className="settings-color-picker-container" 
              style={{
                position: 'absolute',
                top: 'calc(min(50%, 100% - 140px))',
              }}
            >
              <HexAlphaColorPicker 
                color={viewedColor} 
                onChange={(userColor) => previewColor(userColor)}
              />
              <button className="wide-button" onClick={pickColor} style={{ marginTop: '10px' }}>
                {window.t('settings.set_color')}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LayerMenu;