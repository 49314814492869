// src/components/puzzle/utils/pieceGenerator.js
import React from 'react'; // Needed for SVG Defs JSX

/**
 * Основная функция генерации данных и SVG для всех пазлов.
 * Принимает imageBuffer, config, ref.
 */
export const generatePieces = async (imageBuffer, config, ref) => {
  return new Promise((resolve, reject) => {
    // Создаем Blob из буфера изображения и получаем URL
    const blob = new Blob([imageBuffer], { type: 'image/png' });
    const blobUrl = URL.createObjectURL(blob);
    const img = new Image();

    img.onload = () => {
      try {
        const imageDimensions = { width: img.naturalWidth, height: img.naturalHeight };
        if (!imageDimensions.width || !imageDimensions.height) {
          throw new Error("Image loaded with zero dimensions.");
        }

        const aspectRatio = imageDimensions.width / imageDimensions.height;
        const desiredPieceCount = ref.info.game?.settings?.amount || config.amount || 36;
        // Расчет колонок и строк
        let cols = Math.max(2, Math.ceil(Math.sqrt(desiredPieceCount * aspectRatio)));
        let rows = Math.max(2, Math.ceil(desiredPieceCount / cols));
        // Можно доработать корректировку cols для более точного приближения

        const pieceWidthBase = imageDimensions.width / cols;
        const pieceHeightBase = imageDimensions.height / rows;
        const pieceBaseDimensions = { width: pieceWidthBase, height: pieceHeightBase };

        const pieces = {};
        const svgClipPaths = []; // Массив для хранения JSX <clipPath>

        // --- Расчет начальных позиций в трее ---
        const trayPadding = 8; // Отступ внутри трея из CSS
        const trayGap = 8; // Расстояние между элементами из CSS
        const trayScale = config.trayPieceScale || 0.8;
        const baseTrayPieceWidth = pieceWidthBase * trayScale;
        const baseTrayPieceHeight = pieceHeightBase * trayScale;

        // Ориентировочная ширина контента трея
        const estimatedTrayContentWidth = (window.innerWidth || 360) * 0.95 - 2 * trayPadding;

        let currentTrayX = trayPadding;
        let currentTrayY = trayPadding;
        let maxRowHeight = baseTrayPieceHeight;

        for (let r = 0; r < rows; r++) {
          for (let c = 0; c < cols; c++) {
            const pieceId = `piece_${r}_${c}`;


            // --- Расчет начальной позиции в трее с переносом строк ---
            const currentPieceWidthInTray = baseTrayPieceWidth;
            const currentPieceHeightInTray = baseTrayPieceHeight;

            if (currentTrayX > trayPadding && currentTrayX + currentPieceWidthInTray > estimatedTrayContentWidth) {
              currentTrayX = trayPadding;
              currentTrayY += maxRowHeight + trayGap;
              maxRowHeight = currentPieceHeightInTray;
            } else if (currentTrayX > trayPadding) {
              currentTrayX += trayGap;
            }

            // Добавляем случайное смещение для "живости"
            const randomOffsetX = (Math.random() - 0.5) * currentPieceWidthInTray * 0.05;
            const randomOffsetY = (Math.random() - 0.5) * currentPieceHeightInTray * 0.05;
            const initialX = currentTrayX + randomOffsetX;
            const initialY = currentTrayY + randomOffsetY;

            currentTrayX += currentPieceWidthInTray;
            maxRowHeight = Math.max(maxRowHeight, currentPieceHeightInTray);

            // Формирование данных пазла
            const correctPos = { x: c / cols, y: r / rows };
            pieces[pieceId] = {
              id: pieceId,
              correctPos,
              initialTrayPos: { x: initialX, y: initialY },
              currentPos: { x: initialX, y: initialY },
              placed: false,
              placedBy: null,
              inTray: true,
              zIndex: 1,
              order: Math.random(),
              imageCrop: {
                x: c * pieceWidthBase,
                y: r * pieceHeightBase,
                width: pieceWidthBase,
                height: pieceHeightBase,
              },
              baseDimensions: { width: pieceWidthBase, height: pieceHeightBase },
              reserved: 0,
              unreserved: 0,
              ghost: {
                visible: true, 
                opacity: config.ghostOpacity || 0.2,
              },
            };
          }
        }

        // Создаем JSX для SVG <defs> один раз
        const svgDefs = (
          <svg width="0" height="0" style={{ position: 'absolute', pointerEvents: 'none' }}>
            <defs>{svgClipPaths}</defs>
          </svg>
        );

        resolve({
          pieces,
          imageDimensions,
          pieceBaseDimensions,
          rows,
          cols,
          svgDefs,
        });
      } catch (error) {
        console.error("Error during image processing or piece generation:", error);
        URL.revokeObjectURL(blobUrl);
        reject(error);
      }
    };

    img.onerror = (error) => {
      console.error("Failed to load image from blobUrl", error);
      URL.revokeObjectURL(blobUrl);
      reject(new Error('Image loading failed'));
    };

    // Устанавливаем src после onload/onerror
    img.src = blobUrl;
  });
};
