import { useRef, useEffect, useState, useCallback, } from 'react';

import { useGameContext } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { drawPlainMarkerStroke } from './brushes/plain';
import { drawDashedStroke } from './brushes/dashed';
import { drawOutlinedStroke } from './brushes/outlined';
import { drawSprayStroke } from './brushes/spray';
import { drawFeatherStroke } from './brushes/feather';
import { filterClosePoints } from './brushes/helpers/points';
import { drawBristleStroke } from './brushes/bristle';
import { drawRembrandtStroke } from './brushes/rembrandt';
import { drawSparkleStroke } from './brushes/sparkle';
import { drawNeonStroke } from './brushes/neon';

export const useSoftDraw = ({

  wipeTempCanvas,

  saveHistory,
  getEventPos,
  redrawCanvas,

  touchZoomStart,
  touchZoomMove,
  
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    activeUserIdRef,
    gameInfoRef,

    isAppleDevice,
    userSetsRef,
    forceRender,
  } = gameContext;

  const {
    tempContextRef,
    contextRef,

    zoomFactorRef,
    isDrawingRef,
    setDrawingPosition,
    strokeTimeRef,

    isRenderingStrokesRef,

  } = canvasContext;

  const {
    colorRef,
    visibleColorFun,
    gradientColorFun,
    actualSoftnessFun,
    brushSetsFun,
    brushLinkFun,

    lineWidthRef,
    showPalette, setShowPalette,

    softnessRef,

    brushTypeRef,
    effectTypeRef,
    activeToolRef,

    lastActionTimeRef,

  } = brushContext;

  const {
    userStrokesRef,
    setRedrawer,
  } = HistoryContext;

  const lastPointRef = useRef([]);
  const currentStrokeRef = useRef([]);


  const addStroke = (currentStroke, stroke = {}) => {

    if (isRenderingStrokesRef.current) { return; }

    let points = [...currentStroke].map(point=>{
      if (Array.isArray(point)) {return point}
      else {return [point.x, point.y]}
    });


    const sets = brushSetsFun();
    const basicBrushLink = brushLinkFun();
    const commonSets = brushSetsFun('common');

    const composition = basicBrushLink === 'texture' ? 
    sets.composition : 
    sets.composition ?? commonSets.composition;

    Object.assign(sets, commonSets, { composition });

    const smooth = (!sets.speedDependence && !sets.spreading && !stroke.shapeId);
    if (smooth) {
      points = filterClosePoints(points, Math.round(commonSets.smoothing / zoomFactorRef.current))
    }

    const effect = activeToolRef.current === 'effect' ? effectTypeRef.current : null;

    const newStroke = {
        color: colorRef.current, 
        gradientColor: gradientColorFun(),
        lineWidth: lineWidthRef.current, 
        softness: softnessRef.current, 
        points,
        time: strokeTimeRef.current,
        type: 'stroke', // Указываем тип записи как штрих
        userId: activeUserIdRef.current,
        tool: activeToolRef.current,
        brush: basicBrushLink,
        effect,
        sets,
        ...stroke,
    };

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    myStrokes.push(newStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory();

  };

  const startDrawing = ({ nativeEvent }) => {

    if (Date.now() - lastActionTimeRef.current < 100) {return;}

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };;
    setDrawingPosition(position)

    isDrawingRef.current = true;
    strokeTimeRef.current = Date.now();
    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    tempContextRef.current.save();

    const basicBrushLink = brushLinkFun();

    if (['pencil'].includes(basicBrushLink)) {tempContextRef.current.globalAlpha = 0.7;}
    if (['watercolor'].includes(basicBrushLink)) {tempContextRef.current.globalAlpha = 0.5;}

    const point = getEventPos(nativeEvent);
    lastPointRef.current = point;
    currentStrokeRef.current = [point];

    forceRender();


  };

  const draw = async ({ nativeEvent }) => {
    if (!isDrawingRef.current) return;

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };;
    setDrawingPosition(position)

    wipeTempCanvas();
    const point = getEventPos(nativeEvent)
    lastPointRef.current = point;
    currentStrokeRef.current.push(point);

    const sets = brushSetsFun();
    const basicBrushLink = brushLinkFun();
    const softness = actualSoftnessFun();
    const commonSets = brushSetsFun('common');
    Object.assign(sets, commonSets, { composition: sets.composition ?? commonSets.composition });

    let points = currentStrokeRef.current.map(point=>[point.x, point.y]);
    if (!sets?.speedDependence) {
      points = filterClosePoints(points, Math.round(commonSets.smoothing / zoomFactorRef.current))
    }

    const params =  { 
      isApple: isAppleDevice(),
     };


    const stroke = {
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      points,
      sets,
      time: strokeTimeRef.current,
    }

    await drawPreview(stroke);

  };

  const drawPreview = async (stroke, more = {})=>{

    const context = tempContextRef.current;

    const {
      brush = brushLinkFun(),
      softness = actualSoftnessFun(),
      params =  { 
        isApple: isAppleDevice(),
       },
    } = more;

    const previewComplexBrushes = !userSetsRef.current.moreCache;

    if (['feather', 'ink'].includes(brush)) {
      await drawFeatherStroke(stroke, context);
    } else if (brush === 'dashed') {
      await drawDashedStroke(stroke, context);
    } else if (brush === 'outlined') {
      await drawOutlinedStroke(stroke, context);
    } else if (brush === 'bristle' && previewComplexBrushes) {
      await drawBristleStroke(stroke, context);
    } else if (brush === 'rembrandt' && previewComplexBrushes) {
      await drawRembrandtStroke(stroke, context);
    } else if (brush === 'neon' && previewComplexBrushes) {
      await drawNeonStroke(stroke, context);
    } else if (brush === 'sparkle' && previewComplexBrushes) {
      await drawSparkleStroke(stroke, context);
    } else if (brush === 'test' && previewComplexBrushes) {
    } else if (
      (brush === 'spray' || softness > 0)
      && (previewComplexBrushes || !isAppleDevice())
      ) {
      stroke.softness = softness;
      await drawSprayStroke(stroke, null, context, params)
    } else {
      await drawPlainMarkerStroke(stroke, context)
    }

  }

  const finishDrawing = ({ nativeEvent }) => {
    if (!isDrawingRef.current) return;

    isDrawingRef.current = false;
    addStroke(currentStrokeRef.current);

    currentStrokeRef.current = [];
    tempContextRef.current.restore();

    wipeTempCanvas();



  };
   

  const startDrawingTouch = (event) => {

    if (Date.now() - lastActionTimeRef.current < 300) {return;}
    event.preventDefault(); 

    if (event.touches.length > 1) {
      if (currentStrokeRef.current.length < 5) {
        currentStrokeRef.current = [];
        wipeTempCanvas();
      } else {
        finishDrawingTouch();
      }
      return touchZoomStart(event);
    }

    isDrawingRef.current = true;
    strokeTimeRef.current = Date.now();

    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    tempContextRef.current.save();

    const basicBrushLink = brushLinkFun();

    if (['pencil'].includes(basicBrushLink)) {tempContextRef.current.globalAlpha = 0.7;}
    if (['watercolor'].includes(basicBrushLink)) {tempContextRef.current.globalAlpha = 0.5;}

    const touch = event.touches[0]; // Берем первое касание

    const position = { x: touch.clientX, y: touch.clientY };;
    setDrawingPosition(position)

    const touchPos = getEventPos(touch);
    lastPointRef.current = touchPos;
    currentStrokeRef.current = [touchPos];

    forceRender();

  };

  const drawTouch = async (event) => {

    event.preventDefault();
    if (event.touches.length === 2) { 
      return touchZoomMove(event);
     }

    if (!isDrawingRef.current) return;

    const touch = event.touches[0];

    const position = { x: touch.clientX, y: touch.clientY };
    setDrawingPosition(position)

    const touchPos = getEventPos(touch);

    lastPointRef.current = touchPos;
    currentStrokeRef.current.push(touchPos);

    wipeTempCanvas();

    const sets = brushSetsFun();
    const basicBrushLink = brushLinkFun();
    const softness = actualSoftnessFun();
    const commonSets = brushSetsFun('common');
    Object.assign(sets, commonSets, { composition: sets.composition ?? commonSets.composition });

    let points = currentStrokeRef.current.map(point=>[point.x, point.y]);

    if (!sets?.speedDependence) {
      points = filterClosePoints(points, Math.round(commonSets.smoothing / zoomFactorRef.current))
    }

    const params =  { 
      isApple: isAppleDevice(),
     };

    const stroke = {
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      lineWidth: lineWidthRef.current,
      points,
      sets,
      time: strokeTimeRef.current,
    };

    await drawPreview(stroke);

  };

  const finishDrawingTouch = () => {
    if (!isDrawingRef.current) return;

    isDrawingRef.current = false;
    if (currentStrokeRef.current.length > 1) {
      addStroke(currentStrokeRef.current);
    }
    currentStrokeRef.current = [];
    tempContextRef.current.restore();

    wipeTempCanvas();

  };
  

  const fill = ({nativeEvent}) => {

    if (isRenderingStrokesRef.current) { return; }

    if (Date.now() - lastActionTimeRef.current < 100) {return;}
    lastActionTimeRef.current = Date.now();

    const { x, y } = getEventPos(nativeEvent);

    let fillStroke;

    if (ifBackgroundFill()) {
      
      fillStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'background',
        color: colorRef.current,
      }

    } else {

      const sets = brushSetsFun('filler');

      fillStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'fill',
        x: x,
        y: y,
        color: colorRef.current,
        sets,
      };
    }

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];

    myStrokes.push(fillStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory ()

  }

  function ifBackgroundFill () {

    const allStrokes = Object.values(userStrokesRef.current).flat();
    const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);
    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');

    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);
    const hasUsualStrokes = strokesToRender.find(stroke=>stroke.type !== 'background');
    return !hasUsualStrokes;
    
  }

  return {

    addStroke,
    drawPreview,

    startDrawing,
    draw,
    finishDrawing,

    startDrawingTouch,
    drawTouch,
    finishDrawingTouch,

    fill,
  }
};

