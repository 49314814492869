// src/store/socketSlice.js
import { io } from 'socket.io-client';


const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const SOCKET_SERVER_PORTS = process.env.REACT_APP_SOCKET_PORTS.split(',');

const createSocketSlice = (set, get, ref) => {

  ref.connection = {
    lastSaveTime: 0,
    lastErrorPopupTime: 0,
    lastPreviewTime: 0,
    lastConnectionAttempt: 0,
  };


  return {
    socket: null,
    socketStatus: 'disconnected',
  
    // Функция для подключения к сокет-серверу и регистрации
    connectSocket: async () => {
  
      const { socket } = get();
      if (socket) {
        window.croco?.log('✖️socket.off');
        socket.off('connect');
        socket.off('reconnect');
        socket.off('reconnect_attempt');
        socket.off('reconnect_failed');
        socket.off('disconnect');
        socket.off('error');
        socket.disconnect(); // Закрываем старое соединение
      }

      window.croco?.log('⏳Connecting...');

      const telegram = ref.telegram;
      const {
        gameId, 
        gameParam, 
        telegramUserId, 
        clientId, 
        initData, 
        socketPortNum, 
      } = telegram;
    
      const PORT = SOCKET_SERVER_PORTS[socketPortNum] || SOCKET_SERVER_PORTS[0];
      const FULL_SERVER_URL = `${SOCKET_SERVER_URL}:${PORT}`;
      const newSocket = io(FULL_SERVER_URL, {
        reconnection: true,
        reconnectionAttempts: 1000,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      set({ socket: newSocket, socketStatus: 'connecting' });
      get().initHistory(newSocket)

      ref.connection.lastConnectionAttempt = Date.now();

      newSocket.on('connect', () => {

        window.croco?.log('🌐Connected');
        ref.connection.isConnectingNow = false;
        // Обновляем статус и увеличиваем счётчик соединений
        set(state => ({ socketStatus: 'connected' }));
        
        // Отправляем событие регистрации на сервер
        const emitTime = Date.now();

        newSocket.emit('register', { 
          gameId, 
          gameParam, 
          initData, 
          userId: telegramUserId,
          clientId, 
          debugMode: ref.info?.user?.debugMode,
         }, (result) => {
  
          get().setIsValid(result?.verified);

          window.croco?.log(`🔐Verified: ${result?.verified}`);
  
          if (result?.serverTime) {
  
            const receiveTime = Date.now();
            const socketRTT = receiveTime - emitTime;
            ref.timeDifference = Math.floor(Date.now() - (result?.serverTime - socketRTT / 2));
            window.logPerformance?.(`Socket RTT:`, `${socketRTT} ms`);
            window.logPerformance?.(`Time difference:`, `${ref.timeDifference} ms`);
  
          }
  
        });
      });
      
      newSocket.on('reconnect', (attemptNumber) => {
        console.log(`Socket reconnected after ${attemptNumber} attempts`);
        // Регистрация уже вызывается в 'connect', так как она срабатывает и при восстановлении
      });

      newSocket.on('reconnect_attempt', (attemptNumber) => {
        console.log(`Reconnection attempt #${attemptNumber}`);
        set({ socketStatus: 'connecting' });
      });

      newSocket.on('reconnect_failed', () => {
        console.error('Reconnection failed after all attempts');
        set({ socketStatus: 'disconnected' });
      });

      newSocket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
        set({ socketStatus: 'disconnected' });
      });

      newSocket.on('error', (error) => {
        console.error('Socket error:', error);
      });

    },
    
  };
};

export default createSocketSlice;
