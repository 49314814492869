// BrushSizeIndicator
import useStore from '../../../store';

const BrushSizeIndicator = ({ 
  store,
  position, 
  clickPosition, 
 }) => {

    const lineWidth = useStore((state) => state.lineWidth);
    const zoomFactor = useStore((state) => state.zoomFactor);

    if (!position) {
      return null; // Не отображаем компонент, если позиция или размер кисти не заданы
    }

    const { menu, brush, } = store.ref;
    const {
      actualSoftnessFun,
      visibleColorFun,
      visibleLineWidthFun,
    } = brush.methods;

    // Вычисляем смещение для центрирования по горизонтали и позиционирования дна кружка на указателе
    const horizontalOffset = -visibleLineWidthFun() / 2;
    const verticalOffset = -visibleLineWidthFun() - 70;

    const blurSize = `${actualSoftnessFun() * visibleLineWidthFun()}px`; // Пример коэффициента умножения на 3

    const maxOffsetY = menu.panel.fastColorIndicatorY
    const brushSizeText = lineWidth.toFixed(Math.min(1, Math.floor(zoomFactor/3)));

    return (
        <div
            style={{
                position: 'fixed',
                left: `${position.x + horizontalOffset}px`, // Центрирование по горизонтали
                top: `${Math.min(maxOffsetY, position.y) + verticalOffset}px`, // Позиционирование дна кружка на указателе
                width: `${visibleLineWidthFun()}px`,
                height: `${visibleLineWidthFun()}px`,
                pointerEvents: 'none', // Исключает элемент из событий указателя
                zIndex: 200,
            }}
        >
          <div 
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%', // Делаем круг
              background: visibleColorFun(),
              filter: `blur(${blurSize})`, 
              boxShadow: '0 0 0 0.3px grey, 0 0 0 0.3px black',
            }}
          />
          <div style={{
              zIndex: 55, 
              color: 'grey',
              fontSize: '14px',
              opacity: 0.9,
              transform: `translate(${3 + visibleLineWidthFun()}px, ${-visibleLineWidthFun()/2 -10}px)`
            }}>
            {brushSizeText}px
          </div>
        </div>
    );
};

export default BrushSizeIndicator;
