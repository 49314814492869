
export function drawPlainStroke (stroke, context, brushSettings = {}) {

  const {
    points, color, lineWidth, increaseLineWidth = 0,
  } = stroke;

  context.lineCap = brushSettings.squareBrush ? 'square' : 'round';
  context.lineJoin = brushSettings.squareBrush ? 'bevel' : 'round';
  if (stroke.lineDash) { context.setLineDash(stroke.lineDash); }

  // Рисуем сплошной штрих
  context.strokeStyle = color;
  context.lineWidth = lineWidth + increaseLineWidth;

  if (points.length === 1) {
    drawPlainPoint(stroke, context, brushSettings);
  } else if (points.length === 2) {
    drawStraightLine (points, context)
  } else {
    drawStroke(points, context);
  }
  
}

export function drawPlainPoint(stroke, bufferCtx, brushSettings = {}) {

  const { points, lineWidth, color,} = stroke;
  const { squareBrush } = brushSettings;

  let point = points[0];
  
  bufferCtx.fillStyle = color;
  
  if (squareBrush) {
    // Рисуем квадрат
    const halfSize = lineWidth / 2;
    bufferCtx.fillRect(
      point.x - halfSize, 
      point.y - halfSize, 
      lineWidth, 
      lineWidth
    );
    
  } else {
    // Рисуем круг
    bufferCtx.beginPath();
    bufferCtx.arc(
      point.x, 
      point.y, 
      lineWidth / 2, 
      0, 
      Math.PI * 2
    );
    bufferCtx.fill();
  }
}

function drawStraightLine (points, context) {

  let firstPoint = points[0];
  let secondPoint = points[1];

  context.beginPath();
  context.moveTo(firstPoint.x, firstPoint.y);

  context.lineTo(
    secondPoint.x, 
    secondPoint.y,
  );
  context.stroke();
  
}

function drawStroke (points, bufferCtx) {

  bufferCtx.beginPath();
  bufferCtx.moveTo(points[0].x, points[0].y);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = {
      x: (nextPoint.x + currentPoint.x) / 2,
      y: (nextPoint.y + currentPoint.y) / 2
    }
    bufferCtx.quadraticCurveTo(nextPoint.x, nextPoint.y, midPoint.x, midPoint.y);
  }
  const lastPoint = points[points.length - 1]
  bufferCtx.lineTo(
    lastPoint.x, 
    lastPoint.y,
    );
  bufferCtx.stroke();
  
}

