import { useEffect, } from 'react';
import useStore from '../../store';

export const usePointer = (ref) => {

  const strokesApplied = useStore(state=>state.strokesApplied);

  const { info, work, brush, drawing, history, convy } = ref;
  const { mouse, keyboard, zoom, } = convy;
  const { hotMouse, shape, smartShape } = brush;

  const {
    serverTimeFun,
  } = info.methods;

  const {
    // DrawingMethods
    wipeTempCanvas,
    startDrawing,
    continueDrawing,
    finishDrawing,
  
    startPickingColorFromCanvas,
    searchForColorFromCanvas,
    pickColorFromCanvas,
  
    fill,
  
    changeViewStart,
    changeViewMove,
    changeLayerMove,
    simpleZoom,

    // Touch
    touchZoomStart,
    touchZoomMove,
    touchZoomEnd,
  
    // SHAPES
    handleShapeStart,
    handleShapeMove,
    handleShapeEnd,

    // SHAPE Transformer
    handleSmartTransformMove,
    handleSmartTransformEnd,

    // STROKE Transform
    finishStrokeTransform,
  
    // HotMouse
    altRightClickStart,
    altRightClickMove,
    startCtrlClickMove,
    moveCtrlClickMove,
  
    // TEST
    getEventPos,
    straightLine,
  
  } = drawing.methods;


  const handlePointerDown = (nativeEvent) => {

    useStore.setState(state=>({keyPressed: state.keyPressed + 1}));

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    useStore.setState({ mousePosition: position });

    nativeEvent.target.setPointerCapture(nativeEvent.pointerId);

    const activeTool = useStore.getState().activeTool;
    const brushType = useStore.getState().brushType;
    const effectType = useStore.getState().effectType;

    if (
      !nativeEvent.target.closest('.temp-canvas')
      && !nativeEvent.target.closest('.backing-canvas')
      && !nativeEvent.target.closest('.reference-image-wrapper')
    ) { return; }

    if (['mouse', 'pen'].includes(nativeEvent.pointerType)) {

      if (nativeEvent.button === 1) {
        nativeEvent.preventDefault();
        return;
      }

 

      mouse.mouseIsPressed = true;

      if (keyboard.controlIsDown && !keyboard.altIsDown) {
        startCtrlClickMove({ nativeEvent })
      } else if (keyboard.altIsDown && nativeEvent.button === 2) {
        altRightClickStart({ nativeEvent });
      } else if (keyboard.tabIsDown) {
        straightLine({ nativeEvent });
      } else if (
        !keyboard.tabIsDown &&
        (keyboard.spaceIsDown || nativeEvent.button === 2 || (keyboard.controlIsDown && keyboard.altIsDown))
      ) {
        if (nativeEvent.target.closest('.temp-canvas')) {
          changeViewStart({ nativeEvent });
        }
      } else if (activeTool === 'pipette') {
        startPickingColorFromCanvas({ nativeEvent });
      } else if (activeTool === 'brush' && brushType === 'shape') {
        handleShapeStart({ nativeEvent });
      } else if (activeTool === 'effect' && effectType === 'filler') {
        // Do nothing
      } else {
        startDrawing({ nativeEvent });
      }

    } else if (nativeEvent.pointerType === 'touch') {

      useStore.setState({ mouseDetected: true });

      if (activeTool === 'effect' && effectType === 'filler') {
        // Do nothing
      } else if (activeTool === 'pipette') {
        startPickingColorFromCanvas({ nativeEvent });
      } else if (activeTool === 'brush' && brushType === 'shape') {
        handleShapeStart({ nativeEvent });
      } else {
        if (
          mouse.lastPenActivity < Date.now() - 5000
          && !ref.info?.user.fingerOff
          ) {
          startDrawing({nativeEvent});
        }
      }
   
    }
  };

  const handlePointerMove = (nativeEvent) => {

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    useStore.setState({ mousePosition: position });
    
    const pointerType = nativeEvent.pointerType;

    const activeTool = useStore.getState().activeTool;
    const showPalette = useStore.getState().showPalette;

    if (pointerType === 'pen') {
      mouse.lastPenActivity = Date.now();
    }

    if (
      !nativeEvent.target.closest('.temp-canvas')
      && !nativeEvent.target.closest('.backing-canvas')
    ) { return; }                   

    if (pointerType === 'touch') {

      useStore.setState({ mouseDetected: false });

      if (shape.drawingShape) {
        handleShapeMove({ nativeEvent });
      } else if (smartShape.transformer) {
        handleSmartTransformMove({ nativeEvent });
      } else if (activeTool === 'pipette') {
        searchForColorFromCanvas({ nativeEvent });
      } else {
        continueDrawing({nativeEvent});
      }

    } else {

      useStore.setState({ mouseDetected: true });

      if (showPalette) {
        return;
      }
      if (!nativeEvent.buttons) {
        return;
      }

      if (shape.drawingShape) {
        handleShapeMove({ nativeEvent });
      } else if (smartShape.transformer) {
        handleSmartTransformMove({ nativeEvent });
      } else if (keyboard.controlIsDown && !keyboard.altIsDown) {
        moveCtrlClickMove({ nativeEvent })
      } else if (keyboard.altIsDown && nativeEvent.buttons === 2) {
        altRightClickMove({ nativeEvent });
        hotMouse.active = true;
      } else if (
        !keyboard.tabIsDown &&
        (keyboard.spaceIsDown || nativeEvent.buttons === 2 || (keyboard.controlIsDown && keyboard.altIsDown))
      ) {
        if (nativeEvent.target.closest('.temp-canvas')) {
          if (keyboard.altIsDown) {
            changeLayerMove({ nativeEvent });
          } else {
            changeViewMove({ nativeEvent });
          }
        }
      } else if (activeTool === 'pipette') {
        searchForColorFromCanvas({ nativeEvent });
      } else {
        continueDrawing({ nativeEvent });
      }
    }
  };

  
  const handlePointerUp = (nativeEvent) => {

    useStore.setState(state=>({keyPressed: state.keyPressed + 1}));

    nativeEvent.target.releasePointerCapture(nativeEvent.pointerId);

    const activeTool = useStore.getState().activeTool;

    if (nativeEvent.pointerType === 'touch') {

      if (shape.drawingShape) {
        handleShapeEnd({ nativeEvent });
      } else if (smartShape.transformer) {
        handleSmartTransformEnd({ nativeEvent });
      } else if (activeTool === 'pipette' && nativeEvent.target === convy.temp.ref) {
        pickColorFromCanvas({ nativeEvent });
      } else {
        finishDrawing?.({ nativeEvent });
      }

    } else {
      
      mouse.mouseIsPressed = false;
      zoom.hotMouseStartPosition = null;
      
      nativeEvent.preventDefault();

      if(convy.layerTransformation?.type === 'mouse') {
        history.methods.transformationStroke();
      }

      const simpleZoomWithCtrl = keyboard.controlIsDown && nativeEvent.button === 0 && !keyboard.altIsDown;
      const simpleZoomOut =
        !keyboard.tabIsDown &&
        !keyboard.altIsDown &&
        nativeEvent.button === 2 &&
        (keyboard.controlIsDown || keyboard.spaceIsDown);

      if (shape.drawingShape) {
        handleShapeEnd({ nativeEvent });
      } else if (smartShape.transformer) {
        handleSmartTransformEnd({ nativeEvent });
      } else if (simpleZoomOut) {
        simpleZoom({ nativeEvent });
      } else if (simpleZoomWithCtrl) {
        simpleZoom({ nativeEvent });
      } else if (
        activeTool === 'pipette' 
        && nativeEvent.target === convy.temp.ref
        && !hotMouse.active
        ) {
        pickColorFromCanvas({ nativeEvent });
      } else {
        finishDrawing?.({ nativeEvent, force: true });
      }

      hotMouse.active = false;
    }
  };


  const handleClick = (nativeEvent) => {

    const activeTool = useStore.getState().activeTool;
    const effectType = useStore.getState().effectType;

    if (keyboard.altIsDown) { return; }
    if (keyboard.controlIsDown) { return; }
    if (keyboard.spaceIsDown) { return; }
    if (keyboard.shiftIsDown) { return; }

    if (
      activeTool === 'effect' 
      && effectType === 'filler'
      && nativeEvent.target === convy.temp.ref
      ) {
      fill({ nativeEvent });
    } 

  }

  const handleContextMenu = (nativeEvent) => {

    useStore.setState({ mouseDetected: false });

    if (nativeEvent.target.closest('.input-field')) { return; }
    nativeEvent.preventDefault();
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }
    nativeEvent.stopPropagation();

  };

  const handleTouchStart = (nativeEvent) => {

    if (nativeEvent.touches.length === 1) { return; }
    if (['pen', 'mouse'].includes(drawing.isDrawing?.pointerType)) { return; }
    if (drawing.isDrawing && drawing.isDrawing.started < serverTimeFun() - 300) { return; }

    useStore.setState({ mouseDetected: false });

    if (nativeEvent.touches.length > 3) {
      nativeEvent.preventDefault()
      // isZooming = true;

      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      nativeEvent.preventDefault()


    } else if (nativeEvent.touches.length === 2) {
      touchZoomStart(nativeEvent)
    } 

  };

  const handleTouchMove = (nativeEvent) => {

    if (nativeEvent.touches.length === 1) { return; }
    if (!zoom.isZooming) { return; }

    useStore.setState({ mouseDetected: false });

    if (nativeEvent.target.closest('.touch-active')) { return; }
    if (nativeEvent.target.closest('.premium-menu')) { return; }
    if (nativeEvent.target.closest('.settings-menu')) { return; }

    if (nativeEvent.touches.length > 3) {
      shape.drawingShape = false;
      // Do nothing
    } else if (nativeEvent.touches.length === 3) {
      shape.drawingShape = false;
      wipeTempCanvas();

    } else if (nativeEvent.touches.length === 2) {

      drawing.isDrawing = false;
      shape.drawingShape = false;
      wipeTempCanvas();
      touchZoomMove(nativeEvent);

    } 


  };

  const handleTouchEnd = (nativeEvent) => {

    setTimeout(() => { useStore.setState({ mouseDetected: false }); }, 10);

    if (nativeEvent.touches.length === 0) {
      
      touchZoomEnd();
      finishStrokeTransform();

      if (drawing.isDrawing?.pointerType === 'touch') {
        finishDrawing?.({ force: true })
      }

      if(convy.layerTransformation?.type === 'touch') {
        history.methods.transformationStroke();
      }
    }

  };


  useEffect(() => {

    if (!strokesApplied) { return; }

    setTimeout(() => {
      if (!mouse.pointerListenersAreSet) {
        mouse.pointerListenersAreSet = true;

        window.addEventListener('pointerdown', handlePointerDown);
        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);
        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('click', handleClick);

        // Cleanup event listeners on unmount
        return () => {
          window.removeEventListener('pointerdown', handlePointerDown);
          window.removeEventListener('pointermove', handlePointerMove);
          window.removeEventListener('pointerup', handlePointerUp);
          window.removeEventListener('contextmenu', handleContextMenu);
          window.removeEventListener('click', handleClick);

        };
      }
    }, 500);
  }, [strokesApplied]); // Empty dependency array ensures this runs once on mount

  return {
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handleContextMenu,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};
