
// Helper to calculate bounding box for stroke segment
export function getStrokeBounds(points, lineWidth, additionalPadding = 0) {
  if (!points || points.length === 0) return null;
  
  const padding = lineWidth * 1; // Extra padding for glyph size
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  
  points.forEach(point => {
    minX = Math.min(minX, point.x - padding - additionalPadding);
    minY = Math.min(minY, point.y - padding - additionalPadding);
    maxX = Math.max(maxX, point.x + padding + additionalPadding);
    maxY = Math.max(maxY, point.y + padding + additionalPadding);
  });

  return {
    x: Math.floor(minX),
    y: Math.floor(minY),
    width: Math.ceil(maxX - minX),
    height: Math.ceil(maxY - minY)
  };
}

export function translatePoints(points, offsetX, offsetY) {
  return points.map(point => ({
    ...point,
    x: point.x + offsetX,
    y: point.y + offsetY,
  }));
}

export function filterClosePoints(points, minDistance) {
  points = points.slice(0);
  if (!minDistance) { return points; }
  if (points.length < 4) { return points; }

  points = points.slice(0);

  const lastPoint = points.pop();
  const filteredPoints = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const dx = points[i].x - filteredPoints[filteredPoints.length - 1].x;
    const dy = points[i].y - filteredPoints[filteredPoints.length - 1].y;
    if (dx * dx + dy * dy >= minDistance * minDistance) {
      filteredPoints.push(points[i]);
    }
  }
  filteredPoints.push(lastPoint);
  return filteredPoints;
}


export function interpolatePointsUniform(points, desiredSpacing = 10) {
  if (points.length < 2) return points;

  let interpolatedPoints = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const startPoint = interpolatedPoints[interpolatedPoints.length - 1];
    const controlPoint = points[i - 1];
    const endPoint = {
      x: (points[i - 1].x + points[i].x) / 2, 
      y: (points[i - 1].y + points[i].y) / 2,
    };
    if (startPoint.pressure) { endPoint.pressure = (points[i - 1].pressure + points[i].pressure) / 2}

    const bezierPoints = generateUniformBezierPoints(startPoint, controlPoint, endPoint, desiredSpacing);

    if (bezierPoints.length > 1) {
      // Исключаем первую точку, чтобы избежать дублирования
      interpolatedPoints.push(...bezierPoints.slice(1));
    }
  }

  return interpolatedPoints;
}


function generateUniformBezierPoints(startPoint, controlPoint, endPoint, desiredSpacing) {
  const totalLength = estimateBezierLength(startPoint, controlPoint, endPoint);

  // Проверяем, что totalLength - число и больше нуля
  if (isNaN(totalLength) || totalLength <= 0) {
    return [startPoint, endPoint]; // Возвращаем начало и конец кривой
  }

  const numPoints = Math.max(2, Math.ceil(totalLength / desiredSpacing));
  const points = [];

  for (let i = 0; i <= numPoints; i++) {
    const t = i / numPoints;
    const point = quadraticBezier(startPoint, controlPoint, endPoint, t);
    points.push(point);
  }

  return points;
}

function quadraticBezier(startPoint, controlPoint, endPoint, t) {
  const x =
    (1 - t) * (1 - t) * startPoint.x +
    2 * (1 - t) * t * controlPoint.x +
    t * t * endPoint.x;
  const y =
    (1 - t) * (1 - t) * startPoint.y +
    2 * (1 - t) * t * controlPoint.y +
    t * t * endPoint.y;
    
  const resultPoint = {x, y}
  if (startPoint.pressure) {
    resultPoint.pressure = (1 - t) * (1 - t) * startPoint.pressure +
    2 * (1 - t) * t * controlPoint.pressure +
    t * t * endPoint.pressure;
  }
  return resultPoint;
}

export function estimateBezierLength(startPoint, controlPoint, endPoint) {
  const steps = 10; // Можно увеличить для большей точности
  let length = 0;
  let prevPoint = startPoint;

  for (let i = 1; i <= steps; i++) {
    const t = i / steps;
    const point = quadraticBezier(startPoint, controlPoint, endPoint, t);
    const dx = point.x - prevPoint.x;
    const dy = point.y - prevPoint.y;
    length += Math.sqrt(dx * dx + dy * dy);
    prevPoint = point;
  }

  return length;
}


export function generateWavePoints (parm = {}) {

  const {
    width = 500,
    height = 300,
    steps = 50,
    amplitude = 50,
    frequency = 0.025,
    x: startX = 60,
    y: startY = 0,
  } = parm;

  const points = [];
  const canvasWidth = width;
  const canvasHeight = height;
  const step = Math.floor(canvasWidth / steps); // Делим ширину на 49 промежутков для 50 точек

  for (let x = 0; x <= canvasWidth; x += step) {
    const y = canvasHeight / 2 + amplitude * Math.sin(frequency * x);
    points.push({ x: x + startX, y: y + startY });
  }

  // Убеждаемся, что точек не более 50
  while (points.length > steps) {
    points.pop();
  }

  return points;
};
