// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.controls {
  position: absolute;
  width: 100%;
  justify-content: center;
  transition: top 0.2s ease, opacity 1s ease;
}

.controls-frame {
  padding: 15px;
  border-radius: 12px;
  background: rgba(100, 100, 100, 0.0);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.controls-row {
  position: relative; /* Это нужно, чтобы абсолютно позиционированный элемент ориентировался относительно этого контейнера */
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 12px; 
}

.control-button {
  color: var(--tg-theme-button-color);
}

.under-controls {
  position: fixed;
  bottom: 0;
  width: calc(50vh);
  height: 100px;
  left: 50%;
  transform: translate(-50%);
  background-color: transparent;
  pointer-events: auto;
  z-index: 101;
}


.brush-info {
  position: absolute;
  left: 90%; /* Прижимаем элемент к правому краю контейнера */
  padding: 10px 10px; /* Немного отступов для визуальной приятности */
  z-index: 55; 
  color: grey;
  font-size: 13px;
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/board/ControlPanel.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,WAAW;EACX,uBAAuB;EACvB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB,EAAE,sGAAsG;EAC1H,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,iBAAiB;EACjB,aAAa;EACb,SAAS;EACT,0BAA0B;EAC1B,6BAA6B;EAC7B,oBAAoB;EACpB,YAAY;AACd;;;AAGA;EACE,kBAAkB;EAClB,SAAS,EAAE,gDAAgD;EAC3D,kBAAkB,EAAE,+CAA+C;EACnE,WAAW;EACX,WAAW;EACX,eAAe;EACf,YAAY;AACd","sourcesContent":["\r\n.controls {\r\n  position: absolute;\r\n  width: 100%;\r\n  justify-content: center;\r\n  transition: top 0.2s ease, opacity 1s ease;\r\n}\r\n\r\n.controls-frame {\r\n  padding: 15px;\r\n  border-radius: 12px;\r\n  background: rgba(100, 100, 100, 0.0);\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 15px;\r\n}\r\n\r\n.controls-row {\r\n  position: relative; /* Это нужно, чтобы абсолютно позиционированный элемент ориентировался относительно этого контейнера */\r\n  display: flex;\r\n  align-items: center; \r\n  justify-content: center; \r\n  gap: 12px; \r\n}\r\n\r\n.control-button {\r\n  color: var(--tg-theme-button-color);\r\n}\r\n\r\n.under-controls {\r\n  position: fixed;\r\n  bottom: 0;\r\n  width: calc(50vh);\r\n  height: 100px;\r\n  left: 50%;\r\n  transform: translate(-50%);\r\n  background-color: transparent;\r\n  pointer-events: auto;\r\n  z-index: 101;\r\n}\r\n\r\n\r\n.brush-info {\r\n  position: absolute;\r\n  left: 90%; /* Прижимаем элемент к правому краю контейнера */\r\n  padding: 10px 10px; /* Немного отступов для визуальной приятности */\r\n  z-index: 55; \r\n  color: grey;\r\n  font-size: 13px;\r\n  opacity: 0.8;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
