// innerGlow.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { createCanvas, cloneCanvas } from './helpers/canvas';
import { drawStrokeWithGradient, drawPlainStroke } from './plain';

const defaultBrushSettings = brushDefaults.neon || {};

export async function drawInnerShadowStroke(stroke, context, params) {
  const {
    lineWidth,
    color,
    sets = {},
  } = stroke;
  
  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    neonSize = 150,
    opacity = 1,
    shadowColor = 'black',
  } = brushSettings;

  const maskCtx = createCanvas(context);
  await drawPlainStroke(stroke, maskCtx, brushSettings);

  // Канвас для окружающей штрих заливки
  const frameCtx = createCanvas(context);
  // Заполняем теневой канвас цветом (это нужно, чтобы тень была видна)
  frameCtx.fillStyle = color;
  frameCtx.fillRect(0, 0, frameCtx.canvas.width, frameCtx.canvas.height);

  // Накладываем маску на теневой канвас с режимом destination-out
  frameCtx.globalCompositeOperation = 'destination-out';
  frameCtx.drawImage(maskCtx.canvas, 0, 0);

  // Создаем канвас для будущей тени
  const shadowCtx = createCanvas(context);
  // Применяем настройки тени
  shadowCtx.shadowColor = shadowColor;
  shadowCtx.shadowBlur = neonSize;
  shadowCtx.shadowOffsetX = 0;
  shadowCtx.shadowOffsetY = 0;

  for (let i = 1; i < 40; i++) {
    shadowCtx.drawImage(frameCtx.canvas, 0, 0);
  }

  shadowCtx.globalCompositeOperation = 'destination-in';
  shadowCtx.drawImage(maskCtx.canvas, 0, 0);

  context.drawImage(maskCtx.canvas, 0, 0);
  context.drawImage(shadowCtx.canvas, 0, 0);
}