import React, { useEffect, useState } from 'react';
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

import RecentColorsPalette from './RecentColorsPalette'; 
import PaletteInstruments from './PaletteInstruments'; 
import GradientInstrument from './GradientInstrument'; 

import useStore from '../../../../store';

function ColorPalettePopup({ store }) {

  useStore((state) => state.premiumGrayscale);

  const color = useStore((state) => state.color);
  const softness = useStore((state) => state.softness);
  const gradientColor = useStore((state) => state.gradientColor);
  const gradientPalette = useStore((state) => state.gradientPalette);

  const lineWidth = useStore((state) => state.lineWidth);

  const { menu, info, brush, } = store.ref;

    const {
      setTheColorFun,
      visibleLineWidthFun,
      setTheSizeFun,

    } = brush.methods;

    const {
      isAppleDevice,
    } = info.methods;


    const onLineWidthChange =(e) => {
      setTheSizeFun(+e.target.value)
    }

    const onClose = () =>{
      useStore.setState({
        showPalette: false,
        gradientPalette: false,
      });
    }

    const timeoutClose = (timer = 100) =>{
      setTimeout(() => {
        onClose();
      }, timer);
    }

    const blurSize = `${softness * visibleLineWidthFun()}px`; // Пример коэффициента умножения на 3

    let positionFromTop = 210;
    positionFromTop = Math.max(positionFromTop, menu.panel.offset - 210);
    positionFromTop = Math.min(positionFromTop, window.innerHeight - 360);

    return (

      <>
        <div className='overlay-background'
          onClick={()=>{
            onClose ();
          }}
          onTouchEnd={()=>{
            setTimeout(() => { onClose() }, 100);
          }}
        ></div>

        <div className='color-palette' style={{ 
            position: 'fixed', 
            left: `50%`,
            transform: `translate(-50%)`,
            top: `${positionFromTop}px`, // Move up accounting for the circle size
            zIndex: 1200,
            display: 'inline-block',
        }}>
            <div style={{ 
                width: `${visibleLineWidthFun()}px`, 
                height: `${visibleLineWidthFun()}px`, 
                borderRadius: '50%', 
                background: color, 
                position: 'absolute', // Absolute positioning
                left: `-32.5px`, // Move the circle to the left
                top: `${-10 - visibleLineWidthFun()}px`, 
                transform: 'translateX(-50%)', // Vertically center the circle
                boxShadow: softness ? 0 : '0 0 0 0.3px grey, 0 0 0 0.3px black',
                filter: `blur(${blurSize})`, 
            }}></div>

            <div style={{ 
                  position: 'absolute', 
                  height: '200px', 
                  display: 'flex', 
                  justifyContent: 'left',
                  left: '0px', // Уже установлено для перемещения влево
                  top: '13%', 
            }}>

                <input
                    type="range"
                    className="widthSlider touch-active"
                    min="1"
                    max="100"
                    value={lineWidth}
                    onChange={onLineWidthChange}
                    onMouseDown={(e) => { 
                      if (isAppleDevice()) { e.preventDefault()  }
                    }}
                    style={{ 
                        height: '177px',
                        width: '25px',
                        position: 'absolute',
                        writingMode: 'vertical-lr',
                        direction: 'rtl',
                        cursor: 'pointer', 
                        background: 'var(--tg-theme-button-color)', 
                        outlineColor: 'palegreen', 
                        left: '-47px',
                        top: '-19px',
                    }}
                />
            </div>

            <HexAlphaColorPicker 
              color={gradientPalette ? gradientColor: color} 
              onChange={setTheColorFun} 
              // onContextMenu={()=>{clipboardColor('copy')}} 
              />

            <HexColorInput 
              className='colorful-input input-field' 
              onChange={setTheColorFun}
              alpha
              // color={gradientPalette ? gradientColor: color}
              placeholder={color.toUpperCase()}
              // placeholder={gradientPalette ? gradientColor: color}


            ></HexColorInput>

            <div style={{ 
              position: 'absolute', 
              left: '210px', 
              top: '3%', 
              pointerEvents: 'none', 
            }}>
              <RecentColorsPalette 
                store={store}
                onClose={timeoutClose}
              />
            </div>
              { gradientPalette ? 
              (
              <div className='gradient-instrument-position'>
              <GradientInstrument 
                store={store}
                onClose={timeoutClose}
              />
              </div>
              ) :
              (
              <div className='palette-instruments-position'>
                <PaletteInstruments 
                  store={store}
                  onClose={timeoutClose}
                />
              </div>
              )
            }
        </div>
      </>
    );
}

export default ColorPalettePopup;
