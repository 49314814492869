// CustomizeBrush

import React, { useRef, useState, useEffect, } from 'react';
import useStore from '../../../store';
import SellBrushPopup from './SellBrushPopup';

import { 
  FaPencilRuler, 
  FaDollarSign,
  FaCog,
 } from 'react-icons/fa';
import { 
  FaClone,
  FaFloppyDisk,
  FaTrash,
 } from 'react-icons/fa6';
import { HexAlphaColorPicker } from "react-colorful";
import './CustomizeBrush.css';
import { useTranslation } from 'react-i18next';
import BrushSizeControl from '../../board/tools/BrushSizeControl'; 
import { createBetterClick } from '../../../utils/interface/createBetterClick'
import GlyphEditor from './GlyphEditor';
import GlyphSelector from './GlyphSelector';
import { glyphCollection } from './glyphs';

function CustomizeBrush({ store }) {

  const { t } = useTranslation();

  useStore(state=>state.render);
  const lineWidth = useStore(state=>state.lineWidth);
  const color = useStore(state=>state.color);
  const softness = useStore(state=>state.softness);

  const { ref } = store;
  const { menu, brush, drawing, convy, info, work, premium } = ref;
  const { game, user } = info;

  const {
    premiumFeatures = [],
  } = premium;

  const {
    brushDefaults,
    sources, 
  } = brush;

  const {
    getTexture,
    brushSetsFun,
    brushLinkFun,
    customBrushSetsFun,

    gradientColorFun,
    setTheColorFun,

    filterClosePoints,
    generateWavePoints,

  } = brush.methods;

  const {
    openMenu,
    closeMenu,
    delayPremiumMenu,
    showPopupMessage,
    showTemporaryHint,
  } = menu.methods;

  const {
    isAppleDevice,
  } = info.methods;

  const {
    saveUserSets,
  } = work.methods;

  const [colorPickerState, setColorPickerState] = useState({
    isOpen: false,
    key: null,
  });
  const [showSellBrushPopup, setShowSellBrushPopup] = useState();

  const brushViews = {
    dashed: {
      lineWidth: 5,
      singleStroke: true,
    },
    feather: {
      lineWidth: 35,
      singleStroke: true,
    },
    ink: {
      lineWidth: 35,
      singleStroke: true,
    },
    filler: {
      removeBrushPreview: true,
      removeCustomSave: true,
    },
    texture: {
    },
    pixelate: {
      removeBrushPreview: true,
      description: t('brush.pixelate.description'),
    }
    
  }

  const compositionMethods = [
    { value: 'source-over', label: t('customize.composition.source-over') },
    { value: 'multiply', label: t('customize.composition.multiply') },
    { value: 'darken', label: t('customize.composition.darken') },
    { value: 'color-burn', label: t('customize.composition.color-burn') },

    { value: 'screen', label: t('customize.composition.screen') },
    { value: 'lighten', label: t('customize.composition.lighten') },
    { value: 'color-dodge', label: t('customize.composition.color-dodge') },

    { value: 'overlay', label: t('customize.composition.overlay') },
    { value: 'exclusion', label: t('customize.composition.exclusion') },

    { value: 'hard-light', label: t('customize.composition.hard-light') },
    { value: 'soft-light', label: t('customize.composition.soft-light') },
    { value: 'difference', label: t('customize.composition.difference') },

    { value: 'hue', label: t('customize.composition.hue') },
    { value: 'saturation', label: t('customize.composition.saturation') },
    { value: 'color', label: t('customize.composition.color') },
    { value: 'luminosity', label: t('customize.composition.luminosity') },
  ];

  const textureCompositionMethods = [
    { value: 'luminosity', label: t('customize.composition.luminosity') },
    { value: 'source-over', label: t('customize.composition.source-over') },
    { value: 'darken', label: t('customize.composition.darken') },

    { value: 'lighten', label: t('customize.composition.lighten') },

    { value: 'hard-light', label: t('customize.composition.hard-light') },
    { value: 'soft-light', label: t('customize.composition.soft-light') },
  ];

  const sparkleShapes = [
    { value: 'square', label: t('customize.shape.square') },
    { value: 'mix', label: t('customize.shape.mix') },
    { value: 'round', label: t('customize.shape.round') },
    { value: 'star', label: t('customize.shape.star') },
    { value: 'spikes', label: t('customize.shape.spikes') },
    { value: 'light', label: t('customize.shape.light') },
    { value: 'triangle', label: t('customize.shape.triangle') },
    { value: 'diamond', label: t('customize.shape.diamond') },
    { value: 'cross', label: t('customize.shape.cross') },
    { value: 'sparkle', label: t('customize.shape.sparkle') },
    { value: 'heart', label: t('customize.shape.heart') },
    { value: 'flower', label: t('customize.shape.flower') },
    { value: 'circle', label: t('customize.shape.circle') },
    { value: 'perimeter', label: t('customize.shape.perimeter') },
    { value: 'horizontalLine', label: t('customize.shape.horizontal_line') },
    { value: 'verticalLine', label: t('customize.shape.vertical_line') },

    // Новые фигуры
    { value: 'moon', label: t('customize.shape.moon') },
    { value: 'cloud', label: t('customize.shape.cloud') },
    { value: 'raindrop', label: t('customize.shape.raindrop') },
    { value: 'snowflake', label: t('customize.shape.snowflake') },
    { value: 'paw', label: t('customize.shape.paw') },
    { value: 'matrix', label: t('customize.shape.matrix') },
    { value: 'stain', label: t('customize.shape.stain') },
    { value: 'klyaksa', label: t('customize.shape.klyaksa') },
    { value: 'shards', label: t('customize.shape.shards') },
  ];

  const shapeFigures = [
    { value: 'line', label: t('customize.figure.line') },
    { value: 'square', label: t('customize.figure.square') },
    { value: 'round', label: t('customize.figure.round') },
    { value: 'arrow', label: t('customize.figure.arrow') },
    { value: 'triangle', label: t('customize.figure.triangle') },
    { value: 'star', label: t('customize.figure.star') },
  ];
  const shapeBrushes = [
    { value: 'plain', label: t('brush.plain.name') },
    { value: 'pencil', label: t('brush.pencil.name') },
    { value: 'bristle', label: t('brush.bristle.name') },
    { value: 'watercolor', label: t('brush.watercolor.name') },
    { value: 'feather', label: t('brush.feather.name') },
    { value: 'ink', label: t('brush.ink.name') },
    { value: 'rembrandt', label: t('brush.rembrandt.name') },
    { value: 'outlined', label: t('brush.outlined.name') },
    { value: 'neon', label: t('brush.outlined.name') },
    { value: 'dashed', label: t('brush.dashed.name') },
    { value: 'glyph', label: t('brush.glyph.name') },
  ];

  const brushParams = {
    common: [
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
      { type: 'toggle', key: 'pressureOpacity', label: t('customize.prop.pressure_opacity') },
      { type: 'slider', key: 'pressureExponent', label: t('customize.prop.pressure_exponent'), min: 1, max: 5, step: 0.01 },
      { type: 'slider', key: 'sensitivity', label: t('customize.prop.sensitivity'), min: 0.1, max: 3, step: 0.01 },
      { type: 'slider', key: 'minimalPressure', label: t('customize.prop.minimal_pressure'), min: 0, max: 1, step: 0.01 },

      { type: 'slider', key: 'smoothing', label: t('customize.prop.smoothing'), min: 0, max: 50, step: 1 },
      { type: 'toggle', key: 'squareBrush', label: t('customize.prop.square_brush') },
      { type: 'select', key: 'composition', label: t('customize.prop.composition'), options: compositionMethods },
      { type: 'toggle', key: 'magnifierButton', label: t('customize.prop.magnifier_button') },
      { type: 'toggle', key: 'brushMagnifier', label: t('customize.prop.brush_magnifier') },
      { type: 'toggle', key: 'eraserMagnifier', label: t('customize.prop.eraser_magnifier') },
    ],
    plain: [
    ],
    shape: [
      { type: 'select', key: 'figure', label: t('customize.prop.figure'), options: shapeFigures },
      { type: 'select', key: 'brush', label: t('customize.prop.brush'), options: shapeBrushes },
    ],
    spray: [
      { type: 'slider', key: 'softness', label: t('customize.prop.softness'), min: 0, max: 0.5, step: 0.01 },
      // { type: 'toggle', key: 'appleSprayFix', label: t('customize.prop.apple_spray_fix') },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    feather: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },

      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },

      { type: 'toggle', key: 'transparentEdges', label: t('customize.prop.transparent_edges') },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnStart', label: t('customize.prop.opacity_on_start'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeStartPoint', label: t('customize.prop.fade_start_length'), min: 0, max: 1, step: 0.01, dependency: {max: 'fadeEndPoint'}  },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnEnd', label: t('customize.prop.opacity_on_end'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeEndPoint', label: t('customize.prop.fade_length_length'), min: 0, max: 1, step: 0.01, dependency: {min: 'fadeStartPoint'}  },

    ],
    ink: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },
      { type: 'slider', key: 'centralWidth', label: t('customize.prop.central_width'), min: 0.00, max: 1, step: 0.01 },

      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },

      { type: 'toggle', key: 'transparentEdges', label: t('customize.prop.transparent_edges') },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnStart', label: t('customize.prop.opacity_on_start'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeStartPoint', label: t('customize.prop.fade_start_length'), min: 0, max: 1, step: 0.01, dependency: {max: 'fadeEndPoint'}  },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnEnd', label: t('customize.prop.opacity_on_end'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeEndPoint', label: t('customize.prop.fade_length_length'), min: 0, max: 1, step: 0.01, dependency: {min: 'fadeStartPoint'}  },

    ],
    bristle: [
      { type: 'slider', key: 'wobble', label: t('customize.prop.wobble'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.002 },
      // { type: 'slider', key: 'ragged', label: t('customize.prop.ragged'), min: 0, max: 3, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    pencil: [
      { type: 'toggle', key: 'outline', label: t('customize.prop.outline') },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },

      { type: 'toggle', key: 'tapering', label: t('customize.prop.tapering') },

      { conditions: ['tapering'], type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { conditions: ['tapering'], type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { conditions: ['tapering'], type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { conditions: ['tapering'], type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },

      { type: 'toggle', key: 'transparentEdges', label: t('customize.prop.transparent_edges') },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnStart', label: t('customize.prop.opacity_on_start'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeStartPoint', label: t('customize.prop.fade_start_length'), min: 0, max: 1, step: 0.01, dependency: {max: 'fadeEndPoint'}  },
      { conditions: ['transparentEdges'], type: 'slider', key: 'opacityOnEnd', label: t('customize.prop.opacity_on_end'), min: 0, max: 1, step: 0.01 },
      { conditions: ['transparentEdges'], type: 'slider', key: 'fadeEndPoint', label: t('customize.prop.fade_length_length'), min: 0, max: 1, step: 0.01, dependency: {min: 'fadeStartPoint'}  },

      { type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    oil: [
      { type: 'toggle', key: 'speedDependence', label: t('customize.prop.speed_dependence') },

      { type: 'slider', key: 'minWidthStart', label: t('customize.prop.min_width_start'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperStartPoint', label: t('customize.prop.taper_start_point'), min: 0, max: 1, step: 0.01, dependency: {max: 'taperEndPoint'} },
      { type: 'slider', key: 'minWidthEnd', label: t('customize.prop.min_width_end'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'taperEndPoint', label: t('customize.prop.taper_end_point'), min: 0, max: 1, step: 0.01, dependency: {min: 'taperStartPoint'} },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    watercolor: [
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'toggle', key: 'spreading', label: t('customize.prop.spreading') },
      { type: 'toggle', key: 'outline', label: t('customize.prop.outline'), min: 0, max: 1, step: 0.1 },
      { conditions: ['outline'], type: 'slider', key: 'outlineSize', label: t('customize.prop.outline_size'), min: 0, max: 10, step: 0.1 },
      { conditions: ['outline'], type: 'slider', key: 'outlineOpacity', label: t('customize.prop.outline_opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'waterBlurSize', label: t('customize.prop.water_blur_size'), min: 0, max: 50, step: 0.5 },
      { type: 'slider', key: 'waterBlurAlpha', label: t('customize.prop.water_blur_alpha'), min: 0, max: 1, step: 0.01 },
      { type: 'toggle', key: 'textureOn', label: t('customize.prop.texture_on') },
      { conditions: ['textureOn'], type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    texture: [
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'blending', label: t('customize.prop.blending'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'textureScale', label: t('customize.prop.texture_scale'), min: 0.1, max: 2, step: 0.01 },
      { type: 'select', key: 'composition', label: t('customize.prop.composition'), options: textureCompositionMethods },
    ],
    rembrandt: [
      { type: 'toggle', key: 'cutEdges', label: t('customize.prop.cut_edges') },
      { conditionsNot: ['cutEdges'], type: 'slider', key: 'tapering', label: t('customize.prop.tapering'), min: 0, max: 1, step: 0.01 },
      { type: 'slider', key: 'density', label: t('customize.prop.density'), min: 0, max: 1, step: 0.01 },
      // { type: 'slider', key: 'ragged', label: t('customize.prop.ragged'), min: 0, max: 3, step: 0.01 },
      { type: 'slider', key: 'wobble', label: t('customize.prop.wobble'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.002 },
      { type: 'slider', key: 'shadowStrength', label: t('customize.prop.shadow_strength'), min: 0, max: 7, step: 0.01 },
      { type: 'toggle', key: 'ropeEffect', label: t('customize.prop.rope_effect') },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    dashed: [
      { type: 'slider', key: 'dashSize', label: t('customize.prop.dash_size'), min: 0, max: 20, step: 0.1 },
      { type: 'slider', key: 'gapSize', label: t('customize.prop.gap_size'), min: 0, max: 20, step: 0.1 },
    ],
    outlined: [
      { type: 'slider', key: 'outlineSize', label: t('customize.prop.outline_size'), min: 0.1, max: 50, step: 0.1 },
      { type: 'color', key: 'outlineColor', label: t('customize.prop.outline_color') },
      { type: 'slider', key: 'outlineOpacity', label: t('customize.prop.outline_opacity'), min: 0, max: 1, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    neon: [
      { type: 'slider', key: 'neonSize', label: t('customize.prop.neon_size'), min: 1, max: 100, step: 1 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0, max: 1, step: 0.01 },
    ],
    sparkle: [
      { type: 'toggle', key: 'glyphOn', label: t('customize.prop.glyph_on') },
      { conditions: ['glyphOn'], type: 'toggle', key: 'polyGlyph', label: t('customize.prop.poly_glyph') },
      { conditions: ['glyphOn'], type: 'glyph', key: 'glyph', label: t('customize.prop.press_glyph') }, // Новая настройка

      { conditionsNot: ['glyphOn'], type: 'select', key: 'sparkleShape', label: t('customize.prop.shape'), options: sparkleShapes },

      // { type: 'slider', key: 'sparkleRadius', label: t('customize.prop.sparkle_radius'), min: 1, max: 50, step: 1 },
      { type: 'toggle', key: 'highDensity', label: t('customize.prop.high_density') },
      { conditionsNot: ['highDensity'], type: 'slider', key: 'sparkleDensity', label: t('customize.prop.sparkle_density'), min: 0.01, max: 1, step: 0.01 },
      { conditions: ['highDensity'], type: 'slider', key: 'sparkleDensity', label: t('customize.prop.sparkle_density'), min: 0.01, max: 8, step: 0.01 },
      { type: 'toggle', key: 'bigSizes', label: t('customize.prop.big_sizes') },
      { conditionsNot: ['bigSizes'], type: 'slider', key: 'sparkleSize', label: t('customize.prop.sparkle_size'), min: 0.5, max: 50, step: 0.1 },
      { conditions: ['bigSizes'], type: 'slider', key: 'sparkleSize', label: t('customize.prop.sparkle_size'), min: 0.5, max: 1000, step: 1 },
      { type: 'toggle', key: 'variableSize', label: t('customize.prop.variable_size') },
      { type: 'slider', key: 'rotate', label: t('customize.prop.rotate'), min: 0, max: 180, step: 1 },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'sparkleDistributionPower', label: t('customize.prop.sparkle_distribution'), min: 0, max: 3, step: 0.01 },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
    ],
    glyph: [
      { type: 'glyph', key: 'glyph', label: t('customize.prop.press_glyph') }, // Новая настройка
      { type: 'toggle', key: 'polyGlyph', label: t('customize.prop.poly_glyph') },
      { type: 'toggle', key: 'pressureOn', label: t('customize.prop.pressure_on') },
      { type: 'toggle', key: 'pressureOpacity', label: t('customize.prop.pressure_opacity') },
      { type: 'toggle', key: 'enhance', label: t('customize.prop.enhance') },
      { type: 'slider', key: 'angle', label: t('customize.prop.angle'), min: 0, max: 360, step: 1 },
      // { type: 'toggle', key: 'mirror', label: t('customize.prop.mirror') },
      { type: 'toggle', key: 'autoRotation', label: t('customize.prop.auto_rotation') },
      { type: 'slider', key: 'stepSize', label: t('customize.prop.step_size'), min: 1, max: 300, step: 0.1 },
      { type: 'toggle', key: 'stepSizeDependence', label: t('customize.prop.step_size_dependence') },
      { type: 'slider', key: 'opacity', label: t('customize.prop.opacity'), min: 0.01, max: 1, step: 0.01 },
      { type: 'toggle', key: 'highTransparency', label: t('customize.prop.high_transparency'), min: 0.01, max: 1, step: 0.01 },
      { type: 'slider', key: 'shadowStrength', label: t('customize.prop.shadow_strength'), min: 0, max: 1, step: 0.01 },

    ],
    filler: [
      { type: 'slider', key: 'tolerance', label: t('customize.prop.tolerance'), min: 0, max: 100, step: 1 },
      { conditionsNot: ['eatEdges'], type: 'slider', key: 'antialiasing', label: t('customize.prop.antialiasing'), min: 0.1, max: 3, step: 0.01 },
      { type: 'toggle', key: 'eatEdges', label: t('customize.prop.eat_edges') },
    ],
    pixelate: [
      { type: 'slider', key: 'size', label: t('customize.prop.size'), min: 1, max: 50, step: 1 },

      { type: 'toggle', key: 'uneven', label: t('customize.prop.uneven') },
      { conditions: ['uneven'], type: 'slider', key: 'sizeX', label: t('customize.prop.size_x'), min: 1, max: 50, step: 1 },
      { conditions: ['uneven'], type: 'slider', key: 'sizeY', label: t('customize.prop.size_y'), min: 1, max: 50, step: 1 },
    ],
    // Add more brush types and their settings as needed
  };

  const brushPreviewCanvasRef = useRef(null);
  const saveTimerRef = useRef(null);
  const isUpdatingPreviewRef = useRef(false);
  
  const [userColorView, setUserColorView] = useState(false);
  const [isGlyphEditorOpen, setIsGlyphEditorOpen] = useState(false);

  const brushKey = ['plain', 'liner'].includes(menu.params.brush) ? 'common' : menu.params.brush;
  const isEffect = menu.params.effect;

  const basicBrushLink = brushLinkFun(brushKey);
  const currentBrushSettings = brushSetsFun(brushKey);
  
  const currentBrushParams = (brushParams[basicBrushLink] || []).filter((brushParam)=>{
    if (brushParam.conditions && !brushParam.conditions.every(condition=>currentBrushSettings[condition])) { return false; }
    if (brushParam.conditionsNot && brushParam.conditionsNot.every(condition=>currentBrushSettings[condition])) { return false; }
    return true;

  });
  const currentBrushView = brushViews[basicBrushLink] || {};
  const currentBrushDefaults = Object.assign({}, brushDefaults[basicBrushLink] || {}, currentBrushSettings.default || {})

  const brushTextures = sources[basicBrushLink] || {};
  const textureNames = Object.keys(brushTextures);
  const hasTextures = currentBrushSettings.textureOn && textureNames.length > 0;


  const handleNewGlyph = (newGlyph) =>{

    const glyphArr = (currentBrushSettings.glyphArr || Object.values(glyphCollection))
    .filter(
      (glyph) => JSON.stringify(glyph) !== JSON.stringify(newGlyph)
    );
    glyphArr.push(newGlyph);
    handleSettingsChange({'glyph': newGlyph, 'glyphArr': glyphArr});
    setIsGlyphEditorOpen(false);


  }

  const checkIfCustomized = (defaults, settings) =>{
    let customized = false;
    for (let param in settings) {
      if (param === 'default') { continue; }
      if (settings[param] !== defaults[param]) {customized = true;}
    }
    return customized;
  }

  const brushIsCustomized = checkIfCustomized(currentBrushDefaults, currentBrushSettings);
  const isUserBrush = user.savedBrushes?.includes(brushKey) || currentBrushSettings.custom;
  const isMarketBrush = user.boughtBrushes?.includes(brushKey) || currentBrushSettings.bought;
  const isBasicBrush = !isUserBrush && !isMarketBrush;
  
  const handleSettingsChange = (sets) => {

    let newBrushSettings = brushSetsFun(brushKey);

    for (let key in sets) {

      let value = sets[key];

      const currentParamItem = currentBrushParams.find(paramItem => paramItem.key === key);

      const stickToDefault = false;
      
      if (stickToDefault && currentParamItem?.type === 'slider' && !isNaN(value) ) {
        const currentBrushDefaults = Object.assign({}, brushDefaults[basicBrushLink] || {}, currentBrushSettings.default || {})
        const defaultValue =  currentBrushDefaults[key] || 1;
        let difference = Math.abs(defaultValue - value) / (currentParamItem.max - currentParamItem.min)
        if (difference < 0.03) { value = defaultValue; }
      }
  
      newBrushSettings[key] = value;
  
      let cdMax = currentParamItem?.dependency?.max;
      let cdMin= currentParamItem?.dependency?.min;
      if (cdMax) {
        newBrushSettings[cdMax] = Math.max(newBrushSettings[cdMax], newBrushSettings[key]);
      }
      if (cdMin) {
        newBrushSettings[cdMin] = Math.min(newBrushSettings[cdMin], newBrushSettings[key]);
      }
  
      for (let param in newBrushSettings) {
        if (newBrushSettings[param] === brushDefaults?.[basicBrushLink]?.[param]) {
          delete newBrushSettings[param];
        }
      }

    }

    user.brush[brushKey] = newBrushSettings;
    menu.methods.render();

    clearTimeout(saveTimerRef.current);
    
    saveTimerRef.current = setTimeout(() => {
      saveUserSets({ [`brush.${brushKey}`]: newBrushSettings });
    }, 1000);

  };

  const handleOverlayClick = () =>{
    if (brush.lastActionTime < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleCloneBrush = () => {

    showPopupMessage({
      title: t('customize.clone_brush_title'),
      message: t('customize.clone_brush_text'),
      input: {
        placeholder: t('customize.clone_brush_placeholder'),
      },
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'submit', type: 'submit', text: t('customize.button_submit')},
      ],

      callback: (buttonId, inputValue) => {
        if (buttonId === 'submit') {
          
          window.croco?.haptic?.();

          const savedBrushes = user.savedBrushes || [];

          let i = 1;
          let newBrushKey = `my_${basicBrushLink}_${i}`;
          while (savedBrushes.includes(newBrushKey)) {
            i ++;
            newBrushKey = `my_${basicBrushLink}_${i}`;
          }

          savedBrushes.push(newBrushKey);

          const settingsToSave = {
            ...customBrushSetsFun(brushKey),
            clone: basicBrushLink,
            label: inputValue,
            created: Date.now(),
            custom: Date.now(),
          }

          delete settingsToSave.default;
          settingsToSave.default = {...settingsToSave}

          saveUserSets({ 
            savedBrushes,
            [`brush.${newBrushKey}`]: settingsToSave,
          })
          openMenu('customizeBrush', {brush: newBrushKey, layer: true});

          useStore.setState({ brushType: newBrushKey});
  
        }
      }
    });

  }

  const handleSaveUserBrush = () => {

    showPopupMessage({
      title: t('customize.save_user_brush_title'),
      message: t('customize.save_user_brush_text', {label: currentBrushSettings.label || ''}),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'save', type: 'submit', text: t('customize.button_save')},
      ],

      callback: (buttonId, inputValue) => {
        if (buttonId === 'save') {
          
          const settingsToSave = customBrushSetsFun(brushKey);
          delete settingsToSave.default;
          settingsToSave.default = { ...settingsToSave }

          saveUserSets({ 
            [`brush.${brushKey}`]: settingsToSave,
          }, ()=>{
            window.croco?.haptic?.();
            menu.methods.render();
          })
  
        }
      }
    });

  }

  const handleDeleteUserBrush = () => {

    const label = currentBrushSettings.label || ''

    showPopupMessage({
      title: t('customize.delete_brush_title', { label }),
      message: t('customize.delete_brush_text', { label }),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'delete', type: 'destructive', text: t('customize.button_delete_confirm')},
      ],

      callback: (buttonId) => {
        if (buttonId === 'delete') {

          let savedBrushes = (user.savedBrushes || [])
          .filter(currentKey=>currentKey !== brushKey);
          let boughtBrushes = (user.boughtBrushes || [])
          .filter(currentKey=>currentKey !== brushKey);

          saveUserSets({ 
            savedBrushes,
            boughtBrushes,
            [`brush.${brushKey}`]: null,
          })
          openMenu('brush');
  
        }
      }
    });

  }

  const handleSellBrush = () => {

    if (!isUserBrush) {
      showTemporaryHint(t('sell.only_custom'));
    } else {
      setShowSellBrushPopup(brushKey)
    }

  }

  const handleResetBrush = () => {

    const label = currentBrushSettings.label || ''

    showPopupMessage({
      title: t('customize.reset_brush_title', { label }),
      message: t('customize.reset_brush_text', { label }),
      buttons: [
        { type: 'cancel', text: t('customize.button_cancel')},
        { id: 'reset', type: 'destructive', text: t('customize.button_reset_confirm')},
      ],
      callback: (buttonId) => {
        if (buttonId === 'reset') {

          const oldBrushSettings = customBrushSetsFun(brushKey);

          let restoredBrushSettings = {};

          if (oldBrushSettings?.clone) {
            restoredBrushSettings = { ...(oldBrushSettings?.default || {
              clone: oldBrushSettings.clone,
              label: oldBrushSettings.label,
            }) };
            delete restoredBrushSettings.default;
            restoredBrushSettings.default = { ...restoredBrushSettings} 
          }

          const needToSave = ['glyphArr'];

          for (let param of needToSave) {
            if (oldBrushSettings[param]) {
              restoredBrushSettings[param] = oldBrushSettings[param]
            }
          }

          saveUserSets({ 
            [`brush.${brushKey}`]: restoredBrushSettings,
          })
          menu.methods.render();
        }
      }
    });

  }

  const updateBrushPreview = async () => {

    if (isUpdatingPreviewRef.current) { return; }
    const canvas = brushPreviewCanvasRef.current;

    if (canvas) {
      isUpdatingPreviewRef.current = true;

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      const sets = brushSetsFun(brushKey);
      const commonSets = brushSetsFun('common');
      Object.assign(sets, commonSets);

      let points = generateWavePoints({
        width: 260,
        height: 200,
        steps: 30,
        amplitude: 30,
        frequency: 0.025,
        x: 40,
        y: 0,
      } );

      const smooth = (!sets.speedDependence && !sets.spreading);
      if (smooth) {
        points = filterClosePoints?.(points, Math.round(commonSets.smoothing)) || points;
      }

      const currentLineWidth = Math.min(120, Math.max(36, lineWidth))

      const strokeTemplate = {
        type: 'stroke',
        color: color,
        gradientColor: gradientColorFun(),
        lineWidth: currentBrushView.lineWidth || currentLineWidth,
        softness,
        brush: isEffect ? null : basicBrushLink,
        effect: isEffect ? basicBrushLink : null,
        sets,
      }

      let strokes = [
        {
          points: points.map(point=>({x: point.x - 10, y: point.y - 20})),
          time: 0,
        },
        {
          points: points.map(point=>({x: point.x + 10, y: point.y +20})),
          time: 0 + 1,
        },
      ].map(stroke=> Object.assign({}, strokeTemplate, stroke))


      if (user.singleStroke) { 
        strokes = [
          {
            points,
            time: 0,
          },
        ].map(stroke=> Object.assign({}, strokeTemplate, stroke));
      }

      if (sets.ragged) {
        // strokes.forEach(stroke=>{
        //   const points = stroke.points;
        //   points.forEach((point, i)=>{
        //     point.pressure = (point.pressure || 1) * Math.exp(-sets.ragged * seededRandom(i));
        //   }) 
        // })
      }

      if (brushKey === 'common') { strokes[0].color = '#AAAAAA' }

      for (let stroke of strokes) {
        context.globalCompositeOperation = stroke.sets?.composition || 'source-over';
        await drawing.methods.drawStroke(stroke, context)
      }
      isUpdatingPreviewRef.current = false;


    }
  };

  useEffect(() => {
    updateBrushPreview(currentBrushSettings);
  }, [currentBrushSettings, lineWidth]);


  const drawTextureCanvas = async (textureCanvas, brushKey, textureName) => {
    if (!textureCanvas) return;
  
    const texture = await getTexture(brushKey, textureName, 'stroke');
    if (!texture) { return; }
  
    const context = textureCanvas.getContext('2d');
  
    // Clear the canvas
    context.clearRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Compute the drawing dimensions
    const drawWidth = texture.width * convy.scale;
    const drawHeight = texture.height * convy.scale;
  
    // Draw the texture onto the canvas
    context.drawImage(texture, 0, 0, drawWidth, drawHeight);
  
    // Apply black color using 'source-in' composite operation
    context.globalCompositeOperation = 'source-in';
    context.fillStyle = 'black';
    context.fillRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Set composite operation to 'destination-over' to draw white background
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = 'white';
    context.fillRect(0, 0, textureCanvas.width, textureCanvas.height);
  
    // Reset composite operation to default
    context.globalCompositeOperation = 'source-over';
  };

  const renderSetting = (setting) => {
    
    switch (setting.type) {
      case 'toggle':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <input
                type="checkbox"
                checked={currentBrushSettings[setting.key] || false}
                onChange={(e) => handleSettingsChange({[setting.key]: e.target.checked})}
              />
              <span>{t(setting.label)}</span>
            </label>
          </div>
        );

      case 'slider':
        return (
          <div className="settings-item touch-active" key={setting.key}>
            <label className="settings-label touch-active">
              <span>{t(setting.label)}: {currentBrushSettings[setting.key]}</span>
            </label>
            <input
              type="range"
              className="customize-brush-slider touch-active"
              min={setting.min}
              max={setting.max}
              step={setting.step}
              value={currentBrushSettings[setting.key] ?? setting.min}
              onChange={(e) => handleSettingsChange({[setting.key]: Number(e.target.value)})}
              onMouseDown={(e) => { 
                if (isAppleDevice()) { e.preventDefault()  }
              }}
            />
          </div>
        );
      case 'select':
        return (
          <div className="settings-item" key={setting.key}>
            <label className="settings-label">
              <span>{t(setting.label)}</span>
            </label>
            <select
              value={currentBrushSettings[setting.key] || setting.options[0].value}
              onChange={(e) => handleSettingsChange({[setting.key]: e.target.value})}
              className="customize-brush-select touch-active"
            >
              {setting.options.map((option) => (
                <option value={option.value} key={option.value}>
                  {t(option.label)}
                </option>
              ))}
            </select>
          </div>
        );

      case 'color':
        return (
          <div
            className="settings-item touch-active"
            key={setting.key}
            onClick={() => setColorPickerState({ isOpen: true, key: setting.key })}
          >
            <label
              className="settings-label"
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <span
                className="settings-color-indicator"
                style={{
                  backgroundColor: currentBrushSettings[setting.key] || '#000000',
                }}
              ></span>
              <span>{t(setting.label)}</span>
            </label>
          </div>
        );
   
        case 'glyph':
          return (
            <GlyphSelector
              currentBrushSettings={currentBrushSettings}
              handleNewGlyph={handleNewGlyph}
              handleSettingsChange={handleSettingsChange}
              onOpenGlyphEditor={() => setIsGlyphEditorOpen(true)}
              showTemporaryHint={showTemporaryHint}
            />
          );
      
        default:
        return null;
    }
  };


  const TextureSelector = () => (
    <div className="texture-selection">
            <span className="section-title">{t('customize.prop.texture', { textureName: currentBrushSettings.texture })}</span>
            <div className="texture-options">
              {textureNames.map((textureName) => (
                <div
                  key={textureName}
                  className={`texture-option ${currentBrushSettings.texture === textureName ? 'active' : ''}`}
                  onClick={() => handleSettingsChange({'texture': textureName})}
                >
                  <canvas
                    ref={(textureCanvas) => drawTextureCanvas(textureCanvas, basicBrushLink, textureName)}
                    width={50}
                    height={50}
                    { ...createBetterClick({
                      onClick: () => handleSettingsChange({'texture': textureName}),
                      onContextMenu: () => handleSettingsChange({'texture': textureName}),
                    }) }
                  />
                </div>
              ))}
            </div>
          </div>
  );


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="customize-brush-menu scroll-active touch-active">
        <span className="customize-brush-title">
          {currentBrushSettings.label ||  t(`brush.${brushKey}.name`)}
        </span>

        { !currentBrushView.removeBrushPreview && (
          <div className = 'customize-brush-preview-row'>

          <button className="glyph-action-button paste-button" onClick={()=>saveUserSets({singleStroke: !user.singleStroke})}>
            {user.singleStroke ? '1' : '2'}
          </button>
            
            <div 
              onClick={()=>{setUserColorView(true)}}
              style={{ 
                width: `${300 * convy.scale}px`,
                height: `${200 * convy.scale}px`,
                transform: 'translate(-15%)'
              }}
            >
              <canvas 
                ref={brushPreviewCanvasRef} 
                width="300" 
                height="200" 
                style={{ 
                  transform: `scale(${convy.scale})`,
                  transformOrigin: 'top left',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div style={{
                transform: 'translate(-30px)'
            }}>
              <BrushSizeControl 
                store={store}
                icon={<FaPencilRuler />}
                defaultBrushSize = {36}
                hideButton = {true}
                hideInidicator = {true}
              />
            </div>
            
          </div>
        )}

        { currentBrushSettings.author && (
          <div className='settings-item'>
            <label className="customize-brush-label">
              <span>©️{ currentBrushSettings.author.first_name } {currentBrushSettings.price ? ` | ${currentBrushSettings.price}🪙` : ''}</span>
            </label>
          </div>
          
        )}
        { currentBrushSettings.author && currentBrushSettings.about && (
          <div className='settings-item'>
            <label className="customize-brush-label">
              <span>{ currentBrushSettings.about }</span>
            </label>
          </div>
        )}

        { currentBrushView.description && (
          <div className='settings-item'>
            <label className="customize-brush-label" style={{opacity: 0.5}}>
              {currentBrushView.description}
            </label>
          </div>
        )}
        
        {currentBrushParams.map(renderSetting)}
        {hasTextures && TextureSelector()}


        { isMarketBrush ? (
        <button 
          className="wide-button" 
          onClick={handleResetBrush}
          style={{
            opacity: brushIsCustomized ? 1 : 0.5,
          }}
          >
            {t('customize.button_reset')}
        </button>
        ) : (
          <div className="icon-button-row">
            <button 
              className="small-button" 
              onClick={handleSellBrush}
              style={{ opacity: isUserBrush ? 1 : 0.5, }}
            >
              <FaDollarSign className="button-small-icon"/>
              {t('sell.menu_button')}
            </button>
            <button 
              className="small-button" 
              onClick={handleResetBrush}
              style={{ opacity: brushIsCustomized ? 1 : 0.5, }}
              >
                {t('customize.button_reset_short')}
            </button>
          </div>
        )}
        
        { isUserBrush && (
          <div className="icon-button-row">
            <button className="icon-button" onClick={handleCloneBrush}>
              <FaClone className='buttons-icon'/>
            </button>
            <button className="icon-button" onClick={handleSaveUserBrush} style={{ opacity: brushIsCustomized ? 1 : 0.5, }}>
              <FaFloppyDisk className='buttons-icon'/>
            </button>
            <button className="icon-button" onClick={handleDeleteUserBrush}>
              <FaTrash className='buttons-icon'/>
            </button>
          </div>
        ) }
        { isMarketBrush && (
          <div className="icon-button-row">
            <button className="icon-button" onClick={handleSaveUserBrush} style={{ opacity: brushIsCustomized ? 1 : 0.5, }}>
              <FaFloppyDisk className='buttons-icon'/>
            </button>
            <button className="icon-button" onClick={handleDeleteUserBrush}>
              <FaTrash className='buttons-icon'/>
            </button>
          </div>
        ) }

        { isBasicBrush 
        && brushKey !== 'common' 
        && !menu.params.effect 
        && !currentBrushView.removeCustomSave 
        && (
            <button 
              className="wide-button" 
              onClick={handleCloneBrush}
              style={{
                // opacity: brushIsCustomized ? 1 : 0.5,
              }}
              >
                <FaClone style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}}/>
                {t('customize.button_clone')}
            </button>
          ) 
        }

        <div className="button-row">
          <button className="small-gray-button" onClick={()=>{openMenu(menu.params?.backMenu || 'brush')}}>
            {t('customize.button_back')}
          </button>
          <button className="small-gray-button" onClick={()=>{closeMenu()}}>
            {t('customize.button_close')}
          </button>
        </div>

        <button className="close-button" onClick={()=>{closeMenu()}}>&#10006;</button>

        { userColorView && (
          <>
            <div className="overlay-background" style={{zIndex: 1400}} onClick={()=>setUserColorView(false)}></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker 
                color={color} 
                onChange={(newColor)=>{ setTheColorFun(newColor); }}
              />
              <button className="wide-button" onClick={()=>setUserColorView(false)} style={{marginTop: '10px'}}>
                {t('settings.set_color')}
              </button>
            </div>
          </>
        ) }

        {colorPickerState.isOpen && (
          <>
            <div
              className="overlay-background"
              style={{ zIndex: 1400 }}
              onClick={() => setColorPickerState({ isOpen: false, key: null })}
            ></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker
                color={currentBrushSettings[colorPickerState.key] || '#000000'}
                onChange={(newColor) => handleSettingsChange({[colorPickerState.key]: newColor})}
              />
              <button
                className="wide-button"
                onClick={() => setColorPickerState({ isOpen: false, key: null })}
                style={{ marginTop: '10px' }}
              >
                {t('settings.set_color')}
              </button>
            </div>
          </>
        )}
      
      </div>

      {isGlyphEditorOpen && (
          <GlyphEditor
            store={store}
            currentGlyph={currentBrushSettings.glyph || glyphCollection.star} // Текущий глиф или звезда по умолчанию
            handleNewGlyph={handleNewGlyph}
            onCancel={() => setIsGlyphEditorOpen(false)} // Закрываем редактор без сохранения
          />
        )}
      {showSellBrushPopup && (
        <SellBrushPopup
          store={store}
          brushKey={brushKey}
          closePopup={()=>{setShowSellBrushPopup(null)}}
          handleSettingsChange={handleSettingsChange}
        />
        )}
    </>
  );
}

export default CustomizeBrush;