// interfaceOffset.js
const interfaceOffset = (store)=>{

  const { ref, zoomFactor } = store;
  const { menu, convy } = ref;

  if (!convy.main.ref) { return {
    controlButtonsStyle: { opacity: 0 },
    drawingAreaStyle: { opacity: 0 },
  }; }

  let controlButtonsStyle = { top: 420 };
  let drawingAreaStyle = { 
    height: `100%`,
    // height: `${Math.max(convy.basicSize * 1.35, window.innerHeight)}px`,
    marginTop: 0,
  };

  const rect = convy.main.ref.getBoundingClientRect();
  menu.panel.rect = rect;

  const isHorizontal = window.innerWidth > window.innerHeight;
  const isFullscreen = window.Telegram?.WebApp?.isFullscreen;

  const horizontalFullscreen = isFullscreen && isHorizontal; 
  const verticalFullscreen = isFullscreen && !isHorizontal; 

  const smallHeight = window.innerHeight < 600
  const distanceFromBottom = 
  smallHeight ? 145 : 
  verticalFullscreen ? 260 : 
  horizontalFullscreen ? 185 : 
  160;

  // const fullScreenRectCorrection = 0;
  const distanceFromRect = 
  verticalFullscreen ? - window.Telegram?.WebApp?.contentSafeAreaInset?.top - 30 :
  horizontalFullscreen ? - window.Telegram?.WebApp?.contentSafeAreaInset?.top + 25 : 15

  // PANEL STYLE
  if (zoomFactor === convy.zoom.minZoomFactor) {
    menu.panel.stuck = false;
  } else if (window.innerHeight - rect.bottom < 220) {
    menu.panel.stuck = true;
  }
  

  if (menu.panel.stuck) {
    menu.panel.offset = window.innerHeight - distanceFromBottom;
    controlButtonsStyle = {
      top: `${menu.panel.offset}px`,
    }
  } else {
    menu.panel.offset = Math.min(rect.bottom + distanceFromRect, window.innerHeight - distanceFromBottom);
    controlButtonsStyle = {
      top: `${menu.panel.offset}px`,
    }
  }

  menu.panel.fastColorIndicatorY = verticalFullscreen ? menu.panel.offset + 120 : menu.panel.offset + 35;

  // DRAWING ARIA STYLE
  if (isFullscreen) {
    if (isHorizontal) {
      drawingAreaStyle.marginTop = window.Telegram?.WebApp?.contentSafeAreaInset.top / 2
    } else {
      drawingAreaStyle.marginTop = window.Telegram?.WebApp?.contentSafeAreaInset.top + 30
    }
  } else {
    drawingAreaStyle.marginTop = 0
  }


  return { controlButtonsStyle, drawingAreaStyle };

} 

export default interfaceOffset