// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* add-scroll.css */

.add-scroll {
  max-height: 90vh; /* или конкретное значение в px */
  overflow-y: auto;
  touch-action: pan-y;

  scrollbar-width: thin;
  scrollbar-color: var(--tg-theme-hint-color) var(--tg-theme-bg-color);
}

.add-scroll::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.add-scroll::-webkit-scrollbar-track {
  background: var(--tg-theme-bg-color);
}

.add-scroll::-webkit-scrollbar-thumb {
  background-color: var(--tg-theme-hint-color);
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/add-scroll.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;EACE,gBAAgB,EAAE,iCAAiC;EACnD,gBAAgB;EAChB,mBAAmB;;EAEnB,qBAAqB;EACrB,oEAAoE;AACtE;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,4CAA4C;EAC5C,kBAAkB;AACpB","sourcesContent":["/* add-scroll.css */\r\n\r\n.add-scroll {\r\n  max-height: 90vh; /* или конкретное значение в px */\r\n  overflow-y: auto;\r\n  touch-action: pan-y;\r\n\r\n  scrollbar-width: thin;\r\n  scrollbar-color: var(--tg-theme-hint-color) var(--tg-theme-bg-color);\r\n}\r\n\r\n.add-scroll::-webkit-scrollbar {\r\n  display: block;\r\n  width: 8px;\r\n}\r\n\r\n.add-scroll::-webkit-scrollbar-track {\r\n  background: var(--tg-theme-bg-color);\r\n}\r\n\r\n.add-scroll::-webkit-scrollbar-thumb {\r\n  background-color: var(--tg-theme-hint-color);\r\n  border-radius: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
