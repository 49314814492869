import { newCanvas } from './canvas';
import { fillGradient } from './gradient'; 

export async function drawTexturedStroke ({
  color,
  gradientColor,
  points,
  lineWidth,

  bufferCtx,
  strokeMaskCtx,
  texture,
  textureScale,
}) {

  const strokeMaskCanvas = strokeMaskCtx.canvas;
  strokeMaskCtx.globalCompositeOperation = 'source-in';
  
  const patternCanvas = document.createElement('canvas');

  patternCanvas.width = texture.width * textureScale;
  patternCanvas.height = texture.height * textureScale;
  const patternCtx = patternCanvas.getContext('2d');
  
  patternCtx.scale(textureScale, textureScale);
  patternCtx.drawImage(texture, 0, 0);
  
  const pattern = strokeMaskCtx.createPattern(patternCanvas, 'repeat');
  
  strokeMaskCtx.fillStyle = pattern;
  strokeMaskCtx.fillRect(0, 0, strokeMaskCanvas.width, strokeMaskCanvas.height);

  fillGradient(strokeMaskCtx, points, color, gradientColor, lineWidth)

  // Добавляем текстурированный мазок на буфер
  bufferCtx.drawImage(strokeMaskCanvas, 0, 0);

  
}

export async function drawNoTextureStroke ({
  color,
  gradientColor,
  points,
  lineWidth,
  strokeMaskCtx,
  context,
}) {

  const strokeMaskCanvas = strokeMaskCtx.canvas;
  strokeMaskCtx.globalCompositeOperation = 'source-in';
  strokeMaskCtx.fillStyle = 'black';
  strokeMaskCtx.fillRect(0, 0, strokeMaskCanvas.width, strokeMaskCanvas.height);
  fillGradient(strokeMaskCtx, points, color, gradientColor, lineWidth)
  // Добавляем текстурированный мазок на буфер
  context.drawImage(strokeMaskCanvas, 0, 0);
  
}

export function applyTextureByStrokeMask({
  context,
  bufferCtx,
  texture,
  strokeMaskCtx,
  color,
  opacity,
  textureScale,
  composition = 'source-over',
}) {

  const canvas = context.canvas;
  const width = canvas.width;
  const height = canvas.height;

  // Создаем временный канвас для результата
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = width;
  tempCanvas.height = height;
  const tempCtx = tempCanvas.getContext('2d');

  // Копируем оригинальное изображение на временный канвас
  tempCtx.drawImage(canvas, 0, 0);

  if (color) {

    applyColoredTextureMask({ 
      context: tempCtx, 
      texture,
      color,
      opacity,
      textureScale,
     });
  
    // Сохраняем текущее состояние контекста
    context.save();
  
    // Рисуем результат только в области штриха
    context.globalCompositeOperation = composition
    context.drawImage(tempCanvas, 0, 0);
  
    // Очищаем область вне штриха
    context.globalCompositeOperation = 'destination-in';
    context.drawImage(strokeMaskCtx.canvas, 0, 0);
  
    // Восстанавливаем исходное состояние контекста
    context.restore();


  } else {

    // Применяем текстурную маску к временному канвасу
    applyTransparencyTextureMask({ 
      context: tempCtx, 
      texture,
      textureScale,
    });

      // Сохраняем текущее состояние контекста
    context.save();

    // Очищаем область вне штриха
    context.globalCompositeOperation = 'destination-in';
    context.drawImage(strokeMaskCtx.canvas, 0, 0);

    // Рисуем результат только в области штриха
    context.globalCompositeOperation = 'source-in';
    context.drawImage(tempCanvas, 0, 0);

    // Восстанавливаем исходное состояние контекста
    context.restore();

  }

  

}


export function applyTransparencyTextureMask({ 
  context, 
  texture,
  textureScale,
 }) {
  const canvas = context.canvas;
  const width = canvas.width;
  const height = canvas.height;

  // Создаем временный канвас для инвертированной текстуры
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = texture.width * textureScale;
  tempCanvas.height = texture.height * textureScale;
  const tempCtx = tempCanvas.getContext('2d');
  
  tempCtx.scale(textureScale, textureScale);

  // Рисуем текстуру на временном канвасе
  tempCtx.drawImage(texture, 0, 0);

  // Создаем паттерн из инвертированной текстуры
  const pattern = context.createPattern(tempCanvas, 'repeat');

  // Сохраняем текущее состояние контекста
  context.save();

  // Устанавливаем режим наложения
  context.globalCompositeOperation = 'destination-in';

  // Заполняем канвас паттерном
  context.fillStyle = pattern;
  context.fillRect(0, 0, width, height);

  // Восстанавливаем исходное состояние контекста
  context.restore();
}

export function applyColoredTextureMask({ 
  context, 
  texture,
  color,
  opacity,
  textureScale,
}) {
  const canvas = context.canvas;
  const width = canvas.width;
  const height = canvas.height;

  // Создаем временный канвас для создания цветной текстуры
  const tempCanvas = document.createElement('canvas');
  // tempCanvas.width = texture.width;
  // tempCanvas.height = texture.height;
  // const tempCtx = tempCanvas.getContext('2d');

  tempCanvas.width = texture.width * textureScale;
  tempCanvas.height = texture.height * textureScale;
  const tempCtx = tempCanvas.getContext('2d');

  tempCtx.scale(textureScale, textureScale);

  // Рисуем оригинальную текстуру на временном канвасе
  tempCtx.drawImage(texture, 0, 0);

  // // Инвертируем альфа-канал текстуры
  // const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
  // for (let i = 3; i < imageData.data.length; i += 4) {
  //   imageData.data[i] = 255 - imageData.data[i];
  // }
  // tempCtx.putImageData(imageData, 0, 0);
  

  // Применяем цвет к текстуре
  tempCtx.globalCompositeOperation = 'source-in';
  // tempCtx.fillStyle = color;
  // tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

  // Создаем паттерн из цветной текстуры
  const pattern = context.createPattern(tempCanvas, 'repeat');

  // Сохраняем текущее состояние контекста
  context.save();

  // Устанавливаем прозрачность
  context.globalAlpha = opacity;

  // Заполняем канвас паттерном
  context.fillStyle = pattern;
  context.fillRect(0, 0, width, height);

  // Восстанавливаем исходное состояние контекста
  context.restore();
}

export function drawTextureByMask({
  context,
  bufferCtx,
  strokeMaskCtx,
  texture,
}) {
  const { width, height } = context.canvas;
  
  // Создаем временный канвас для работы
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = width;
  tempCanvas.height = height;
  const tempCtx = tempCanvas.getContext('2d');

  // Копируем оригинальное изображение на временный канвас
  tempCtx.drawImage(context.canvas, 0, 0);

  // Создаем канвас для текстуры
  const textureCanvas = document.createElement('canvas');
  textureCanvas.width = width;
  textureCanvas.height = height;
  const textureCtx = textureCanvas.getContext('2d');

  // Создаем паттерн из текстуры
  const pattern = textureCtx.createPattern(texture, 'repeat');
  textureCtx.fillStyle = pattern;
  textureCtx.fillRect(0, 0, width, height);

  // Применяем маску к текстуре
  textureCtx.globalCompositeOperation = 'destination-in';
  textureCtx.drawImage(strokeMaskCtx.canvas, 0, 0);

  // Очищаем временный канвас в месте под текстурой
  tempCtx.globalCompositeOperation = 'destination-over';
  tempCtx.fillStyle = 'white';
  tempCtx.fillRect(0, 0, width, height);

  // Накладываем текстуру на временный канвас
  tempCtx.globalCompositeOperation = 'overlay'; // Вы можете экспериментировать с разными режимами
  tempCtx.drawImage(textureCanvas, 0, 0);

  // Создаем маску для ограничения области изменений
  tempCtx.globalCompositeOperation = 'destination-in';
  tempCtx.drawImage(strokeMaskCtx.canvas, 0, 0);

  // Возвращаем результат на исходный канвас
  bufferCtx.drawImage(tempCanvas, 0, 0);

}


export function createStrokeMask (stroke, protoCtx, drawStroke) {

  const maskCtx = newCanvas(protoCtx);
  maskCtx.lineCap = 'round';
  maskCtx.lineJoin = 'round';
  drawStroke(stroke, maskCtx)
  return maskCtx;
  
}

export function duplicateCanvasContext(originalCtx) {

  const cloneCtx = newCanvas(originalCtx);
  cloneCtx.lineCap = originalCtx.lineCap;
  cloneCtx.lineJoin = originalCtx.lineJoin;
  cloneCtx.drawImage(originalCtx.canvas, 0, 0);
  
  return cloneCtx;
}

