// EraserMenu
import './BrushMenu.css'; // Подключаем стили
import React from 'react';
import useStore from '../../../store';
import { 
  FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPenFancy, FaPaintBrush,
  FaDotCircle,
  FaPaw,
  FaCog, 
  FaDrawPolygon,
 } from 'react-icons/fa';
import { 
  FaMarker,
  FaEraser,
  FaBroomBall,
  FaRegLightbulb,
 } from 'react-icons/fa6';
import { 
  TbLineDashed, TbBrush, TbSparkles, TbTexture,
  TbRosette,
 } from "react-icons/tb";

import { useTranslation } from 'react-i18next';
import { createBetterClick } from '../../../utils/interface/createBetterClick'

const brushIcons = {
  'liner': FaEraser,
  'plain': FaMarker,
  'spray': FaSprayCan,
  'bristle': FaPaintBrush,
  'feather': FaFeatherAlt,
  'ink': FaPenFancy,
  'pencil': FaPencilAlt,
  'oil': FaBrush,
  'rembrandt': FaBroomBall,
  'watercolor': TbBrush,
  'outlined': FaDotCircle,
  'dashed': TbLineDashed,
  'neon': FaRegLightbulb,
  'sparkle': TbSparkles,
  'glyph': TbRosette,
  'shape': FaDrawPolygon ,
  'test': FaPaw,
  'texture': TbTexture, // Удалить потом
}

function EraserMenu({ store }) {

  const { t } = useTranslation();

  useStore(state=>state.render);
  const eraserType = useStore(state=>state.eraserType);

  const { ref } = store;
  const { menu, brush, info, work, premium } = ref;
  const { game, user } = info;

  const {
    premiumFeatures = [],
  } = premium;

  const {
    openMenu,
    closeMenu,
    delayPremiumMenu,
  } = menu.methods;

  const {
    saveUserSets,
  } = work.methods;

  const {
    brushSetsFun,
    brushLinkFun,
    customBrushSetsFun,
    changeEraserType,
  } = brush.methods;

  let brushes;
  
  if (game.mode === 'sprint') {

    brushes = [
      'plain',
      'shape',
      'outlined',
      'dashed',
    ].map(key=>{
      return {
        key,
        label: t(`brush.${key}.name`),
        icon: brushIcons[key],
      }
    });
  
  } else {

    const basicBrushes = [
      'liner',
      'plain',
      'shape',
      'spray',
      'bristle',
      'feather',
      'ink',
      'pencil',
      'oil',
      'rembrandt',
      'dashed',
      'neon',
      'sparkle',
      'glyph',

      // 'outlined',
      // 'watercolor',
    ].map(key=>{
      return {
        key,
        label: t(`brush.${key}.name`),
        icon: brushIcons[key],
      }
    });
  
    const savedBrushes = user.savedBrushes || []
    const userBrushes = savedBrushes.map(key=>{
      const brushSettings = customBrushSetsFun(key);
      const basicBrushLink = brushSettings.clone;
      return {
        key,
        label: brushSettings.label,
        icon: brushIcons[basicBrushLink],
      }
    })
  
    brushes = [
      ...basicBrushes,
      ...userBrushes,
    ]

  }

  const handleOverlayClick = () =>{
    if (brush.lastActionTime < Date.now() - 300) {
      closeMenu(); 
    }
  }

  const handleSwitchBrush = (newBrushType) => {

    if (brush.lastActionTime > Date.now() - 300) { return; }

    if (newBrushType !== 'plain' && 
      (!premiumFeatures.includes('brushes') 
      || user.premiumOff)
      ) {
      closeMenu(); 
      delayPremiumMenu('menu');
    } else {

      if (eraserType !== newBrushType) {
        window.croco?.appleHaptic('medium');
        window.croco?.androidHaptic();
      }

      changeEraserType(newBrushType);

      if (newBrushType === 'spray') {

      } else {
        useStore.setState({ softness: 0 })
      }

      brush.brushHistory.push(newBrushType);
      closeMenu(); 

    }

  };

  const handleOpenBrushSettings = (newBrushType, e) => {

    e?.preventDefault(); e?.stopPropagation(); 
    
    brush.lastActionTime = Date.now();
    openMenu('customizeBrush', {brush: newBrushType, layer: true, backMenu: 'eraser'});

    if (newBrushType !== 'plain' && 
      (!premiumFeatures.includes('brushes') 
      || user.premiumOff)
      ) {
      return;
    } 

    if (newBrushType === 'spray') {
      
    } else {
      useStore.setState({ softness: 0 })
    }

    if (newBrushType !== 'common') { 
      brush.brushHistory.push(newBrushType);
      changeEraserType(newBrushType);
    }


  }


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="brush-menu">
      
        <span 
          className="brush-menu-title"
          { ...createBetterClick({
            onClick: (e) => {handleOpenBrushSettings('common', e)},
            onContextMenu: (e) => {handleOpenBrushSettings('common', e)},
          }) }
        >
          {t('brush.eraser_title')}
        </span>
        
        <div className="brush-menu-list add-scroll touch-active">

          {brushes.map((brush) => (
            <div key={brush.key} 
            className={`brush-option-row ${eraserType === brush.key ? 'active' : ''}`}
            // onClick={()=>handleSwitchBrush(brush.key)}
            // onContextMenu={(e)=>{handleOpenBrushSettings(brush.key, e)}}
            { ...createBetterClick({
              onClick: (e) => {handleSwitchBrush(brush.key)},
              onContextMenu: (e) => {handleOpenBrushSettings(brush.key, e)},
            }) }
            >
              <label className="brush-label">
                <brush.icon className={`brush-icon  ${eraserType === brush.key ? 'active' : ''}`}/>
                <span> {brush.label}</span>
                {eraserType === brush.key && (
                <FaCog
                  className="brush-settings-icon"
                  { ...createBetterClick({
                    onClick: (e) => {handleOpenBrushSettings(brush.key, e);},
                    onContextMenu: (e) => {handleOpenBrushSettings(brush.key, e);},
                  }) }
                />
              )}
              </label>
            </div>
            
          ))}
        </div>
      </div>
    </>
  );
}

export default EraserMenu;
