// BrushSizeControl.js
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';

import BrushSizeIndicator from './BrushSizeIndicator'; 
// import { MdVerticalAlignCenter } from 'react-icons/md';
import { filterPosition } from './Throttler/filterPosition.js'; // путь к вашему хуку


import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";


const BrushSizeControl = ({ 
  gameContext,
  canvasContext,
  brushContext,
  icon,
  defaultBrushSize = 7,
  hideButton = false,
  hideInidicator = false,
 }) => {

    const { t } = useTranslation();

    const {
      showTemporaryHint, 

      appleHaptic,
    } = gameContext;
    const {
      visibleLineWidth, 
      
      actualSoftnessFun,
      setTheSizeFun, 
      visibleColorFun,
    
      isResizingBrush, setIsResizingBrush,
    
      multiplierTextColor,
      brushIndicatorFrame,
      brushSetsFun,
      brushLinkFun,
    
    } = brushContext;

    const {
      zoomFactorRef,
      canvasSideRef,
    } = canvasContext;
  
    const [needTipIcon, setNeedTipIcon] = useState(true);
    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);
    
    // Добавлено: Максимальный размер для отображения размера кисти
    const maxSize = 50; // Максимальный размер круга для отображения
    const displaySize = Math.min(visibleLineWidth, maxSize); // Размер для отображения
    const scale = visibleLineWidth / maxSize; // Коэффициент масштабирования

    const brushSizeText = brushContext.lineWidth.toFixed(Math.min(1, Math.floor(canvasContext.zoomFactor/8)));
    const bushOpacityText = Math.round(chroma(brushContext.color).alpha() * 100);

    const squareIndicator = brushSetsFun('common').squareBrush && ['plain', 'dashed', 'outlined', 'pencil', 'feather'].includes(brushLinkFun());

    const startResizing = (event) => {

        setIsResizingBrush(true);
        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;

        appleHaptic('soft');

    };

    const handleContextMenu = (event) =>{
      event.preventDefault();
      setTheSizeFun(defaultBrushSize);
      showTemporaryHint(t('tooltip.line_width_default', { defaultBrushSize }))
    }



    useEffect(() => {

      const handlePointerMove = (event) => {
          if (!isResizingBrush) return;

          let position = {
            x: event.clientX || event.touches[0]?.clientX,
            y: event.clientY || event.touches[0]?.clientY,
          }

          const filteredPosition = filterPosition(position, 'pointerPosition', 100)

          const diffY = pointerPosition.y - filteredPosition.y;
          setPointerPosition(filteredPosition); // Обновление позиции кружка

          if (diffY === 0) {return;}

          const deviderCoef = 800 / canvasSideRef.current * 1.5;
          let lineWidthChange = Math.min(Math.max((diffY) / deviderCoef, -12), 12) / zoomFactorRef.current;

          setTheSizeFun(currentLineWidth => {
            const visualSize = currentLineWidth * zoomFactorRef.current;
            if (visualSize < 30) {
              lineWidthChange = lineWidthChange / Math.sqrt(30 - visualSize);
            } 
            return Math.max(1, currentLineWidth + lineWidthChange)
          })
          hasMoved.current = true; // Установка при перемещении
      };

      const handleHint = (event) =>{
        if (event?.button === 2) { return }
        showTemporaryHint(t('tooltip.line_width_button'), {}, {top: '45%'})
      }

      const handlePointerUp = (event) => {

          setIsResizingBrush(false);

          if (hasMoved.startTouchPoint) {
            const y = event.clientY || event.touches[0]?.clientY;
            const diffY = hasMoved.startTouchPoint.clientY - y;

            if (Date.now() - hasMoved.startTime < 200 && Math.abs(diffY) < 5) {
              handleHint(event);
            } else {}

            setNeedTipIcon(false);
          }

    
      };

      if (isResizingBrush) {
        document.addEventListener('mousemove', handlePointerMove);
        document.addEventListener('mouseup', handlePointerUp);
        document.addEventListener('touchmove', handlePointerMove);
        document.addEventListener('touchend', handlePointerUp);
      }

      return () => {
        document.removeEventListener('mousemove', handlePointerMove);
        document.removeEventListener('mouseup', handlePointerUp);
        document.removeEventListener('touchmove', handlePointerMove);
        document.removeEventListener('touchend', handlePointerUp);
      };

    }, [isResizingBrush, visibleLineWidth, setTheSizeFun, pointerPosition.y]);

    const blurSize = `${actualSoftnessFun() * displaySize}px`; // Пример коэффициента умножения на 3

    

    return (
        <div
            className='allow-context-menu'
            onMouseDown={startResizing}
            onContextMenu={handleContextMenu}
            onTouchStart={startResizing}
            style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'ns-resize',
                userSelect: 'none',
                position: 'relative',
                zIndex: 200,
            }}
        >
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 201,  // Make sure this layer is above all others
                opacity: 0,  // Invisible but still interactive
                top: 0,
                left: 0
            }}></div>

            <div style={{
                width: `${displaySize}px`,
                height: `${displaySize}px`,
                borderRadius: squareIndicator ? '' : '50%',
                background: visibleColorFun(),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                zIndex: 99,
                boxShadow: actualSoftnessFun() ? 0 : brushIndicatorFrame,
                filter: `blur(${blurSize})`, 
            }}>
              <span style={{
                  color: multiplierTextColor,
                  fontSize: '13px',
                  position: 'absolute',
                  left: '51%',
                  top: '47%',
                  transform: 'translate(-50%, -50%)',
                  opacity: scale > 1 ? 0.8 : 0
              }}>
                  x{scale.toFixed(2)}
              </span>
            </div>
              <div style={{
                opacity: (visibleLineWidth < 35) ? 1 : 0,
                transition: 'opacity 200ms',
              }}>

                { hideButton ? null : (
                  <CircleSwitch
                      icon= {needTipIcon ? icon : null}
                      isActive={isResizingBrush}
                      cursor='ns-resize'
                    />   
                )}

                  
                </div>

              <div className="brush-info">
                {brushSizeText}px<br/>
                {bushOpacityText}%
              </div>


            {isResizingBrush && !hideInidicator && (
               <BrushSizeIndicator
                canvasContext={canvasContext}
                brushContext={brushContext}

                position={pointerPosition}
                clickPosition={clickPosition}
                visibleLineWidth={visibleLineWidth}
              />
            )}
            

        </div>
    );
};

export default BrushSizeControl;