import './ControlPanel.css'; // Подключаем стили
import './tools/Palette/ColorPalette.css'; // Подключаем стили

import useStore from '../../store';

import { 
  FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPaintBrush, FaPenFancy, 
  FaFillDrip, FaTint, 
  FaSun, FaMoon, 
  FaHotjar, FaToiletPaper, 
  FaEraser, FaPalette, FaUndoAlt, FaRedoAlt, FaTimes, FaPencilRuler,
  FaDotCircle,
  FaPaw, 
  FaDrawPolygon,
  FaLayerGroup,
} from 'react-icons/fa';
import { 
  FaMarker,
  FaPenToSquare,
  FaEyeDropper,
  FaBroomBall,
  FaRegLightbulb,
} from 'react-icons/fa6';
import { 
  TbPencilDown,
  TbBrush,
  TbLineDashed,
  TbGrain,
  TbContrast2Filled, TbContrast2Off,
  TbZoomIn, TbZoomOut,
  TbSparkles,
  TbTexture,
  TbFilter,
  TbRosette,
 } from 'react-icons/tb';
 import { MdGridOn } from "react-icons/md";

import ColorPalettePopup from './tools/Palette/ColorPalettePopup.js';
import PremiumGrayscalePalette from './tools/Palette/PremiumGrayscalePalette.js';
import GrayscalePicker from './tools/Palette/GrayscalePicker.js';
import EraserColorPicker from './tools/Palette/EraserColorPicker';
import BrushSizeControl from './tools/BrushSizeControl';

import SoftnessControl from './tools/SoftnessControl.js'; 
import BrightnessControl from './tools/BrightnessControl'; 
import OpacityControl from './tools/OpacityControl'; 
import MagnifierIndicator from './tools/Magnifier/MagnifierIndicator.js';

import CircleSwitch from "./tools/CircleSwitch/CircleSwitch.js";

function ControlPanel({ store }) {

    useStore((state) => state.render);

    // const color = useStore((state) => state.color);
    const showPalette = useStore((state) => state.showPalette);
    const premiumGrayscale = useStore((state) => state.premiumGrayscale);
    const activeTool = useStore((state) => state.activeTool);
    const brushType = useStore((state) => state.brushType);
    const eraserType = useStore((state) => state.eraserType);
    const effectType = useStore((state) => state.effectType);
    const isPipettePicking = useStore((state) => state.isPipettePicking);

    const { color } = useStore.getState();

    if (!store.ref.info?.game) { return; }

    const { menu, work, brush, convy, history, info, premium } = store.ref;
    const { game, user } = info;

    const { premiumFeatures = [] } = premium;

    const {
      openMenu,
      showTemporaryHint,
    } = menu.methods;
    const {
      getUserGameSets,
    } = work.methods;
    const { 
      gradientColorFun,
      visibleColorFun,
      changeTool,
      brushLinkFun
    } = brush.methods;
    const { 
      undoLastAction, 
      redoLastAction,
      confirmClearCanvas, 
    } = history.methods;

    const basicBrushLink = brushLinkFun(brushType);

    const iconsForBrush = {
      liner: FaEraser,
      plain: FaMarker,
      stylus: FaPenToSquare,
      shape: FaDrawPolygon,
      outlined: FaDotCircle,
      bristle: FaPaintBrush,
      rembrandt: FaBroomBall,
      feather: FaFeatherAlt,
      ink: FaPenFancy,
      pencil: FaPencilAlt,
      oil: FaBrush,
      watercolor: TbBrush,
      dashed: TbLineDashed,
      spray: FaSprayCan,
      neon: FaRegLightbulb,
      sparkle: TbSparkles,
      glyph: TbRosette,
      test: FaPaw,
    };

    const BrushIcon = iconsForBrush[basicBrushLink] || FaPaintBrush;

    // ERASER
    const basicEraserLink =  brushLinkFun(eraserType);
    const iconsForEraser = {}
    const EraserIcon = iconsForEraser[basicEraserLink] || iconsForBrush[basicEraserLink] || FaPaintBrush;

    // EFFECT
    const EffectIcon = {
      filler: FaFillDrip,
      blur: FaTint,
      noise: TbGrain,
      pixelate: MdGridOn,
      lighten: FaSun,
      darken: FaMoon,
      saturate: FaHotjar,
      desaturate: FaToiletPaper,
      contrast: TbContrast2Filled,
      decontrast: TbContrast2Off,
      zoomin: TbZoomIn,
      zoomout: TbZoomOut,
      texture: TbTexture,
    }[effectType] || TbFilter;

    return (
      <div 
        className='controls-frame'
        ref={(el)=>{ menu.panel.ref = el; }}
      >

        {/* Верхний ряд с инструментами */}
        <div className='controls-row'>

            {
              game?.mode === 'sprint' ? (
                <CircleSwitch
                  icon={<BrushIcon />}
                  isActive={activeTool === "brush"}
                  onClick={() => changeTool("brush")}
                  onContextMenu={()=>{
                    const brush = brushType === 'plain' ? 'common' : brushType;
                    if (['common', 'shape', 'dashed', 'outlined'].includes(brush)) {
                      openMenu('customizeBrush', { brush, layer: true})
                    } else {
                      changeTool("brush")
                    }
                  }}
                />
              ) : 
              (
                <SoftnessControl 
                  store = {store}
                  icon={<BrushIcon />}
                  onContextMenu={()=>{
                    openMenu('customizeBrush', { brush: brushType, layer: true})
                  }}
                />
              )
            }

            <CircleSwitch
              icon={<EraserIcon />}
              isActive={activeTool === "eraser"}
              onClick={() => changeTool("eraser")}
              onContextMenu={()=>{ 
                openMenu('customizeBrush', {brush: eraserType, layer: true, backMenu: 'eraser'})
              }}
              menu={true}
            />

            <OpacityControl 
                store = {store}

                icon= {<EffectIcon />}
                available={premiumFeatures.includes('effect') && !user.premiumOff}

                onContextMenu={()=>{
                  // changeTool("effect");
                  const effect = ['filler', 'pixelate', 'texture'].includes(effectType) ? effectType : 'filler';
                  openMenu('customizeBrush', {brush: effect, effect: true, layer: true, backMenu: 'effect'})
                }}
              />

            <BrightnessControl 
              store = {store}

              icon= {<FaEyeDropper />}
              available={premiumFeatures.includes('pipette') && !user.premiumOff}
            />

            <div 
                className='allow-context-menu'
                style={{
                  // opacity: (activeTool === 'eraser' || (activeTool === 'effect' && effectType !== 'filler')) ? 0.2 : 1,
                }}
                onContextMenu={()=>{
                  useStore.setState({
                    showPalette: true,
                    gradientPalette: getUserGameSets().gradientOn,
                  })
                }}
            >
              <CircleSwitch
                id="paletteToggleButton" 
                icon={<FaPalette />}
                isActive={showPalette}
                color={visibleColorFun()}
                gradientColor={gradientColorFun()}
                onClick={() => useStore.setState({showPalette: true})}
                onTouchEnd={()=>{setTimeout(() => {  useStore.setState({showPalette: true})  }, 100);}}
                svgStyle={activeTool === 'eraser' ? {filter: 'drop-shadow(0px 0px 1px #808080)'} : null}
              />    
            </div>


        </div>
        

        {/* Нижний ряд с управляющими кнопками */}
        <div className='controls-row'>

          <CircleSwitch
            icon={<FaUndoAlt />}
            onClick={(e) => { undoLastAction(); }}
            onContextMenu={()=>{ openMenu('strokeHistory') }}
          />

          <CircleSwitch
            icon={<FaRedoAlt />}
            onClick={(e) => { redoLastAction(); }}
            onContextMenu={()=>{ openMenu('strokeHistory') }}
          />

          <CircleSwitch
            icon={<FaTimes />}
            onClick={(e) => {
              confirmClearCanvas();
            }}
          />
          <CircleSwitch
            icon={<FaLayerGroup />}
            onClick={(e) => {
              if (game?.mode === 'sprint') {
                showTemporaryHint(window.t('tooltip.no_layers_in_sprint'));
              } else {
                openMenu('layer');
              }
            }}
          onContextMenu={()=>{
            openMenu('customizeLayer');
          }}
          />
            <BrushSizeControl 
              store={store}
              icon={<FaPencilRuler />}
            />
          </div>

          {/* Палитра  */}

          {showPalette && (
            activeTool === 'eraser' ? (
              <EraserColorPicker
              store={store}
            />
            ) :
            (!premiumFeatures.includes('colors')
            || user.premiumOff) ? (
       
            <GrayscalePicker
              store={store}
            />
            ) : premiumGrayscale ? (
            <PremiumGrayscalePalette
              store={store}
            />
            ) : (
            <ColorPalettePopup
              store={store}
            />
            )
            )}
            {isPipettePicking && (
              <MagnifierIndicator
                store={store}
              />
            )
          }

      </div>
    );
}

export default ControlPanel;
