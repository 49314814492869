import { use, useEffect, useState, useCallback, } from 'react';
import { useTranslation } from 'react-i18next';

import useStore from '../../store';

import { drawPlainMarkerStroke } from './brushes/marker';
import { drawPencilStroke } from './brushes/pencil';
import { drawWatercolorStroke } from './brushes/watercolor';
import { drawOilStroke } from './brushes/oil';
import { drawDashedStroke } from './brushes/dashed';
import { drawOutlinedStroke } from './brushes/outlined';
import { drawSprayStroke } from './brushes/spray';
import { drawSprayNoBlurStroke } from './brushes/sprayNoBlur';
import { drawFeatherStroke } from './brushes/feather';
import { filterClosePoints } from './brushes/helpers/points';
import { drawBristleStroke } from './brushes/bristle';
import { drawRembrandtStroke } from './brushes/rembrandt';
import { drawSparkleStroke } from './brushes/sparkle';
import { drawNeonStroke } from './brushes/neon';
import { drawGlyphStroke } from './brushes/glyph';

export const useSoftDraw = (ref) => {

  const { t } = useTranslation();
  const { menu, work, telegram, info, convy, drawing, brush, history, } = ref;

  const { 
    showTemporaryHint,
    forceRender,
    openMenu,
  } = menu.methods;

  const {
    isAppleDevice,
    serverTimeFun,
  } = info.methods;

  const {
    saveUserSets,
    getUserGameSets,
  } = work.methods;
  const {
    saveHistory,
  } = history.methods;

  const {
    gradientColorFun,
    brushSetsFun,
    visibleColorFun,
    actualSoftnessFun,

    combinedSetsFun,
    
    brushLinkFun,
    brushKeyFun,

  } = brush.methods;

  const {
    pipette,
  } = brush;

  const {
    userStrokes,
  } = history;

  const {
    zoom,
  } = convy;


  const addReadyMadeStroke = (stroke) =>{

    if (!useStore.getState().canDraw) {
      drawing.isDrawing = null;
      return showTemporaryHint(t('tooltip.cannot_draw'), {force: true, duration: 2000});
    }

    const newStroke = { ...stroke, };

    const layer = convy.methods.getActiveLayer();
    layer.ready = false;

    let myStrokes = userStrokes[telegram.activeUserId];
    myStrokes.push(newStroke);
    userStrokes[telegram.activeUserId] = myStrokes.filter(stroke => !stroke.cancelled);

    saveHistory();
    drawing.methods.redraw();

  }

  const addStroke = (currentPoints, stroke = {}) => {

    if (!useStore.getState().canDraw) {
      drawing.isDrawing = null;
      return showTemporaryHint(t('tooltip.cannot_draw'), {force: true, duration: 2000});
    }

    const { 
      zoomFactor,
      activeTool,
      effectType,
      color,
      softness,
      lineWidth,
     } = useStore.getState();

    let points = currentPoints.map(point=>({
      ...point,
      x: Math.round(point.x * 100) / 100,
      y: Math.round(point.y * 100) / 100,
    }));


    const combinedSets = combinedSetsFun()
    const basicBrushLink = brushLinkFun();

    const smooth = (!combinedSets.speedDependence && !combinedSets.spreading && !stroke.shapeId && !stroke.disableSmoothing);
    if (smooth) {
      points = filterClosePoints(points, Math.round(combinedSets.smoothing / zoomFactor))
    }

    const effect = activeTool === 'effect' ? effectType : null;

    const layer = convy.methods.getActiveLayer();
    const layerLineWidth = lineWidth / layer.info.scale;

    const newStroke = {
      points,
      color: color, 
      gradientColor: gradientColorFun(),
      lineWidth: layerLineWidth, 
      softness: softness, 
      time: drawing.isDrawing?.started,
      step: drawing.isDrawing?.step,
      // time: strokeTime,
      endTime: serverTimeFun(),
      type: 'stroke', // Указываем тип записи как штрих
      userId: telegram.activeUserId,
      tool: activeTool,
      brush: basicBrushLink,
      effect,
      sets: combinedSets,
      layerId: layer.id,
      ...stroke,
    };

    layer.ready = false;

    let myStrokes = userStrokes[telegram.activeUserId];
    myStrokes.push(newStroke);
    userStrokes[telegram.activeUserId] = myStrokes.filter(stroke => !stroke.cancelled);

    drawing.isDrawing = null;

    const brushKey = brushKeyFun();
    brush.steps[brushKey] = brush.steps[brushKey] || 0;
    brush.steps[brushKey] ++;

    // setRedrawer(i => i + 1);
    saveHistory();
    drawing.methods.redraw();

  };

  const countPointPressure = ({ point, nativeEvent }) =>{

    const { activeTool } = useStore.getState();
    const combinedSets = combinedSetsFun();

    if (
      nativeEvent.pointerType === 'pen' 
      && combinedSets.pressureAvailable
      && (combinedSets.pressureOn || combinedSets.pressureOpacity)
      ) {
        point.pressure = nativeEvent.pressure * (combinedSets.sensitivity || 1);

        if (combinedSets.minimalPressure) {
          point.pressure = Math.max(point.pressure, combinedSets.minimalPressure)
        }
        if (combinedSets.pressureExponent > 1) {
          point.pressure = Math.pow(point.pressure, combinedSets.pressureExponent);
        }

        point.pressure  = Math.ceil(point.pressure * 1000) / 1000;

      }


    if (
      ref.info.user.testPressure
      && combinedSets.pressureAvailable
      && (combinedSets.pressureOn || combinedSets.pressureOpacity)
      && activeTool !== 'eraser'
      ) {
      point.pressure = (point.pressure || 1) * Math.exp(-3 * Math.random());
    }


  }

  const startDrawing = ({ nativeEvent }) => {

    if(nativeEvent?.target?.closest('.reference-image-wrapper')) { return; }
    
    const activeLayer = convy.methods.getActiveLayer();
    if (!activeLayer.info.visible || activeLayer.info.deleted) {
      setTimeout(() => {
        showTemporaryHint(t('tooltip.choose_active_layer'));
      }, 500);
      return openMenu('layer');
    }

    if (drawing.isDrawing) {
      if (drawing.isDrawing?.pointerId !== nativeEvent.pointerId) { return; }
      finishDrawing({ force: true });
    }

    if (drawing.animationFrameId) {
      cancelAnimationFrame(drawing.animationFrameId);
      drawing.animationFrameId = null;
    }

    drawing.isDrawingPreview = false;

    if (zoom.isZooming) { return; }

    if (Date.now() - brush.lastActionTime < 100) {return;}

    if (ref.info.user.pointerType !== nativeEvent.pointerType) {
      saveUserSets({ pointerType: nativeEvent.pointerType})
    }

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    useStore.setState({ drawingPosition: position });

    const layer = convy.methods.getActiveLayer();
    const { canvasPoint, layerPoint } = drawing.methods.getLayerCoordinates(nativeEvent, layer.info.clippingFor || layer.id);

    const brushKey = brushKeyFun();

    drawing.isDrawing = { 
      pointerId: nativeEvent.pointerId,
      pointerType: nativeEvent.pointerType,
      started: serverTimeFun(),
      lastMovement: serverTimeFun(),
      lastPoint: layerPoint,
      step: brush.steps[brushKey] || 0,
    };

    const strokeFixStartTime = Math.max(serverTimeFun(), history.lastStrokeTime + Math.round(Math.random() * 50))

    drawing.strokeTime = strokeFixStartTime;
    if (ref.info.game?.mode === 'line') { drawing.methods.redraw(); }

    // const basicBrushLink = brushLinkFun();
    // if (['pencil'].includes(basicBrushLink)) {convy.temp.ctx.globalAlpha = 0.7;} 
    // else if (['watercolor'].includes(basicBrushLink)) {convy.temp.ctx.globalAlpha = 0.5;} 
    // else { convy.temp.ctx.globalAlpha = 1; }
    convy.temp.ctx.globalAlpha = 1;

    countPointPressure({point: layerPoint, nativeEvent})
    countPointPressure({point: canvasPoint, nativeEvent})

    drawing.layerStroke = [layerPoint];
    drawing.previewStroke = [canvasPoint];

    forceRender();

  };


  const continueDrawing = ({ nativeEvent }) => {

    const { zoomFactor, activeTool } = useStore.getState();

    if (!drawing.isDrawing) { return; }
    if (drawing.isDrawing?.pointerId !== nativeEvent.pointerId) { return; }

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };

    useStore.setState({ drawingPosition: position });

    const layer = convy.methods.getActiveLayer();
    const { canvasPoint, layerPoint } = drawing.methods.getLayerCoordinates(nativeEvent, layer.info.clippingFor || layer.id);

    const combinedSets = combinedSetsFun();
    countPointPressure({point: layerPoint, nativeEvent})
    countPointPressure({point: canvasPoint, nativeEvent})
 
    drawing.layerStroke.push(layerPoint);
    drawing.previewStroke.push(canvasPoint);

    let { previewLayer, previewContext, previewLineWidth, previewPoints,  } = definePreviewParams();

    if (!combinedSets?.speedDependence) {
      previewPoints = filterClosePoints(previewPoints, Math.round(combinedSets.smoothing / zoomFactor))
    }

    const stroke = {
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      brush: brushLinkFun(),
      softness: actualSoftnessFun(),
      lineWidth: previewLineWidth,
      points: previewPoints,
      sets: combinedSets,
      time: drawing.isDrawing.started,
      step: drawing.isDrawing.step,
      // time: strokeTime,
      tool: activeTool,
      isEffect: activeTool === 'effect',
    }

    if (drawing.isDrawingPreview) return;
    drawing.isDrawingPreview = true;

    drawing.animationFrameId = requestAnimationFrame(async () => {
      try {
        await drawPreview(stroke, previewContext, previewLayer);
      } finally {
        drawing.isDrawingPreview = false;
      }
    });

    scheduleShape({point: layerPoint, nativeEvent});

  };

  const definePreviewParams = () =>{

    const { lineWidth, activeTool } = useStore.getState();
    const brushLink = brush.methods.brushLinkFun();

    let previewLayer = 'temp';
    let previewContext = convy.temp.ctx;
    let previewLineWidth = lineWidth;
    let previewPoints = drawing.previewStroke;

    const activeLayer = convy.methods.getActiveLayer();

    if (
      activeTool === 'eraser'
      || brushLink === 'sparkle'
      || (activeLayer.info.clippingFor && !info.user.moreCache)
      ) {
      previewLayer = 'preview';
      convy.preview.canvas.width = activeLayer.canvas.width;
      convy.preview.canvas.height = activeLayer.canvas.height;

      previewContext = convy.preview.ctx;
      previewLineWidth = lineWidth / activeLayer.info.scale;
      previewPoints = drawing.layerStroke;
    }
    return { previewLayer, previewContext, previewLineWidth, previewPoints }

  }


  const drawPreview = async (stroke, previewContext = convy.temp.ctx, previewLayer = 'temp')=>{

    previewContext.clearRect(0, 0, previewContext.canvas.width, previewContext.canvas.height);

    const params = { 
      isApple: isAppleDevice(),
    };

    const previewComplexBrushes = true;
    // const previewComplexBrushes = !info.user.moreCache;
    const brush = stroke.brush;

    if (['feather', 'ink'].includes(brush)) {
      await drawFeatherStroke(stroke, previewContext);
    } else if (brush === 'dashed') {
      await drawDashedStroke(stroke, previewContext);
    } else if (brush === 'outlined') {
      await drawOutlinedStroke(stroke, previewContext);
    } else if (brush === 'pencil') {
      await drawPencilStroke(stroke, previewContext);
    } else if (brush === 'watercolor') {
      await drawWatercolorStroke(stroke, previewContext, {...params, preview: true});
    } else if (brush === 'oil') {
      await drawOilStroke(stroke, previewContext, {...params, preview: true});
    } else if (brush === 'bristle' && previewComplexBrushes) {
      await drawBristleStroke(stroke, previewContext);
    } else if (brush === 'rembrandt' && previewComplexBrushes) {
      await drawRembrandtStroke(stroke, previewContext);
    } else if (brush === 'neon' && previewComplexBrushes) {
      await drawNeonStroke(stroke, previewContext);
    } else if (brush === 'sparkle' && previewComplexBrushes) {
      await drawSparkleStroke(stroke, previewContext);
    } else if (brush === 'glyph' && previewComplexBrushes) {
      await drawGlyphStroke(stroke, previewContext, params);
    } else if (brush === 'test' && previewComplexBrushes) {
      // await drawGlyphStroke(stroke, previewContext, params);
    } else if (
      (brush === 'spray' || stroke.softness > 0)
      ) {
      // if (isAppleDevice() && stroke.sets.appleSprayFix) {
      if (isAppleDevice()) {
        await drawSprayStroke(stroke, null, previewContext, params)
        // await drawSprayNoBlurStroke(stroke, null, previewContext)
      } else {
        await drawSprayStroke(stroke, null, previewContext, params)
      }
    } else {
      await drawPlainMarkerStroke(stroke, previewContext)
    }

    if (previewLayer === 'preview') { 
      drawing.methods.redraw({ preview: true }) 
    }

  }

  const finishDrawing = ({ nativeEvent, force }) => {

    if (!drawing.isDrawing) { return; }
    if (drawing.isDrawing.finished) { 
      ref.debug.metrics.errors.push(`‼️ double stroke ${drawing.isDrawing.started}`)
      return; 
    }
    drawing.isDrawing.finished = true;

    if (!force && drawing.isDrawing?.pointerId !== nativeEvent.pointerId) { return };

    addStroke(drawing.layerStroke);

    drawing.layerStroke = [];
    drawing.previewStroke = [];

    // Отменяем запланированный кадр анимации, если он есть
    if (drawing.animationFrameId) {
      cancelAnimationFrame(drawing.animationFrameId);
      drawing.animationFrameId = null;
    }
    drawing.isDrawingPreview = false;


  };

  const scheduleShape = ({ point, nativeEvent }) => {

    if (!useStore.getState().canDraw) { return; }

    const { zoomFactor } = useStore.getState();

    if (!ref.info.user.smartShape) { return; }

    function countDistance(p1, p2) {
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      return Math.sqrt(dx * dx + dy * dy);
    }

    const distance = countDistance(drawing.isDrawing.lastPoint, point) * zoomFactor;

    if (distance > 3) {

      clearTimeout(drawing.isDrawing.shapeTimeout)

      drawing.isDrawing.lastMovement = serverTimeFun();
      drawing.isDrawing.lastPoint = point;

      const started = drawing.isDrawing.started;
      drawing.isDrawing.shapeTimeout = setTimeout(() => {
        createShape({ started, nativeEvent })
      }, 1000);

    } 
  

  }

  const createShape = ({ started, nativeEvent }) => {

    if (!drawing.isDrawing) { return; }
    if (drawing.isDrawing.started !== started) { return; }

    const passed = serverTimeFun() - drawing.isDrawing.lastMovement;
    if (passed < 700) { return; }

    drawing.isDrawing = null;

    const layerShape = require('./smart/autoshape').processPoints(drawing.layerStroke);
    const previewShape = require('./smart/autoshape').processPoints(drawing.previewStroke);

    drawing.layerStroke = [];
    drawing.previewStroke = [];

    window.croco?.haptic?.();
    showTemporaryHint(t('tooltip.shape_transfer_started'), {force: true, duration: 2000});

    drawing.methods.handleSmartTransformStart({
      nativeEvent,
      layerShape,
      previewShape,
    })

  };
   

  const fill = ({nativeEvent}) => {

    const { color } = useStore.getState();

    if (history.isRendering) { return; }

    if (Date.now() - brush.lastActionTime < 100) {return;}
    brush.lastActionTime = Date.now();

    const layer = convy.methods.getActiveLayer();
    layer.ready = false;
    
    let fillStroke;

    if (ifBackgroundFill(layer.id)) {
      fillStroke = {
        time: serverTimeFun(),
        userId: telegram.activeUserId,
        type: 'background',
        color: color,
        layerId: layer.id,
      }
    } else {

      const sets = brushSetsFun('filler');
      const { layerPoint } = drawing.methods.getLayerCoordinates(nativeEvent, layer.info.clippingFor || layer.id);
      const { x, y } = layerPoint;

      fillStroke = {
        time: serverTimeFun(),
        userId: telegram.activeUserId,
        type: 'fill',
        x: x,
        y: y,
        color: color,
        sets,
        layerId: layer.id,
      };
    }

    let myStrokes = userStrokes[telegram.activeUserId];

    myStrokes.push(fillStroke);
    userStrokes[telegram.activeUserId] = myStrokes.filter(stroke=> !stroke.cancelled);
    saveHistory();
    setTimeout(() => { drawing.methods.redraw(); }, 0);

  }

  function ifBackgroundFill (layerId) {

    const allStrokes = Object.values(userStrokes).flat();
    const combinedStrokes = allStrokes.filter(stroke => stroke.layerId === layerId && !stroke.cancelled).sort((a, b) => a.time - b.time);
    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');

    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);
    const hasUsualStrokes = strokesToRender.find(stroke=>stroke.type !== 'background');
    return !hasUsualStrokes;
    
  }

  return {

    addReadyMadeStroke,
    addStroke,
    drawPreview,

    startDrawing,
    continueDrawing,
    finishDrawing,

    fill,
  }
};

