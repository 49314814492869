// feather.js
import chroma from 'chroma-js';
import { filterClosePoints, interpolatePointsUniform } from './helpers/points';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient, calculateGradientPoints } from './helpers/gradient'; 
import { drawStrokeWithGradient } from './plain';


export async function drawFeatherStroke(stroke, context) {


  let {
    color,
    gradientColor,
    lineWidth,
    points,
    shapeId,
    sets = {},
    brush,
  } = stroke;

  const defaultBrushSettings = brushDefaults[brush] || brushDefaults.feather;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  if (points.length === 0) return;
  if (points.length === 1) {
    return await drawStrokeWithGradient(stroke, context, brushSettings)
  }

  let { 
    speedDependence,
    edgeWidening,
    centralWidth = 0.4,

    minWidthStart = 0.01, // Мин. толщина начала, 1 = lineWidth
    taperStartPoint = 0.5, // Длина сужения начала, 0.5 = целая половина штриха
    minWidthEnd = 0.01, // Мин. толщина конца, 1 = lineWidth
    taperEndPoint = 0.5, // Длина сужения конца

    transparentEdges,
    opacityOnStart = 0,
    fadeStartPoint = 0.3,
    opacityOnEnd = 0,
    fadeEndPoint = 0.7,

  } = brushSettings;

  if (taperStartPoint > taperEndPoint) {
    const midpoint = (taperStartPoint + taperEndPoint) / 2;
    taperStartPoint = midpoint;
    taperEndPoint = midpoint;
  }
  if (fadeStartPoint > fadeEndPoint) {
    const midpoint = (fadeStartPoint + fadeEndPoint) / 2;
    fadeStartPoint = midpoint;
    fadeEndPoint = Math.min(midpoint + 0.05, 1);
  }

  if (!speedDependence && !shapeId) {
    points = filterClosePoints(points, Math.min(lineWidth, 20));
    points = interpolatePointsUniform(points, Math.min(lineWidth, 50))
  }

  // Создаем отдельный буфер
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = brushSettings.squareBrush ? 'square' : 'round';
  bufferCtx.lineJoin = brushSettings.squareBrush ? 'miter' : 'round';

  const smoothPoints = interpolatePoints(points);
  const totalPoints = smoothPoints.length;

  // Вычисление центральной части без сужения
  let centerPartStart = Math.floor(totalPoints * taperStartPoint);
  let centerPartEnd = Math.floor(totalPoints * taperEndPoint);

  centerPartStart = Math.max(0, Math.min(centerPartStart, totalPoints - 1));
  centerPartEnd = Math.max(0, Math.min(centerPartEnd, totalPoints - 1));

  bufferCtx.strokeStyle = 'rgba(255, 255, 255, 1)';
  bufferCtx.beginPath();

  for (let i = 0; i < totalPoints - 1; i++) {

    const startPoint = smoothPoints[i];
    const endPoint = smoothPoints[i + 1];
    const segmentWidth = computeLineWidth({
      index: i,
      totalPoints,
      lineWidth,
      centerPartStart,
      centerPartEnd,
      minWidthStart,
      minWidthEnd,
      edgeWidening,
      centralWidth,
    });

    if (segmentWidth === 0) { continue; }
    // const segmentWidth1 = computeLineWidth(i, totalPoints, lineWidth, centerPartStart, centerPartEnd, minWidthStart, minWidthEnd);

    bufferCtx.beginPath();
    bufferCtx.moveTo(startPoint[0], startPoint[1]);
    bufferCtx.lineTo(endPoint[0], endPoint[1]);
    bufferCtx.lineWidth = segmentWidth;
    bufferCtx.stroke();

  }

  // Применяем цвет и прозрачность к буферу
  bufferCtx.globalCompositeOperation = 'source-in';

  if (transparentEdges 
    && (opacityOnStart < 1 || opacityOnEnd < 1)
    // && smoothPoints.length > 1
    && calculateDistance(smoothPoints[0], smoothPoints[smoothPoints.length - 1]) > 15
    ) {

    const chromaStartColor = chroma(color);
    const chromaEndColor = chroma(gradientColor || color);

    const originalStartColorlAlpha = chromaStartColor.alpha();
    const originalEndColorlAlpha = chromaEndColor.alpha();

    const colorOnStart = chromaStartColor.alpha(opacityOnStart * originalStartColorlAlpha).hex();
    const colorOnEnd = chromaEndColor.alpha(opacityOnEnd * originalEndColorlAlpha).hex();

    const gradientPoints = calculateGradientPoints(smoothPoints[0], smoothPoints[totalPoints-1]);

    const gradient = bufferCtx.createLinearGradient(
      gradientPoints.start[0], gradientPoints.start[1],
      gradientPoints.end[0], gradientPoints.end[1]
      );
      
    gradient.addColorStop(0, colorOnStart);
    gradient.addColorStop(fadeStartPoint, color);
    gradient.addColorStop(fadeEndPoint, gradientColor || color);
    gradient.addColorStop(1, colorOnEnd);
    bufferCtx.fillStyle = gradient;
    bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  
  } else if (gradientColor) {

    await fillGradient(bufferCtx, points, color, gradientColor, lineWidth)

  } else {

    bufferCtx.fillStyle = color;
    bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  }

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);

}


export function interpolatePoints(points, interpolationCoef = 1) {
  if (points.length < 2) return points;
  if (points.length === 2) { return linearInterpolate(points); }

  function distance(p1, p2) {
    const dx = p2[0] - p1[0];
    const dy = p2[1] - p1[1];
    return Math.sqrt(dx * dx + dy * dy);
  }

  let interpolatedPoints = [];

  // Start with the first point
  interpolatedPoints.push(points[0]);

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1];
    const currentPoint = points[i];

    // Calculate the distance between prevPoint and currentPoint
    const dist = distance(prevPoint, currentPoint);

    // Calculate the number of interpolations based on the distance
    const numInterpolations = Math.min(20, Math.round(interpolationCoef * dist));

    // Calculate the midpoint between prevPoint and currentPoint
    const midPoint = [
      (prevPoint[0] + currentPoint[0]) / 2,
      (prevPoint[1] + currentPoint[1]) / 2
    ];

    const controlPoint = prevPoint;
    const startPoint = interpolatedPoints[interpolatedPoints.length - 1];
    const endPoint = midPoint;

    // Sample points along the quadratic Bézier curve
    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);

      const x =
        (1 - t) * (1 - t) * startPoint[0] +
        2 * (1 - t) * t * controlPoint[0] +
        t * t * endPoint[0];
      const y =
        (1 - t) * (1 - t) * startPoint[1] +
        2 * (1 - t) * t * controlPoint[1] +
        t * t * endPoint[1];

      interpolatedPoints.push([x, y]);
    }

    // Add the end point of the segment
    interpolatedPoints.push(endPoint);
  }

  // Add the last point
  // interpolatedPoints.push(points[points.length - 1]);
  return interpolatedPoints;
}


export function linearInterpolate(points, interpolationCoef = 1) {
  if (points.length < 2) { return points; }

  const result = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const start = points[i - 1];
    const end = points[i];

    const distance = Math.sqrt(
      Math.pow(end[0] - start[0], 2) + Math.pow(end[1] - start[1], 2)
    );

    const numInterpolations = Math.max(1, Math.round(interpolationCoef * distance));

    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);
      const x = start[0] + t * (end[0] - start[0]);
      const y = start[1] + t * (end[1] - start[1]);
      result.push([x, y]);
    }
  }

  result.push(points[points.length - 1]);
  return result;

}

export function computeLineWidth({
  index,
  totalPoints,
  lineWidth,
  centerPartStart,
  centerPartEnd,
  minWidthStart = 0.01,
  minWidthEnd = 0.01,
  edgeWidening = false,
  centralWidth = 0.2,
  smoothingFactor = 0.5
}) {

  function easeInOutQuad(t) {
    return t < 0.5
      ? 2 * t * t
      : 1 - Math.pow(-2 * t + 2, 2) / 2;
  }
  
  function interpolate(start, end, t, edgeWidening = false, power = 4) {
    let smoothT;
    if (edgeWidening) {
      smoothT = 1 - Math.pow(1 - t, power);
    } else {
      smoothT = easeInOutQuad(t);
    }
    return start + (end - start) * (smoothingFactor * smoothT + (1 - smoothingFactor) * t);
  }

  const minWidthStartPx = minWidthStart * lineWidth;
  const minWidthEndPx = minWidthEnd * lineWidth;
  const centerWidth = edgeWidening ? lineWidth * centralWidth : lineWidth;

  if (index <= centerPartStart) {
    const t = index / (centerPartStart || 1); // Avoid division by zero
    return interpolate(minWidthStartPx, centerWidth, t);
  } else if (index >= centerPartEnd) {
    const t = (index - centerPartEnd) / ((totalPoints - 1 - centerPartEnd) || 1); // Avoid division by zero
    return interpolate(centerWidth, minWidthEndPx, t);
  } else {
    return centerWidth;
  }
}

function calculateDistance(point1, point2) {
  const dx = point2[0] - point1[0];
  const dy = point2[1] - point1[1];
  return Math.sqrt(dx * dx + dy * dy);
}

