import { useEffect, } from 'react';
import useStore from '../../store';

export const usePuzzlePointer = () => {

  const puzzleState = useStore(state=>state.puzzleState);

  const { ref } = useStore.getState();

  const { info, work, brush, drawing, history, convy } = ref;
  const { mouse, keyboard, zoom, } = convy;

  const handlePointerDown = (nativeEvent) => {

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    useStore.setState({ pointerPosition: position });

  };

  const handlePointerMove = (nativeEvent) => {

    const position = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    useStore.setState({ pointerPosition: position });
  };

  
  const handlePointerUp = (nativeEvent) => {

  };


  const handleClick = (nativeEvent) => {


  }

  const handleContextMenu = (nativeEvent) => {

  };

  const handleTouchStart = (nativeEvent) => {
    if (nativeEvent.touches && nativeEvent.touches.length > 0) {
      const touch = nativeEvent.touches[0];
      const position = { x: touch.clientX, y: touch.clientY };
      useStore.setState({ pointerPosition: position });
    }
  };

  const handleTouchMove = (nativeEvent) => {


  };

  const handleTouchEnd = (nativeEvent) => {


  };


  useEffect(() => {

    if (!puzzleState) { return; }

    setTimeout(() => {
      if (!mouse.pointerListenersAreSet) {
        mouse.pointerListenersAreSet = true;

        window.addEventListener('pointerdown', handlePointerDown);
        window.addEventListener('pointermove', handlePointerMove);
        window.addEventListener('pointerup', handlePointerUp);
        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('click', handleClick);
        window.addEventListener('touchstart', handleTouchStart);

        // Cleanup event listeners on unmount
        return () => {
          window.removeEventListener('pointerdown', handlePointerDown);
          window.removeEventListener('pointermove', handlePointerMove);
          window.removeEventListener('pointerup', handlePointerUp);
          window.removeEventListener('contextmenu', handleContextMenu);
          window.removeEventListener('click', handleClick);
          window.removeEventListener('touchstart', handleTouchStart);

        };
      }
    }, 500);
  }, [puzzleState]); // Empty dependency array ensures this runs once on mount

  return {
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handleContextMenu,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};

export default usePuzzlePointer;
