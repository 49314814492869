// src/components/puzzle/PuzzleArea.js
import React, { useRef, useEffect } from 'react';
import useStore from '../../store';
import PuzzlePiece from './PuzzlePiece';
import './PuzzleArea.css'; // Убедитесь, что CSS импортирован

const PuzzleArea = () => {
  // Подписываемся на нужные части для ререндера
  useStore((state) => state.puzzleRender);
  const isInitialized = useStore(state => state.ref.puzzle.state.isInitialized);
  const boardDimensions = useStore(state => state.ref.puzzle.state.boardDimensions);
  const svgDefs = useStore(state => state.ref.puzzle.state.svgDefs);
  const pieces = useStore(state => state.ref.puzzle.state.pieces);
  const previewOpacity = useStore(state => state.ref.puzzle.state.config?.previewOpacity);
  const bgUrl = useStore(state => state.ref.puzzle.state.bgUrl);
  const dimensionsCalculated = useStore(state => state.ref.puzzle.state.dimensionsCalculated); // Отслеживаем расчет

  const areaRef = useRef(null); // Ref для контейнера .puzzle-area-container

  const { ref } = useStore.getState();
  const { puzzle } = ref;

  // --- Piece Filtering ---
  const pieceIds = Object.keys(pieces || {});

  const boardPieceIds = pieceIds.filter(pieceId => {

    const { draggingPiece } = puzzle.state;
    const isDraggingThis = draggingPiece?.pieceId === pieceId; 

    const piece = pieces[pieceId];
    // Элемент на доске, если он размещен ИЛИ НЕ в трее
    return piece && !isDraggingThis && (piece.placed || piece.onBoard);

  });

  // --- Dimension Calculation on Resize ---
  useEffect(() => {
    if (!areaRef.current) return;

    const observer = new ResizeObserver(() => {
      // Пересчитываем размеры при изменении размера контейнера, ТОЛЬКО если пазл инициализирован
       if (ref.puzzle.state.isInitialized) {
        // Используем requestAnimationFrame для предотвращения слишком частых вызовов
        window.requestAnimationFrame(() => {
            ref.puzzle.methods.calculateBoardDimensions();
        });
      }
    });

    observer.observe(areaRef.current);

    // --- Первичный расчет размеров ---
    // Вызываем расчет после монтирования и инициализации, если он еще не был сделан
    // Или если размеры нулевые (например, после сброса)
    if (isInitialized && (!dimensionsCalculated || boardDimensions.width === 0)) {
         window.croco?.log("Triggering initial board dimension calculation.");
         // Небольшая задержка, чтобы убедиться, что DOM готов
         const timerId = setTimeout(() => {
              ref.puzzle.methods.calculateBoardDimensions();
         }, 50);
         return () => {
             clearTimeout(timerId); // Очищаем таймаут при размонтировании
             observer.disconnect();
         };
    }


    return () => observer.disconnect();
    // Зависимости: isInitialized (чтобы начать расчет), dimensionsCalculated (чтобы не повторять без нужды)
    // boardDimensions.width (чтобы пересчитать, если сбросились в 0)
  }, [isInitialized, dimensionsCalculated, boardDimensions.width, ref.puzzle.methods]); // Добавляем ref.puzzle.methods


  // --- Background Guide Style ---
  // Используем данные из useStore
  const backgroundStyle = {
    backgroundImage: bgUrl ? `url(${bgUrl})` : 'none',
    opacity: previewOpacity ?? 0.25,
    width: `${boardDimensions.width}px`,
    height: `${boardDimensions.height}px`,
  };

  // Этот обработчик помогает предотвратить случайную прокрутку при перетаскивании
  const handleAreaTouchStart = (e) => {
    // Если касание не на пазле, можно предотвратить действия браузера
    // для улучшения UX при манипуляции с доской
    if (!e.target.closest('.puzzle-piece')) {
      // Не блокируем полностью, чтобы можно было скроллить доску,
      // если она больше экрана
    }
  };

  return (
    <div
      ref={areaRef}
      id="puzzle-area-container" // ID для ResizeObserver и расчетов
      className="puzzle-area-container"
      style={{ touchAction: 'pan-x pan-y' }} // Разрешаем стандартный скролл, но отключаем масштабирование
      onTouchStart={handleAreaTouchStart}
    >
      {/* Центрирующий контейнер */}
      <div className="puzzle-area-center-wrapper">
        {/* Контейнер реального размера доски, видим только если размеры посчитаны */}
        {isInitialized && boardDimensions.width > 0 && (
          <div
             id="real-puzzle-area" // ID для позиционирования и drop-логики
             className="puzzle-area-guide-and-pieces"
             style={{ 
               width: `${boardDimensions.width}px`, 
               height: `${boardDimensions.height}px`,
               overflow: 'visible'
             }}
          >
            {/* Фоновая подсказка */}
            <div className="puzzle-background-guide" style={backgroundStyle}></div>

            {/* Рендерим элементы пазла, относящиеся к доске */}
            {boardPieceIds.map(pieceId => (
              <PuzzlePiece key={pieceId} pieceId={pieceId} />
            ))}
          </div>
        )}
      </div>
      {/* Рендерим SVG определения один раз */}
      {svgDefs}
    </div>
  );
};

export default PuzzleArea;