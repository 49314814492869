// GameNotFound.js
import React from 'react';
import './ResultsOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';

function GameNotLoaded({
  store,
  gameError,
}) {

  const { t } = useTranslation();

  let text = 
  gameError.error === 'notFound' ? t('inform.game_not_found') : 
  gameError.error === 'strokesError' ? t('inform.strokes_error') : ''

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'var(--tg-theme-bg-color)',
          opacity: 0.5,
          zIndex: 1100,
        }}
      ></div>
      <div className="results">
        <div className="results-text">{text}</div>

      <div className="button-container">
        <button 
          className="results-button lower-button" 
          onClick={window.Telegram.WebApp.close}>
          {t('results.close')}
        </button>
      </div>
      </div>
    </>
  );
}

export default GameNotLoaded;
