// watercolor.js
import chroma from 'chroma-js';
import { applyPixiBlur } from './pixi/pixiBlur'; // Reusing the seededRandom function
import { 
  duplicateCanvasContext,
  createStrokeMask, 
  applyTextureByStrokeMask,
  drawTexturedStroke, 
  drawNoTextureStroke,
} from './helpers/texturize';
import { 
  createCanvas,
} from './helpers/canvas';

import { getTexture, brushDefaults } from './helpers/brushLoader';
const defaultBrushSettings = brushDefaults.watercolor;

export async function drawWatercolorStroke(stroke, context, params) {

  let {
    color,
    gradientColor,
    lineWidth,
    points,
    time,
    sets = {},
  } = stroke;

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    spreading,
    outline,
    textureOn,
    texture,
    textureScale = 1,
    waterBlurSize = 15,
    waterBlurAlpha = 0.8,
  } = brushSettings;

  const textureName = texture || 'waterAi';
  
  let watercolorTexture = await getTexture('watercolor', textureName);
  let watercolorTextureWhite = await getTexture('watercolor', textureName, 'light');

  const hasBlur = brushSettings.waterBlurSize && brushSettings.waterBlurAlpha;

  const cloneContext = duplicateCanvasContext(context);
  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  const outlineColor = chromaColor.darken(1).hex();

  // const alphaDivisor = 1 + luminance;
  // const newAlpha = originalAlpha / alphaDivisor;
  const newAlpha = originalAlpha * brushSettings.opacity;

  color = chromaColor.alpha(1).hex();

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = cloneContext.canvas.width;
  bufferCanvas.height = cloneContext.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  // Возвращаем режим наложения в нормальное состояние
  bufferCtx.globalCompositeOperation = 'source-over';

  const strokeMaskCtx = createStrokeMask({
    points,
    lineWidth,
    color,
    time,
    brushSettings,
  }, cloneContext, drawStroke);

  // Применяем текстуру к основному мазку
  if (watercolorTexture) {

    let strokeMaskForBlurCtx = createStrokeMask({
      points,
      lineWidth,
      color,
      time,
      brushSettings,
    }, cloneContext, drawStroke);;

    if (hasBlur) {
      await applyBlurAlongPath({
        context: cloneContext, 
        maskCanvas: strokeMaskForBlurCtx.canvas, 
        lineWidth, 
        originalAlpha, 
        isApple: false,
        waterBlurSize,
        waterBlurAlpha,
      }, );
    }

    if (textureOn) {

      await drawTexturedStroke({
        color,
        gradientColor,
        points,
        lineWidth,
  
        bufferCtx,
        strokeMaskCtx,
        texture: watercolorTexture,
        textureScale,
        // composition: 'luminosity',
        composition: 'source-over',

      })

      applyTextureByStrokeMask({
        context: cloneContext,
        bufferCtx,
        strokeMaskCtx: strokeMaskForBlurCtx,
        texture: watercolorTextureWhite,
        color: 'white',
        opacity: 0.2,
        textureScale,
        composition: 'luminosity',
        // composition: 'source-over',

      })

    } else {

      await drawNoTextureStroke({
        color,
        gradientColor,
        points,
        lineWidth,

        texture: watercolorTexture,
        textureScale,

        strokeMaskCtx,
        context: bufferCtx,
      })
      
    }

  }

  // Рисуем буфер на основном холсте
  cloneContext.globalAlpha = newAlpha;
  cloneContext.drawImage(bufferCanvas, 0, 0);
  cloneContext.globalAlpha = 1; // Возвращаем значение по умолчанию

  context.drawImage(cloneContext.canvas, 0, 0)

  if (outline) {
    drawOutline ({
      points,
      lineWidth,
      color: outlineColor,
      time,
      brushSettings,
    }, context)
  }

}


// Функция для рисования сужающегося штриха
function drawStroke({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  time,
  brushSettings,
}, context) {

  if (brushSettings.spreading) { lineWidth = lineWidth * 0.82 }

  drawStrokeLine({
    points, 
    lineWidth, 
    color, 
    offset, 
    time,
    tapering: false,
    jitterAmount: brushSettings.spreading ? lineWidth / 10 : 0,
  }, context)

  if (brushSettings.spreading) {

    let radius = lineWidth / 2.2;
    let lines = points.length === 1 ? 30 : 30;
    let offsets = createCircleOffsets(radius, offset, lines, time);
    let adjustedPoints = addEdgePoints(points, 2)

    offsets.forEach((offset, i)=>{

      drawStrokeLine({
        points: adjustedPoints, 
        lineWidth: lineWidth / 5, 
        color, 
        offset, 
        jitterAmount: lineWidth / 6,
        time: time + i,
      }, context)
    })

  }


}


function drawOutline (stroke, context) {

  const {
    outlineSize = 1,
    outlineOpacity = 1,
    spreading,
  } = stroke.brushSettings;

  const strokeMaskCtx = createCanvas(context);
  if (spreading) {
    strokeMaskCtx.lineCap = 'butt';
  } else {
    strokeMaskCtx.lineCap = 'round';
  }
  strokeMaskCtx.lineJoin = 'round';
  drawStroke({
    ...stroke, 
    lineWidth: stroke.lineWidth + outlineSize / 2
  }, strokeMaskCtx)

  strokeMaskCtx.globalCompositeOperation = 'destination-out';

  drawStroke({
    ...stroke, 
    color: 'black', 
    lineWidth: stroke.lineWidth - outlineSize / 2,
  }, strokeMaskCtx)

  context.globalAlpha = outlineOpacity;
  context.drawImage(strokeMaskCtx.canvas, 0, 0);
  
}


function createCircleOffsets(radius, baseOffset = { x: 0, y: 0 }, numPoints, seed) {
  const offsets = [];
  
  // Вспомогательная функция для округления до 3 знаков после запятой
  const round = (num) => Math.round(num * 1000) / 1000;

  for (let i = 0; i < numPoints; i++) {
    // Базовый угол
    const baseAngle = (i * 2 * Math.PI) / numPoints;
    
    // Добавляем небольшое случайное отклонение к углу
    const maxDeviation = Math.PI / numPoints / 4; // Максимальное отклонение - 1/8 расстояния между точками
    const angle = baseAngle + (seededRandom(seed + i) * 2 - 1) * maxDeviation;
    
    // Добавляем небольшую вариацию к радиусу
    const radiusVariation = 0.05; // 5% вариации
    const adjustedRadius = radius * (1 + (seededRandom(seed + i + 1) * 2 - 1) * radiusVariation);
    
    // Вычисляем координаты, учитывая baseOffset
    const x = round(baseOffset.x + adjustedRadius * Math.cos(angle));
    const y = round(baseOffset.y + adjustedRadius * Math.sin(angle));
    
    offsets.push({ x, y });
  }

  return offsets;
}


function addEdgePoints(points, amount) {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  // Создаем массивы с дублированными краевыми точками
  const startPoints = Array(amount).fill(firstPoint);
  const endPoints = Array(amount).fill(lastPoint);
  // Объединяем все в один массив
  const adjustedPoints = [...startPoints, ...points, ...endPoints];
  return adjustedPoints;
}


function drawStrokeLine({
  points,
  lineWidth,
  color,
  offset = { x: 0, y: 0 },
  time,
  tapering,
  jitterAmount,
}, context) {

  if (points.length === 1) {
    points = [points[0], points[0], points[0], points[0], points[0]]
  }
  // const watercolorPoints = points;
  if (jitterAmount) {
    points = addWatercolorEffectToPoints(points, lineWidth, jitterAmount, time);
  }

  const stroke = {
    points, 
    lineWidth, 
    color, 
    offset, 
  }
 
  if (points.length === 1) {
    return drawPoint(stroke, context);
  } else {
    return drawPlainStroke(stroke, context);
  }

}

function drawPlainStroke ({
  points, lineWidth, color, offset,
}, context) {

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  context.beginPath();
  context.moveTo(points[0][0] + offset.x, points[0][1] + offset.y);

  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2
    ];
    context.quadraticCurveTo(nextPoint[0] + offset.x, nextPoint[1] + offset.y, midPoint[0] + offset.x, midPoint[1] + offset.y);
  }
  const lastPoint = points[points.length - 1]
  context.lineTo(
    lastPoint[0] + offset.x, 
    lastPoint[1] + offset.y,
    );
  context.stroke();
  
}

function drawPoint ({
  points, lineWidth, color, offset,
}, context) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  context.beginPath();
  context.arc(
    point[0] + offset.x, 
    point[1] + offset.y, 
    (lineWidth / 2), 
    0, 
    Math.PI * 2
    );
  context.fillStyle = color;
  context.fill();
}


function addWatercolorEffectToPoints(points, lineWidth, jitterAmount, time) {
  if ( points.length <= 2 ) { return points; }

  const watercolorPoints = [];
  jitterAmount = jitterAmount || Math.max(2, lineWidth / 10); // Максимальное отклонение в пикселях

  points.forEach((point, i) => {
    // Генерируем случайный угол
    const angle = seededRandom(time + i) * 2 * Math.PI;
    
    // Генерируем случайное расстояние от 0 до jitterAmount
    const distance = seededRandom(time + i) * jitterAmount;
    
    // Вычисляем смещение в полярных координатах
    const jitterX = distance * Math.cos(angle);
    const jitterY = distance * Math.sin(angle);
    
    watercolorPoints.push([
      point[0] + jitterX,
      point[1] + jitterY
    ]);
  });

  return watercolorPoints;
}


function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

async function applyBlurAlongPath({
  context, 
  maskCanvas, 
  lineWidth, 
  originalAlpha, 
  isApple,
  waterBlurSize = 15,
  waterBlurAlpha = 0.8,
}) {

  // Создаем канвас для вырезанной области
  const maskedCanvas = document.createElement('canvas');
  maskedCanvas.width = context.canvas.width;
  maskedCanvas.height = context.canvas.height;
  const maskedCtx = maskedCanvas.getContext('2d');

  // Вырезаем область по маске
  maskedCtx.drawImage(context.canvas, 0, 0);
  maskedCtx.globalCompositeOperation = 'destination-in';
  maskedCtx.drawImage(maskCanvas, 0, 0);

  // Создаем канвас для блюра
  const blurredCanvas = document.createElement('canvas');
  blurredCanvas.width = context.canvas.width;
  blurredCanvas.height = context.canvas.height;
  const blurredCtx = blurredCanvas.getContext('2d');

  // Применяем блюр к вырезанной области
  if (isApple) {
    blurredCtx.drawImage(maskedCanvas, 0, 0);
    await applyPixiBlur(blurredCanvas, waterBlurSize);
  } else {
    blurredCtx.filter = `blur(${waterBlurSize}px)`;
    blurredCtx.drawImage(maskedCanvas, 0, 0);
  }

  // Обрезаем размытое изображение по маске
  blurredCtx.globalCompositeOperation = 'destination-in';
  blurredCtx.drawImage(maskCanvas, 0, 0);

  // Накладываем заблюренную область
  context.globalAlpha = waterBlurAlpha;
  context.globalCompositeOperation = 'source-over';
  context.drawImage(blurredCanvas, 0, 0);
  context.globalAlpha = 1;

  // Восстанавливаем настройки контекста
  context.globalCompositeOperation = 'source-over';
}


