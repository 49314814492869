// plain.js
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 
import { drawPressureStroke, createPressureStroke } from './helpers/pressure'; 
import { drawPlainStroke } from './helpers/plain'; 
import { getStrokeBounds, translatePoints } from './helpers/points'; 
// import { drawOpacityStroke } from './helpers/pressureOpacity'; 

const defaultBrushSettings = brushDefaults.marker;

export async function drawPlainMarkerStroke(stroke, context, params) {

  const {
    points,
    isEffect,
    gradientColor,
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  const {
    pressureOn,
    pressureOpacity,
    pressureAvailable,
  } = brushSettings;

  const withPressure = pressureAvailable && (pressureOn || pressureOpacity) && points[0].pressure;

  if (!gradientColor && !withPressure && !isEffect) {
    return drawPlainStroke(stroke, context, brushSettings);
  }

  drawStrokeWithGradient(stroke, context, brushSettings)

}

export function drawStrokeWithGradient(stroke, context, brushSettings) {
  const { gradientColor } = stroke;
  if (gradientColor) {
    drawGradientStroke(stroke, context, brushSettings);
  } else {
    drawPressureStroke(stroke, context, brushSettings);
  }
}

function drawGradientStroke(stroke, context, brushSettings) {
  const { points, color, gradientColor, lineWidth } = stroke;

  // Вычисляем границы штриха
  const bounds = getStrokeBounds(points, lineWidth);
  if (!bounds) return;

  const offsetX = -bounds.x;
  const offsetY = -bounds.y;
  const translatedPoints = translatePoints(points, offsetX, offsetY);

  // Создаем временный канвас минимального размера
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = bounds.width;
  bufferCanvas.height = bounds.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Рисуем штрих на временном канвасе
  createPressureStroke({...stroke, points: translatedPoints}, bufferCtx, brushSettings);
  fillGradient(bufferCtx, translatedPoints, color, gradientColor, stroke.lineWidth);

  // Переносим результат на основной холст
  context.drawImage(
    bufferCanvas,
    0, 0, bounds.width, bounds.height,
    bounds.x, bounds.y, bounds.width, bounds.height
  );
}


