// src/handlers/keyboardHandlers.js
import chroma from 'chroma-js';
import useStore from '../../store';

const handleToolSwitch = (event, { changeTool }) => {
  if (event.code === 'KeyB') changeTool('brush', { fixed: true });
  else if (event.code === 'KeyE') changeTool('eraser', { fixed: true });
  else if (event.code === 'KeyF') changeTool('effect', { fixed: true });
};

const handleLayerSelection = (event, { convy }) => {
  if (
    /^Digit[0-9]$/.test(event.code) && !event.altKey 
    && !(event.code === 'Digit0' && event.ctrlKey)
    ) {
    event.preventDefault();
    const keyNumber = parseInt(event.code.replace('Digit', ''), 10);
    const index = keyNumber === 0 ? 9 : keyNumber - 1;
    if (event.ctrlKey || convy.keyboard.spaceIsDown) {
      convy.methods.toggleLayerVisibilityByIndex(index);
    } else {
      convy.methods.fastKeyLayer(keyNumber);
    }
    return true;
  }
  return false;
};

const handleMenuToggle = (event, { toggleMenu }) => {
  if (event.code === 'KeyW') toggleMenu('word');
  else if (event.code === 'Backquote') toggleMenu('settings');
  else if (event.code === 'KeyL') toggleMenu('layer');
  else if (event.code === 'KeyP') toggleMenu('lag');
};

const handleUserSetsToggle = (event, { user, saveUserSets }) => {
  if (event.code === 'KeyI') {
    saveUserSets({showDebug: !user.showDebug})
  };
  if (event.code === 'KeyR') {
    event.stopPropagation();
    event.preventDefault();
    useStore.setState(state=>({showReference: !state.showReference}))
  };
};

const handleModifierKeys = (event, { keyboard, activeTool, brush }) => {
  if (event.key === 'Alt') {
    event.preventDefault();
    keyboard.altIsDown = true;
    if (!keyboard.controlIsDown && !keyboard.spaceIsDown && activeTool !== 'pipette') {
      brush.previousTool = activeTool;
      useStore.setState({ activeTool: 'pipette' });
    }
  } else if (event.key === 'Control') {
    event.preventDefault();
    keyboard.controlIsDown = true;
    if (!keyboard.altIsDown) useStore.setState({ zoomMode: true });
  } else if (event.code === 'Space') {
    event.preventDefault();
    keyboard.spaceIsDown = true;
    useStore.setState({ zoomMode: true });
  } else if (event.code === 'Tab') {
    event.preventDefault();
    keyboard.tabIsDown = true;
  } else if (event.key === 'Shift') {
    event.preventDefault();
    keyboard.shiftIsDown = true;
    useStore.setState({ showPalette: true });
  }
};

const handleUndoRedo = (event, { undoLastAction, redoLastAction }) => {
  if ((event.ctrlKey && event.code === 'KeyZ') || event.code === 'Backspace') {
    event.preventDefault();
    undoLastAction();
  } else if (event.ctrlKey && event.code === 'KeyX') {
    event.preventDefault();
    redoLastAction();
  }
};

const handleLineWidth = (event, { setTheSizeFun }) => {
  const lineWidth = useStore.getState().lineWidth;
  const stepSize = Math.round(Math.cbrt(lineWidth));
  let newLineWidth;

  if (['KeyX', 'KeyS'].includes(event.code) && !event.ctrlKey && !event.altKey && !event.shiftKey) {
    event.preventDefault();
    newLineWidth = Math.max(1, lineWidth - stepSize);
    setTheSizeFun(newLineWidth);
  } else if (['KeyZ', 'KeyA'].includes(event.code) && !event.ctrlKey && !event.altKey && !event.shiftKey) {
    event.preventDefault();
    newLineWidth = Math.max(1, lineWidth + stepSize);
    setTheSizeFun(newLineWidth);
  }
};

const handleVisibilityAndPaste = (event, ref) => {
  if (['KeyV'].includes(event.code)) {
    event.preventDefault();
    if (event.ctrlKey) {
      navigator.clipboard.readText()
        .then((text) => {
          try {
            const color = chroma(text);
            if (color) {
              ref.brush.methods.setTheColorFun(color.hex());
              ref.menu.methods.showTemporaryHint(
                <ref.components.ColorHint text={window.t('tooltip.saved_color_taken')} hexColor={color.hex()} />,
                { force: true, duration: 1500 },
                { backgroundColor: 'rgba(0, 0, 0, 0.3)', minWidth: '100px', padding: '8px' }
              );
            }
          } catch (e) {}
        })
        .catch();
    } else {
      const layer = ref.convy.methods.getActiveLayer();
      if (layer) {
        layer.info.visible = !layer.info.visible;
        ref.work.methods.saveGameSets({ layerInfo: ref.convy.layerInfo });
        ref.drawing.methods.redraw();
        ref.menu.methods.showTemporaryHint(
          layer.info.visible
            ? window.t('layer_menu.visible', { layerName: layer.info.name })
            : window.t('layer_menu.invisible', { layerName: layer.info.name }),
          { force: true }
        );
      }
    }
  }
};

const handleNavigation = (event, { convy, drawing }) => {
  if (['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(event.code)) {
    event.preventDefault();
    if (event.ctrlKey) {
      if (event.code === 'ArrowRight') useStore.setState(state => ({ rotation: state.rotation + 5 }));
      else if (event.code === 'ArrowLeft') useStore.setState(state => ({ rotation: state.rotation - 5 }));
    } else if (event.altKey) {
      const activeLayer = convy.methods.getActiveLayer();
      if (event.code === 'ArrowRight') activeLayer.info.offset.x += 10;
      else if (event.code === 'ArrowLeft') activeLayer.info.offset.x -= 10;
      else if (event.code === 'ArrowUp') activeLayer.info.offset.y -= 10;
      else if (event.code === 'ArrowDown') activeLayer.info.offset.y += 10;
      drawing.methods.redraw();
    } else {
      if (event.code === 'ArrowRight') useStore.setState(state => ({ panOffset: { x: state.panOffset.x + 10, y: state.panOffset.y } }));
      else if (event.code === 'ArrowLeft') useStore.setState(state => ({ panOffset: { x: state.panOffset.x - 10, y: state.panOffset.y } }));
      else if (event.code === 'ArrowUp') useStore.setState(state => ({ panOffset: { x: state.panOffset.x, y: state.panOffset.y - 10 } }));
      else if (event.code === 'ArrowDown') useStore.setState(state => ({ panOffset: { x: state.panOffset.x, y: state.panOffset.y + 10 } }));
    }
  }
};

const handleResetZoom = (event) => {
  if (event.ctrlKey && event.code === 'Digit0') {
    event.preventDefault();
    useStore.setState({ panOffset: { x: 0, y: 0 }, zoomFactor: 1, rotation: 0 });
  }
};

const handleCloseMenu = (event, { closeMenu }) => {
  if (event.code === 'Enter' || event.code === 'Escape') {
    event.preventDefault();
    closeMenu();
    useStore.setState({
      showPalette: false,
      showPremiumMenu: false,
      showPremiumInfo: false,
      showModeHelp: false,
      showPopupMessage: false,
    });
  }
};

export {
  handleToolSwitch,
  handleLayerSelection,
  handleMenuToggle,
  handleUserSetsToggle,
  handleModifierKeys,
  handleUndoRedo,
  handleLineWidth,
  handleVisibilityAndPaste,
  handleNavigation,
  handleResetZoom,
  handleCloseMenu,
};