// useZoomPC
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDrawingContext  } from '../../contexts/DrawingContext';
import { filterPosition } from '../helpers/filterPosition'; // путь к вашему хуку


export const useZoomPhone = ({

  getCanvasPoint,
  
}) => {

  const canvasContext = useDrawingContext();

  const {
    tempCanvasRef,
    canvasDimensionsRef,

    isZooming, setIsZooming,
    zoomFactor, setZoomFactor,
    panOffset, setPanOffset,
    panOffsetRef,
    zoomFactorRef,

    maxZoomFactorRef,

    // TEST
    touch1Ref,
    touch2Ref,
    distanceRef,
    zoomCenterRef,

    canvasScaleRef,
    isDrawingRef,

  } = canvasContext;


  const initialDistanceRef = useRef(null);
  const initialZoomFactorRef = useRef(null);
  const initialZoomCenterRef = useRef(null);
  const initialAngleRef = useRef(null);

  const initialOffsetRef = useRef(null);
  const initialCanvasRectRef = useRef(null);

  const initialCanvasPoint = useRef(null);

  const modeLockedRef = useRef(null);

  const zoomStartTime = useRef(0);

  function phoneZoomDebugText () {

    if (!initialZoomCenterRef.current) {return;}

    const canvasPoint = initialCanvasPoint.current;
    return `canvasPoint ${JSON.stringify(canvasPoint)} panOffset ${JSON.stringify(panOffsetRef.current)}`

  }

  const calculateDistance = (touch1, touch2 = {clientX: 0, clientY: 0}) => {
    const xDiff = touch1.clientX - touch2.clientX;
    const yDiff = touch1.clientY - touch2.clientY;

    const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff)

    // TEST
    touch1Ref.current = {
      x: touch1.clientX.toFixed(2),
      y: touch1.clientY.toFixed(2),
    };
    touch2Ref.current = {
      x: touch2.clientX.toFixed(2),
      y: touch2.clientY.toFixed(2),
    };

    distanceRef.current = distance.toFixed(2);
    return distance;

  };

  const calculateMovement = (point1, point2 = {clientX: 0, clientY: 0}) => {
    const xDiff = point1.x - point2.x;
    const yDiff = point1.y - point2.y;
    return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  };

  const calculateCenter = (touch1, touch2 = {clientX: 0, clientY: 0}) => {
    const centerPoint = {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    };

    // TEST
    zoomCenterRef.current = {
      x: centerPoint.x.toFixed(2),
      y: centerPoint.y.toFixed(2),
    };
    return centerPoint;
  };

  const calculateAngle = (touch1, touch2) => {
    const dx = touch2.pageX - touch1.pageX;
    const dy = touch2.pageY - touch1.pageY;
    return Math.atan2(dy, dx) * 180 / Math.PI;
  };
  


  const touchZoomStart = useCallback((event) => {
    if (event.touches.length === 2 && !isZooming) {

      isDrawingRef.current = false;

      const distance = calculateDistance(event.touches[0], event.touches[1]);

      if (distance > 400) { return; }

      initialDistanceRef.current = distance;
      initialZoomFactorRef.current = zoomFactorRef.current;
      const center = calculateCenter(event.touches[0], event.touches[1]);

      if (center.x < 0 || center.y < 0) { return; }

      initialZoomCenterRef.current = center;
      initialOffsetRef.current = panOffsetRef.current || {x: 0, y: 0};
      initialCanvasRectRef.current = tempCanvasRef.current.getBoundingClientRect();
      modeLockedRef.current = null;
      initialAngleRef.current = calculateAngle(event.touches[0], event.touches[1]);

      initialCanvasPoint.current = getCanvasPoint(initialZoomCenterRef.current, initialCanvasRectRef.current);

      setIsZooming(true);
    }
  }, []);


  const touchZoomMove = useCallback((event) => {

    if (event.touches.length === 2 && isZooming) {

      isDrawingRef.current = false;

      const distance = calculateDistance(event.touches[0], event.touches[1]);
      const center = calculateCenter(event.touches[0], event.touches[1]);

      const isValidDistance = distance > 0 && !isNaN(distance) && distance < 400;
      const isValidCenter = center && !isNaN(center.x) && !isNaN(center.y) && center.x > 0 && center.y > 0;

      if (!isValidDistance || !isValidCenter) {return;}

      const difference = Math.abs(distance - initialDistanceRef.current);
      const zoomFactorChange = distance / initialDistanceRef.current;
      
      const moved = calculateMovement(center, initialZoomCenterRef.current);
      
      const currentAngle = calculateAngle(event.touches[0], event.touches[1]);
      const angleChange = currentAngle - initialAngleRef.current;

      if (
        modeLockedRef.current !== 'move'
        && (modeLockedRef.current === 'zoom' || difference > moved)
        ) {

        if (zoomFactorChange > 1.2 || zoomFactorChange < 0.8) {
          modeLockedRef.current = 'zoom';
        }

        let newZoomFactor = Math.min(Math.max(initialZoomFactorRef.current * zoomFactorChange, 1), maxZoomFactorRef.current);

        if (newZoomFactor < 1.1) {newZoomFactor = 1;}

        const canvasPoint = initialCanvasPoint.current;

        let newZoomOffset = {
          x: (canvasPoint.x * canvasScaleRef.current * (initialZoomFactorRef.current - newZoomFactor)) + initialOffsetRef.current.x,
          y: (canvasPoint.y * canvasScaleRef.current * (initialZoomFactorRef.current - newZoomFactor)) + initialOffsetRef.current.y,
        }
        
        if (newZoomFactor === 1 && zoomFactorChange < 1) {
          newZoomOffset = {x: 0, y: 0}
        }

        setZoomFactor(newZoomFactor);
        setPanOffset(newZoomOffset);

      } else if (modeLockedRef.current === 'move' || moved > 5) {

        if (moved > 30) {
          modeLockedRef.current = 'move';
        }

        const newPointOffset = {
          x: (center.x - initialZoomCenterRef.current.x) + initialOffsetRef.current.x,
          y: (center.y - initialZoomCenterRef.current.y) + initialOffsetRef.current.y,
        }

        const max = {
          x: 95,
          y: 95,
        }
        const min = {
          x: -95 -((canvasDimensionsRef.current.width) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
          y: -95 -((canvasDimensionsRef.current.height) * (zoomFactorRef.current - 1)) * canvasScaleRef.current * 1,
        }
    
        newPointOffset.x = Math.max(min.x, newPointOffset.x)
        newPointOffset.x = Math.min(max.x, newPointOffset.x)
    
        newPointOffset.y = Math.max(min.y, newPointOffset.y)
        newPointOffset.y = Math.min(max.y, newPointOffset.y)

        setPanOffset(newPointOffset);

      }

    }
  }, [isZooming, setPanOffset]);

  const resetZoomStart = useCallback(() =>{
    zoomStartTime.current = Date.now();
  }, []);

  const resetZoomEnd = useCallback((force) =>{
    if (
      force ||
      (zoomStartTime.current > Date.now() - 350 
      && zoomFactorRef.current === 1)) {
      setPanOffset({x: 0, y: 0});
      setZoomFactor(1);
    }
  }, [setPanOffset, setZoomFactor]);


  return {
    touchZoomStart,
    touchZoomMove,
    resetZoomStart,
    resetZoomEnd,
    phoneZoomDebugText,
  };
};

