import useStore from '../../../store';

class ShapeTransformer {
  /**
   * @param {Array<{x:number,y:number}>} points - исходные точки (в глобальных координатах)
   * @param {{x:number,y:number}} center - координаты центра
   * @param {{x:number,y:number}} initialCoordinates - начальное положение курсора (в глобальных координатах)
   */
  constructor({
    layerShape,
    previewShape,
    initialCoordinates, 
    startTime,
  }) {

    this.startTime = startTime;
    this.preview = {
      center: { ...previewShape.center },
      originalPoints: [...previewShape.points],
      localPoints: this.toLocalPoints(previewShape.points, previewShape.center),
      initialPointDist: this.distance(previewShape.center, previewShape.initialPoint),
      initialPointAngle: this.toAngle(previewShape.initialPoint, previewShape.center),
    }
    this.layer = {
      center: { ...layerShape.center },
      originalPoints: [...layerShape.points],
      localPoints: this.toLocalPoints(layerShape.points, layerShape.center),
      initialPointDist: this.distance(layerShape.center, layerShape.initialPoint),
      initialPointAngle: this.toAngle(layerShape.initialPoint, layerShape.center),
    }
    
  }

  toLocalPoints(points, center) {
    return points.map((p) => ({
      x: p.x - center.x,
      y: p.y - center.y,
      pressure: p.pressure,
    }));
  }

  toAngle(initialPoint, centerPoint) {
    return Math.atan2(
      initialPoint.y - centerPoint.y,
      initialPoint.x - centerPoint.x
    );
  }

  /**
   * Вызывается при каждом движении указателя
   * @param {{x: number, y: number}} newPointerPos - новое положение указателя (глобальные координаты)
   * @returns {Array<{x:number,y:number}>} - новые координаты всех точек фигуры
   */

  update(newPointerPos, source = this.preview) {
    const distNow = this.distance(source.center, newPointerPos);
    // Если начальное расстояние = 0, чтобы избежать NaN, берём масштаб 1
    const scale =
      source.initialPointDist === 0
        ? 1
        : distNow / source.initialPointDist;

    // Вычисляем угол
    const angleNow = Math.atan2(
      newPointerPos.y - source.center.y,
      newPointerPos.x - source.center.x
    );

    const angleDiff = angleNow - source.initialPointAngle;

    // Трансформируем каждую локальную точку
    const cosA = Math.cos(angleDiff);
    const sinA = Math.sin(angleDiff);

    const transformed = source.localPoints.map((lp) => {
      // Сначала масштаб
      let x = lp.x * scale;
      let y = lp.y * scale;

      // Потом поворот
      const rx = x * cosA - y * sinA;
      const ry = x * sinA + y * cosA;

      // Переводим обратно в глобальные координаты
      return {
        x: source.center.x + rx,
        y: source.center.y + ry,
        pressure: lp.pressure,
      };
    });

    return transformed;
  }

  /**
   * "Финализировать" - если нужно сохранить результат
   */

  finalize(newPointerPos) {
    const finalPoints = this.update(newPointerPos, this.layer);
    // Можно переписать originalPreviewPoints
    this.layer.originalPoints = finalPoints;
    return finalPoints;
  }

  distance(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
}


export const useSmartShapeTransform = (ref) => {

  const { menu, info, drawing, brush, } = ref;

  const {
    serverTimeFun,
  } = info.methods;


  const {
    gradientColorFun,
    visibleColorFun,
    combinedSetsFun,
  } = brush.methods;

  const {
    getLayerCoordinates,
    wipeTempCanvas,
    drawPreview,
    addStroke,
  } = drawing.methods;

  const {
    smartShape,
  } = brush;

  const handleSmartTransformStart = ({
    nativeEvent,
    layerShape,
    previewShape,
  }) => {

    const { lineWidth, brushType } = useStore.getState();

    const coordrinates = getLayerCoordinates(nativeEvent);
    layerShape.initialPoint = coordrinates.layerPoint;
    previewShape.initialPoint = coordrinates.canvasPoint;

    // Создаём объект трансформера
    smartShape.transformer = new ShapeTransformer({
      layerShape,
      previewShape,
      startTime: serverTimeFun(),
    });

    wipeTempCanvas();
    // Можно нарисовать превью (stroke)
    const combinedSets = combinedSetsFun()
    const stroke = {
      points: previewShape.points,
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      brush: brushType,
      lineWidth: lineWidth,
      sets: combinedSets,
      time: smartShape.transformer.startTime,
    };
    drawPreview(stroke);


  };

  const handleSmartTransformMove = ({ nativeEvent }) => {

    if (!smartShape.transformer) return;

    const { lineWidth, brushType } = useStore.getState();

    wipeTempCanvas();
    
    const coordrinates = getLayerCoordinates(nativeEvent);
    const newPoints = smartShape.transformer.update(coordrinates.canvasPoint);
    const combinedSets = combinedSetsFun()

    // Можно нарисовать превью (stroke)
    const stroke = {
      points: newPoints,
      color: visibleColorFun(),
      gradientColor: gradientColorFun(),
      brush: brushType,
      lineWidth: lineWidth,
      sets: combinedSets,
      time: smartShape.transformer.startTime,
      disableSmoothing: true,
    };

    drawPreview(stroke);

  };

  /**
   * При завершении (отпускании указателя):
   * - Получаем финальные точки
   * - Если нужно, добавляем в историю
   */
  const handleSmartTransformEnd = ({ nativeEvent }) => {

    if (!smartShape.transformer) return;

    const coordrinates = getLayerCoordinates(nativeEvent);
    const finalPoints = smartShape.transformer.finalize(coordrinates.layerPoint);

    wipeTempCanvas();

    const serverTime = serverTimeFun();
    addStroke(finalPoints, { 
      time: serverTime,
      disableSmoothing: true,
     });

    // Сброс
    smartShape.transformer = null;
  };

  return {
    handleSmartTransformStart,
    handleSmartTransformMove,
    handleSmartTransformEnd,
  };
};
