
import { TbCopy } from 'react-icons/tb';

export const handlePermanentSave = ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('permanent.title'),
    message: t('permanent.text'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('permanent.button_cancel') },
      { id: 'save', type: 'default', text: t('permanent.button_save') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'save') {
      window.croco?.haptic?.();

      hostAction('permanent', null, (result)=>{

        window.croco?.haptic?.();

        if (result?.success) {
          showPopupMessage({
            title: t('permanent_created.success_title'),
            message: t('permanent_created.success_message'),
          });
        } else {
          showPopupMessage({
            title: t('permanent_created.error_title'),
            message: t('permanent_created.error_message') + `<br/><br/>⚠️ ${result?.error}`,
          });
        }

      });
    }
  }

  showPopupMessage(popupParams)

};


export const handleAuction = ({ t, hostAction, showPopupMessage, game }) => {

  if (game.moderation) {
    return applyToAuction ({ hostAction, showPopupMessage, t, game});
  }

  const popupParams = {
    title: t('auction.title'),
    message: t('auction.text'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('auction.button_cancel') },
      { id: 'go', type: 'default', text: t('auction.button_go') },
    ],
  };
  popupParams.callback = (buttonId) => {
    if (buttonId === 'go') {
      applyToAuction ({ hostAction, showPopupMessage, t, game});
    }
  }
  showPopupMessage(popupParams)

};

const applyToAuction = ({ hostAction, showPopupMessage, t, game})=> {

  window.croco?.haptic?.();

  hostAction('auction', null, (result)=>{
    window.croco?.haptic?.();

    if (result?.error) {
      showPopupMessage({
        title: t('auction_applied.error_title'),
        message: result.error,
      });
    } else {
      game.moderation = {};
      showPopupMessage({
        title: t('auction_applied.success_title'),
        message: t('auction_applied.success_message'),
      });
    }

  });
  
}

export const handleCreateSticker = ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('sticker.title'),
    message: t('sticker.text'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('sticker.button_cancel') },
      { id: 'go', type: 'default', text: t('sticker.button_go') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'go') {
      window.croco?.haptic?.();

      hostAction('createSticker', null, (result)=>{
        window.croco?.haptic?.();

        if (result?.success) {
          showPopupMessage({
            title: t('sticker_created.success_title'),
            message: t('sticker_created.success_message'),
          });
        } else {
          showPopupMessage({
            title: t('sticker_created.error_title'),
            message: t('sticker_created.error_message') + `<br/><br/>⚠️ ${result?.error}`,
          });
        }

      });
    }
  }
  
  showPopupMessage(popupParams)

};


export const handleOpenInBrowser = ({ t, hostAction, showPopupMessage, showTemporaryHint }) =>{

  const popupParams = {
    title: t('fullscreen.title'),
    message: t('fullscreen.text'),
    buttons: [
      { id: 'no', type: 'cancel', text: t('fullscreen.button_cancel') },
      { id: 'yes', type: 'default', text: t('fullscreen.button_confirm') },
    ],
    items: [
      { id: 'clipboard', icon: TbCopy, label: t('fullscreen.copy_to_clipboard') },
      { type: 'set', key: 'openBrowser', label: t('settings.open_browser_on_start') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'yes') { makeItFullScreen() }
    if (buttonId === 'clipboard') { 
      const currentUrl = new URL(window.location.href);
      navigator.clipboard.writeText(currentUrl);
      showTemporaryHint(t('fullscreen.copied_to_clipboard'), {duration: 1500, force: true}, { top: '40%' });
     }
  }
  showPopupMessage(popupParams)


}


const makeItFullScreen = () =>{

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('inBrowser', '1');
  window.open(currentUrl.toString(), '_blank');

  setTimeout(() => {
    window.Telegram.WebApp.close();
  }, 100); 

  // () => window.Telegram.WebApp.openTelegramLink('http://t.me/CrocoDraw_Bot/open')
  // () => window.Telegram.WebApp.openLink('http://t.me/CrocoDraw_Bot/open')
  // () => window.Telegram.WebApp.openLink(window.location.href)
}


export const handlePetAvatar = async ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('pet.avatar.set_popup.title'),
    message: t('pet.avatar.set_popup.message'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('pet.avatar.set_popup.button_cancel') },
      { id: 'set', type: 'default', text: t('pet.avatar.set_popup.button_set') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'set') {

      hostAction('setAvatar', null, (result)=>{

        if (result?.success) {

          window.croco?.haptic?.();
          showPopupMessage({
            title: t('pet.avatar.success_popup.title'),
            message: t('pet.avatar.success_popup.message'),
          });

        } else if (result?.error === 'no_pet') {

          showPopupMessage({
            title: t('pet.avatar.error_no_pet.title'),
            message: t('pet.avatar.error_no_pet.message'),
          });
          
        } else if (result?.error === 'egg') {

          showPopupMessage({
            title: t('pet.avatar.error_egg.title'),
            message: t('pet.avatar.error_egg.message'),
          });

        } else if (result?.error === 'denied') {

          showPopupMessage({
            title: t('pet.avatar.error_denied.title'),
            message: t('pet.avatar.error_denied.message'),
          });

        }

      });

    }
  }
  showPopupMessage(popupParams)

}


export const handleSaveAccessory = async ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('pet.accessory.save.title'),
    message: t('pet.accessory.save.text'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('pet.accessory.save.button_cancel') },
      { id: 'go', type: 'default', text: t('pet.accessory.save.button_go') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'go') {
      window.croco?.haptic?.();

      hostAction('saveAccessory', null, (result)=>{
        window.croco?.haptic?.();

        if (result.success) {
          showPopupMessage({
            title: t('pet.accessory.save_result.success_title'),
            message: t('pet.accessory.save_result.success_message'),
          });
        } else {
          showPopupMessage({
            title: t('pet.accessory.save_result.error_title'),
            message: t('pet.accessory.save_result.error_message') + result?.error,
          });
        }

      });
    }
  }
  showPopupMessage(popupParams)

}

