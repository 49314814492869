import React, { useRef, useState, useEffect } from 'react';
import { FaUndo } from 'react-icons/fa'; // Импортируем иконку "назад"
import './GlyphEditor.css';

// Функция для упрощения штриха (алгоритм Дугласа-Пекера)
function simplifyPoints(points, tolerance = 1) {
  if (points.length <= 2) return points;

  const getDistance = (point, lineStart, lineEnd) => {
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;
    const lenSquared = dx * dx + dy * dy;
    if (lenSquared === 0) return Math.sqrt((point.x - lineStart.x) ** 2 + (point.y - lineStart.y) ** 2);

    let t = ((point.x - lineStart.x) * dx + (point.y - lineStart.y) * dy) / lenSquared;
    t = Math.max(0, Math.min(1, t));

    const closestX = lineStart.x + t * dx;
    const closestY = lineStart.y + t * dy;

    return Math.sqrt((point.x - closestX) ** 2 + (point.y - closestY) ** 2);
  };

  const simplify = (startIdx, endIdx, points, tolerance, keep) => {
    let maxDist = 0;
    let maxIdx = startIdx;

    for (let i = startIdx + 1; i < endIdx; i++) {
      const dist = getDistance(points[i], points[startIdx], points[endIdx]);
      if (dist > maxDist) {
        maxDist = dist;
        maxIdx = i;
      }
    }

    if (maxDist > tolerance) {
      const left = simplify(startIdx, maxIdx, points, tolerance, []);
      const right = simplify(maxIdx, endIdx, points, tolerance, []);
      return [...left.slice(0, -1), ...right];
    } else {
      return [points[startIdx], points[endIdx]];
    }
  };

  return simplify(0, points.length - 1, points, tolerance, []);
}

// Функция для нормализации координат глифа
function normalizeGlyph(strokes, canvasSize) {
  if (!strokes || strokes.length === 0) return [];

  // Собираем все точки всех штрихов для определения границ
  const allPoints = strokes.flatMap(stroke => stroke.points);

  if (allPoints.length === 0) return [];

  // Находим границы
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  allPoints.forEach(p => {
    minX = Math.min(minX, p.x);
    minY = Math.min(minY, p.y);
    maxX = Math.max(maxX, p.x);
    maxY = Math.max(maxY, p.y);
  });

  const width = maxX - minX;
  const height = maxY - minY;
  if (width === 0 || height === 0) return [];

  // Центрируем
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  // Масштабируем, чтобы глиф помещался в круг радиуса 10
  const scaleFactor = Math.min(20 / width, 20 / height);

  const roundCoordinate = (x => Math.round(x * 100) / 100);

  // Нормализуем каждый штрих, сохраняя их относительное расположение
  return strokes.map(stroke => ({
    points: stroke.points.map(p => ({
      x: roundCoordinate((p.x - centerX) * scaleFactor),
      y: roundCoordinate((p.y - centerY) * scaleFactor),
    })),
  }));
}

function GlyphEditor({ store, currentGlyph, handleNewGlyph, onCancel }) {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentStroke, setCurrentStroke] = useState([]); // Текущий штрих
  const [strokes, setStrokes] = useState([]); // Все завершенные штрихи
  const canvasSize = 200; // Размер канваса для рисования

  // Обновление канваса
  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvasSize, canvasSize);
    ctx.fillStyle = '#f0f0f0';
    ctx.fillRect(0, 0, canvasSize, canvasSize);

    // Рисуем сетку для удобства
    ctx.strokeStyle = '#ddd';
    ctx.lineWidth = 0.5;
    for (let i = 0; i <= canvasSize; i += 20) {
      ctx.beginPath();
      ctx.moveTo(i, 0);
      ctx.lineTo(i, canvasSize);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(0, i);
      ctx.lineTo(canvasSize, i);
      ctx.stroke();
    }

    // Рисуем текущий глиф, если он есть
    if (currentGlyph) {
      const glyphStrokes = Array.isArray(currentGlyph) ? [{ points: currentGlyph }] : currentGlyph;
      drawGlyphPreview(ctx, glyphStrokes, canvasSize);
    }

    // Рисуем завершенные штрихи (с заливкой)
    strokes.forEach(stroke => drawStroke(ctx, stroke.points, true));

    // Рисуем текущий штрих (без заливки)
    if (currentStroke.length > 0) {
      drawStroke(ctx, currentStroke, false);
    }
  };

  useEffect(() => {
    redrawCanvas();
  }, [currentGlyph, strokes, currentStroke]);

  const drawGlyphPreview = (ctx, glyphStrokes, size) => {
    if (!glyphStrokes || glyphStrokes.length === 0) return;

    const scaleFactor = size / 20; // Исходный диаметр звезды = 20
    const offset = size / 2; // Смещаем в центр канваса

    glyphStrokes.forEach(stroke => {
      const points = stroke.points;
      if (points.length === 0) return;

      ctx.beginPath();
      ctx.moveTo(offset + points[0].x * scaleFactor, offset + points[0].y * scaleFactor);
      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(offset + points[i].x * scaleFactor, offset + points[i].y * scaleFactor);
      }
      ctx.closePath();
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fill();
    });
  };

  const drawStroke = (ctx, points, fill = false) => {
    if (points.length === 0) return;

    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.stroke();

    if (fill) {
      ctx.closePath();
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fill();
    }
  };

  const startDrawing = (e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setIsDrawing(true);
    setCurrentStroke([{ x, y }]);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    e.preventDefault(); // Предотвращаем стандартное поведение

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setCurrentStroke(prev => [...prev, { x, y }]);
  };

  const stopDrawing = (e) => {
    if (!isDrawing) return;

    e.preventDefault(); // Предотвращаем стандартное поведение

    setIsDrawing(false);

    if (currentStroke.length > 2) {
      // Упрощаем штрих
      const simplifiedPoints = simplifyPoints(currentStroke, 1);
      // Добавляем штрих в массив strokes (без нормализации на этом этапе)
      setStrokes(prev => [...prev, { points: simplifiedPoints }]);
    }

    setCurrentStroke([]);
  };

  const handleSetGlyph = () => {
    if (strokes.length > 0) {
      // Нормализуем все штрихи вместе
      const normalizedGlyph = normalizeGlyph(strokes, canvasSize);
      handleNewGlyph(normalizedGlyph);
    }
    onCancel(); // Закрываем редактор
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation()
    if (strokes.length > 0) {
      // Если есть штрихи, устанавливаем глиф
      handleSetGlyph();
    } else {
      // Если штрихов нет, отменяем
      onCancel();
    }
  };

  // Функция отмены последнего штриха
  const handleUndo = () => {
    setStrokes(prev => prev.slice(0, -1)); // Удаляем последний штрих
  };

  return (
    <>
      <div className="glyph-overlay-background" onClick={handleOverlayClick}></div>
      <div className="glyph-editor-container">
        <span className="glyph-editor-title">{window.t('glyph.editor.title')}</span>
        <canvas
          ref={canvasRef}
          width={canvasSize}
          height={canvasSize}
          onPointerDown={startDrawing}
          onPointerMove={draw}
          onPointerUp={stopDrawing}
          style={{ border: '1px solid #ccc', backgroundColor: '#f0f0f0', cursor: 'crosshair' }}
        />
        <div className="glyph-editor-buttons">
          {/* Кнопка "Назад", отображается только если есть штрихи */}
          {strokes.length > 0 && (
            <button className="glyph-action-button undo-button" onClick={handleUndo}>
              <FaUndo />
            </button>
          )}
          {/* Кнопка "Установить" или "Отмена" */}
          <button className="gray-button" onClick={strokes.length > 0 ? handleSetGlyph : onCancel}>
            {strokes.length > 0 ? window.t('glyph.editor.set') : window.t('glyph.editor.cancel')}
          </button>
        </div>
      </div>
    </>
  );
}

export default GlyphEditor;