// src/components/puzzle/PuzzleArea.js
import useStore from '../../store';
import PuzzlePiece from './PuzzlePiece';

const DraggingPiece = () => {
  // Подписываемся на нужные части для ререндера
  useStore((state) => state.puzzleRender);
  const { ref } = useStore.getState();
  const { puzzle } = ref;
  const { draggingPiece } = puzzle.state;
  if (!draggingPiece) return null;

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 1000,
      }}
      className="dragging-piece-container"
    >
      <PuzzlePiece pieceId={draggingPiece.pieceId} />
    </div>
  );
};

export default DraggingPiece;