export function freeMemory({ imageCache, activeLayerId }) {
  // 1. Собираем информацию о кэшах
  const caches = [];
  const allTimes = []; // Для хранения всех времен добавления кэшей (для глобальной защиты)

  for (const [layerId, cacheMap] of Object.entries(imageCache)) {
    const entries = Array.from(cacheMap.entries());
    if (entries.length === 0) continue;

    // Сортируем кэши внутри слоя по убыванию времени добавления (value.time)
    const sortedEntries = entries.sort((a, b) => b[1].time - a[1].time);

    // Если это активный слой, защищаем 5 последних кэшей, иначе только самый последний
    const isActiveLayer = activeLayerId && layerId === activeLayerId;
    const protectedCountPerLayer = isActiveLayer ? Math.min(5, sortedEntries.length) : 1;

    // Добавляем все времена добавления в глобальный список для определения 5 последних
    entries.forEach(([strokeTime, value]) => {
      allTimes.push({ layerId, strokeTime, addTime: value.time });
    });

    // Собираем информацию о кэшах в этом слое
    entries.forEach(([strokeTime, value], index) => {
      const timeIndex = sortedEntries.findIndex(([t]) => t === strokeTime);
      const isProtectedByLayer = timeIndex < protectedCountPerLayer; // Защита по слою

      caches.push({
        layerId,
        strokeTime, // Время штриха (ключ карты)
        addTime: value.time, // Время добавления (для сортировки)
        protectedByLayer: isProtectedByLayer,
        protectedGlobally: false,
        cacheMap,
      });
    });
  }

  // 2. Определяем 5 последних кэшей глобально (по времени добавления)
  if (allTimes.length > 0) {
    allTimes.sort((a, b) => b.addTime - a.addTime);
    const globalProtectedTimes = new Set(
      allTimes.slice(0, Math.min(5, allTimes.length)).map(t => t.strokeTime)
    );
    caches.forEach(cache => {
      if (globalProtectedTimes.has(cache.strokeTime)) {
        cache.protectedGlobally = true;
      }
    });
  }

  // 3. Определяем окончательную защиту: кэш защищён, если он защищён либо по слою, либо глобально
  caches.forEach(cache => {
    cache.protected = cache.protectedByLayer || cache.protectedGlobally;
  });

  // 4. Если общее количество кэшей <= 20, ничего не делаем
  const totalCaches = caches.length;
  if (totalCaches <= 20) return;

  // 5. Определяем, сколько кэшей нужно удалить
  const toRemoveCount = totalCaches - 20;

  // 6. Собираем незащищённые кэши и сортируем их по времени добавления (удаляем самые старые)
  const removableCaches = caches
    .filter(c => !c.protected)
    .sort((a, b) => a.addTime - b.addTime);

  // 7. Удаляем кэши, пока не достигнем нужного количества удалений
  let removed = 0;
  for (const cache of removableCaches) {
    if (removed >= toRemoveCount) break;
    window.croco?.log(`🗑 deleting cache: ${cache.strokeTime}`);

    cache.cacheMap.delete(cache.strokeTime);
    removed++;
  }
}