// saveDrawing.js
export const saveDrawing = ({
  socket, 
  gameId, 
  telegramUserId,
  drawingMode,
  userId, 
  moderId,
  strokes, 
  colors, 
  side,
  dimensions,
  orientation,
  connectionStatusRef,
}) => {

  if (!socket) {return;}

  try {

    const initTime = Date.now();

    socket.emit('strokes', { gameId, drawingMode, userId: telegramUserId, }, { 
      side, 
      dimensions, 
      orientation, 
      strokes: strokes.slice(-100), 
      length: strokes.length, 
      userId, 
      moderId,
      colors,
      }, (result)=>{
      connectionStatusRef.current.lastSaveTime = Date.now();
    });

    setTimeout(() => {
      if (initTime > connectionStatusRef.current.lastSaveTime) {
        connectionStatusRef.current.lastConnectionErrorTime = Date.now();
      }
    }, 2000);

    console.log("Sending strokes: ", strokes.length); // Для отладки

  } catch (e) {

    connectionStatusRef.current.lastConnectionErrorTime = Date.now();
    console.error("Failed to send canvas data to server:", strokes); // Для отладки
    
  }
};