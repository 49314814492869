// StrokeHistoryOverlay.js
import './StrokeHistoryOverlay.css';
import useStore from '../../../store';

function StrokeHistoryOverlay({ store }) {
  const { ref } = store;
  const { menu, telegram, history, drawing, work, info } = ref;
  const { closeMenu } = menu.methods;
  const { saveUserGameSets } = work.methods;
  const { showTemporaryHint } = menu.methods;

  // Текущая категория из ref.info.user.historyCat ('strokes' или 'users')
  const currentCategory = info.user.historyCat || 'strokes';

  // Смена категории
  const switchCategory = (category) => {
    work.methods.saveUserSets({ historyCat: category });
  };

  // --- Логика для Strokes ---
  const myStrokes = history.userStrokes[telegram.activeUserId] || [];

  if (!history.strokePreviewCache) {
    history.strokePreviewCache = new Map();
  }

  const recentStrokes = myStrokes
    .sort((a, b) => a.time - b.time)
    .filter(stroke => !stroke.tech)
    .slice(-30)
    .reverse();

  const getStrokePreview = (stroke) => {
    if (history.strokePreviewCache.has(stroke.time)) {
      return history.strokePreviewCache.get(stroke.time);
    }

    const canvas = document.createElement('canvas');
    canvas.width = 25;
    canvas.height = 25;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (stroke.type === 'stroke' && stroke.points?.length && !stroke.effect) {
      const points = stroke.points;
      const minX = Math.min(...points.map(p => p.x));
      const maxX = Math.max(...points.map(p => p.x));
      const minY = Math.min(...points.map(p => p.y));
      const maxY = Math.max(...points.map(p => p.y));

      const strokeWidth = maxX - minX;
      const strokeHeight = maxY - minY;
      const lineWidthOffset = stroke.lineWidth ? stroke.lineWidth : 2;

      const contentWidth = strokeWidth + lineWidthOffset * 2;
      const contentHeight = strokeHeight + lineWidthOffset * 2;

      const scale = Math.min(20 / contentWidth, 20 / contentHeight);

      const centerX = (minX + maxX) / 2;
      const centerY = (minY + maxY) / 2;

      const offsetX = 12.5 - (centerX - minX + lineWidthOffset) * scale;
      const offsetY = 12.5 - (centerY - minY + lineWidthOffset) * scale;

      const currentStroke = { ...stroke };
      currentStroke.points = points.map(p => ({
        x: (p.x - minX) * scale + offsetX,
        y: (p.y - minY) * scale + offsetY,
        pressure: p.pressure || 1,
      }));
      currentStroke.lineWidth = Math.min(stroke.lineWidth * scale, 3);

      ref.drawing.methods.drawPreview({
        ...currentStroke,
        brush: 'plain',
      }, ctx);
    } else if (stroke.color) {
      ctx.fillStyle = stroke.color;
      ctx.beginPath();
      const centerX = 12.5;
      const centerY = 12.5;
      const outerRadius = 8;
      const innerRadius = 4;
      const spikes = 5;

      for (let i = 0; i < spikes * 2; i++) {
        const radius = i % 2 === 0 ? outerRadius : innerRadius;
        const angle = (i * Math.PI) / spikes - Math.PI / 2;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      }
      ctx.closePath();
      ctx.fill();
    }

    const dataUrl = canvas.toDataURL('image/png');
    history.strokePreviewCache.set(stroke.time, dataUrl);
    return dataUrl;
  };

  const formatStrokeInfo = (stroke) => {
    let info = '';
    if (stroke.type === 'stroke' && stroke.points?.length) {
      info += `${stroke.points.length} pts`;
    } else {
      info += (stroke.note || stroke.type);
    }
    if (stroke.brush) info += ` • ${stroke.brush}`;
    if (stroke.effect) info += ` • ${stroke.effect}`;
    info += ` • ${new Date(stroke.time).toLocaleTimeString()}`;
    return info;
  };

  // --- Логика для Users ---
  const allUsers = ref.info.game.users;
  const userStrokes = history.userStrokes;

  const usersList = Object.keys(allUsers)
    .map(userId => {
      const currentUser = allUsers[userId];
      const strokeCount = (userStrokes[userId] || []).filter(stroke => !stroke.tech).length;
      return {
        userId,
        firstName: currentUser.first_name,
        strokeCount,
        banStrokes: currentUser.sets?.banStrokes || false
      };
    })
    .filter(user => user.strokeCount)
    .sort((a, b) => b.strokeCount - a.strokeCount);

  const isAdminOrModerator = telegram.telegramUserId === info.game.user_key || info.user.moder;

  const handleUserClick = (userId, currentBanStatus) => {
    if (!isAdminOrModerator) {
      showTemporaryHint(window.t('tooltip.access_denied'));
      return;
    }
    saveUserGameSets({ 
      userId, 
      banStrokes: !currentBanStatus 
    });
    ref.drawing.methods.redraw?.();
  };

  const handleRedoStrokes = () => {
    // Обновляем параметры URL без перезагрузки
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('cacheOff', '1');
    window.history.replaceState({}, document.title, currentUrl.toString());
    
    // Вызываем стандартную перезагрузку
    window.location.reload();
  };

  return (
    <>
      <div className="overlay-background" onClick={() => closeMenu()}></div>
      <div className="history-menu">
        <div className="history-header">
          <span className="history-title">
            {currentCategory === 'strokes' ? window.t('history.last_strokes') : window.t('history.restrict_users')}
          </span>
          <div className="history-category-switcher">
            <button
              className={`history-category-btn ${currentCategory === 'strokes' ? 'active' : ''}`}
              onClick={() => switchCategory('strokes')}
            >
              {window.t('history.button_strokes')}
            </button>
            <button
              className={`history-category-btn ${currentCategory === 'users' ? 'active' : ''}`}
              onClick={() => switchCategory('users')}
            >
              {window.t('history.button_users')}
            </button>
          </div>
        </div>

        <div className="history-list add-scroll touch-active">
          {currentCategory === 'strokes' ? (
            recentStrokes.length === 0 ? (
              <div className="history-item">
                <span className="history-item-info">No strokes yet</span>
              </div>
            ) : (
              recentStrokes.map((stroke, index) => (
                <div
                  key={index}
                  className="history-item"
                  onClick={() => history.methods.handleStrokeHistoryClick(stroke)}
                  style={{ opacity: stroke.cancelled ? 0.4 : 1 }}
                >
                  <span className="history-item-info">
                    {stroke.cancelled ? '❌ ' : '✔️ '}
                    <span className="semi-bold">{formatStrokeInfo(stroke)}</span>
                  </span>
                  <img
                    src={getStrokePreview(stroke)}
                    alt="Stroke preview"
                    className="stroke-preview"
                  />
                </div>
              ))
            )
          ) : (
            usersList.length === 0 ? (
              <div className="history-item">
                <span className="history-item-info">No users yet</span>
              </div>
            ) : (
              usersList.map((user) => (
                <div
                  key={user.userId}
                  className="history-item"
                  onClick={() => handleUserClick(user.userId, user.banStrokes)}
                  style={{ opacity: user.banStrokes ? 0.4 : 1 }}
                >
                  <span className="history-item-info">
                    {user.banStrokes ? '❌ ' : '✔️ '}
                    <span className="semi-bold">{user.firstName}</span>
                    {` • ${user.strokeCount} strokes`}
                  </span>
                </div>
              ))
            )
          )}
        </div>

        { currentCategory === 'users' && (
          <button 
          className="wide-button" 
          onClick={handleRedoStrokes}
          style={{ backgroundColor: 'var(--tg-theme-destructive-text-color)' }}
        >
          {window.t('history.redo_strokes')}
        </button>
        )}
        
        <button className="wide-button" onClick={() => closeMenu()}>
          {window.t('settings.back')}
        </button>
      </div>
    </>
  );
}

export default StrokeHistoryOverlay;