import React, { useState } from 'react';
import useStore from '../../../store';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { TbArrowsDiagonal2 } from 'react-icons/tb';

import './Reference.css';

const Reference = ({ store }) => {
  const setState = useStore.setState;

  const { ref } = store;
  const { menu } = ref;

  menu.source = menu.source || {};
  const source = menu.source;

  const [, forceUpdate] = useState(0);
  const update = () => forceUpdate(n => n + 1);

  const handleClose = () => {
    setState({ showReference: false });
  };

  const handleDelete = () => {
    menu.source = {};
    update();
  };

  const handleUrlInputBlur = (e) => {
    const url = e.target.value.trim();
    if (url) {
      const img = new Image();
      // При необходимости для кроссдоменных изображений:
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const maxWidth = window.innerWidth * 0.5;
        const maxHeight = window.innerHeight * 0.3;
        let displayWidth = img.naturalWidth;
        let displayHeight = img.naturalHeight;
        const widthRatio = displayWidth / maxWidth;
        const heightRatio = displayHeight / maxHeight;
        const ratio = Math.max(widthRatio, heightRatio, 1);
        displayWidth /= ratio;
        displayHeight /= ratio;

        // Создаем оффскрин-канвас
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        menu.source = {
          image: url,
          naturalWidth: img.naturalWidth,
          naturalHeight: img.naturalHeight,
          displayWidth,
          displayHeight,
          x: 20,
          y: 20,
          dragging: false,
          resizing: false,
          resizingByHandle: false,
          pinchStartDistance: null,
          initialWidth: displayWidth,
          initialHeight: displayHeight,
          initialX: null,
          initialY: null,
          canvas, // сохраняем созданный канвас
        };
        update();
      };
      img.onerror = () => {
        // Можно добавить уведомление об ошибке
      };
      img.src = url;
    }
  };

  // Функция обработки изображения (для загрузки из файла и буфера обмена)
  const processImage = (dataUrl, callback) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const maxWidth = window.innerWidth * 0.5;
      const maxHeight = window.innerHeight * 0.3;
      let displayWidth = img.naturalWidth;
      let displayHeight = img.naturalHeight;
      const widthRatio = displayWidth / maxWidth;
      const heightRatio = displayHeight / maxHeight;
      const ratio = Math.max(widthRatio, heightRatio, 1);
      displayWidth /= ratio;
      displayHeight /= ratio;
      // Создаем оффскрин-канвас
      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      menu.source = {
        image: dataUrl,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
        displayWidth,
        displayHeight,
        x: 20,
        y: 20,
        dragging: false,
        resizing: false,
        resizingByHandle: false,
        pinchStartDistance: null,
        initialWidth: displayWidth,
        initialHeight: displayHeight,
        initialX: null,
        initialY: null,
        canvas, // сохраняем созданный канвас
      };
      update();
      if (callback) callback();
    };
    img.onerror = () => {
      alert("Ошибка при загрузке изображения");
    };
    img.src = dataUrl;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUrl = event.target.result;
        processImage(dataUrl);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Выбранный файл не является изображением");
    }
  };

  // Обработка вставки изображения из буфера обмена
  const handlePaste = (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        const blob = item.getAsFile();
        const reader = new FileReader();
        reader.onload = (event) => {
          const dataUrl = event.target.result;
          processImage(dataUrl, () => {
            // Очищаем поле ввода, чтобы пользователь видел, что вставка произошла
            e.target.value = '';
          });
        };
        reader.readAsDataURL(blob);
        e.preventDefault();
        break;
      }
    }
  };

  const handleTouchStart = (e) => {
    if (useStore.getState().activeTool === 'pipette') { return; }
    if (!source.image) return;
    if (e.touches.length === 1) {
      source.dragging = true;
      source.initialX = e.touches[0].clientX - source.x;
      source.initialY = e.touches[0].clientY - source.y;
    } else if (e.touches.length === 2) {
      source.resizing = true;
      const [touch1, touch2] = e.touches;
      const dx = touch2.clientX - touch1.clientX;
      const dy = touch2.clientY - touch1.clientY;
      source.pinchStartDistance = Math.hypot(dx, dy);
      source.initialWidth = source.displayWidth;
      source.initialHeight = source.displayHeight;
    }
    update();
  };

  const handleTouchMove = (e) => {
    if (useStore.getState().activeTool === 'pipette') { return; }
    if (!source.image) return;
    if (source.dragging && e.touches.length === 1) {
      const touch = e.touches[0];
      source.x = touch.clientX - source.initialX;
      source.y = touch.clientY - source.initialY;
      update();
    } else if (source.resizing && e.touches.length === 2) {
      const [touch1, touch2] = e.touches;
      const dx = touch2.clientX - touch1.clientX;
      const dy = touch2.clientY - touch1.clientY;
      const distance = Math.hypot(dx, dy);
      const scale = distance / source.pinchStartDistance;
      const centerX = source.x + source.displayWidth / 2;
      const centerY = source.y + source.displayHeight / 2;
      source.displayWidth = source.initialWidth * scale;
      source.displayHeight = source.initialHeight * scale;
      source.x = centerX - source.displayWidth / 2;
      source.y = centerY - source.displayHeight / 2;
      update();
    }
    e.preventDefault();
  };

  const handleTouchEnd = () => {
    if (useStore.getState().activeTool === 'pipette') { return; }

    source.dragging = false;
    source.resizing = false;
    source.pinchStartDistance = null;
    update();
  };

  const handleResizeMouseDown = (e) => {
    if (useStore.getState().activeTool === 'pipette') { return; }

    e.stopPropagation();
    e.preventDefault();
    source.resizingByHandle = true;
    source.resizeStartX = e.clientX;
    source.resizeStartY = e.clientY;
    source.initialWidthHandle = source.displayWidth;
    source.initialHeightHandle = source.displayHeight;

    const handleMouseMove = (e) => {
      if (!source.resizingByHandle) return;
      const dx = e.clientX - source.resizeStartX;
      const dy = e.clientY - source.resizeStartY;
      const scaleX = (source.initialWidthHandle + dx) / source.initialWidthHandle;
      const scaleY = (source.initialHeightHandle + dy) / source.initialHeightHandle;
      const scale = Math.min(scaleX, scaleY);
      if (scale > 0) {
        source.displayWidth = source.initialWidthHandle * scale;
        source.displayHeight = source.initialHeightHandle * scale;
      }
      update();
    };

    const handleMouseUp = () => {
      source.resizingByHandle = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleResizeTouchStart = (e) => {
    if (useStore.getState().activeTool === 'pipette') { return; }
    
    e.stopPropagation();
    e.preventDefault();
    if (e.touches.length === 1) {
      source.resizingByHandle = true;
      source.resizeStartX = e.touches[0].clientX;
      source.resizeStartY = e.touches[0].clientY;
      source.initialWidthHandle = source.displayWidth;
      source.initialHeightHandle = source.displayHeight;
    }
    update();
  };

  const handleResizeTouchMove = (e) => {
    
    if (!source.resizingByHandle || e.touches.length !== 1) return;
    const touch = e.touches[0];
    const dx = touch.clientX - source.resizeStartX;
    const dy = touch.clientY - source.resizeStartY;
    const scaleX = (source.initialWidthHandle + dx) / source.initialWidthHandle;
    const scaleY = (source.initialHeightHandle + dy) / source.initialHeightHandle;
    const scale = Math.min(scaleX, scaleY);
    if (scale > 0) {
      source.displayWidth = source.initialWidthHandle * scale;
      source.displayHeight = source.initialHeightHandle * scale;
    }
    update();
    e.preventDefault();
  };

  const handleResizeTouchEnd = () => {
    
    source.resizingByHandle = false;
    update();
  };

  if (!store.showReference) return null;

  const renderPromptContainer = () => {
    return (
      <div className="reference-prompt-container">
        <div className="reference-title">{window.t('reference.title')}</div>
        <input
          type="text"
          className="reference-url-input-field input-field"
          placeholder={window.t('reference.placeholder')}
          onChange={handleUrlInputBlur}
          onBlur={handleUrlInputBlur}
          onPaste={handlePaste} // Обработчик вставки
        />
        <div className="message-popup-button-container">
          <button
            className="message-popup-button cancel-button"
            onClick={handleClose}
          >
            {window.t('reference.button_cancel')}
          </button>
          <label className="message-popup-button">
            {window.t('reference.button_open_file')}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </label>
        </div>
      </div>
    );
  };

  const renderReferenceImate = () => {
    return (
      <div className="reference-container">
        <div
          className="reference-image-wrapper"
          style={{
            position: 'absolute',
            left: source.x,
            top: source.y,
            width: source.displayWidth,
            height: source.displayHeight,
          }}

          onPointerDown={(e)=>{
            ref.drawing.methods.startPickingColorFromCanvas({ nativeEvent: e });
          }}
          onPointerMove={(e)=>{
            ref.drawing.methods.searchForColorFromCanvas({ nativeEvent: e });
          }}
          onPointerUp={(e)=>{
            ref.drawing.methods.pickColorFromCanvas({ nativeEvent: e });
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={(e) => {

            if (useStore.getState().activeTool === 'pipette') {  return;}

            e.preventDefault();
            source.dragging = true;
            source.initialX = e.clientX - source.x;
            source.initialY = e.clientY - source.y;
            const handleMouseMove = (e) => {
              source.x = e.clientX - source.initialX;
              source.y = e.clientY - source.initialY;
              update();
            };
            const handleMouseUp = () => {
              source.dragging = false;
              window.removeEventListener('mousemove', handleMouseMove);
              window.removeEventListener('mouseup', handleMouseUp);
            };
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
          }}
        >
          <img
            src={source.image}
            alt="Reference"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            draggable="false"
            onDragStart={(e) => e.preventDefault()}
          />
          <button
            className="reference-close-button"
            onClick={handleClose}
          >
            <FaTimes className="reference-button-icon" />
          </button>
          <button
            className="reference-delete-button"
            onClick={handleDelete}
          >
            <FaTrash className="reference-button-icon" />
          </button>
          <div
            className="reference-resize-handle"
            onMouseDown={handleResizeMouseDown}
            onTouchStart={handleResizeTouchStart}
            onTouchMove={handleResizeTouchMove}
            onTouchEnd={handleResizeTouchEnd}
          >
            <TbArrowsDiagonal2 />
          </div>
        </div>
      </div>
    );
  };

  return (
    !source.image ? (
      renderPromptContainer()
    ) : (
      renderReferenceImate()
    )
  );
};

export default Reference;
