import { useState, useRef, useEffect } from 'react';

const useDragAndDropSort = ({
  items,
  onSort,
  containerRef,
  keyExtractor = (item) => item.id,
  canDrag = () => true,
  dragThreshold = 0, // Порог смещения в пикселях (по умолчанию 5)
}) => {
  const [draggingId, setDraggingId] = useState(null);
  const [dragOverId, setDragOverId] = useState(null);
  const [dragDirection, setDragDirection] = useState(null);
  const [ghostPosition, setGhostPosition] = useState({ x: 0, y: 0 });
  const [isDraggingActive, setIsDraggingActive] = useState(false); // Флаг активного перетаскивания
  const itemRefs = useRef({});
  const startPos = useRef({ x: 0, y: 0 }); // Начальная позиция указателя
  const actualPos = useRef({ x: 0, y: 0 }); // Начальная позиция указателя
  const touchDelayTimeout = useRef(null);

  const handlePointerDown = (e, itemId) => {
    if (!canDrag(itemId)) return;

    e.preventDefault();
    e.stopPropagation();

    const element = itemRefs.current[itemId];
    if (!element) return;

    // Сохраняем начальную позицию указателя
    startPos.current = { x: e.clientX, y: e.clientY };
    actualPos.current = { x: e.clientX, y: e.clientY };

    if (e.pointerType === 'touch') {
      // Очищаем предыдущий таймаут, если он есть
      if (touchDelayTimeout.current) {
        clearTimeout(touchDelayTimeout.current);
      }
      
      // Устанавливаем задержку для touch
      touchDelayTimeout.current = setTimeout(() => {

        // Вычисляем смещение от начальной позиции
        const deltaX = Math.abs(actualPos.current.x - startPos.current.x);
        const deltaY = Math.abs(actualPos.current.y - startPos.current.y);

        if (deltaX > 15 || deltaY > 15) { return; }

        setDraggingId(itemId);
        setIsDraggingActive(true);
        window.croco?.haptic?.(); // Виброотклик при начале перетаскивания
        setGhostPosition({ 
          x: e.clientX - containerRef.current.getBoundingClientRect().left, 
          y: e.clientY - containerRef.current.getBoundingClientRect().top 
        });
      }, 500); // 500мс задержка

    } else {

      setDraggingId(itemId);

    }

    document.body.style.userSelect = 'none';
  };

  const handlePointerMove = (e) => {

    actualPos.current = { x: e.clientX, y: e.clientY };

    if (!draggingId) return;

    e.preventDefault();
    e.stopPropagation();

    const containerRect = containerRef.current.getBoundingClientRect();
    const localX = e.clientX - containerRect.left;
    const localY = e.clientY - containerRect.top;

    // Вычисляем смещение от начальной позиции
    const deltaX = Math.abs(e.clientX - startPos.current.x);
    const deltaY = Math.abs(e.clientY - startPos.current.y);

    // Если смещение превысило порог, активируем перетаскивание
    if (e.pointerType === 'touch'
      || (!isDraggingActive && (deltaX > dragThreshold || deltaY > dragThreshold))) {
      setIsDraggingActive(true);
      setGhostPosition({ x: localX, y: localY });
    }

    // Если перетаскивание активно, обновляем позицию "призрака"
    if (isDraggingActive) {
      setGhostPosition({ x: localX, y: localY });

      const elements = document.elementsFromPoint(e.clientX, e.clientY);
      const draggableItem = elements.find(el => el.dataset.draggableId);

      if (draggableItem) {
        const targetId = draggableItem.dataset.draggableId;
        if (targetId && targetId !== draggingId) {
          setDragOverId(targetId);

          const fromIndex = items.findIndex(item => keyExtractor(item) === draggingId);
          const toIndex = items.findIndex(item => keyExtractor(item) === targetId);
          setDragDirection(fromIndex < toIndex ? 'down' : 'up');
        } else {
          setDragOverId(null);
          setDragDirection(null);
        }
      } else {
        setDragOverId(null);
        setDragDirection(null);
      }
    }
  };

  const handlePointerUp = (e) => {
    if (touchDelayTimeout.current) {
      clearTimeout(touchDelayTimeout.current);
    }
    

    if (!draggingId) return;

    e.preventDefault();
    e.stopPropagation();

    // Если перетаскивание было активно и есть целевой элемент
    if (isDraggingActive && dragOverId && dragOverId !== draggingId) {
      const sorted = [...items];
      const fromIndex = sorted.findIndex(item => keyExtractor(item) === draggingId);
      const toIndex = sorted.findIndex(item => keyExtractor(item) === dragOverId);

      if (fromIndex !== -1 && toIndex !== -1) {
        const [movedItem] = sorted.splice(fromIndex, 1);
        sorted.splice(toIndex, 0, movedItem);

        const updatedItems = sorted.map((item, idx) => ({
          ...item,
          order: sorted.length - idx - 1,
        }));

        onSort(updatedItems);
      }
    }

    setDraggingId(null);
    setDragOverId(null);
    setDragDirection(null);
    setGhostPosition({ x: 0, y: 0 });
    setIsDraggingActive(false);
    document.body.style.userSelect = '';
  };

  useEffect(() => {
    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);
    document.addEventListener('pointercancel', handlePointerUp);

    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      document.removeEventListener('pointercancel', handlePointerUp);
    };
  }, [draggingId, dragOverId, isDraggingActive]);

  return {
    draggingId,
    dragOverId,
    dragDirection,
    ghostPosition,
    itemRefs,
    handlePointerDown,
    handlePointerUp,
    isDraggingActive, // Возвращаем флаг, чтобы можно было использовать его для стилей
  };
};

export default useDragAndDropSort;