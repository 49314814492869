import './LayerThumbnail.css';

function LayerThumbnail({ 
  layerId, 
  store,
  style = {},
  onClick,
}) {
  const { ref } = store;
  const { menu, convy } = ref;

  // Функция генерации миниатюры
  const generateThumbnail = (canvas) => {
    if (!canvas) return null;

    const maxSize = 40;
    const scale = Math.min(maxSize / canvas.width, maxSize / canvas.height);
    const width = canvas.width * scale;
    const height = canvas.height * scale;

    const thumbnailCanvas = document.createElement('canvas');
    thumbnailCanvas.width = width;
    thumbnailCanvas.height = height;
    const ctx = thumbnailCanvas.getContext('2d');

    const tileSize = 4.5;
    const lightColor = '#ffffff';
    const darkColor = '#cccccc';

    for (let y = 0; y < height; y += tileSize) {
      for (let x = 0; x < width; x += tileSize) {
        const isEvenTile = (Math.floor(x / tileSize) + Math.floor(y / tileSize)) % 2 === 0;
        ctx.fillStyle = isEvenTile ? lightColor : darkColor;
        ctx.fillRect(x, y, tileSize, tileSize);
      }
    }

    ctx.drawImage(canvas, 0, 0, width, height);

    // Возвращаем объект с URL и размерами
    return {
      src: thumbnailCanvas.toDataURL(),
      width,
      height,
    };
  };

  // Получение или генерация миниатюры
  const getThumbnail = () => {
    const layer = convy.methods.getLayer(layerId);
    const canvas = layer?.canvas;
    if (!canvas) return null;

    const thumbIsFresh = layer.thumbnail && layer.ready === layer.thumbGenTime;

    if (!thumbIsFresh) {
      const thumbnail = generateThumbnail(canvas);
      layer.thumbGenTime = layer.ready;
      layer.thumbnail = thumbnail;
    }
    return layer.thumbnail;
  };

  const thumbnail = getThumbnail();

  // Обработчик для предотвращения стандартного поведения
  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return thumbnail ? (
    <div
      className="layer-thumbnail"
      style={{
        ...style,
        backgroundImage: `url(${thumbnail.src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: `${thumbnail.width}px`, // Устанавливаем ширину миниатюры
        height: `${thumbnail.height}px`, // Устанавливаем высоту миниатюры
      }}
      onContextMenu={preventDefault}
      {...menu.methods.createBetterClick({ onClick })}
    />
  ) : null;
}

export default LayerThumbnail;