// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spectator-controls {
  position: absolute;
  top: 81%; 
  width: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 1021;
  left: 10%; /* Центрирование блока */
  margin: 5px;
}

.spectator-text {
  font-size: 1.2em; /* Увеличение размера текста */
  color: var(--tg-theme-text-color); /* Темный цвет текста для лучшей читаемости */
  margin-bottom: 10px; /* Отступ снизу для разделения текста и кнопки */
  text-align: center;
}

.spectator-button {
  background-color: var(--tg-theme-button-color); 
  color: var(--tg-theme-button-text-color);
  font-size: 16px;
  
  width: 200px; 
  padding: 11px 18px; /* Паддинг для увеличения размера кнопки */
  border: none;
  border-radius: 12px;
  cursor: pointer; 
  transition: background-color 0.3s; /* Плавное изменение фона при наведении */
  margin: 5px;

}`, "",{"version":3,"sources":["webpack://./src/components/board/Spectator.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS,EAAE,wBAAwB;EACnC,WAAW;AACb;;AAEA;EACE,gBAAgB,EAAE,8BAA8B;EAChD,iCAAiC,EAAE,6CAA6C;EAChF,mBAAmB,EAAE,gDAAgD;EACrE,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;EAC9C,wCAAwC;EACxC,eAAe;;EAEf,YAAY;EACZ,kBAAkB,EAAE,0CAA0C;EAC9D,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,iCAAiC,EAAE,yCAAyC;EAC5E,WAAW;;AAEb","sourcesContent":[".spectator-controls {\r\n  position: absolute;\r\n  top: 81%; \r\n  width: 80%;\r\n  justify-content: center;\r\n  align-items: center;\r\n  display: flex;\r\n  flex-direction: column;\r\n  z-index: 1021;\r\n  left: 10%; /* Центрирование блока */\r\n  margin: 5px;\r\n}\r\n\r\n.spectator-text {\r\n  font-size: 1.2em; /* Увеличение размера текста */\r\n  color: var(--tg-theme-text-color); /* Темный цвет текста для лучшей читаемости */\r\n  margin-bottom: 10px; /* Отступ снизу для разделения текста и кнопки */\r\n  text-align: center;\r\n}\r\n\r\n.spectator-button {\r\n  background-color: var(--tg-theme-button-color); \r\n  color: var(--tg-theme-button-text-color);\r\n  font-size: 16px;\r\n  \r\n  width: 200px; \r\n  padding: 11px 18px; /* Паддинг для увеличения размера кнопки */\r\n  border: none;\r\n  border-radius: 12px;\r\n  cursor: pointer; \r\n  transition: background-color 0.3s; /* Плавное изменение фона при наведении */\r\n  margin: 5px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
