// useStrokeTransform.js
import useStore from '../../store';

// Вспомогательные функции для вычислений
const calculateDistance = (point1, point2 = { clientX: 0, clientY: 0 }) => {
  const xDiff = (point1.x || point1.clientX) - (point2.x || point2.clientX);
  const yDiff = (point1.y || point1.clientY) - (point2.y || point2.clientY);
  return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
};

const calculateCenter = (touch1, touch2 = { clientX: 0, clientY: 0 }) => {
  return {
    x: (touch1.clientX + touch2.clientX) / 2,
    y: (touch1.clientY + touch2.clientY) / 2,
  };
};

const calculateAngle = (touch1, touch2) => {
  const dx = touch2.pageX - touch1.pageX;
  const dy = touch2.pageY - touch1.pageY;
  return Math.atan2(dy, dx) * 180 / Math.PI;
};

export const useStrokeTransform = (ref) => {
  const { menu, drawing, info, telegram, convy, brush, history } = ref;
  const { user } = info;
  const { showTemporaryHint } = menu.methods;
  const { zoom } = convy;

  const {
    getLayerCoordinates,
    redraw,
    wipeTempCanvas,
    drawPreview,
    addReadyMadeStroke,
  } = drawing.methods;

  const {
    serverTimeFun,
  } = info.methods;

  const {
    transformStroke,
  } = brush;

  const {
    gradientColorFun,
    visibleColorFun,
    combinedSetsFun,
  } = brush.methods;

  // Преобразование точек из системы слоя в систему канваса
  const convertPointsFromLayerToCanvas = (points, layerId) => {
    const layer = convy.methods.getLayer(layerId);
    const layerInfo = convy.layerInfo[layerId] || {};
    const { offset = { x: 0, y: 0 }, scale = 1, rotation = 0 } = layerInfo;
    const layerCanvas = layer.canvas;
    const mainCanvas = convy.main.ref;

    // Разница размеров между слоем и основным канвасом
    const extraX = (layerCanvas.width - mainCanvas.width) / 2;
    const extraY = (layerCanvas.height - mainCanvas.height) / 2;

    // Центр слоя
    const centerX = layerCanvas.width / 2;
    const centerY = layerCanvas.height / 2;

    return points.map(point => {
      // 1. Вычитаем центр слоя, чтобы получить локальные координаты
      const localX = point.x - centerX;
      const localY = point.y - centerY;

      // 2. Применяем масштаб слоя
      const scaledX = localX * scale;
      const scaledY = localY * scale;

      // 3. Применяем поворот слоя
      const angleRad = (rotation * Math.PI) / 180;
      const rotatedX = scaledX * Math.cos(angleRad) - scaledY * Math.sin(angleRad);
      const rotatedY = scaledX * Math.sin(angleRad) + scaledY * Math.cos(angleRad);

      // 4. Добавляем смещение слоя и переводим в систему основного канваса
      const canvasX = rotatedX + centerX + offset.x - extraX;
      const canvasY = rotatedY + centerY + offset.y - extraY;

      return {
        x: canvasX,
        y: canvasY,
        pressure: point.pressure,
      };
    });
  };

  const transferStrokePoints = ({
    points,
    scaleFactor,
    angleDelta,
    finalOffsetDelta,
    centerPoint,
  }) => {
    return points.map(point => {
      const relativePoint = {
        x: point.x - centerPoint.x,
        y: point.y - centerPoint.y,
      };

      // Масштабирование
      const scaledX = relativePoint.x * scaleFactor;
      const scaledY = relativePoint.y * scaleFactor;

      // Вращение
      const radDelta = (angleDelta * Math.PI) / 180;
      const rotatedX = scaledX * Math.cos(radDelta) - scaledY * Math.sin(radDelta);
      const rotatedY = scaledX * Math.sin(radDelta) + scaledY * Math.cos(radDelta);

      // Смещение
      return {
        x: centerPoint.x + rotatedX + finalOffsetDelta.x,
        y: centerPoint.y + rotatedY + finalOffsetDelta.y,
        pressure: point.pressure,
      };
    });
  };

  const startStrokeTransform = (event) => {
    if (event.touches.length !== 2) return;
    if (Object.keys(zoom.initialized || {}).length > 0) return;
    if (!useStore.getState().canDraw) return;

    const { userStrokes } = history;
    const myStrokes = (userStrokes[telegram.activeUserId] || [])
      .filter(stroke => stroke.type === 'stroke' && !stroke.cancelled && !stroke.hidden);

    if (!myStrokes.length) return;

    zoom.strokeMode = true;
    
    const lastStroke = myStrokes[myStrokes.length - 1];

    transformStroke.originalStroke = {
      ...lastStroke,
      previewPoints: convertPointsFromLayerToCanvas(lastStroke.points, lastStroke.layerId),
    };


    transformStroke.go = {
      initialDistance: zoom.initialDistance,
      initialAngle: zoom.initialTouchAngle,
      initialCenterScreen: zoom.initialZoomCenter,
      initialPoints: [...lastStroke.points],
      time: serverTimeFun(),
    };
    transformStroke.initialized = {};

    lastStroke.cancelled = true;
    lastStroke.time++;

    const { layerPoint } = getLayerCoordinates(transformStroke.go.initialCenterScreen, lastStroke.layerId);
    transformStroke.go.initialCenterLayer = layerPoint;

    const layer = convy.methods.getLayer(lastStroke.layerId);
    layer.ready = false;

    wipeTempCanvas();
    redraw();
    window.croco?.haptic?.();
    showTemporaryHint(window.t('tooltip.stroke_transform_started'), { force: true, duration: 2000 });

    setTimeout(() => {
      updateStrokeTransform(event)
    }, 100);
  };

  const updateStrokeTransform = (event) => {
    if (!transformStroke.go || event.touches.length !== 2) return;

    const activeLayer = convy.methods.getActiveLayer();
    const { zoomFactor, rotation } = useStore.getState();
    const layer = convy.methods.getLayer(transformStroke.originalStroke.layerId);

    // Текущие параметры жеста
    const newDistance = calculateDistance(event.touches[0], event.touches[1]);
    const newCenterScreen = calculateCenter(event.touches[0], event.touches[1]);
    const newAngle = calculateAngle(event.touches[0], event.touches[1]);

    const applyThreshold = false;

    // Масштаб
    let scaleFactor = newDistance / transformStroke.go.initialDistance;
    if (applyThreshold) {
      scaleFactor = scaleThreshold({ scaleFactor, newDistance });
    }

    // Вращение
    let angleDelta = newAngle - transformStroke.go.initialAngle;
    if (applyThreshold) {
      angleDelta = rotationThreshold({ angleDelta, newAngle });
    }

    // Смещение
    const rawOffsetDelta = {
      x: newCenterScreen.x - transformStroke.go.initialCenterScreen.x,
      y: newCenterScreen.y - transformStroke.go.initialCenterScreen.y,
    };
    const canvasRotationRad = ((rotation + layer.info.rotation) * Math.PI) / 180;
    const adjustedOffsetDelta = {
      x: rawOffsetDelta.x * Math.cos(-canvasRotationRad) - rawOffsetDelta.y * Math.sin(-canvasRotationRad),
      y: rawOffsetDelta.x * Math.sin(-canvasRotationRad) + rawOffsetDelta.y * Math.cos(-canvasRotationRad),
    };
    const layerScale = activeLayer.info.scale || 1;
    let finalOffsetDelta = {
      x: adjustedOffsetDelta.x / (convy.scale * layerScale),
      y: adjustedOffsetDelta.y / (convy.scale * layerScale),
    };

    if (applyThreshold) {
      finalOffsetDelta = offsetThreshold({ finalOffsetDelta, newCenterScreen, activeLayer });
    }

    // Трансформируем точки в системе слоя
    transformStroke.go.transformedPoints = transferStrokePoints({
      points: transformStroke.go.initialPoints,
      scaleFactor,
      angleDelta,
      finalOffsetDelta,
      centerPoint: transformStroke.go.initialCenterLayer,
    });

    // Преобразуем трансформированные точки в систему канваса для превью
    const transformedPreviewPoints = convertPointsFromLayerToCanvas(
      transformStroke.go.transformedPoints,
      activeLayer.id
    );

    // Отрисовываем превью
    wipeTempCanvas();
    const previewStroke = {
      ...transformStroke.originalStroke,
      points: transformedPreviewPoints,
      lineWidth: transformStroke.originalStroke.lineWidth * layerScale,
    };
    drawPreview(previewStroke);
  };

  const scaleThreshold = ({ scaleFactor, newDistance }) => {
    if (!transformStroke.initialized.scale) {
      if (scaleFactor > 1.15 || scaleFactor < 0.85) {
        transformStroke.initialized.scale = true;
        transformStroke.go.initialDistance = newDistance;
        scaleFactor = 1;
      } else {
        scaleFactor = 1;
      }
    }
    return scaleFactor;
  };

  const rotationThreshold = ({ angleDelta, newAngle }) => {
    if (!transformStroke.initialized.rotation) {
      if (Math.abs(angleDelta) > 10) {
        transformStroke.initialized.rotation = true;
        transformStroke.go.initialAngle = newAngle;
        angleDelta = 0;
      } else {
        angleDelta = 0;
      }
    }
    return angleDelta;
  };

  const offsetThreshold = ({ finalOffsetDelta, newCenterScreen, activeLayer }) => {
    if (!transformStroke.initialized.offset) {
      const offsetDistance = calculateDistance(newCenterScreen, transformStroke.go.initialCenterScreen);
      if (offsetDistance > 15) {
        transformStroke.initialized.offset = true;
        transformStroke.go.initialCenterScreen = newCenterScreen;
        transformStroke.go.initialCenterLayer = getLayerCoordinates(newCenterScreen, activeLayer.id).layerPoint;
        finalOffsetDelta = { x: 0, y: 0 };
      } else {
        finalOffsetDelta = { x: 0, y: 0 };
      }
    }
    return finalOffsetDelta;
  };

  const finishStrokeTransform = () => {
    if (!transformStroke.go) return;

    const finalPoints = transformStroke.go.transformedPoints || transformStroke.go.initialPoints;
    const newStroke = {
      ...transformStroke.originalStroke,
      points: finalPoints,
      time: serverTimeFun(),
      endTime: serverTimeFun(),
    };

    delete transformStroke.pre;
    delete transformStroke.go;
    delete transformStroke.originalStroke;

    addReadyMadeStroke(newStroke);
  };

  return {
    startStrokeTransform,
    updateStrokeTransform,
    finishStrokeTransform,
  };
};