// effect.js
import { applyPixiFilter } from './pixi/pixiFilters';
import { applyGlfxFilter } from './glfx/glfxFilters'; // Новый импорт
import { brushDefaults } from './helpers/brushLoader';

export async function drawEffectStroke({
  lineWidth,
  points,
  color,
  opacity,
  effect,
  time,
  sets = {},
}, context) {

  if (points.length === 0) return;

  const defaultBrushSettings = brushDefaults[effect] || {};
  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  let filter = 'adjustment';
  let filterModule = 'pixi'; // По умолчанию используем Pixi.js
  let options;
  let blurCoef = 0.2;

  // Select options based on the effect
  switch (effect) {

    case 'pixelate':
      filter = 'pixelate';
      blurCoef = 0;
      options = {
        // size: Math.max(5, Math.min(25, lineWidth / 5)),
        size: brushSettings.uneven ?  [brushSettings.sizeX, brushSettings.sizeY] : brushSettings.size,
      };
      break;


    // Существующие эффекты, теперь частично заменены на glfx.js
    case 'blur': // Размытие (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxTriangleBlur'; 
      blurCoef = 0;
      options = {
        radius: 10, // Радиус размытия
      };
      break;

    case 'noise': // Шум (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxNoise';
      options = {
        noise: 0.05, // Уровень шума (аналогично вашему значению в CRTFilter)
      };
      break;

    case 'lighten': // Осветление (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxBrightnessContrast';
      options = {
        brightness: 0.06, // Аналогично вашему значению 1.06 (переводим в диапазон glfx.js)
        contrast: 0, // Без изменения контраста
      };
      break;
    case 'darken': // Затемнение (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxBrightnessContrast';
      options = {
        brightness: -0.05, // Аналогично вашему значению 0.95 (переводим в диапазон glfx.js)
        contrast: 0, // Без изменения контраста
      };
      break;
    case 'saturate': // Насыщение (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxHueSaturation';
      options = {
        hue: 0, // Без изменения оттенка
        saturation: 0.1, // Аналогично вашему значению 1.1 (переводим в диапазон glfx.js)
      };
      break;
    case 'desaturate': // Обесцвечивание (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxHueSaturation';
      options = {
        hue: 0, // Без изменения оттенка
        saturation: -0.1, // Аналогично вашему значению 0.9 (переводим в диапазон glfx.js)
      };
      break;
    case 'contrast': // Контраст (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxBrightnessContrast';
      options = {
        brightness: 0, // Без изменения яркости
        contrast: 0.15, // Аналогично вашему значению 1.15 (переводим в диапазон glfx.js)
      };
      break;
    case 'decontrast': // Деконтраст (замена Pixi.js на glfx.js)
      filterModule = 'glfx';
      filter = 'glfxBrightnessContrast';
      options = {
        brightness: 0, // Без изменения яркости
        contrast: -0.15, // Аналогично вашему значению 0.85 (переводим в диапазон glfx.js)
      };
      break;
    case 'zoomin': // Увеличение (нет точного аналога в glfx.js, оставляем Pixi.js)
      filter = 'bulgePinch';
      blurCoef = 0;
      options = {
        radius: lineWidth / 2,
        strength: 0.1,
        center: {
          x: points[0].x / context.canvas.width,
          y: points[0].y / context.canvas.height
        }
      };
      break;
    case 'zoomout': // Уменьшение (нет точного аналога в glfx.js, оставляем Pixi.js)
      filter = 'bulgePinch';
      blurCoef = 0;
      options = {
        radius: lineWidth / 2,
        strength: -0.1,
        center: {
          x: points[0].x / context.canvas.width,
          y: points[0].y / context.canvas.height
        }
      };
      break;
    case 'outline': // Контур (нет аналога в glfx.js, оставляем Pixi.js)
      filter = 'outline';
      blurCoef = 0;
      options = {
        thickness: 4,
        color: 0x000000,
        quality: 0.1,
        alpha: 1,
        knockout: false
      };
      break;


    // Эффекты на glfx.js
    case 'sharpen': // Резкость
      filterModule = 'glfx';
      filter = 'glfxUnsharpMask';
      blurCoef = 0;
      options = {
        radius: 20,
        strength: 1.5,
      };
      break;
    case 'vibrance': // Усиление яркости цветов
      filterModule = 'glfx';
      filter = 'glfxVibrance';
      options = {
        amount: 0.5,
      };
      break;
    case 'sepia': // Сепия
      filterModule = 'glfx';
      filter = 'glfxSepia';
      options = {
        amount: 0.8,
      };
      break;
    case 'vignette': // Виньетка
      filterModule = 'glfx';
      filter = 'glfxVignette';
      blurCoef = 0;
      options = {
        size: 0.5,
        amount: 0.5,
      };
      break;
    case 'lensBlur': // Размытие линзы
      filterModule = 'glfx';
      filter = 'glfxLensBlur';
      blurCoef = 0;
      options = {
        radius: 10,
        brightness: 0,
        angle: 0,
      };
      break;
    case 'tiltShift': // Эффект Tilt-Shift
      filterModule = 'glfx';
      filter = 'glfxTiltShift';
      blurCoef = 0;
      options = {
        startX: 0,
        startY: 0,
        endX: context.canvas.width,
        endY: context.canvas.height,
        blurRadius: 15,
        gradientRadius: 200,
      };
      break;
    case 'triangleBlur': // Треугольное размытие
      filterModule = 'glfx';
      filter = 'glfxTriangleBlur';
      blurCoef = 0;
      options = {
        radius: 20,
      };
      break;
    case 'zoomBlur': // Зум-размытие
      filterModule = 'glfx';
      filter = 'glfxZoomBlur';
      blurCoef = 0;
      options = {
        centerX: points[0].x,
        centerY: points[0].y,
        strength: 0.2,
        innerRadius: 50,
        outerRadius: 200,
      };
      break;
    case 'colorHalftone': // Цветной полутон
      filterModule = 'glfx';
      filter = 'glfxColorHalftone';
      blurCoef = 0;
      options = {
        centerX: points[0].x,
        centerY: points[0].y,
        angle: 0,
        size: 10,
      };
      break;
    case 'dotScreen': // Эффект точечного экрана
      filterModule = 'glfx';
      filter = 'glfxDotScreen';
      blurCoef = 0;
      options = {
        centerX: points[0].x,
        centerY: points[0].y,
        angle: 0,
        size: 10,
      };
      break;
    case 'edgeWork': // Выделение краёв
      filterModule = 'glfx';
      filter = 'glfxEdgeWork';
      blurCoef = 0;
      options = {
        radius: 3,
      };
      break;
    case 'hexagonalPixelate': // Шестиугольная пикселизация
      filterModule = 'glfx';
      filter = 'glfxHexagonalPixelate';
      blurCoef = 0;
      options = {
        centerX: context.canvas.width / 2, // Центр пикселизации (можно настроить)
        centerY: context.canvas.height / 2,
        scale: brushSettings.uneven ? Math.max(brushSettings.sizeX, brushSettings.sizeY) : (brushSettings.size || 20), // Аналогично вашему size
      };
      break;
    case 'ink': // Эффект чернил
      filterModule = 'glfx';
      filter = 'glfxInk';
      options = {
        strength: 0.5,
      };
      break;
    case 'glfxBulgePinch': // Выпуклость/вогнутость (альтернатива Pixi bulgePinch)
      filterModule = 'glfx';
      filter = 'glfxBulgePinch';
      blurCoef = 0;
      options = {
        centerX: points[0].x,
        centerY: points[0].y,
        radius: lineWidth / 2,
        strength: 0.5,
      };
      break;
    case 'swirl': // Завихрение
      filterModule = 'glfx';
      filter = 'glfxSwirl';
      blurCoef = 0;
      options = {
        centerX: points[0].x,
        centerY: points[0].y,
        radius: 100,
        angle: 0,
      };
      break;
    case 'matrixWarp': // Матричное искажение
      filterModule = 'glfx';
      filter = 'glfxMatrixWarp';
      blurCoef = 0;
      options = {
        matrix: [
          1, 0, 0, 0,
          0, 1, 0, 0,
          0, 0, 1, 0,
          0, 0, 0, 1
        ],
        inverse: false,
        bilinear: true,
      };
      break;
    case 'perspective': // Перспективное искажение
      filterModule = 'glfx';
      filter = 'glfxPerspective';
      blurCoef = 0;
      options = {
        from: [
          0, 0,
          context.canvas.width, 0,
          context.canvas.width, context.canvas.height,
          0, context.canvas.height
        ],
        to: [
          0, 0,
          context.canvas.width, 0,
          context.canvas.width, context.canvas.height,
          0, context.canvas.height
        ],
        bilinear: true,
      };
      break;
    case 'posterize': // Постеризация
      filterModule = 'glfx';
      filter = 'glfxPosterize';
      options = {
        levels: 6,
      };
      break;
    case 'solarize': // Соляризация
      filterModule = 'glfx';
      filter = 'glfxSolarize';
      options = {
        amount: 1,
      };
      break;

    default:
      console.error('Unknown effect:', effect);
      return;
  }

  const maskCanvas = document.createElement('canvas');
  maskCanvas.width = context.canvas.width;
  maskCanvas.height = context.canvas.height;
  const maskCtx = maskCanvas.getContext('2d');

  // Draw the stroke path
  const currentLineWidth = lineWidth;
  drawStroke(maskCtx, points, currentLineWidth, blurCoef);

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Copy the original canvas content to the buffer
  bufferCtx.drawImage(context.canvas, 0, 0);
  await applyEffectAlongPath({
    context: bufferCtx,
    maskCanvas,
    lineWidth,
    filter,
    filterModule, // Передаём модуль фильтра
    options,
    time,
  });

  // Draw the result on the main canvas
  context.drawImage(bufferCanvas, 0, 0);
}

function drawStroke(context, points, lineWidth, blurCoef) {
  context.lineCap = 'round';
  context.lineJoin = 'round';
  context.lineWidth = lineWidth;

  const blurSize = lineWidth * blurCoef;
  context.filter = `blur(${blurSize}px)`;

  if (points.length === 1) {
    let point = points[0];
    if (Array.isArray(point)) {} else { point = [point.x, point.y]; }
        
    // Для одиночной точки рисуем круг
    context.beginPath();
    context.arc(point.x, point.y, lineWidth / 2, 0, Math.PI * 2);
    context.fill();
  } else {
    context.beginPath();
    context.moveTo(points[0].x, points[0].y);
  
    for (let i = 1; i < points.length; i++) {
      const nextPoint = points[i - 1];
      const currentPoint = points[i];
      const midPoint = {
        x: (nextPoint.x + currentPoint.x) / 2,
        y: (nextPoint.y + currentPoint.y) / 2
      };
      context.quadraticCurveTo(nextPoint.x, nextPoint.y, midPoint.x, midPoint.y);
    }
    const lastPoint = points[points.length - 1];
    context.lineTo(lastPoint.x, lastPoint.y);
    context.stroke();
  }
}

async function applyEffectAlongPath({
  context,
  maskCanvas,
  lineWidth,
  filter,
  filterModule, // Новый параметр
  options,
  time,
}) {
  const effectedCanvas = document.createElement('canvas');
  effectedCanvas.width = context.canvas.width;
  effectedCanvas.height = context.canvas.height;
  const effectedContext = effectedCanvas.getContext('2d');

  effectedContext.drawImage(context.canvas, 0, 0);

  // Выбираем, какой модуль фильтров использовать
  let filterApplied = false;
  if (filterModule === 'pixi') {
    filterApplied = await applyPixiFilter(effectedContext.canvas, filter, options);
  } else if (filterModule === 'glfx') {
    filterApplied = await applyGlfxFilter(effectedContext.canvas, filter, options);
  }

  if (!filterApplied) {
    console.error('Failed to apply filter:', filter);
    return;
  }

  // Apply the blur using the mask
  context.save();
  context.globalCompositeOperation = 'source-over';
  context.drawImage(effectedCanvas, 0, 0);
  context.globalCompositeOperation = 'destination-in';
  context.drawImage(maskCanvas, 0, 0);
  context.restore();
}