import chroma from 'chroma-js';
import { drawPlainStroke } from '../helpers/plain';
import { interpolatePointsUniform  } from './points';


export function createOpacityStroke(stroke, context, brushSettings) {
  const {
    lineWidth,
    increaseLineWidth = 0,
    color = 'black',
  } = stroke;

  let { points } = stroke;

  const {
    pressureOn,
    pressureOpacity,
    pressureAvailable,
    moreCache, 
  } = brushSettings;

  const pressureCoef = 1;

  const turnedOn = pressureOn || pressureOpacity;
  
  if (!pressureAvailable || !turnedOn || !points[0].pressure) {
    return drawPlainStroke(stroke, context, brushSettings);
  }
  
  if (points.length === 1) {
    return drawPoint(stroke, context, brushSettings);
  }
  const desiredSpacing = moreCache ? 25 : 5;
  
  points = interpolatePointsUniform(points, desiredSpacing);

  // Вычисляем bounding box штриха
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  points.forEach(point => {
    minX = Math.min(minX, point.x);
    minY = Math.min(minY, point.y);
    maxX = Math.max(maxX, point.x);
    maxY = Math.max(maxY, point.y);
  });

  // Добавляем отступы для учета ширины линии
  const padding = lineWidth + increaseLineWidth;
  minX = Math.floor(minX - padding);
  minY = Math.floor(minY - padding);
  maxX = Math.ceil(maxX + padding);
  maxY = Math.ceil(maxY + padding);

  const width = maxX - minX;
  const height = maxY - minY;

  // Создаем канвас для черно-белого штриха
  const grayscaleCanvas = document.createElement('canvas');
  grayscaleCanvas.width = width;
  grayscaleCanvas.height = height;
  const grayscaleCtx = grayscaleCanvas.getContext('2d');

  // Смещаем точки относительно нового канваса
  const shiftedPoints = points.map(point => ({
    x: point.x - minX,
    y: point.y - minY,
    pressure: point.pressure,
  }));

  // Рисуем черно-белый штрих
  grayscaleCtx.lineCap = 'round';
  grayscaleCtx.lineJoin = 'round';
  grayscaleCtx.globalCompositeOperation = 'lighten';

  const totalPoints = shiftedPoints.length;

  for (let i = 0; i < totalPoints - 1; i++) {
    const startPoint = shiftedPoints[i];
    const endPoint = shiftedPoints[i + 1];

    const segmentWidth = pressureOn ? lineWidth * (startPoint.pressure + endPoint.pressure) / 2 * pressureCoef : lineWidth;

    // Рассчитываем среднее давление для текущего сегмента
    const avgPressure = ((startPoint.pressure + endPoint.pressure) / 2) * pressureCoef;

    // Ограничиваем давление в диапазоне от 0 до 1
    const clampedPressure = Math.max(0, Math.min(1, avgPressure));

    // Конвертируем давление в яркость (от черного к белому)
    const brightness = 255 * clampedPressure;
    const grayValue = Math.round(brightness);
    const grayColor = `rgb(${grayValue}, ${grayValue}, ${grayValue})`;

    grayscaleCtx.beginPath();
    grayscaleCtx.moveTo(startPoint.x, startPoint.y);
    grayscaleCtx.lineTo(endPoint.x, endPoint.y);
    grayscaleCtx.lineWidth = segmentWidth + increaseLineWidth;
    grayscaleCtx.strokeStyle = grayColor;
    grayscaleCtx.stroke();
  }

  // Получаем ImageData черно-белого штриха
  const grayscaleImageData = grayscaleCtx.getImageData(0, 0, width, height);
  const grayscaleData = grayscaleImageData.data; // Uint8ClampedArray

  // Создаем ImageData для цветного штриха с применением альфа-канала
  const colorImageData = context.createImageData(width, height);
  const colorData = colorImageData.data; // Uint8ClampedArray

  // Цвет штриха с альфа = 1
  const chromaColor = chroma(color).rgba();
  const [r, g, b, a] = chromaColor.map(v => Math.round(v));

  // Преобразуем яркость и альфа-канал из grayscaleData в альфа-канал colorData
  for (let i = 0; i < grayscaleData.length; i += 4) {
    const gray = grayscaleData[i]; // Так как R=G=B, берем любой канал
    const alpha = grayscaleData[i + 3]; // Альфа-канал пикселя из grayscaleData

    // Нормализуем значения к диапазону от 0 до 1
    const normalizedGray = gray / 255;
    const normalizedAlpha = alpha / 255;

    // Итоговый альфа-канал - произведение яркости и альфа-канала
    const finalAlpha = normalizedGray * normalizedAlpha;

    if (finalAlpha > 0) {
      colorData[i] = r;                                     // R
      colorData[i + 1] = g;                                 // G
      colorData[i + 2] = b;                                 // B
      colorData[i + 3] = Math.round(finalAlpha * 255);      // A
    }
    // Если finalAlpha равна 0, пиксель остается полностью прозрачным
  }

  // Рисуем цветное изображение на основном контексте
  context.putImageData(colorImageData, minX, minY);
}


export function drawPoint(stroke, bufferCtx, brushSettings) {
  const {
    color,
    lineWidth,
    points,
  } = stroke;

  const pressureCoef = 1;

  const point = points[0];

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  const segmentAlpha = originalAlpha * point.pressure * pressureCoef;
  const segmentColor = chromaColor.alpha(segmentAlpha).hex();

  bufferCtx.beginPath();
  bufferCtx.arc(point.x, point.y, (lineWidth / 2), 0, 2 * Math.PI, false);
  bufferCtx.fillStyle = segmentColor;
  bufferCtx.fill();
}

export function interpolatePoints(points, interpolationCoef = 1) {
  if (points.length <= 2) return points;

  function distance(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  let interpolatedPoints = [];

  // Начинаем с первой точки
  interpolatedPoints.push(points[0]);

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1];
    const currentPoint = points[i];

    // Вычисляем расстояние между предыдущей и текущей точкой
    const dist = distance(prevPoint, currentPoint);

    // Определяем количество интерполяций на основе расстояния
    const numInterpolations = Math.min(20 * interpolationCoef, Math.round(interpolationCoef * dist));

    // Вычисляем среднюю точку между предыдущей и текущей точкой
    const midPoint = {
      x: (prevPoint.x + currentPoint.x) / 2,
      y: (prevPoint.y + currentPoint.y) / 2,
      pressure: (prevPoint.pressure + currentPoint.pressure) / 2,
    };

    const controlPoint = prevPoint;
    const startPoint = interpolatedPoints[interpolatedPoints.length - 1];
    const endPoint = midPoint;

    // Интерполируем точки по квадратичной кривой Безье
    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);

      const x =
        (1 - t) * (1 - t) * startPoint.x +
        2 * (1 - t) * t * controlPoint.x +
        t * t * endPoint.x;
      const y =
        (1 - t) * (1 - t) * startPoint.y +
        2 * (1 - t) * t * controlPoint.y +
        t * t * endPoint.y;
      const pressure =
        (1 - t) * (1 - t) * startPoint.pressure +
        2 * (1 - t) * t * controlPoint.pressure +
        t * t * endPoint.pressure;

      interpolatedPoints.push({ x, y, pressure });
    }

    // Добавляем конечную точку сегмента
    interpolatedPoints.push(endPoint);
  }

  return interpolatedPoints;
}

