// src/components/board/Board.jsx

import './Board'; // Подключаем стили
import './Spectator.css'; // Подключаем стили

import useStore from '../../store';
import useConvy from '../../hooks/useConvy';
import ZoomFactor from './indicators/ZoomFactor';
import ConvyBrushIndicator from './indicators/ConvyBrushIndicator'; 
import ChatOverlay from './indicators/ChatOverlay'; 
import MetricsDebug from './indicators/DebugMenu';
import interfaceOffset from './tools/interfaceOffset';

const Board = () => {

  useStore((state) => state.render);

  const panOffset = useStore((state) => state.panOffset);
  const zoomFactor = useStore((state) => state.zoomFactor);
  const rotation = useStore((state) => state.rotation);

  const store = useStore.getState();

  const { telegram, menu, convy, info, drawing, } = store.ref;

  const {
    topMenuTitle,
  } = info.methods || {};

  const {
    openMenu,
  } = menu.methods || {};

  const {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleContextMenu,
  } = drawing.methods || {};

  const {
    game
  } = info;

  const main = convy.main;
  const temp = convy.temp;

  useConvy();

  const { controlButtonsStyle, drawingAreaStyle } = interfaceOffset(store)

  const guessTheWordText = 
  game?.hint?.active ? 
  game?.hint.hints.map(hint=>hint.opened ? ` ${hint.letter} ` : '⬜️').join('') : 
  window.t('spectator.guess_the_word')

  return (
    <div 
    className="board-container"
    style={drawingAreaStyle}
    >
      {(info?.user?.showDebug) && (
        <MetricsDebug store={ store } />
      )}

      {zoomFactor <= 1 && (
        <div className="word" onClick={()=>{openMenu('drawing')}}>
          {guessTheWordText}
        </div>
      )}

      <ZoomFactor
        store={store}
      />
      <ChatOverlay
        store={store}
      /> 
      <ConvyBrushIndicator 
        store={store}
      />

      <div className='backing-canvas'
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onContextMenu={handleContextMenu}
        style = {{
          width: window.innerWidth,
          height: `${(convy.main.ref?.bottom || 420) + 200 }px`,
        }}
      />
      
      <div className="canvas-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onContextMenu={handleContextMenu}
        style={{
          left: '50%',       // Горизонтальное центрирование
          transform: `translateX(-50%) translate(${panOffset.x}px, ${panOffset.y + convy.styleDimensions.height / 2}px) scale(${zoomFactor}) rotate(${rotation}deg)`,
        }}
      >

        <canvas
          key={main.id}
          width={main.width}
          height={main.height}
          ref={(el) => {
            if (el && !main.ref) {
              main.ref = el;
              main.ctx = el.getContext('2d');
              convy.scale = (el.clientWidth / el.width) / zoomFactor;
              setTimeout(() => {
                store.setupCanvasSizes();
                drawing.methods.redraw().then(()=>{
                  useStore.setState({ boardReady: true });
                  // setTimeout(() => { drawing.methods.redraw(); }, 1000);
                });
              }, 10);
            }
          }}
          className="main-canvas"
          style={{
            transform: `translate(-50%, -50%)`,
            width: `${convy.styleDimensions.width}px`,
            height: `${convy.styleDimensions.height}px`,
            boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.2)`,
          }}
        />
        <canvas
          key={temp.id}
          width={temp.width}
          height={temp.height}
          ref={(el) => {
            if (el && !temp.ref) {
              temp.ref = el;
              temp.ctx = el.getContext('2d');
            }
          }}
          className="temp-canvas"
          style={{
            transform: `translate(-50%, -50%)`,
            width: `${convy.styleDimensions.width}px`,
            height: `${convy.styleDimensions.height}px`,
          }}
        />
      </div>

      <div className='spectator-controls' style={controlButtonsStyle}>
        <p className="spectator-text">
          {window.t('spectator.who_draws', { authorName: info.game?.author?.first_name })}
        </p>
        <button 
          className="spectator-button" 
          onClick={window.Telegram?.WebApp?.close}
        >
          Закрыть
        </button>
      </div>

    </div>
  );
};

export default Board;
