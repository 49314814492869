// src/store/brushSlice.js

import chroma from 'chroma-js';
import { getTexture, sources, brushDefaults, preloadTextures } from '../hooks/convy/brushes/helpers/brushLoader';
import { filterClosePoints, generateWavePoints } from '../hooks/convy/brushes/helpers/points';
import i18n from '../locales/i18n'

const createBrushSlice = (set, get, ref) => {

  const { t } = i18n;

  Object.assign(ref.components, {
    ColorHint: ({ text, hexColor }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div
          style={{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            backgroundColor: hexColor,
            margin: '7px',
          }}
        />
        <span style = {{paddingRight: '10px'}}>{text}</span>
      </div>
    ),
  })


  // Инициализируем раздел ref для кистей, если он ещё не создан
  ref.brush = {

    sources,
    brushDefaults,

    steps: {},
    
    lastPenActivity: 0,
    lastActionTime: Date.now(),

    previousTool: "brush",
    brushHistory: [],
    effectHistory: [],

    prevColor: '#000000FF',

    colorToClipboard: null,

    brushColor: '#000000FF',
    eraserColor: '#FFFFFFFF',

    quickColorChangeTime: 0,
    lastColors: [],
    previousColor: '#000000FF',

    brushSize: 7,
    eraserSize: 25,

    pipette: {
      pickedColor: '#000',
    },
    fast: {},
    hotMouse: {},

    shape: {},
    smartShape: {},
    transformStroke: {},
    
  };


  ref.brush.methods = {
    // Функции-хелперы
    getTexture,
    preloadTextures,
    filterClosePoints,
    generateWavePoints,

    gradientColorFun: () => {
      const activeTool = get().activeTool;
      const gradientColor = get().gradientColor;
      const userGameSets = ref.work.methods.getUserGameSets?.() || {};
      let actualGradientColor = userGameSets.gradientOn ? gradientColor : null;
      if (['effect', 'eraser'].includes(activeTool)) {
        actualGradientColor = null;
      }
      return actualGradientColor;
    },

    actualSoftnessFun: () => {
      const sets = ref.brush.methods.brushSetsFun();
      const softnessValue = sets.softness || get().softness || 0;
      return get().activeTool === 'effect' ? 0 : softnessValue;
    },

    customizeCurrentBrush: (actualBrushType) => {
      const activeTool = get().activeTool;
      actualBrushType =
        actualBrushType ||
        (activeTool === 'effect'
          ? get().effectType
          : activeTool === 'eraser'
          ? 'plain'
          : get().brushType);

      ref.menu.methods.openMenu?.('customizeBrush', { brush: actualBrushType, layer: true });
    },

    customBrushSetsFun: (brushKey)=>{

      const state = get();
      const oldBrushSets = state.ref.info?.user?.[brushKey];
      const brushSets = state.ref.info?.user?.brush?.[brushKey] || oldBrushSets || {}

      return brushSets;

    },

    brushSetsFun: (brushKey) => {

      const state = get();
      
      // Если не передали brushKey, выбираем его в зависимости от активного инструмента
      brushKey = brushKey ||
        (
          state.activeTool === 'effect' ? state.effectType :
          state.activeTool === 'eraser' ? state.eraserType :
          state.brushType
          );
        

      const brushPreview = brushKey === 'preview' ? ref.info.game.brushPreview : null;

      // Объединяем настройки по умолчанию и пользовательские
      const oldBrushSets = ref.info?.user?.[brushKey];
      const brushSets = brushPreview 
      || ref.info?.user?.brush?.[brushKey] 
      || oldBrushSets || {}

      const basicBrushLink = brushSets.clone || brushKey;
      const defaultSets = brushDefaults[basicBrushLink] || {};

      return { ...defaultSets, ...brushSets };
    },

    brushKeyFun: () => {

      const state = get();
      return state.activeTool === 'effect' ? state.effectType :
      state.activeTool === 'eraser' ? state.eraserType :
      state.brushType;

    },

    brushLinkFun: (brushKey) => {

      const state = get();
      
      brushKey = brushKey || 
      (
        state.activeTool === 'effect' ? 'plain' :
        state.activeTool === 'eraser' ? state.eraserType :
        state.brushType
      );

      const brushPreview = brushKey === 'preview' ? ref.info.game.brushPreview : null;

      const game = state.ref.info?.game;

      const sprintForbiddenBrush =
        game.mode === 'sprint' && !['plain', 'dashed', 'outlined'].includes(brushKey);

      if (sprintForbiddenBrush) {
        brushKey = 'plain';
      }

      const oldBrushSets = ref.info?.user?.[brushKey];
      const brushSets = brushPreview 
      || ref.info?.user?.brush?.[brushKey] 
      || oldBrushSets || {};

      const basicBrushLink = brushSets.clone || brushKey;
      return basicBrushLink;

    },

    combinedSetsFun: (brushKey) => {

      brushKey = brushKey ||
      (
        get().activeTool === 'effect' ? get().effectType :
        get().activeTool === 'eraser' ? get().eraserType :
        get().brushType
        );

      const basicBrushLink = ref.brush.methods.brushLinkFun(brushKey);
      const commonSets = ref.brush.methods.brushSetsFun('common');
      const sets = ref.brush.methods.brushSetsFun(brushKey);
      const composition =
        basicBrushLink === 'texture'
          ? sets.composition
          : (sets.composition ?? commonSets.composition);

      
      const combinedSets = {
        ...commonSets,
        ...sets,
        composition,
        moreCache: get().ref.info?.user?.moreCache,
      }

      const unnesessary = [
        'label', 'about', 'preview',
      ];
      for (let param of unnesessary) {
        delete combinedSets[param];
      }
      if (!combinedSets.polyGlyph) {
        delete combinedSets['glyphArr'];
      }
      
      return combinedSets;
    },

    ifIndicateBrushFun: () => {

      const now = Date.now();
      const quickColorChangeTime = ref.brush.quickColorChangeTime;

      const { showMenu, activeTool, effectType, showPalette } = get();

      const { keyboard } = ref.convy;
      const isKeyDown = keyboard.controlIsDown
      || keyboard.shiftIsDown 
      || keyboard.spaceIsDown

      const indicateEffectBrush = activeTool === 'effect' && !['filler'].includes(effectType);
      const indicateStandartBrush = ['brush', 'eraser'].includes(activeTool);

      const { fast } = ref.brush;
      const fastBrushActive = fast.isResizingBrush
      || fast.isChangingBrightness 
      || fast.isChangingOpacity 
      || fast.isChangingSoftness;

      const pointerType = ref.info.user.pointerType;
      const pcPenDevice = pointerType === 'pen' && !ref.convy.isTouchDevice;
      const tabPenDevice = pointerType === 'pen' && ref.convy.isTouchDevice;

      const appropriatePointer = 
      pointerType === 'mouse' 
      || (activeTool === 'eraser' && ref.drawing.isDrawing) 
      || (ref.info.user.solidBrushIndicator  
        &&(pcPenDevice || (tabPenDevice && ref.drawing.isDrawing)))

      const indicateBrush =
        (
          (
            (indicateStandartBrush || indicateEffectBrush)
            || (quickColorChangeTime > now - 100)
          ) 
          && !showPalette
          && !showMenu?.[0]
          && !isKeyDown
          && !fastBrushActive
          && appropriatePointer
        ) || ref.brush.hotMouse.active;
        
      return indicateBrush;
    },

    visibleColorFun: () => {
      const { activeTool, color } = get();
      
      // const chromaColor = chroma(color);
      // if (chromaColor.alpha() === 0) {
      //   return '#FFFFFF50';
      // }

      if (['eraser'].includes(activeTool)) {

        const chromaAlpha = chroma(color).alpha();
        const whiteOpacityColor = chroma('FFFFFF').alpha(chromaAlpha).hex();
        return whiteOpacityColor;
        
      } else if (['effect'].includes(activeTool)) {
        const effectType = get().effectType;
        if (['filler'].includes(effectType)) return color;
        if (['darken'].includes(effectType)) return '#00000030';
        return '#FFFFFF50';
      } else {
        return color;
      }
    },

    setTheColorFun: (colorInput, param) => {

      const { color, activeTool, gradientPalette, gradientColor } = get();

      let newColor =
        typeof colorInput === 'function' ? colorInput(color) : colorInput;
        
      // Если отсутствует премиум-фича «colors» – приводим цвет к оттенкам серого
      const { premiumFeatures = [] } = ref.premium;
      const userSets = ref.info?.user || {};
      if (!premiumFeatures.includes('colors') || userSets.premiumOff) {
        const chromaObj = chroma(newColor);
        const alpha = chromaObj.alpha();
        newColor = chromaObj.set('hsv.s', 0).alpha(alpha).hex();
      }

      const ifSetGradientColor = param !== 'color' && (gradientPalette || param === 'gradientColor')

      if (ifSetGradientColor) {
        set({ gradientColor: newColor });
      } else {
        set({ color: newColor });
      }

      if (ifSetGradientColor) {
        ref.brush.gradientColor = newColor;
      } else if (activeTool === 'eraser') {
        ref.brush.eraserColor = newColor;
      } else {
        ref.brush.brushColor = newColor;
      }

      const cssPaletteColor = ifSetGradientColor ? gradientColor : color;
      document.documentElement.style.setProperty('--brush-color', cssPaletteColor);
      document.documentElement.style.setProperty('--brush-solid-color', chroma(cssPaletteColor).alpha(1).hex('rgba'));

      ref.work.methods.debounceUserGameSets({ 
        eraserColor: ref.brush.eraserColor,
        brushColor: ref.brush.brushColor,
        gradientColor: gradientColor,
       })

    },

    visibleLineWidthFun: ()=>{
      return get().lineWidth * get().zoomFactor * (ref.convy.scale || 1)
    },

    setTheSizeFun: (sizeInput) => {
      const currentLineWidth = get().lineWidth;
      const newSize =
        typeof sizeInput === 'function' ? sizeInput(currentLineWidth) : sizeInput;
      if (get().activeTool === 'eraser') {
        ref.brush.eraserSize = newSize;
      } else {
        ref.brush.brushSize = newSize;
      }
      set({ lineWidth: newSize });
      ref.work.methods.debounceUserGameSets({ 
        eraserSize: ref.brush.eraserSize,
        brushSize: ref.brush.brushSize,
       })
    },

    handleLastColors: (newColor) => {
      newColor = newColor || get().color;
      set({ color: newColor });
      const filteredColors = (ref.brush.lastColors || []).filter(
        (c) => c !== newColor
      );
      filteredColors.push(newColor);
      ref.brush.lastColors = filteredColors;
    },

    adjustColor: (cssVariable, baseColor) => {
      const getCSSVariableValue = (variable) =>
        getComputedStyle(document.documentElement)
          .getPropertyValue(variable)
          .trim();
      const cssColor = getCSSVariableValue(cssVariable);
      const cssChroma = chroma(cssColor);
      const baseChroma = chroma(baseColor);
      const [, s, l] = cssChroma.hsl();
      const [h] = baseChroma.hsl();
      const resultColor = chroma.hsl(h, s, l);
      return resultColor.css();
    },

    blendColors: (cssVariable, baseColor, opacity = 0.2) => {
      const getCSSVariableValue = (variable) =>
        getComputedStyle(document.documentElement)
          .getPropertyValue(variable)
          .trim();
      const cssColor = getCSSVariableValue(cssVariable);
      const cssChroma = chroma(cssColor);
      const baseChroma = chroma(baseColor);
      const blendedColor = chroma.mix(cssChroma, baseChroma, opacity, 'rgb');
      return blendedColor.css();
    },

    copyCurrentColorToClipboard: async () => {
      try {
        const hexColor = chroma(get().color).hex();
        await navigator.clipboard.writeText(hexColor);
        ref.brush.colorToClipboard = hexColor;

        ref.menu.methods.showTemporaryHint?.(
          <ref.components.ColorHint text={hexColor + t('tooltip.color_copied')} hexColor={hexColor} />,
          {force: true}
        );

      } catch (e) {
        // обработка ошибок (при необходимости)
      }
    },

    clipboardColor: async (action) => {
      if (action === 'copy') {
        return await ref.brush.methods.copyCurrentColorToClipboard();
      }

      try {
        let clipboardText = await navigator.clipboard.readText();
        if (chroma.valid(clipboardText)) {
          const hexColor = chroma(clipboardText).hex();
          if (
            hexColor !== get().color &&
            hexColor !== ref.brush.colorToClipboard
          ) {
            ref.brush.methods.setTheColorFun?.(hexColor);
            ref.menu.methods.showTemporaryHint?.(
              <ref.components.ColorHint text={hexColor + t('tooltip.color_pasted')} hexColor={hexColor} />,
              {force: true}
            );
            return true;
          }
        }
      } catch (e) {
        // обработка ошибок
      }
      
      return await ref.brush.methods.copyCurrentColorToClipboard();
    },


    changeBrushType: async(brushType)=>{
      set({ brushType });
      ref.work.methods.saveUserSets({ brushType })

      const brushSets = ref.brush.methods.brushSetsFun(brushType);
      if (brushSets.texture) {
        preloadTextures(brushType, brushSets.texture)
      }

    },
    changeEraserType: async(eraserType)=>{
      set({ eraserType })
      ref.work.methods.saveUserSets({ eraserType })
      
      const brushSets = ref.brush.methods.brushSetsFun(eraserType);
      if (brushSets.texture) {
        preloadTextures(eraserType, brushSets.texture)
      }
    },
    changeEffectType: async(effectType)=>{
      set({ effectType })
      ref.work.methods.saveUserSets({ effectType })

      const brushSets = ref.brush.methods.brushSetsFun(effectType);
      if (brushSets.texture) {
        preloadTextures(effectType, brushSets.texture)
      }
    },

    changeTool: async(newTool, more = {})=> {

      const { event, fixed } = more;

      const { menu, brush, premium, info } = ref;
      const { 
        toggleMenu, 
        appleHaptic, 
        delayPremiumMenu,
      } = menu.methods;

      const { user } = info;
      const { premiumFeatures } = premium;

      const activeTool = get().activeTool;
  
      if (Date.now() - brush.lastActionTime < 300) {return;}
      brush.lastActionTime = Date.now();
  
      if (newTool === 'brush' && activeTool === 'brush') {
        set({activeTool: newTool});
        return toggleMenu('brush');
      }
  
      if (newTool === 'effect' && activeTool === 'effect') {
        set({activeTool: newTool});
        return toggleMenu('effect');
      }
      if (newTool === 'eraser' && activeTool === 'eraser') {
        set({activeTool: newTool});
        return toggleMenu('eraser');
      }
  
      appleHaptic?.('soft');
  
      if (['effect', 'pipette'].includes(newTool)) {
        if (!premiumFeatures.includes(newTool) || user.premiumOff) {
          delayPremiumMenu?.('menu');
          return;
        }
      }
      
      if (newTool === activeTool && !fixed) {
        newTool = 'brush';
      }
    
      if (newTool === 'eraser') {
        set({ color: ref.brush.eraserColor, lineWidth: ref.brush.eraserSize });
      } else if (['brush', 'effect'].includes(newTool)) {
        set({ color: ref.brush.brushColor, lineWidth: ref.brush.brushSize });
      }

      brush.previousTool = activeTool;
      if (newTool !== activeTool) {
        set({activeTool: newTool});
      }
    }
  }


  return {
    // РЕАКТИВНОЕ состояние кисти
    activeTool: "brush",           // текущий активный инструмент ("brush", "eraser", "effect" и т.д.)
    brushType: "plain",            // тип кисти (например, "plain")
    eraserType: "plain",            // тип кисти (например, "plain")
    effectType: "filler",          // тип эффекта (например, "filler")
    color: '#000000FF',            // текущий цвет
    gradientColor: '#FF0000FF',    // цвет для градиента
    backgroundColor: '#FFFFFFFF',    // цвет для градиента
    gradientPalette: null,         // выбранная палитра градиента (если есть)
    lineWidth: 7,                  // толщина линии
    softness: 0.0,                 // мягкость кисти
    showPalette: false,            // показывать ли палитру цветов
    magnifierOn: false,            // включён ли лупа/мастер-пипетка
    premiumGrayscale: false,       // включён ли премиум-серый режим
    isPipettePicking: false,       // активен ли выбор цвета пипеткой
    pickingPlace: { x: 0, y: 0 },   // координаты для пипетки
    visibleLineWidth: 7,           // отображаемая толщина линии (может отличаться от lineWidth)

  };
};

export default createBrushSlice;
