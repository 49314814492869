// sparkle.js
import { brushDefaults } from './helpers/brushLoader';

const defaultBrushSettings = brushDefaults.sparkle || {};

export async function drawSparkleStroke(stroke, context, params) {
  const {
    sets = {},
  } = stroke;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);
  
  // Создаем буферный канвас
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  // Добавляем искры
  addSparkles(stroke, bufferCtx, brushSettings);
  
  // Копируем результат на основной холст
  context.drawImage(bufferCanvas, 0, 0);
}

function addSparkles(stroke, context, brushSettings) {
  const {
    points, 
    color, 
    lineWidth,
  } = stroke;

  const {
    sparkleDensity = 0.5,          // Плотность искр (0-1)
    sparkleSize = 1,               // Размер отдельной искры
    sparkleDistributionPower = 0.8,// Степень распределения искр
    sparkleShape,       // Форма искры
    variableSize, 
    rotate,
  } = brushSettings;

  context.fillStyle = color;
  context.globalAlpha = 1;

  let density = Math.floor(lineWidth * sparkleDensity / sparkleSize );
  density = Math.max(density, 1)

  // Проходим по точкам штриха с шагом, зависящим от плотности
  const step = Math.max(1, Math.round(1 / Math.sqrt(sparkleDensity/2)));
  
  for (let i = 0; i < points.length; i += step) {
    const point = points[i];
    
    // Создаем искры вокруг каждой точки
    for (let j = 0; j < density; j++) {
      const seed1 = stroke.time + point[0] + point[1] + j + i;
      const seed2 = stroke.time + point[0] * point[1] + j - 1;

      const angle = seededRandom(seed1) * 2 * Math.PI;
      const randomValue = seededRandom(seed2);
      const radius = Math.pow(randomValue, sparkleDistributionPower) * lineWidth * 0.5;
      
      const sparkleX = point[0] + radius * Math.cos(angle);
      const sparkleY = point[1] + radius * Math.sin(angle);

      // Выбираем форму искры
      let shape = sparkleShape;
      if (sparkleShape === 'mix') {
        shape = getPseudoRandomShape(stroke, point, i, j);
      }

      let adjustedSize = sparkleSize;
      if (variableSize) {
        adjustedSize = getPseudoRandomSize(stroke, point, sparkleSize, i, j);
      }

      if (sparkleSize <= 2 && !variableSize) {
        shape = 'square';
      }

      // Вычисляем псевдослучайный угол вращения
      let rotationAngleRadians = 0;
      if (rotate > 0) {
        const seedRotation = stroke.time + point[0] * point[1] + i + j + 1000; // Используем другой seed
        const randomRotationValue = seededRandom(seedRotation);
        const rotationAngleDegrees = -rotate + randomRotationValue * (2 * rotate);
        rotationAngleRadians = rotationAngleDegrees * (Math.PI / 180);
      }

      context.strokeStyle = color; // Установите цвет обводки

      // Рисуем отдельную искру
      drawSparkleShape(context, sparkleX, sparkleY, adjustedSize, shape, rotationAngleRadians);
    }
  }
  
  context.globalAlpha = 1;
}

// Функция для получения псевдослучайной формы искры
function getPseudoRandomShape(stroke, point, i, j) {
  const shapes = [
    'round', 
    'star', 
    'diamond', 
    'cross',
    'sparkle',
    'horizontalLine',
    'verticalLine',
  ];
  const seed = stroke.time + point[0] + point[1] + i + j * j;
  const randomValue = seededRandom(seed);
  const index = Math.floor(randomValue * shapes.length);
  return shapes[index];
}

// Функция для получения псевдослучайного размера искры
function getPseudoRandomSize(stroke, point, baseSize, i, j) {
  const seed = stroke.time + point[0] * point[1] + i + j;
  const randomValue = seededRandom(seed);
  const minSize = baseSize / 3;
  const maxSize = baseSize * 3;
  return minSize + randomValue * (maxSize - minSize);
}

// Функция для рисования отдельной искры заданной формы
function drawSparkleShape(context, x, y, size, shape, rotation) {
  context.save();
  context.translate(x, y);
  if (rotation) {
    context.rotate(rotation);
  }

  switch(shape) {
    case 'square':
      context.fillRect(-size / 2, -size / 2, size, size);
      break;
    case 'round':
      context.beginPath();
      context.arc(0, 0, size / 2, 0, 2 * Math.PI);
      context.fill();
      break;
    case 'star':
      drawStar(context, 0, 0, size / 1.3, 5, 0.5);
      break;
    case 'sparkle':
      drawStar(context, 0, 0, size / 1.3, 4, 0.23);
      break;
    case 'triangle':
      context.beginPath();
      context.moveTo(0, -size / 2);
      context.lineTo(size / 2, size / 2);
      context.lineTo(-size / 2, size / 2);
      context.closePath();
      context.fill();
      break;
    case 'diamond':
      context.beginPath();
      context.moveTo(0, -size / 2);
      context.lineTo(size / 2, 0);
      context.lineTo(0, size / 2);
      context.lineTo(-size / 2, 0);
      context.closePath();
      context.fill();
      break;
    case 'cross':
      context.fillRect(-size / 2, -size / 6, size, size / 3);
      context.fillRect(-size / 6, -size / 2, size / 3, size);
      break;
    case 'circle':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.arc(0, 0, size / 2, 0, 2 * Math.PI);
      context.stroke();
      break;
    case 'perimeter':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.rect(-size / 2, -size / 2, size, size);
      context.stroke();
      break;
    case 'horizontalLine':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.moveTo(-size / 2, 0);
      context.lineTo(size / 2, 0);
      context.stroke();
      break;
    case 'verticalLine':
      context.lineWidth = Math.max(size / 20, 0.5);
      context.beginPath();
      context.moveTo(0, -size / 2);
      context.lineTo(0, size / 2);
      context.stroke();
      break;
    case 'heart':
      drawHeart(context, 0, 0, size);
      break;
    case 'flower':
      drawFlower(context, 0, 0, size);
      break;

    case 'moon':
      drawMoon(context, size);
      break;
    case 'cloud':
      drawCloud(context, size);
      break;
    case 'raindrop':
      drawRaindrop(context, size);
      break;
    case 'snowflake':
      drawSnowflake(context, size);
      break;
    case 'paw':
      drawPaw(context, size);
      break;
    case 'musicNote':
      drawMusicNote(context, size);
      break;
    default:
      context.fillRect(-size / 2, -size / 2, size, size);
  }

  context.restore();
}

// Функция для рисования звезды
function drawStar(ctx, cx, cy, outerRadius, points, inset) {
  ctx.save();
  ctx.beginPath();
  ctx.translate(cx, cy);
  ctx.moveTo(0, -outerRadius);
  for (let i = 0; i < points; i++) {
    ctx.rotate(Math.PI / points);
    ctx.lineTo(0, -outerRadius * inset);
    ctx.rotate(Math.PI / points);
    ctx.lineTo(0, -outerRadius);
  }
  ctx.closePath();
  ctx.fill();
  ctx.restore();
}

// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}


// Функция для рисования сердца
function drawHeart(context, x, y, size) {
  context.save();
  context.beginPath();
  const topCurveHeight = size * 0.3;
  context.moveTo(x, y + topCurveHeight);
  // Левая сторона сердца
  context.bezierCurveTo(
    x, y,
    x - size / 2, y,
    x - size / 2, y + topCurveHeight
  );
  // Левая нижняя часть
  context.bezierCurveTo(
    x - size / 2, y + (size + topCurveHeight) / 2,
    x, y + (size + topCurveHeight) / 1.5,
    x, y + size
  );
  // Правая нижняя часть
  context.bezierCurveTo(
    x, y + (size + topCurveHeight) / 1.5,
    x + size / 2, y + (size + topCurveHeight) / 2,
    x + size / 2, y + topCurveHeight
  );
  // Правая сторона сердца
  context.bezierCurveTo(
    x + size / 2, y,
    x, y,
    x, y + topCurveHeight
  );
  context.closePath();
  context.fill();
  context.restore();
}

// Функция для рисования цветка
function drawFlower(context, x, y, size) {
  context.save();
  context.translate(x, y);
  context.beginPath();
  const petalCount = 6;
  const petalRadius = size / 2;
  for (let i = 0; i < petalCount; i++) {
    const angle = (i * 2 * Math.PI) / petalCount;
    context.save();
    context.rotate(angle);
    context.moveTo(0, 0);
    context.quadraticCurveTo(petalRadius / 2, -petalRadius / 2, 0, -petalRadius);
    context.quadraticCurveTo(-petalRadius / 2, -petalRadius / 2, 0, 0);
    context.restore();
  }
  context.fill();
  context.restore();
}


function drawMoon(context, size) {
  context.beginPath();
  context.arc(0, 0, size / 2, 0.5 * Math.PI, 1.5 * Math.PI, false);
  context.arc(-size / 5, 0, size / 2, 1.5 * Math.PI, 0.5 * Math.PI, true);
  context.closePath();
  context.fill();
}


function drawCloud(context, size) {
  const radius = size / 4;
  context.beginPath();
  context.arc(-radius, 0, radius, Math.PI * 0.5, Math.PI * 1.5);
  context.arc(0, -radius, radius, Math.PI, 0);
  context.arc(radius, 0, radius, Math.PI * 1.5, Math.PI * 0.5);
  context.closePath();
  context.fill();
}


function drawRaindrop(context, size) {
  context.save();
  context.beginPath();
  
  const topY = -size * 0.9;
  context.moveTo(0, topY);
  
  // Правая сторона капли - важные изменения здесь
  context.bezierCurveTo(
    size/32, topY,         // Первая точка ещё ближе к центру
    size/2, -size/2.5,     // Вторая точка поднята выше
    size/2, size/4
  );
  
  context.arc(0, size/4, size/2, 0, Math.PI, false);
  
  context.bezierCurveTo(
    -size/2, -size/2.5,    
    -size/32, topY,        
    0, topY
  );
  
  context.closePath();
  context.fill();
  context.restore();
}

function drawSnowflake(context, size) {
  context.lineWidth = size / 15;
  const lines = 6;
  for (let i = 0; i < lines; i++) {
    const angle = (i * Math.PI) / (lines / 2);
    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo((size / 2) * Math.cos(angle), (size / 2) * Math.sin(angle));
    context.stroke();
  }
}



function drawPaw(context, size) {
  // Рисуем основную подушечку
  context.beginPath();
  context.arc(0, size / 6, size / 4, 0, 2 * Math.PI);
  context.fill();

  const toeOffsets = [
    [-size / 4, -size / 6],
    [0, -size / 4],
    [size / 4, -size / 6],

  ];

  toeOffsets.forEach(offset => {
    context.beginPath();
    context.arc(offset[0], offset[1], size / 8, 0, 2 * Math.PI);
    context.fill();
  });
}



function drawMusicNote(context, size) {
  context.lineWidth = size / 15;

  // Рисуем головку нотки (наклоненный овал)
  context.beginPath();
  context.ellipse(0, 0, size / 5, size / 4, -Math.PI / 4, 0, 2 * Math.PI);
  context.fill();

  // Рисуем штиль
  const stemHeight = size / 2;
  const stemOffsetX = size / 5 * Math.cos(-Math.PI / 4);
  const stemOffsetY = size / 5 * Math.sin(-Math.PI / 4);

  context.beginPath();
  context.moveTo(stemOffsetX, stemOffsetY);
  context.lineTo(stemOffsetX, stemOffsetY - stemHeight);
  context.stroke();

  // Рисуем флажок
  context.beginPath();
  context.moveTo(stemOffsetX, stemOffsetY - stemHeight);
  context.quadraticCurveTo(
    stemOffsetX + size / 4, // Контрольная точка по X
    stemOffsetY - stemHeight + size / 6, // Контрольная точка по Y
    stemOffsetX, // Конечная точка по X
    stemOffsetY - stemHeight + size / 3 // Конечная точка по Y
  );
  context.stroke();
}
