// SettingsOverlay.js

import React, { useState, useRef } from 'react';
import { HexAlphaColorPicker } from "react-colorful";
import { FaWrench, FaCog, FaTabletAlt, FaRegClock } from 'react-icons/fa';

import './SettingsOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import { SquareIcon, HorizontalIcon, VerticalIcon } from './OrientationIcons';


function SettingsOverlay({
  gameContext,
  brushContext,
}) {

  const { 
    gameId,
    telegramUserId,
    gameInfoRef, 
    userSetsRef, 

    openMenu, closeMenu,
    saveUserSets, 
    saveGameSets, 
    hostAction,
    delayPremiumMenu,
    showPopupMessage,
   } = gameContext;
  const { 
    color, 
   } = brushContext;

  const { t } = useTranslation();

  const [userColorView, setUserColorView] = useState(false);
  const [viewedColor, setViewedColor] = useState(color);
  const setParamRef = useRef(null);

  let gameSettings = [
    { key: 'highRes', label: t('settings.high_res') },
    // { key: 'rotate', label: t('settings.rotate') },
  ];
  if (['horizontal', 'vertical'].includes(gameInfoRef.current?.settings?.orientation)) {gameSettings = gameSettings.filter(set=>set.key !== 'highRes')}

  const userSettings = [
    { key: 'cachePng', label: t('settings.cache_png') },
    { key: 'proCanvas', label: t('settings.pro_canvas') },
    { key: 'moreCache', label: t('settings.more_cache') },
    // { key: 'openBrowser', label: t('settings.open_browser_on_start') },
  ];

  if (userSetsRef.current.moder || userSetsRef.current.premiumOff) {
    userSettings.push({ key: 'premiumOff', label: t('settings.premium_off') })
  }
  if (['1431457632', '141015727'].includes(telegramUserId)) {
    userSettings.push({ key: 'moder', label: 'Crocomoto' })
    userSettings.push({ key: 'iphone', label: t('settings.iphone_fix') })
  }

  const handleUserSetCheckbox = (key) => {
    saveUserSets({ [key]: !userSetsRef.current[key] })
  };

  const handleGameSetCheckbox = (key) => {

    const gameSettings = gameInfoRef.current?.settings;
    // saveUserSets({ [key]: !gameSettings[key] })
    saveGameSets({ [key]: !gameSettings[key] }, (result)=>{
      window.location.reload();
    });

  };

  const moderEdit = () => {
    saveUserSets({ 
      moderEditing: userSetsRef.current.moderEditing === gameId ? null : gameId }, (result)=>{
      window.location.reload();
    })
  };

  const startColorPick = (param) => {
    setParamRef.current = param;
    setViewedColor(userSetsRef.current[param] || color);
    setUserColorView(true);
  };
  
  const onColorPicking = (userColor) =>{
    setViewedColor(userColor);
  }

  const endColorPick = () => {
    const param = setParamRef.current;
    saveUserSets({ [param]: viewedColor });
    setUserColorView(false);
  };


  const handleOrientationChange = (newOrientation) => {
    saveUserSets({ orientation: newOrientation });

    const gameSets = { orientation: newOrientation };
    saveGameSets(gameSets, (result)=>{
      window.location.reload();
    });
  };


  const handleDeviceInfo = () =>{

    const userAgent = navigator.userAgent;
    const popupParams = {
      title: t('settings.your_device'),
      message: userAgent,
    };
    popupParams.callback = (buttonId) => {}
    showPopupMessage(popupParams)

  }



  return (
    <>
      <div className="overlay-background" onClick={() => closeMenu()}></div>
      <div className="settings-menu" style = {{opacity: userColorView ? 0 : 1}}>
        <span className="settings-title">{t('settings.title')}</span>
        {userSettings.map((setting) => (
          <div key={setting.key} className="settings-item">
            <label className="settings-label" style = {{
              opacity: userSetsRef.current[setting.key] ? 1 : 0.5
            }}>
              <input
                type="checkbox"
                checked={userSetsRef.current[setting.key]}
                onChange={() => handleUserSetCheckbox(setting.key)}
              />
              <span>{setting.label}</span>
            </label>
          </div>
        ))}

        <div className="settings-item" onClick={handleDeviceInfo}>
          <label className="settings-label">
          <FaTabletAlt className="settings-label-icon"/>
            <span>{t('settings.device')}</span>
          </label>
        </div>
        <div className="settings-item" onClick={()=>{openMenu('lag', {layer: true})}}>
          <label className="settings-label">
          <FaRegClock className="settings-label-icon"/>
            <span>{t('settings.lags')}</span>
          </label>
        </div>

        <div className="settings-item" onClick={()=>{startColorPick('startColor')}}>
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span  className="settings-color-indicator" style={{
                backgroundColor: userSetsRef.current.startColor || '#000000FF',
              }}
            ></span>
            <span>{t('settings.start_color')}</span>
          </label>
        </div>

        <div className="settings-item" onClick={()=>{startColorPick('startBgColor')}}>
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span  className="settings-color-indicator" style={{
                backgroundColor: userSetsRef.current.startBgColor || '#000000FF',
              }}
            ></span>
            <span>{t('settings.start_bg_color')}</span>
          </label>
        </div>

        <button className="wide-button" onClick={() => {
          closeMenu();
          delayPremiumMenu('menu');
        }}>
          {t('settings.premium')}
        </button>

        <button className="gray-button" onClick={()=>{openMenu('drawing', true)}}>
          <FaWrench style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('drawing.title')}
        </button>

        <button className="gray-button" onClick={() => closeMenu()}>
          {t('settings.back')}
        </button>
      </div>

      { userColorView && (
          <>
            <div className="overlay-background" style={{zIndex: 1400}} onClick={endColorPick}></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker 
                color={viewedColor} 
                onChange={onColorPicking}
              />
              <button className="wide-button" onClick={endColorPick} style={{marginTop: '10px'}}>
                {t('settings.set_color')}
              </button>
            </div>
          </>
      ) }

    </>
  );
}

export default SettingsOverlay;
