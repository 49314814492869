// ChatOverlay.js
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function ChatOverlay({ store }) {
    const { t } = useTranslation();

    const { info } = store.ref;

    const [recentMessages, setRecentMessages] = useState([]);
    const [isVisible, setIsVisible] = useState(true);  // Состояние для контроля видимости чата

    const truncateAuthor = (author = '') => {
      return author.length > 10 ? `${author.slice(0, 12)}...` : author;
    };

    const truncateMessageText = (message = 'empty') => {
      return message.length > 100 ? `${message.slice(0, 100)}...` : message;
    };

    const renderFormattedMessage = (text) => {
      console.log('Входящий текст:', text); // Посмотрим что приходит
      
      if (!text) return '';
      
      const parts = text.split(/(<\/?[bi]>)/g);
      console.log('После split:', parts); // Посмотрим результат split
      
      let isBoldActive = false;
      let isItalicActive = false;
      
      return parts.map((part, index) => {
        console.log('Обработка части:', part, 'Индекс:', index);
        
        switch(part) {
          case '<b>':
            isBoldActive = true;
            return null;
          case '</b>':
            isBoldActive = false;
            return null;
          case '<i>':
            isItalicActive = true;
            return null;
          case '</i>':
            isItalicActive = false;
            return null;
          default:
            return (
              <span key={index} style={{
                fontWeight: isBoldActive ? 'bold' : 'normal',
                fontStyle: isItalicActive ? 'italic' : 'normal'
              }}>
                {part}
              </span>
            );
        }
      });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {

            let chat = info?.chat?.slice(-5) || [];

            // const showTimeDuration = Date.now() - 30000;
            // chat = chat.filter(message =>
            //     message.time > showTimeDuration
            // );

            chat = chat.filter(message => !message.untilTime || message.untilTime > Date.now());

            setRecentMessages(chat);
        }, 300);

        return () => clearInterval(intervalId);
    }, []);

    // Функция для переключения видимости чата
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    function ColorCircle({ color }) {
      // Если цвета нет, то ничего не рендерим
      if (!color) return null;
    
      return (
        <div
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: '6px',
          }}
        />
      );
    }

    return (
      <>
        {recentMessages.length > 0 && (<button onClick={toggleVisibility} style={{
                position: 'absolute',
                top: '5px',
                right: '10px',
                backgroundColor: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                border: 'none',
                borderRadius: '12px',
                padding: '5px 8px',
                cursor: 'pointer',
                fontSize: '14px',
                opacity: 0.7,
                zIndex: 1500,
            }}>
                {isVisible ? t('chat.hide') : t('chat.show')}
            </button>)}

          {recentMessages.length > 0 && isVisible && (
              <div style={{
                  position: 'absolute',
                  top: '33px',
                  right: '0px',
                  padding: '10px',
                  color: 'rgba(0, 0, 0, 1)',
                  maxWidth: '220px',
                  zIndex: 1005,
                  fontSize: '12px',
                  overflowY: 'auto',
                  maxHeight: '300px',
                  border: 'none',
                  pointerEvents: 'none',
              }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {recentMessages.map((message, index) => (
                        <div key={index} style={{
                            backgroundColor: 'rgba(215, 215, 215, 0.4)',
                            margin: '2px 0',
                            padding: '2px 6px 3px',
                            borderRadius: '10px',
                            fontSize: '1.1em',
                            opacity: 0.85,
                            display: 'inline-block', // Элемент адаптируется по размеру контента
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ColorCircle color={message.color} />
                              {message.message ? (
                                <div>{renderFormattedMessage(message.message)}</div>
                              ) : (
                                <>
                                  <strong style={{ marginRight: '4px' }}>{truncateAuthor(message.author)}</strong>
                                  <span>: {truncateMessageText(message.text)}</span>
                                </>
                              )}
                              
                            </div>
                        </div>

                    ))}
                  </div>
              </div>
          )}
      </>
  );
}

export default ChatOverlay;
