// glfxBlur.js
import glfx from 'glfx';

export async function applyGlfxBlur(canvas, blurAmount) {
  try {
    blurAmount *= 2.3;
    const fxCanvas = glfx.canvas();
    const texture = fxCanvas.texture(canvas);
    fxCanvas.draw(texture);
    fxCanvas.triangleBlur(blurAmount);
    fxCanvas.update();
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(fxCanvas, 0, 0, canvas.width, canvas.height);
    return true;
  } catch (error) {
    console.error('Error applying glfx blur:', error);
    return false;
  }
}