// BrushMenu

import './BrushMenu.css';
import React, { useState, useEffect } from 'react';
import useStore from '../../../store';
import { 
  FaBrush, FaSprayCan, FaPencilAlt, FaFeatherAlt, FaPenFancy, FaPaintBrush,
  FaDotCircle, FaPaw, FaCog, FaDrawPolygon,
} from 'react-icons/fa';
import { 
  FaMarker, FaPenToSquare, FaBroomBall, FaRegLightbulb,
} from 'react-icons/fa6';
import { 
  TbPencilDown, TbLineDashed, TbBrush, TbSparkles, TbTexture, TbRosette,
} from "react-icons/tb";
import { useTranslation } from 'react-i18next';
import { createBetterClick } from '../../../utils/interface/createBetterClick'

const brushIcons = {
  'plain': FaMarker,
  'stylus': FaPenToSquare,
  'spray': FaSprayCan,
  'bristle': FaPaintBrush,
  'feather': FaFeatherAlt,
  'ink': FaPenFancy,
  'pencil': FaPencilAlt,
  'oil': FaBrush,
  'rembrandt': FaBroomBall,
  'watercolor': TbBrush,
  'outlined': FaDotCircle,
  'dashed': TbLineDashed,
  'neon': FaRegLightbulb,
  'sparkle': TbSparkles,
  'glyph': TbRosette,
  'shape': FaDrawPolygon,
  'test': FaPaw,
  'texture': TbTexture,
}

function BrushMenu({ store }) {
  const { t } = useTranslation();
  useStore(state => state.render);
  const brushType = useStore(state => state.brushType);

  const { ref } = store;
  const { menu, brush, info, work, premium } = ref;
  const { game, user } = info;

  const {
    premiumFeatures = [],
  } = premium;

  const {
    openMenu,
    closeMenu,
    delayPremiumMenu,
  } = menu.methods;

  const {
    saveUserSets,
  } = work.methods;

  const {
    brushSetsFun,
    brushLinkFun,
    customBrushSetsFun,
    changeBrushType,
  } = brush.methods;


  // Разделение кистей на категории
  const getBrushes = () => {
    if (game.mode === 'sprint') {
      return {
        basic: [
          'plain', 'shape', 'outlined', 'dashed'
        ].map(key => ({
          key,
          label: t(`brush.${key}.name`),
          icon: brushIcons[key],
        })),
        custom: []
      };
    }

    const basicBrushes = [
      'plain', 'shape', 'spray', 'bristle', 'feather', 'ink', 'pencil',
      'watercolor', 'oil', 'rembrandt', 'dashed', 'outlined', 'neon',
      'sparkle', 'glyph'
    ].map(key => ({
      key,
      label: t(`brush.${key}.name`),
      icon: brushIcons[key],
    }));

    const savedBrushes = user.savedBrushes || [];
    const customBrushes = savedBrushes.map(key => {
      const brushSettings = customBrushSetsFun(key);
      const basicBrushLink = brushSettings.clone;
      return {
        key,
        label: brushSettings.label,
        icon: brushIcons[basicBrushLink],
      };
    });

    let boughtBrushes = user.boughtBrushes || [];

    const boughtBrushesFix = Object.keys(user.brush || {})
    .filter(brushKey=>!boughtBrushes.includes(brushKey) && user.brush?.[brushKey]?.author);

    boughtBrushes = boughtBrushes.concat(boughtBrushesFix)

    const marketBrushes = boughtBrushes.map(key => {
      const brushSettings = customBrushSetsFun(key);
      const basicBrushLink = brushSettings.clone || 'plain';
      return {
        key,
        label: brushSettings.label,
        icon: brushIcons[basicBrushLink],
      };
    });

    return {
      basicBrushes,
      customBrushes,
      marketBrushes,
    };
  };

  const { basicBrushes, customBrushes, marketBrushes } = getBrushes();
  const currentBrushes = 
  user.brushCat === 'market' ? marketBrushes :
  user.brushCat === 'custom' ? customBrushes :
  basicBrushes;

  const handleOverlayClick = () => {
    if (brush.lastActionTime < Date.now() - 300) {
      closeMenu();
    }
  };

  const handleSwitchBrush = (newBrushType) => {
    if (brush.lastActionTime > Date.now() - 300) return;

    if (newBrushType !== 'plain' && 
        (!premiumFeatures.includes('brushes') || user.premiumOff)) {
      closeMenu();
      delayPremiumMenu('menu');
      return;
    }

    if (brushType !== newBrushType) {
      window.croco?.appleHaptic('medium');
      window.croco?.androidHaptic();
    }

    changeBrushType(newBrushType);
    if (newBrushType !== 'spray') {
      useStore.setState({ softness: 0 });
    }
    brush.brushHistory.push(newBrushType);
    closeMenu();
  };

  const handleOpenBrushSettings = (newBrushType, e) => {
    e?.preventDefault();
    e?.stopPropagation();

    brush.lastActionTime = Date.now();
    openMenu('customizeBrush', { brush: newBrushType, layer: true });

    if (newBrushType !== 'plain' && 
        (!premiumFeatures.includes('brushes') || user.premiumOff)) {
      return;
    }

    if (newBrushType !== 'spray') {
      useStore.setState({ softness: 0 });
    }

    if (newBrushType !== 'common') {
      brush.brushHistory.push(newBrushType);
      changeBrushType(newBrushType);
    }
  };

  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="brush-menu">

        <span 
          className="brush-menu-title"
          {...createBetterClick({
            onClick: (e) => handleOpenBrushSettings('common', e),
            onContextMenu: (e) => handleOpenBrushSettings('common', e),
          })}
        >
          {t('brush.title')}
          <FaCog style={{ paddingLeft: '15px' }} />
        </span>

        <div className="brush-menu-list add-scroll touch-active">
          {currentBrushes.map((brush) => (
            <div
              key={brush.key}
              className={`brush-option-row ${brushType === brush.key ? 'active' : ''}`}
              {...createBetterClick({
                onClick: () => handleSwitchBrush(brush.key),
                onContextMenu: (e) => handleOpenBrushSettings(brush.key, e),
              })}
            >
              <label className="brush-label">
                <brush.icon className={`brush-icon  ${brushType === brush.key ? 'active' : ''}`} />
                <span> {brush.label}</span>
                {brushType === brush.key && (
                  <FaCog
                    className="brush-settings-icon"
                    {...createBetterClick({
                      onClick: (e) => handleOpenBrushSettings(brush.key, e),
                      onContextMenu: (e) => handleOpenBrushSettings(brush.key, e),
                    })}
                  />
                )}
              </label>
            </div>
          ))}
        </div>

        {/* Переключатель категорий */}
        <div className="brush-category-switcher">
          <button
            className={`category-btn ${!['custom', 'market'].includes(user.brushCat) ? 'active' : ''}`}
            onClick={() => saveUserSets({brushCat: 'basic'})}
          >
            {t('brush.category.basic')}
          </button>
          <button
            className={`category-btn ${user.brushCat === 'custom' ? 'active' : ''}`}
            onClick={() => saveUserSets({brushCat: 'custom'})}
            disabled={!customBrushes.length}
          >
            {t('brush.category.custom')}
          </button>
          { marketBrushes[0] && (
            <button
            className={`category-btn ${user.brushCat === 'market' ? 'active' : ''}`}
            onClick={() => saveUserSets({brushCat: 'market'})}
            disabled={!customBrushes.length}
          >
            {t('brush.category.market')}
          </button>
          )}
        </div>
      </div>
    </>
  );
}

export default BrushMenu;