export const handleCreateSticker = ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('sticker.title'),
    message: t('sticker.text'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('sticker.button_cancel') },
      { id: 'go', type: 'default', text: t('sticker.button_go') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'go') {
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

      hostAction('createSticker', null, (result)=>{
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

        if (result?.success) {
          showPopupMessage({
            title: t('sticker_created.success_title'),
            message: t('sticker_created.success_message'),
          });
        } else {
          showPopupMessage({
            title: t('sticker_created.error_title'),
            message: t('sticker_created.error_message'),
          });
        }

      });
    }
  }
  showPopupMessage(popupParams)

};


export const handleFullScreen = ({ t, hostAction, showPopupMessage }) =>{

  const popupParams = {
    title: t('fullscreen.title'),
    message: t('fullscreen.text'),
    buttons: [
      { id: 'no', type: 'cancel', text: t('fullscreen.button_cancel') },
      { id: 'yes', type: 'default', text: t('fullscreen.button_confirm') },
    ],
    settings: [{ key: 'openBrowser', label: t('settings.open_browser_on_start') }],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'yes') { makeItFullScreen() }
  }
  showPopupMessage(popupParams)


}


const makeItFullScreen = () =>{

  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('brw', '1');
  window.open(currentUrl.toString(), '_blank');

  setTimeout(() => {
    window.Telegram.WebApp.close();
  }, 100); 

  // () => window.Telegram.WebApp.openTelegramLink('http://t.me/CrocoDraw_Bot/open')
  // () => window.Telegram.WebApp.openLink('http://t.me/CrocoDraw_Bot/open')
  // () => window.Telegram.WebApp.openLink(window.location.href)
}


export const handlePetAvatar = async ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('pet.avatar.set_popup.title'),
    message: t('pet.avatar.set_popup.message'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('pet.avatar.set_popup.button_cancel') },
      { id: 'set', type: 'default', text: t('pet.avatar.set_popup.button_set') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'set') {

      hostAction('setAvatar', null, (result)=>{

        if (result?.success) {

          window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
          showPopupMessage({
            title: t('pet.avatar.success_popup.title'),
            message: t('pet.avatar.success_popup.message'),
          });

        } else if (result?.error === 'no_pet') {

          showPopupMessage({
            title: t('pet.avatar.error_no_pet.title'),
            message: t('pet.avatar.error_no_pet.message'),
          });
          
        } else if (result?.error === 'egg') {

          showPopupMessage({
            title: t('pet.avatar.error_egg.title'),
            message: t('pet.avatar.error_egg.message'),
          });

        } else if (result?.error === 'denied') {

          showPopupMessage({
            title: t('pet.avatar.error_denied.title'),
            message: t('pet.avatar.error_denied.message'),
          });

        }

      });

    }
  }
  showPopupMessage(popupParams)

}


export const handleSaveAccessory = async ({ t, hostAction, showPopupMessage }) => {

  const popupParams = {
    title: t('pet.accessory.save.title'),
    message: t('pet.accessory.save.text'),
    buttons: [
      { id: 'cancel', type: 'cancel', text: t('pet.accessory.save.button_cancel') },
      { id: 'go', type: 'default', text: t('pet.accessory.save.button_go') },
    ],
  };

  popupParams.callback = (buttonId) => {
    if (buttonId === 'go') {
      window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

      hostAction('saveAccessory', null, (result)=>{
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

        if (result.success) {
          showPopupMessage({
            title: t('pet.accessory.save_result.success_title'),
            message: t('pet.accessory.save_result.success_message'),
          });
        } else {
          showPopupMessage({
            title: t('pet.accessory.save_result.error_title'),
            message: t('pet.accessory.save_result.error_message') + result?.error,
          });
        }

      });
    }
  }
  showPopupMessage(popupParams)

}

