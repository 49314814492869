// rembrandt.js
import chroma from 'chroma-js';
import { brushDefaults } from './helpers/brushLoader';
import { fillGradient } from './helpers/gradient'; 
import { getStrokeBounds, translatePoints } from './helpers/points'; 


const defaultBrushSettings = brushDefaults.rembrandt || {};

export async function drawRembrandtStroke(stroke, context, params) {

  let {
    color,
    gradientColor,
    lineWidth,
    points,
    sets = {},
  } = stroke;

  if (points.length === 0) return;

  const brushSettings = Object.assign({}, defaultBrushSettings, sets);

  let { 
    shadowStrength = 0.5,
    opacity,
  } = brushSettings;

  const chromaColor = chroma(color);
  const originalAlpha = chromaColor.alpha();
  color = chromaColor.alpha(1).hex();

  let bristleColor;
  if (gradientColor) {
    bristleColor = chroma('black').alpha(opacity / 2).hex();
  } else {
    bristleColor = chromaColor.alpha(opacity / 2).hex();
  }

  const darkenedColor = chromaColor.darken(12 * shadowStrength).hex();

  // Вычисляем границы штриха
  const bounds = getStrokeBounds(points, lineWidth);
  if (!bounds) return;

  const offsetX = -bounds.x;
  const offsetY = -bounds.y;
  const translatedPoints = translatePoints(points, offsetX, offsetY);

  // Создаем временный канвас минимального размера
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = bounds.width;
  bufferCanvas.height = bounds.height;
  const bufferCtx = bufferCanvas.getContext('2d');


  const actualStroke = Object.assign({}, stroke, {
    color: bristleColor,
    points: translatedPoints,
  });

  drawBristles(actualStroke, bufferCtx, brushSettings);

  if (gradientColor) {
    fillGradient(bufferCtx, translatedPoints, color, gradientColor, lineWidth)
  }

  // Создаем второй буферный холст для теней
  const shadowCanvas = document.createElement('canvas');
  shadowCanvas.width = bounds.width;
  shadowCanvas.height = bounds.height;
  const shadowCtx = shadowCanvas.getContext('2d');


  if (shadowStrength > 0) {

    // Рисуем белую тень
    shadowCtx.save();
    shadowCtx.globalAlpha = 0.5;
    shadowCtx.globalCompositeOperation = 'source-over';
    shadowCtx.shadowColor = 'white';
    shadowCtx.shadowBlur = 0.5;
    shadowCtx.shadowOffsetX = 0.4;
    shadowCtx.shadowOffsetY = 0.4;
    shadowCtx.drawImage(bufferCanvas, 0, 0);
    shadowCtx.restore();

    // Рисуем черную тень
    shadowCtx.save();
    shadowCtx.globalCompositeOperation = 'source-over';
    shadowCtx.shadowColor = darkenedColor;


    if (shadowStrength < 1) {
      shadowCtx.shadowOffsetX = 0.7;
      shadowCtx.shadowOffsetY = 0.7;
      shadowCtx.globalAlpha = 0.7 * Math.sqrt(shadowStrength * 2);
      shadowCtx.shadowBlur = 2 * Math.cbrt(shadowStrength * 2);
      shadowCtx.drawImage(bufferCanvas, 0, 0);
    } else {
      shadowCtx.shadowOffsetX = shadowStrength * 0.5;
      shadowCtx.shadowOffsetY = shadowStrength;
      shadowCtx.globalAlpha = 0.7 * Math.sqrt(shadowStrength * 2);
      shadowCtx.shadowBlur = 2 * Math.sqrt(shadowStrength * 2);
  
      for (let i = 0; i < Math.ceil(shadowStrength); i++) {
        shadowCtx.drawImage(bufferCanvas, 0, 0);
      }
    }
  }

  shadowCtx.restore();

  // Рисуем оригинальное изображение поверх теней
  shadowCtx.globalCompositeOperation = 'source-over';
  shadowCtx.drawImage(bufferCanvas, 0, 0);

  // Рисуем финальный результат на основном холсте
  context.globalAlpha = gradientColor ? 1 : originalAlpha;
  context.drawImage(
    shadowCanvas, 
    0, 0, bounds.width, bounds.height,
    bounds.x, bounds.y, bounds.width, bounds.height
  );

  context.globalAlpha = 1; // Возвращаем исходное значение альфа-канала


}


// Простая функция для генерации псевдослучайных чисел
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}


// Основная функция корректировки точек штриха с плоскими концами
function adjustStrokePointsFlatEnds(points, offset) {
  if (points.length < 4) return points;

  // Функция для вычисления вектора между двумя точками
  function calculateVector(p1, p2) {
    return { x: p2.x - p1.x, y: p2.y - p1.y };
  }

  // Функция для вычисления длины вектора
  function vectorLength(v) {
    return Math.sqrt(v.x * v.x + v.y * v.y);
  }

  // Функция для нормализации вектора
  function normalizeVector(v) {
    const length = vectorLength(v);
    return { x: v.x / length, y: v.y / length };
  }

  // Вычисляем векторы направления в начале и конце штриха
  const startVector = normalizeVector(calculateVector(points[0], points[2]));
  const endVector = normalizeVector(calculateVector(points[points.length - 3], points[points.length - 1]));

  // Проецируем смещение на направление штриха
  const startProjection = Math.abs(offset.x * startVector.x + offset.y * startVector.y);
  const endProjection = Math.abs(offset.x * endVector.x + offset.y * endVector.y);

  let adjustedPoints = [...points];

  // Функция для обработки конца штриха (начало или конец)
  function processEnd({
    startIndex,
    endIndex,
    step,
    spliceStep,
    projection,
  }) {
    let currentIndex = startIndex;
    let remainingDistance = projection;

    while (currentIndex !== endIndex && remainingDistance > 0) {
      const p1 = adjustedPoints[currentIndex];
      const p2 = adjustedPoints[currentIndex + step];

      if (!p1 || !p2) break;

      const v = calculateVector(p1, p2);
      const vLength = vectorLength(v);

      if (vLength <= remainingDistance) {
        // Если расстояние между точками меньше оставшегося расстояния для укорочения,
        // удаляем точку и продолжаем обработку
        adjustedPoints.splice(currentIndex, 1);
        currentIndex += spliceStep;
        remainingDistance -= vLength;
      } else {
        // Иначе смещаем точку
        const ratio = remainingDistance / vLength;
        adjustedPoints[currentIndex] = {
          x: Math.round((p1.x + v.x * ratio) * 10) / 10,
          y: Math.round((p1.y + v.y * ratio) * 10) / 10,
        };
        break;
      }
    }
  }

  // Обрабатываем начало штриха
  processEnd({
    startIndex: 0,
    endIndex: adjustedPoints.length - 2,
    step: 1,
    spliceStep: 0,
    projection: startProjection + seededRandom(offset.x) * 0.1 * startProjection,
  });

  // Обрабатываем конец штриха
  processEnd({
    startIndex: adjustedPoints.length - 1,
    endIndex: 1,
    step: -1,
    spliceStep: -1,
    projection: endProjection + seededRandom(offset.y) * 0.1 * startProjection,
  });

  return adjustedPoints;
}


// Основная функция корректировки точек штриха
function adjustStrokePoints(points, offset, tapering) {

  if (points.length < 4) return points;

  // Функция для вычисления наклона между двумя точками
  function calculateSlope(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.atan2(dy, dx);
  }

  // Функция для вычисления разницы наклонов в диапазоне от 0 до 1
  function calculateSlopeDifference(slope1, slope2) {
    let diff = Math.abs(slope1 - slope2);
    diff = diff > Math.PI ? 2 * Math.PI - diff : diff;
    return (diff / (Math.PI / 2));
  }

  // Вспомогательная функция для вычисления длины вектора
  function vectorLength(v) {
    return Math.sqrt(v.x * v.x + v.y * v.y);
  }

  const offsetSlope = calculateSlope({ x: 0, y: 0 }, { x: offset.x, y: offset.y });
  const startStrokeSlope = calculateSlope(points[2], points[0]);
  const startSlopeDifference = calculateSlopeDifference(offsetSlope, startStrokeSlope);
  const endStrokeSlope = calculateSlope(points[points.length - 3], points[points.length - 1]);
  const endSlopeDifference = calculateSlopeDifference(offsetSlope, endStrokeSlope);
  
  const startEffectiveDistance = offset.distance * startSlopeDifference * tapering * 2;
  const endEffectiveDistance = offset.distance * endSlopeDifference * tapering * 2;

  let adjustedPoints = [...points];

  // Функция для обработки конца штриха (начало или конец)
  function processEnd({
    startIndex, 
    endIndex, 
    step,
    spliceStep,
    effectiveDistance,
  }) {
    let currentIndex = startIndex;
    let remainingDistance = effectiveDistance * 2;

    while (currentIndex !== endIndex && remainingDistance > 0) {
      const p1 = adjustedPoints[currentIndex];
      const p2 = adjustedPoints[currentIndex + step];

      if (!p1 || !p2) break;

      const v = { x: p2.x - p1.x, y: p2.y - p1.y };
      const vLength = vectorLength(v);

      if (vLength <= remainingDistance) {
        // Если расстояние между точками меньше оставшегося расстояния для укорочения,
        // удаляем точку и продолжаем обработку
        adjustedPoints.splice(currentIndex, 1);
        currentIndex += spliceStep;
        remainingDistance -= vLength;
      } else {

        // Иначе смещаем точку
        const ratio = remainingDistance / vLength;
        adjustedPoints[currentIndex] = {
          x: Math.round((p1.x + v.x * ratio) * 10)/10,
          y: Math.round((p1.y + v.y * ratio) * 10)/10,
        };
        break;
      }
    }
  }

  processEnd({
    startIndex: 0,
    endIndex: adjustedPoints.length - 1,
    step: 1,
    spliceStep: 0,
    effectiveDistance: startEffectiveDistance,
  });
  processEnd({
    startIndex: adjustedPoints.length - 1,
    endIndex: 0,
    step: -1,
    spliceStep: -1,
    effectiveDistance: endEffectiveDistance,
  });
  
  adjustedPoints = adjustedPoints.length > 1 ? adjustedPoints : [];
  return adjustedPoints;
}


// Функция для генерации отступов ворсинок и порогов давления
function generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor) {
  const baseRadius = lineWidth * 0.5 * 0.9;
  let offsets = [];

  for (let i = 0; i < bristleCount; i++) {
    const angle = seededRandom(time + i) * Math.PI * 2;
    const distance = seededRandom(time + i + 100) * baseRadius;

    const x = Math.cos(angle) * distance;
    const y = Math.sin(angle) * distance;

    const randX = (seededRandom(time + i + 200) - 0.5) * 2 * randomnessFactor * baseRadius;
    const randY = (seededRandom(time + i + 300) - 0.5) * 2 * randomnessFactor * baseRadius;

    const pressureThreshold = seededRandom(time + i + 500); // Пороговое значение давления от 0 до 1

    offsets.push({
      x: x + randX,
      y: y + randY,
      distance: Math.sqrt((x + randX) ** 2 + (y + randY) ** 2),
      pressureThreshold, // Добавляем пороговое давление для ворса
    });
  }

  // Фильтруем ворсинки в центре
  const innerThreshold = baseRadius * 2 / 3;
  const innerBristles = offsets.filter(offset => offset.distance < innerThreshold);
  const outerBristles = offsets.filter(offset => offset.distance >= innerThreshold);

  // Оставляем только часть внутренних ворсинок
  const keptInnerBristles = innerBristles
    .sort(() => seededRandom(time + 400) - 0.5)
    .slice(0, Math.ceil(innerBristles.length * 0.3));

  // Объединяем отфильтрованные внутренние ворсинки с внешними
  offsets = [...keptInnerBristles, ...outerBristles];

  // Добавляем центральную ворсинку с нулевым порогом давления
  offsets.push({ x: 0, y: 0, distance: 0, pressureThreshold: 0 });

  return offsets;
}

function drawBristles(stroke, bufferCtx, brushSettings) {

  const {
    lineWidth,
    points,
    time,
    color,
  } = stroke;

  let { 
    density = 0.5,
  } = brushSettings;

  if (density < 0.5) { density = Math.pow(density, 2) * 2; }

  const randomnessFactor = 0.3; // Контролирует степень случайности (0-1)
  const π = Math.PI;
  const maxBristles = 400;

  let bristleCount = Math.round((lineWidth / 8) ** 2 * π * density * 8);
  let bristleWidth = 0.7;

  if (bristleCount > maxBristles) {
    bristleWidth *= Math.sqrt(2 * bristleCount / maxBristles)
    bristleCount = maxBristles;
  }

  const offsets = generateBristleOffsets(time, lineWidth, bristleCount, randomnessFactor);

  bufferCtx.lineCap = 'square';
  bufferCtx.lineJoin = 'round';

  offsets.forEach(offset => {
    drawOneBristle({
      points,
      lineWidth: bristleWidth,
      mainLineWidth: lineWidth,
      color,
      offset,
      time,
    }, bufferCtx, brushSettings);
  });
}

function drawOneBristle0({
  points,
  lineWidth,
  mainLineWidth,
  color,
  offset = { x: 0, y: 0 },
  plusWidth = 0,
  tapering,
  cutEdges,
  time,
  wobbleAmount,
  ropeEffect,
}, context, pressureThreshold) {

  // else {
  //   return drawPlainStroke(stroke, context);
  // }
}

function drawOneBristle({
  points, 
  lineWidth, 
  mainLineWidth,
  color, 
  offset = { x: 0, y: 0 }, 
  plusWidth, 
  time, 
}, context, brushSettings) {

  const {
    pressureThreshold = 1,
  } = offset;

  let { 
    cutEdges = false,
    tapering = 0.5,
    wobble = 0,
    ropeEffect,
  } = brushSettings;

  if (cutEdges) {
    points = adjustStrokePointsFlatEnds(points, offset, mainLineWidth);
  } else {
    points = adjustStrokePoints(points, offset, tapering);
  }

  let wobbleAmount = mainLineWidth * wobble;


  const stroke = {
    points,
    lineWidth,
    color,
    offset,
    plusWidth,
    time,
    pressureThreshold,
    wobbleAmount,
    ropeEffect,
  };

  if (points.length === 0) {
    return;
  } else if (points.length === 1) {
    return drawPoint(stroke, context);
  } 

  context.strokeStyle = color;
  context.lineWidth = lineWidth;

  let isDrawingSegment = false;
  const offsetSeed = offset.x * offset.y;

  for (let i = 1; i < points.length; i++) {
    const prevPoint = points[i - 1];
    const currPoint = points[i];

    const pressure = currPoint.pressure !== undefined ? currPoint.pressure : 1;

    if (pressure >= pressureThreshold) {
      if (!isDrawingSegment) {
        context.beginPath();
        context.moveTo(prevPoint.x + offset.x, prevPoint.y + offset.y);
        isDrawingSegment = true;
      }

      let midPoint;
      if (wobbleAmount) {


        const wobbleX = (seededRandom(time + offsetSeed + i + 200) - 0.5) * wobbleAmount;
        const wobbleY = (seededRandom(time + offsetSeed + i + 300) - 0.5) * wobbleAmount;
  
        midPoint = {
          x: (prevPoint.x + currPoint.x + wobbleX) / 2,
          y: (prevPoint.y + currPoint.y + wobbleY) / 2
        };

      } else {
        midPoint = {
          x: (prevPoint.x + currPoint.x) / 2,
          y: (prevPoint.y + currPoint.y) / 2,
        };
      }

      if (i < points.length -1) {
        context.quadraticCurveTo(
          prevPoint.x + offset.x,
          prevPoint.y + offset.y,
          midPoint.x + (ropeEffect ? 0 : offset.x),
          midPoint.y + (ropeEffect ? 0 : offset.y),
        );
      } else {
        context.lineTo(
          currPoint.x + offset.x, 
          currPoint.y + offset.y, 
        );
      }

      // context.quadraticCurveTo(
      //   prevPoint.x + offset.x,
      //   prevPoint.y + offset.y,
      //   midPoint.x + (ropeEffect ? 0 : offset.x),
      //   midPoint.y + (ropeEffect ? 0 : offset.y),
      // );

    } else {
      if (isDrawingSegment) {
        context.stroke();
        isDrawingSegment = false;
      }
    }
  }

  if (isDrawingSegment) {
    context.stroke();
  }
}

function drawPoint({
  points, lineWidth, color, offset, plusWidth, pressureThreshold,
}, context) {

  const point = points[0];
  const pressure = point.pressure !== undefined ? point.pressure : 1;

  if (pressure >= pressureThreshold) {
    context.beginPath();
    context.arc(
      point.x + offset.x,
      point.y + offset.y,
      (lineWidth / 2) + plusWidth,
      0,
      Math.PI * 2
    );
    context.fillStyle = color;
    context.fill();
  }
}