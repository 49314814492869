// src/store/premiumSlice.js

const plans = {

  user: {

    default: [
      'pipette',
      'layers',
    ],

    artist: [
      'colors',
      'effect',
      'pipette',
      'hints',
      'personoalWord',
      // '',
    ],
    master: [
      'colors',
      'effect',
      'pipette',
      'hints',
      'personoalWord',

      'saveColors',
      'brushes',
      'layers',
      // '',
    ],

    moder: [
      'colors',
      'effect',
      'pipette',
      'hints',
      'personoalWord',

      'saveColors',
      'brushes',
      'layers',
    ],
  },


  group: {
    workshop: [
      'colors',
      'effect',
      'pipette',
      'hints',
      'personoalWord',

      // '',
    ],
    artschool: [
      'colors',
      'effect',
      'pipette',
      'hints',
      'personoalWord',

      'saveColors',
      'brushes',
      'layers',
    ],

  },

}



const createPremiumSlice = (set, get, ref) => {

  ref.premium = {
    premiumSubs: [],
    premiumFeatures: [],
    // premiumFeatures: ['brushes', 'colors', 'pipette', 'effect', 'personoalWord'],
  };

  return {

    applyPremium: ()=> {
    
      const now = Math.max(Date.now(), ref.info.game?.created || 0);
      const activeSubs = [];
      let activeFeatures = [];
  
      const userSettings = ref.info.user;
      const groupSettings = ref.info.group;
      const serverPrefs = ref.info.prefs;
  
      activeFeatures = activeFeatures.concat(plans.user.default);
  
  
      if (serverPrefs.promotion === 'new_year') {
        activeSubs.push('new_year');
        activeFeatures = activeFeatures.concat(plans.user.master);
      }
  
      if (!userSettings.premiumOff) {
  
        for (let plan in plans.user) {
          if (userSettings?.[plan] > now) {
            activeSubs.push(plan);
            activeSubs.push('user');
            activeFeatures = activeFeatures.concat(plans.user[plan]);
          }
        }
    
        for (let plan in plans.group) {
          if (groupSettings?.[plan] > now) {
            activeSubs.push(plan);
            activeSubs.push('group');
            activeFeatures = activeFeatures.concat(plans.group[plan]);
          }
        }
    
        if (userSettings.moder || ref.telegram.testCanvas) {
          activeSubs.push('moder');
          activeFeatures = activeFeatures.concat(plans.user.moder);
        } else if (userSettings.guild) {
          activeSubs.push('guild');
          activeFeatures = activeFeatures.concat(plans.user.master);
        }
  
      }
  
      activeFeatures = [...new Set(activeFeatures)];
  
      ref.premium.premiumSubs = activeSubs;
      ref.premium.premiumFeatures = activeFeatures;
  
    }
  };
};

export default createPremiumSlice;

