// useHelpers.js
import useStore from '../../store';

export const useHelpers = (ref) => {

  const { convy, menu } =  ref;

  const wipeTempCanvas = () => {
    const canvas = convy.temp.ref;
    const context = convy.temp.ctx;
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const getLayerCoordinates = (point, layerId) => {
    // Получаем координаты из основного канваса (например, 800×800)
    const canvasPoint = getCanvasCoordinates(point); 
  
    // Определяем активный слой, если layerId не передан
    if (!layerId) {
      const activeLayer = ref.convy.methods.getActiveLayer();
      layerId = activeLayer.info.clippingFor || activeLayer.id;
    }
    
    const layerInfo = convy.layerInfo[layerId] || {};
    let { offset = { x: 0, y: 0 }, scale = 1, rotation = 0 } = layerInfo;
  
    // Получаем основной канвас и канвас слоя
    const mainCanvas = convy.main.ref; // 800×800
    const layerCanvas = ref.convy.layers[layerId].canvas; // например, 2400×2400
  
    // Разница размеров: смещение, чтобы перейти из системы основного канваса в систему слоя
    const extraX = (layerCanvas.width - mainCanvas.width) / 2; //  (2400 - 800)/2 = 800
    const extraY = (layerCanvas.height - mainCanvas.height) / 2; //  аналогично
  
    // Переводим координаты из системы основного канваса в базовую систему слоя:
    const baselinePoint = {
      x: canvasPoint.x + extraX,
      y: canvasPoint.y + extraY,
    };
  
    // Центр канваса слоя
    const centerX = layerCanvas.width / 2;
    const centerY = layerCanvas.height / 2;
  
    // Применяем обратные трансформации слоя:
    // 1. Вычитаем центр слоя и смещение слоя
    const localDX = baselinePoint.x - centerX - offset.x;
    const localDY = baselinePoint.y - centerY - offset.y;
  
    // 2. Инвертируем поворот
    const angleRad = rotation * Math.PI / 180;
    const unrotatedX = localDX * Math.cos(-angleRad) - localDY * Math.sin(-angleRad);
    const unrotatedY = localDX * Math.sin(-angleRad) + localDY * Math.cos(-angleRad);
  
    // 3. Инвертируем масштаб
    const localX = unrotatedX / scale;
    const localY = unrotatedY / scale;
  
    // 4. Возвращаем координаты в системе слоя: добавляем центр слоя обратно
    const layerPoint = {
      x: centerX + localX,
      y: centerY + localY,
    };

  
    return {
      canvasPoint: roundCoordinates(canvasPoint), 
      layerPoint: roundCoordinates(layerPoint),  
    };

  };

  const roundCoordinates = ({ x, y }, decimals = 2) => {
    const factor = Math.pow(10, decimals);
    return {
      x: Math.round(x * factor) / factor,
      y: Math.round(y * factor) / factor
    };
  };
  
  const getReferenceCoordinates = (nativeEvent) =>{

    const referenceCanvas = menu?.source?.canvas;
    if (!referenceCanvas) return { x: 0, y: 0 };

    const referenceElement = nativeEvent.target.closest('.reference-image-wrapper');
    if (!referenceElement) return null;
    const rect = referenceElement.getBoundingClientRect();
    let x = nativeEvent.clientX - rect.left;
    let y = nativeEvent.clientY - rect.top;
    // Пересчёт координат из размеров отображаемого элемента в натуральные размеры изображения
    const scaleX = referenceCanvas.width / rect.width;
    const scaleY = referenceCanvas.height / rect.height;
    x = Math.floor(x * scaleX);
    y = Math.floor(y * scaleY);

    return { x, y }
  }

  const getCanvasCoordinates = (point) => {

    const x = point.x || point.clientX;
    const y = point.y || point.clientY;
    const canvas = convy.main.ref;

    const rotation = useStore.getState().rotation;
    const zoomFactor = useStore.getState().zoomFactor;

    // Получаем геометрический rect канвы (bounding box повернутого элемента)
    const rect = canvas.getBoundingClientRect();
    // Центр канвы в координатах окна
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    // Смещение от центра в координатах события
    const dx = x - centerX;
    const dy = y - centerY;
    // Инвертируем поворот: угол в радианах (положительный угол соответствует повороту по часовой стрелке)

    const angle = rotation * Math.PI / 180;
    // const angle = layer.rotation * Math.PI / 180;

    // Применяем обратное вращение:
    const unrotatedDX = dx * Math.cos(-angle) - dy * Math.sin(-angle);
    const unrotatedDY = dx * Math.sin(-angle) + dy * Math.cos(-angle);
    // Получаем CSS-ширину и высоту канвы (до поворота) из computed style.
    const computedStyle = window.getComputedStyle(canvas);
    const displayedWidth = parseFloat(computedStyle.width);
    const displayedHeight = parseFloat(computedStyle.height);
    // Получаем координаты относительно не повернутой канвы:
    const pointerCSSX = unrotatedDX + displayedWidth * zoomFactor / 2;
    const pointerCSSY = unrotatedDY + displayedHeight * zoomFactor / 2;
    // Вычисляем масштаб: внутренний размер канвы делён на CSS‑размер

    const scaleX = canvas.width / (displayedWidth * zoomFactor);
    const scaleY = canvas.height / (displayedHeight * zoomFactor);

    // Переводим координаты в систему канвы:
    const canvasX = pointerCSSX * scaleX || 0;
    const canvasY = pointerCSSY * scaleY || 0;
    return { x: canvasX, y: canvasY };
  };

  const centralCanvasOffset = (point) => {

    const canvas = convy.main.ref;
    
    const pointOnCanvas = getCanvasCoordinates(point);
    const fromCenter = {
      x: pointOnCanvas.x - canvas.width / 2,
      y: pointOnCanvas.y - canvas.height / 2,
    }

    return fromCenter;

  }

  const getEventPos = (event) => {

    event = event?.touches?.[0] || event;

    const zoomFactor = useStore.getState().zoomFactor;
    const rect = convy.main.ref.getBoundingClientRect();

    let x = ((event.clientX - rect.left) / convy.scale) / zoomFactor;
    let y = ((event.clientY - rect.top) / convy.scale) / zoomFactor;
    
    x = Math.round(x * 10) / 10;
    y = Math.round(y * 10) / 10;

    return { x, y, };
  };

  const getCanvasPoint = (
    {x, y}, 
    rect = convy.temp.ref.getBoundingClientRect(),
    ) =>{

    const zoomFactor = useStore.getState().zoomFactor;

    let pointX = ((x - rect.left) / convy.scale) / zoomFactor;
    let pointY = ((y - rect.top) / convy.scale) / zoomFactor;

    return {
      x: pointX,
      y: pointY,
    };
  } 
  
  return {
    wipeTempCanvas,
    getEventPos,
    getCanvasPoint,
    getLayerCoordinates,
    getCanvasCoordinates,
    getReferenceCoordinates,
    centralCanvasOffset,
  }
};

