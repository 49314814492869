

export function filterClosePoints(points, minDistance) {
  points = points.slice(0);
  if (!minDistance) { return points; }
  if (points.length < 4) { return points; }

  points = points.slice(0);

  const lastPoint = points.pop();
  const filteredPoints = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const dx = points[i][0] - filteredPoints[filteredPoints.length - 1][0];
    const dy = points[i][1] - filteredPoints[filteredPoints.length - 1][1];
    if (dx * dx + dy * dy >= minDistance * minDistance) {
      filteredPoints.push(points[i]);
    }
  }
  filteredPoints.push(lastPoint);
  return filteredPoints;
}


export function interpolatePointsUniform(points, desiredSpacing = 10) {
  if (points.length < 2) return points;

  let interpolatedPoints = [points[0]];

  for (let i = 1; i < points.length; i++) {
    const p0 = interpolatedPoints[interpolatedPoints.length - 1];
    const p1 = points[i - 1];
    const p2 = [
      (points[i - 1][0] + points[i][0]) / 2,
      (points[i - 1][1] + points[i][1]) / 2
    ];

    const bezierPoints = generateUniformBezierPoints(p0, p1, p2, desiredSpacing);

    if (bezierPoints.length > 1) {
      // Исключаем первую точку, чтобы избежать дублирования
      interpolatedPoints.push(...bezierPoints.slice(1));
    }
  }

  return interpolatedPoints;
}

function generateUniformBezierPoints(p0, p1, p2, desiredSpacing) {
  const totalLength = estimateBezierLength(p0, p1, p2);

  // Проверяем, что totalLength - число и больше нуля
  if (isNaN(totalLength) || totalLength <= 0) {
    return [p0, p2]; // Возвращаем начало и конец кривой
  }

  const numPoints = Math.max(2, Math.ceil(totalLength / desiredSpacing));

  const points = [];

  for (let i = 0; i <= numPoints; i++) {
    const t = i / numPoints;
    const point = quadraticBezier(p0, p1, p2, t);
    points.push(point);
  }

  return points;
}

function quadraticBezier(p0, p1, p2, t) {
  const x =
    (1 - t) * (1 - t) * p0[0] +
    2 * (1 - t) * t * p1[0] +
    t * t * p2[0];
  const y =
    (1 - t) * (1 - t) * p0[1] +
    2 * (1 - t) * t * p1[1] +
    t * t * p2[1];
  return [x, y];
}

function estimateBezierLength(p0, p1, p2) {
  const steps = 10; // Можно увеличить для большей точности
  let length = 0;
  let prevPoint = p0;

  for (let i = 1; i <= steps; i++) {
    const t = i / steps;
    const point = quadraticBezier(p0, p1, p2, t);
    const dx = point[0] - prevPoint[0];
    const dy = point[1] - prevPoint[1];
    length += Math.sqrt(dx * dx + dy * dy);
    prevPoint = point;
  }

  return length;
}