// useZoomPC
import useStore from '../../store';

export const useZoomPc = (ref) => {

  const { menu, drawing, telegram, convy, info, brush, history } = ref;

  const { 
    centralCanvasOffset,
   } = drawing.methods;

  const {
    zoom,
  } = convy;


  const changeZoomPc = (delta, newZoomFactor) => {

    const zoomFactor = useStore.getState().zoomFactor;
    const panOffset = useStore.getState().panOffset;
    const mousePosition = useStore.getState().mousePosition;
    const rotation = useStore.getState().rotation; 
  
    let coefficient = zoomFactor * 0.1 + 0.25;
    const changeAmount = (delta > 0 ? -coefficient : coefficient);
  
    newZoomFactor = newZoomFactor || zoomFactor + changeAmount; 
    newZoomFactor = Math.max(newZoomFactor, 1); 
    newZoomFactor = Math.min(newZoomFactor, zoom.maxZoomFactor);
  
    // Вычисляем смещение от центра в системе канвы (без учёта поворота)
    const canvasDistanceFromCenter = centralCanvasOffset(mousePosition);
  
    // Преобразуем это смещение с учётом поворота:
    const angleRad = rotation * Math.PI / 180;
    const rotatedOffset = {
      x: canvasDistanceFromCenter.x * Math.cos(angleRad) - canvasDistanceFromCenter.y * Math.sin(angleRad),
      y: canvasDistanceFromCenter.x * Math.sin(angleRad) + canvasDistanceFromCenter.y * Math.cos(angleRad)
    };
  
    // Новое смещение (panOffset) корректируется с учетом разницы масштабов и поворота
    let newZoomOffset = {
      x: (rotatedOffset.x * convy.scale * (zoomFactor - newZoomFactor)) + panOffset.x,
      y: (rotatedOffset.y * convy.scale * (zoomFactor - newZoomFactor)) + panOffset.y,
    };
  
    if (newZoomFactor === 1) {
      newZoomOffset = { x: 0, y: 0 };
    }
   
    useStore.setState({ 
      zoomFactor: newZoomFactor, 
      panOffset: newZoomOffset, 
    });

  };

  const changeLayerScalePc = (delta) =>{ 

    const activeLayer = convy.methods.getActiveLayer();
    if (!convy.layerTransformation) {
      convy.layerTransformation = {
        prev: JSON.parse(JSON.stringify(activeLayer.info)),
        type: 'keys',
      };
    }

    const coefficient = activeLayer.info.scale * 0.05 + 0.1;
    const changeAmount = (delta > 0 ? -coefficient : coefficient);
    
    activeLayer.info.scale += changeAmount;
    drawing.methods.redraw();

  }

  const changeViewStart = ({nativeEvent}) => {

    const panOffset = useStore.getState().panOffset;
    
    zoom.movingStartPoint = { x: nativeEvent.clientX, y: nativeEvent.clientY };
    zoom.initialOffset = panOffset || {x: 0, y: 0};

    // Сохраняем начальное смещение активного слоя
    const activeLayer = convy.methods.getActiveLayer();
    zoom.initialLayerOffset = { ...activeLayer.info.offset };

  };

  const ifViewWasChanged = ()=>{

    const mousePosition = useStore.getState().mousePosition;

    if (!zoom.movingStartPoint) { return false; }
    
    const changeX = Math.abs(zoom.movingStartPoint.x - mousePosition.x)
    const changeY= Math.abs(zoom.movingStartPoint.y - mousePosition.y)

    if ( changeX < 4 && changeY < 4 ) {
      return false;
    } else {
      return true;
    }

  }


  const changeViewMove = ({nativeEvent}) => {

    if (!nativeEvent.buttons) {return;}
    if (!zoom.movingStartPoint) {return;}
    if (nativeEvent.target.closest('.allow-context-menu')) { return; }

    if (!ifViewWasChanged()) { return; }

    const { zoomFactor, mousePosition } = useStore.getState();

    const newPointOffset = {
      x: (mousePosition.x - zoom.movingStartPoint.x) + zoom.initialOffset.x,
      y: (mousePosition.y - zoom.movingStartPoint.y) + zoom.initialOffset.y,
    }

    const maxOffset = zoomFactor < 1 ? 
    200 : 
    150 * zoomFactor ** 1.13 ;

    const max = {
      x: maxOffset,
      y: maxOffset,
    }
    const min = {
      x: -maxOffset,
      y: -maxOffset,
    }

    newPointOffset.x = Math.max(min.x, newPointOffset.x)
    newPointOffset.x = Math.min(max.x, newPointOffset.x)

    newPointOffset.y = Math.max(min.y, newPointOffset.y)
    newPointOffset.y = Math.min(max.y, newPointOffset.y)

    useStore.setState({ panOffset: newPointOffset });

  }

  const changeLayerMove = ({ nativeEvent }) => {

    if (!useStore.getState().canDraw) { return; }
    
    if (!nativeEvent.buttons) return;
    if (!zoom.movingStartPoint) return;
  
    // Получаем текущую позицию курсора (экранные координаты)
    const currentPos = { x: nativeEvent.clientX, y: nativeEvent.clientY };
  
    // Вычисляем дельту движения в экранных координатах
    const deltaScreen = {
      x: currentPos.x - zoom.movingStartPoint.x,
      y: currentPos.y - zoom.movingStartPoint.y,
    };
  
    // Получаем глобальные параметры трансформации
    const { zoomFactor, rotation } = useStore.getState(); // rotation в градусах
  
    // Переводим угол в радианы
    const angle = rotation * Math.PI / 180;
  
    // Применяем обратное вращение к дельте
    // (мы хотим "отменить" поворот глобальной области, чтобы движение было правильно интерпретировано)
    const deltaLocal = {
      x: (deltaScreen.x * Math.cos(-angle) - deltaScreen.y * Math.sin(-angle)) / zoomFactor / convy.scale,
      y: (deltaScreen.x * Math.sin(-angle) + deltaScreen.y * Math.cos(-angle)) / zoomFactor / convy.scale,
    };
  
    // Новое смещение слоя = начальное смещение + вычисленная дельта в локальной системе

    const newLayerOffset = {
      x: zoom.initialLayerOffset.x + deltaLocal.x,
      y: zoom.initialLayerOffset.y + deltaLocal.y,
    };

    const activeLayer = convy.methods.getActiveLayer();
    if (!convy.layerTransformation) {
      convy.layerTransformation = {
        prev: JSON.parse(JSON.stringify(activeLayer.info)),
        type: 'mouse',
      };
    }
    // Обновляем offset активного слоя
    activeLayer.info.offset = newLayerOffset;
    drawing.methods.redraw();
    
  };

  const resetZoom = () => {

    menu.panel.stuck = false;
    
    useStore.setState({ 
      zoomFactor: 1,
      panOffset: { x: 0, y: 0 },
      rotation: 0,
    });

    setTimeout(() => { menu.methods.render(); }, 0);
    

  }

  const simpleZoom = ({ nativeEvent }) => {

    if (!zoom.movingStartPoint) {return;}

    const zoomFactor = useStore.getState().zoomFactor;

    if (
      !ifViewWasChanged()
      ) {

        let newZoomFactor =  zoomFactor;
        if (nativeEvent.button === 2) {
          newZoomFactor *= 0.5;
        } else if (nativeEvent.button === 1) {
          newZoomFactor = 1;
        } else {
          newZoomFactor *= 2;
        }

        newZoomFactor = Math.max(newZoomFactor, 1);
        changeZoomPc(null, newZoomFactor)
      }

  };

  return {
    resetZoom,
    simpleZoom,
    ifViewWasChanged,
    changeZoomPc,
    changeLayerScalePc,
    changeViewStart,
    changeViewMove,
    changeLayerMove,
  };
};

