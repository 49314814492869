// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layer-thumbnail {
  max-width: 50px;
  max-height: 40px;
  margin-right: -3px;
  border: 1px solid #ccc;
  vertical-align: middle;
  user-select: none;           /* Запрещает выделение */
  -webkit-user-select: none;   /* Для Safari */
  -webkit-touch-callout: none; /* Отключает меню на iOS */
  touch-action: none;          /* Отключает стандартные жесты */
}
`, "",{"version":3,"sources":["webpack://./src/components/menus/layer/LayerThumbnail.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,iBAAiB,YAAY,wBAAwB;EACrD,yBAAyB,IAAI,eAAe;EAC5C,2BAA2B,EAAE,0BAA0B;EACvD,kBAAkB,WAAW,gCAAgC;AAC/D","sourcesContent":[".layer-thumbnail {\r\n  max-width: 50px;\r\n  max-height: 40px;\r\n  margin-right: -3px;\r\n  border: 1px solid #ccc;\r\n  vertical-align: middle;\r\n  user-select: none;           /* Запрещает выделение */\r\n  -webkit-user-select: none;   /* Для Safari */\r\n  -webkit-touch-callout: none; /* Отключает меню на iOS */\r\n  touch-action: none;          /* Отключает стандартные жесты */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
