// src/store/convySlice.js


const createConvySlice = (set, get, ref) => {

  // Инициализируем объект convy, если он ещё не создан
  if (!ref.convy) {
    const basicSize = Math.min(window.innerWidth, window.innerHeight * 0.7);
    const styleDimensions = { width: basicSize * 0.98, height: basicSize * 0.98 };
    
    ref.convy = {

      edgeScale: 1.2,

      isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0, 
      basicSize,
      styleDimensions,
      initialStyleDimensions: styleDimensions,

      controlPanelOffset: 420,

      scale: 1,

      mouse: {
        mouseIsPressed: false,
        isStylusHovering: false,
        lastPenActivity: 0,
      },
      keyboard: {
        shiftIsDown: false,
        altIsDown: false,
        controlIsDown: false,
        spaceIsDown: false,
        tabIsDown: false,
        keyZIsDown: false,
        keyXIsDown: false,
        showPalette: false,
      },
      zoom: {
        maxZoomFactor: 20,
        minZoomFactor: 0.4,
      },

      layers: {},
    };
  }

  ref.convy.layerInfo = {
    base: {
      order: 1,
      id: 'base',
      edgeScale: 1,
      // edgeScale: ref.convy.edgeScale,
      visible: true,
      scale: 1,
      offset: { x: 0, y: 0 },
      rotation: 0,
      opacity: 1,
      composition: 'source-over',
      name: 'Base layer',

      shadowOn: false,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      
      filterOn: false,
      filterBlur: 0,
      filterBrightness: 1,
      filterContrast: 1,
      filterSaturate: 1,
    },
  };

  if (!ref.convy.main) {
    ref.convy.main = {
      id: 'main',
      ref: null, // сюда будет записан DOM-элемент канвы
      width: 800,
      height: 800,
    };
    ref.convy.temp = {
      id: 'temp',
      ref: null, // сюда будет записан DOM-элемент канвы
      width: 800,
      height: 800,
    };

    ref.convy.magnifier = {};

    const softCanvas = document.createElement('canvas');
    ref.convy.soft = {
      canvas: softCanvas,
      ctx: softCanvas.getContext('2d'),
    };

    const previewCanvas = document.createElement('canvas');
    ref.convy.preview = {
      canvas: previewCanvas,
      ctx: previewCanvas.getContext('2d'),
    };

    const cloneCanvas = document.createElement('canvas');
    ref.convy.clone = {
      canvas: cloneCanvas,
      ctx: cloneCanvas.getContext('2d'),
    };
    
  }


  ref.convy.methods = {
    getActiveLayer: () => {
      return ref.convy.methods.getLayer(ref.work.methods.getUserGameSets().activeLayerId || 'base');
    },
    getLayer: (layerId)=>{
      const layer = ref.convy.layers[layerId] || ref.convy.methods.createLayer(layerId);
      return layer;
    },

    updateLayerOrders: () => {

      // Пересчитываем order для всех слоев
      const layersObj = ref.convy.layerInfo;
      const updatedLayersArray = Object.keys(layersObj)
          .map(id => ({ id, ...layersObj[id] }))
          .sort((a, b) => b.order - a.order);
      
      // Присваиваем новые целочисленные значения order от большего к меньшему
      updatedLayersArray.forEach((layer, index) => {
        layersObj[layer.id].order = updatedLayersArray.length - index;
      });
       
    },

    createLayer: (layerId, above) => {

      layerId = layerId || ('layer_' + Date.now());

      const info = ref.convy.layerInfo[layerId] || ref.convy.methods.newLayerInfo(layerId, above);
      
      const mainCanvas = ref.convy.main.ref;
      if (!mainCanvas) { return; }
      
      const offCanvas = document.createElement('canvas');
      offCanvas.width = mainCanvas.width * (info.edgeScale || ref.convy.edgeScale);
      offCanvas.height = mainCanvas.height * (info.edgeScale || ref.convy.edgeScale);
      const offCtx = offCanvas.getContext('2d');

      ref.convy.layers[layerId] = { 
        canvas: offCanvas, 
        ctx: offCtx,
        isTransformed: ()=>ref.convy.methods.isLayerTransformed(layerId),
        render: {},
      };

      ref.convy.layers[layerId].id = layerId;
      ref.convy.layers[layerId].info = info;
      return ref.convy.layers[layerId];
    },


    newLayerInfo: (layerId, above) => {
      const info = {};
    
      const layersObj = ref.convy.layerInfo;
    
      // Создаем новый слой
      const newLayer = {
        id: layerId,
        order: above + 0.5,
        visible: true,
        composition: 'source-over',
        opacity: 1,
        rotation: 0,
        scale: 1,
        offset: { x: 0, y: 0 },
        shadowOn: false,
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        filterOn: false,
        filterBlur: 0,
        filterBrightness: 1,
        filterContrast: 1,
        filterSaturate: 1,
        name: `Слой ${Object.keys(layersObj).length + 1}`,
        edgeScale: ref.convy.edgeScale,
        ...info,
      };
    
      // Добавляем новый слой в объект
      layersObj[layerId] = newLayer;
    
      // Пересчитываем order для всех слоев
      const updatedLayersArray = Object.keys(layersObj)
        .map(id => ({ id, ...layersObj[id] }))
        .sort((a, b) => b.order - a.order);
    
      // Присваиваем новые целочисленные значения order от большего к меньшему
      updatedLayersArray.forEach((layer, index) => {
        layer.order = updatedLayersArray.length - index;
        layersObj[layer.id].order = layer.order;
      });
    
      return layersObj[layerId];
    },

    layerMetrics: (layerId, layerStrokes = []) =>{

      const { difficulty, strokesToRender, cacheStroke } = ref.drawing.methods.prepareActiveStrokesForLayer({ layerId, layerStrokes });
      const { difficulty: saveDifficulty } = ref.drawing.methods.prepareActiveStrokesForLayer({ layerId, layerStrokes });

      const icon = 
      difficulty < 40 ? '🟢' :
      difficulty < 500 ? '🟡' :
      '🔴' 

      let text = `📏${layerStrokes.length} | ${strokesToRender.length} ${icon}${difficulty} (${saveDifficulty})`

      const lastStroke = layerStrokes[layerStrokes.length-1];
      const cacheStrokeType = cacheStroke?.note || cacheStroke?.brush || cacheStroke?.type || '';
      const lastStrokeType = lastStroke?.note || lastStroke?.brush || lastStroke?.type || ''

      if (ref.info.user.showDebug) {
        text += ` ${cacheStroke?.time || ''} ${cacheStrokeType}`
      } else {
        text += ` ${lastStrokeType}`;
      }

      return text;

    },


    isLayerTransformed: (layerId)=>{

      const layer = ref.convy.methods.getLayer(layerId);
      const layerInfo = layer.info;
      const transformation = 
      (layerInfo.offset.x || layerInfo.offset.y) 
      || layerInfo.scale !== 1 
      || layerInfo.rotation;

      const hasTransformation = transformation ? true : false;

      if (hasTransformation && !layer.wasFirstTransformed) {
        layer.wasFirstTransformed = Date.now();
      } else if (!hasTransformation) {
        delete layer.wasFirstTransformed;
      }
      return hasTransformation;

    },

    fastKeyLayer: (keyNumber) => {
      const targetIndex = keyNumber === 0 ? 9 : keyNumber - 1;
      const layersArray = Object.keys(ref.convy.layerInfo)
        .map(id => ({ id, ...ref.convy.layerInfo[id] }))
        .filter(layerData => !layerData.deleted)
        .sort((a, b) => a.order - b.order);
  
      if (targetIndex >= 0 && targetIndex < layersArray.length) {
        const targetLayer = layersArray[targetIndex];
  
        ref.work.methods.saveUserGameSets({ activeLayerId: targetLayer.id });
        ref.menu.methods.showTemporaryHint(
          window.t('layer_menu.choosen_layer', { layerName: targetLayer.name }),
          { force: true }
        );
        ref.menu.methods.render();
      }
    },

    toggleLayerVisibilityByIndex: (index) => {
      const layersArray = Object.keys(ref.convy.layerInfo)
        .map(id => ({ id, ...ref.convy.layerInfo[id] }))
        .filter(layerData => !layerData.deleted)
        .sort((a, b) => a.order - b.order); 

      if (index >= 0 && index < layersArray.length) {
        const targetLayer = layersArray[index];
        const layerId = targetLayer.id;
        const layerInfo = ref.convy.layerInfo[layerId];

        const prev = JSON.parse(JSON.stringify(layerInfo));
        layerInfo.visible = !layerInfo.visible;
        ref.history.methods.transformationStroke({
          layerId, 
          prev,
          info: layerInfo,
          stroke: {
            note: 'layer visibility',
            tech: true,
          },
        });

        ref.drawing.methods.redraw(); // Перерисовываем холст

        ref.menu.methods.showTemporaryHint(
          layerInfo.visible
            ? window.t('layer_menu.visible', { layerName: layerInfo.name })
            : window.t('layer_menu.invisible', { layerName: layerInfo.name }),
          { force: true }
        );
        ref.menu.methods.appleHaptic?.('light'); // Виброотклик
      }
      // Если слоя нет, ничего не делаем
    },

  };

  return {
    // Глобальные параметры трансформации для всего холста
    activeLayerId: 'base',

    mousePosition: { x: 0, y: 0 },
    mouseDetected: false,
    keyPressed: 0,

    panOffset: { x: 0, y: 0 },
    zoomFactor: 1,
    rotation: 0, // поворот всего холста в градусах

  };
};

export default createConvySlice;
