// OpacityControl.js
import React, { useState, useEffect } from 'react';
import chroma from 'chroma-js';
import CircleSwitch from "./CircleSwitch/CircleSwitch.js";
import ColorIndicator from './ColorIndicator'; 
import { filterPosition } from './Throttler/filterPosition.js';
import useStore from '../../../store';

const OpacityControl = ({ 
  store,
  icon,
  available,
  onContextMenu,
}) => {

  const { color, gradientColor, activeTool } = useStore.getState();

  const { brush, menu } = store.ref;
  const { forceRender } = menu.methods;
  const { setTheColorFun, changeTool } = brush.methods;

  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 

  function ensure8DigitHex(inputColor) {
    if (typeof inputColor !== 'string') {
      return '#000000FF'; // fallback
    }
  
    const color = inputColor.trim();
  
    if (color.startsWith('#')) {
      const hexStr = color.slice(1);
      if (/^[0-9A-Fa-f]{8}$/.test(hexStr)) {
        return '#' + hexStr.toUpperCase(); 
      }
      if (/^[0-9A-Fa-f]{6}$/.test(hexStr)) {
        return '#' + hexStr.toUpperCase() + 'FF';
      }
    }
  
    try {
      const c = chroma(color);
      const alpha8 = Math.round(c.alpha() * 255)
        .toString(16)
        .padStart(2, '0')
        .toUpperCase();
      const hexNoAlpha = c.hex().slice(1).toUpperCase();
      return `#${hexNoAlpha}${alpha8}`;
  
    } catch (err) {
      return '#000000FF';
    }
  }

  const startChangingOpacity = (event) => {
    event.preventDefault();
    if (event?.button === 2) { return; }
    brush.fast.isChangingOpacity = true;
    const x = event.clientX || event.touches?.[0]?.clientX;
    const y = event.clientY || event.touches?.[0]?.clientY;
    setClickPosition({ x, y });
    setPointerPosition({ x, y });
    // Сохраняем данные в brush.fast
    brush.fast.startTime = Date.now();
    brush.fast.startTouchPoint = event?.touches?.[0] || event;
  };

  useEffect(() => {
    const handlePointerMove = (event) => {
      event.preventDefault();
      const workColorRaw = brush.gradientPalette ? gradientColor : color;
      
      if (event?.button === 2) { return; }
      if (!brush.fast.isChangingOpacity) return;
  
      const position = {
          x: event.clientX || event.touches?.[0]?.clientX,
          y: event.clientY || event.touches?.[0]?.clientY,
      };
  
      const filteredPosition = filterPosition(position, 'pointerPosition', 100);
      const diffY = pointerPosition.y - filteredPosition.y;
      setPointerPosition(filteredPosition);
      
      if (diffY === 0) return;
  
      let currentHexColor = ensure8DigitHex(workColorRaw);
      const alphaHex = currentHexColor.slice(-2);
      const currentAlphaHex = parseInt(alphaHex, 16);
      
      let newAlphaHex = currentAlphaHex - Math.round(diffY);
      newAlphaHex = Math.max(0, Math.min(255, newAlphaHex));
      
      const baseColor = currentHexColor.slice(0, -2);
      const newAlphaHexStr = newAlphaHex.toString(16).padStart(2, '0');
      const newColor = baseColor + newAlphaHexStr;
  
      setTheColorFun(newColor);
      brush.fast.hasMoved = true;
    };
  
    const handlePointerUp = (event) => {
      event.preventDefault();
      if (event?.button === 2) { return; }
      brush.fast.isChangingOpacity = false;
      if (brush.fast.startTouchPoint && Date.now() - brush.fast.startTime < 350) {
        changeTool("effect");
      }
      forceRender();
    };
  
    if (brush.fast.isChangingOpacity) {
      document.addEventListener('mousemove', handlePointerMove);
      document.addEventListener('mouseup', handlePointerUp);
      document.addEventListener('touchmove', handlePointerMove);
      document.addEventListener('touchend', handlePointerUp);
    }
  
    return () => {
      document.removeEventListener('mousemove', handlePointerMove);
      document.removeEventListener('mouseup', handlePointerUp);
      document.removeEventListener('touchmove', handlePointerMove);
      document.removeEventListener('touchend', handlePointerUp);
    };
  
  }, [brush.fast.isChangingOpacity]);
  
  const theAlpha = chroma(color).alpha();
  let alpha = `${Math.round(theAlpha * 100)}%`;
  if (theAlpha <= 0.1) {
    alpha += ` (${ensure8DigitHex(color).slice(-2)})`;
  }
  
  return (
    <div>
      <div
        onContextMenu={onContextMenu}
        onMouseDown={startChangingOpacity}
        onTouchStart={startChangingOpacity}
        style={{
          cursor: 'pointer', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50px',
          height: '50px',
        }}
      >
        <CircleSwitch
          icon={icon}
          available={available}
          isActive={activeTool === "effect"}
          menu={true}
        />
      </div>
      {brush.fast.isChangingOpacity && (
        <ColorIndicator
          store={store}
          position={pointerPosition}
          clickPosition={clickPosition}
          alpha={alpha}
        />
      )}
    </div>
  );
};

export default OpacityControl;
