// src/App.jsx
import React, { useEffect } from 'react';
import RootComponent from './components/RootComponent';
import './utils/styles/custom-slider.css';
import './utils/styles/add-scroll.css';
import './locales/i18n';
import { applyThemeColors, applyAdditionalShades } from './utils/styles/themeColors';
import { ContextMenuEmulator } from './utils/interface/contextMenuEmulator';
import Lobby from './components/lobby/Lobby';


function App() {
  // Применяем тему из параметров Telegram (если они есть)
  applyThemeColors(window.Telegram?.WebApp?.themeParams || {});
  applyAdditionalShades('--tg-theme-secondary-bg-color', 1.5);
  applyAdditionalShades('--tg-theme-button-color', 1.2);
  
  // Примечание: инициализация темы происходит в puzzleSlice,
  // когда компонент PuzzleBoard монтируется

  useEffect(() => {
    const preventTouch = (e) => {
      if (e.target.closest('.touch-active')) { return; }
      if (e.target.matches('input[type="range"]')) { return; }
      if (e.target.closest('.premium-menu')) { return; }
      if (e.target.closest('.settings-menu')) { return; }
      e.preventDefault();
    };

    document.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      document.removeEventListener('touchmove', preventTouch)
    };

  }, []);

  useEffect(() => {
    const contextMenuEmulator = new ContextMenuEmulator({
      delay: 500,    // Adjust the delay as needed
      threshold: 10  // Adjust the threshold as needed
    });
    contextMenuEmulator.init();
    // Cleanup on unmount
    return () => {
      contextMenuEmulator.destroy();
    };
  }, []);

  const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
  const testing = REACT_APP_VERSION === 'dev';
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const startParam = urlParams.get('tgWebAppStartParam');

  return (
    <>
     {(startParam || testing) ?  (<RootComponent />)  : (<Lobby />) }
    </>
  );
}

export default App;
